<template>

	<div id="lore">

		<div class="lore-login">

			<div class="lore-login-box">

				<div class="lore-login-top">

					<div class="lore-login-top-left">

						<!-- <div class="lore-login-lang" @click="golanguage"></div> -->

						<div class="lore-login-service"></div>

					</div>

					<div class="lore-login-top-right" @click="goindex">

						<svg class="svg-inline--fa fa-xmark fa-w-10 login-close" aria-hidden="true" data-prefix="fas"
							data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">

							<path class="" fill="currentColor"
								d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z">
							</path>

						</svg>

					</div>

				</div>

				<div class="lore-login-logo">

					<img src="/static/image/big-logo.png" alt="" />

				</div>

				<div class="lore-login-main">

					<div class="lore-login-tab">

						<div class="lore-login-tab-item login-tab-act">会员登入</div>

						<div class="lore-login-tab-item" @click="gopage('/joinus')">注册账号</div>

					</div>

					<form class="lore-login-form">

						<div class="lore-login-field username">

							<input id="username" type="text" v-model="account" name="username" placeholder="账号"
								maxlength="15" />

							<div class="unicode">
								<span>@55</span>
							</div>

						</div>

						<div class="lore-login-field password">

							<input id="passwd" name="passwd" v-model="password" :type="isShowPass ? 'text' : 'password'"
								placeholder="密码" />

							<div class="unicode">
								<div :class="['password-toggle', isShowPass ? 'password-toggle-act' : '']"
									@click="isShowPass = !isShowPass"></div>
							</div>

						</div>

						<div class="lore-login-remember">

							<div class="lore-login-remember-check" @click="toggleRemember">

								<!-- 复选框样式切换 -->
								<div :class="['lore-login-remember-checkbox', isRemember ? 'checkbox-act' : '']"></div>
								<span>储存账号</span>

							</div>

							<!-- <div class="password-forget" @click="gopage('/forget-password')">忘记密码？</div> -->
							<div class="password-forget" >忘记密码？</div>


						</div>

						<div class="lore-login-submit" @click="login()">登录</div>

					</form>

					<div class="extra-btns-wrap">

						<a class="go-app" @click="gopage(false)" target="_blank">原生app下载</a>
						<a class="go-ubbrowser" @click="openlocalurl('/ub.html')" target="_blank">{{$t('寰宇浏览器')}}</a>
						
					</div>

				</div>

				<div class="login-footer">

					<a class="login-footer-btn go-home" href="/#/">
						<i class="login-footer-icon"></i> 随便逛逛</a>

					<a class="login-footer-btn go-pc" href="/?pc=1">
						<i class="login-footer-icon"></i> 去电脑版</a>

				</div>

			</div>

		</div>

		<!-- 验证码 -->
		<div class="captcha-pop" v-show="showcheckyzm">

			<div class="captcha-wrapper" style="display: block;">

				<svg @click="showcheckyzm = false" class="svg-inline--fa fa-circle-xmark fa-w-16 captcha-close"
					aria-hidden="true" data-prefix="far" data-icon="circle-xmark" role="img"
					xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">

					<path class="" fill="currentColor"
						d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z">
					</path>

				</svg>


				<div class="captcha-title">请进行验证</div>

				<div class="captcha-field field-realname">

					<input id="real_name" name="real_name" class="captcha-field-input" type="text" maxlength="30"
						autocomplete="off">

				</div>

				<div class="captcha-field field-checkcode">

					<input id="rmNum" name="rmNum" placeholder="请输入验证码" class="captcha-field-input" type="text"
						maxlength="6" autocomplete="off"><img class="captcha-img"
						src="https://www810046020746552.002tgpd.com:5569/infe/verify/macpic?SR=13a6e88693f0ccae7af4">

				</div>

				<div class="clearfix">

					<div class="captcha-img-hint">
						<svg class="svg-inline--fa fa-rotate-right fa-w-16 captcha-img-refresh" aria-hidden="true"
							data-prefix="fas" data-icon="rotate-right" role="img" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512">
							<path class="" fill="currentColor"
								d="M468.9 32.11c13.87 0 27.18 10.77 27.18 27.04v145.9c0 10.59-8.584 19.17-19.17 19.17h-145.7c-16.28 0-27.06-13.32-27.06-27.2c0-6.634 2.461-13.4 7.96-18.9l45.12-45.14c-28.22-23.14-63.85-36.64-101.3-36.64c-88.09 0-159.8 71.69-159.8 159.8S167.8 415.9 255.9 415.9c73.14 0 89.44-38.31 115.1-38.31c18.48 0 31.97 15.04 31.97 31.96c0 35.04-81.59 70.41-147 70.41c-123.4 0-223.9-100.5-223.9-223.9S132.6 32.44 256 32.44c54.6 0 106.2 20.39 146.4 55.26l47.6-47.63C455.5 34.57 462.3 32.11 468.9 32.11z">
							</path>
						</svg>
						<span>点击上图更新验证码显示</span>
					</div>

				</div>

				<div class="captcha-img-submit" @click="checkyzm()">确认</div>

			</div>
			<div></div>
		</div>


		<!-- 公共弹窗 -->
		<GlobalModal ref="globalmodal"></GlobalModal>

	</div>

</template>
<script>
	import {
		Dialog
	} from 'vant';
	export default {
		name: "login",
		data() {

			return {

				registerInfo: {},
				loginInfo: {},
				imgLis: ["2PYL", "6AQ5", "8PHD", "21I7", "69HM", "ACWA", "DUZ7", "IY98", "K647", "M52T", "NY52", "NZFA",
					"SN76", "SP4D", "VAEO", "YFQM", "ZZU5", "7GQT", "LFW3", "NU2T", "UAE3"
				],
				index: 0,
				infoType: 0, //0 是登陆 1是注册
				psw1: true,
				psw2: true,
				psw3: true,
				pid: "",
				// 新加
				// 登录
				isRemember: false,

				isShowPass: false,
				// 注册
				isShowRePass: false,

				isSHowSurePass: false,
				// 连动注册
				lianIndex: "no",
				// 没有连动
				showNoAC: false,

				account: '',

				password: '',

				showcheckname: false, // 真实姓名功能

				showcheckyzm: false, // 验证码验证功能

			}

		},

		created() {

			let that = this

			var query = that.$route.query

			if (query.type) {
				// console.log(query)

				that.infoType = query.type

			}

			if (query.pid) {

				that.pid = query.pid

			}

			that.changIndex();

		},

		methods: {
			
			openlocalurl(url) {
			
				window.location.href = url; // 访问 public 文件夹中的 myfile.html
			
			},
			
			back(){
				
			    this.$router.isBack = true
			    this.$router.replace({
			        path:'/login',
			        query: {
			            redirect: this.$route.query.redirect
			        }
			    })
				
			},
			
			toReg(){
			    this.$router.push({
			        path:'/reg',
			        query:{
			            redirect: this.$route.query.redirect
			        }
			    })
			},
			
			// 切换复选框状态
			toggleRemember() {
				this.isRemember = !this.isRemember;
			},
			
			gopage(url) {
			
				let that = this;
			
				if (url == false) {
			
					Dialog.alert({
						title: '提示🔔',
						message: '当前暂未开放',
					}).then(() => {
			
						// localStorage.clear();
						// sessionStorage.clear();
						// router.replace('/login');
			
					});
					return;
			
				}
			
				if (url == this.$route.fullPath) {
					return;
				}
			
				that.$router.push({
					path: url
				});
			
			},
			
			goindex() {
				this.$router.push({
					path: '/'
				});
			},

			golanguage() {
				this.$router.push({
					path: '/language'
				});
			},

			changPsw(name) {
				this[name] = !this[name]
			},

			checkyzm() {

				const title = "Custom Title";

				const message = "This is a custom message for the modal.";

				const confirm = "confirm";

				this.$refs.globalmodal.open(title, message, confirm); // 传入标题和消息

			},

			changInfoType(type) {

				let that = this
				if (that.infoType == type) {
					return
				}
				that.infoType = type
				that.changIndex()
				that.loginInfo = {}
				that.registerInfo = {}
				this.psw1 = true
				this.psw2 = true
				this.psw3 = true

			},
			changIndex() {

				this.index = parseInt(20 * Math.random())

			},

			login() {

				let that = this;

				if (!that.account) {

					that.$refs.globalmodal.open('讯息', '请输入账号!!', '确定'); // 传入标题和消息
					return;

				}
				
				if (!that.password) {

					that.$refs.globalmodal.open('讯息', '请输入密码!!', '确定'); // 传入标题和消息
					return;

				}

				// 如果选中记住账号，将账号存储到 localStorage
				if (that.isRemember) {
					localStorage.setItem('account', that.account);
					// localStorage.setItem('password', that.password);
				} else {
					localStorage.removeItem('account');
					// localStorage.removeItem('password');
				}

				var params = {
					name: that.account,
					password: that.password,
					captcha: '',
					key: ''
				};

				that.$parent.showLoading();

				that.$apiFun.post('/api/auth/login', params).then((res) => {
					
					if (res.code !== 200) {
						
						that.$refs.globalmodal.open('讯息', res.message, '确定'); // 传入标题和消息
						return;
						
					}
					
					if (res.code === 200) {
						
						localStorage.setItem('expires_at', res.data.expires_at);
						
						localStorage.setItem('expires_in', res.data.expires_in);
						
						localStorage.setItem('token_type', res.data.token_type);
						
						localStorage.setItem("token", res.data.access_token)
						
						that.$store.commit("changToken",res.data.access_token)
						
						that.$parent.getUserInfo() //获取用户信息
						
						that.$parent.goNav("/")
						
					}
					
					that.$parent.hideLoading()
					
				})

			},
		
		},
		
		mounted() {
			
			let that = this;
			// 页面加载时检查 localStorage 是否有存储账号
			const savedUsername = localStorage.getItem('account');
			if (savedUsername) {
				that.account = savedUsername;
				// that.password = localStorage.getItem('password');
				that.isRemember = true; // 如果有存储，自动勾选记住账号
			}
			
		},
		
		updated() {
			let that = this
		},
		
		beforeDestroy() {},
	}
</script>
<style lang="scss" scoped>
	#lore {
		height: 100vh;
	}

	.lore-login {
		
		background-color: #f9faff;
		height: 100%;

		.lore-login-box {
			
			height: 100%;
			padding: 0.6rem 4% 0;
			position: relative;
			background: #fff;
			
			.lore-login-top {
				padding-left: 0.3rem;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.lore-login-top-left {
					display: flex;
					align-items: center;

					.lore-login-lang {
						width: 1.25rem;
						height: 1.25rem;
						background-image: url("/static/image/lang.png");
						background-repeat: no-repeat;
						background-size: 100%;
						background-position: 0 0;
					}

					.lore-login-service {
						
						// margin-left: 1.5625rem;
						
						display: inline-block;
						width: 1.25rem;
						height: 1.25rem;
						background-image: url("/static/image/service.png");
						background-repeat: no-repeat;
						background-size: 100%;
						background-position: 0 0;
					}
				}

				.lore-login-top-right {
					display: flex;
					align-items: center;

					.login-close {
						color: #a4aabb;
						right: 0.75rem;
						width: 1.875rem;
						height: 1.875rem;
					}
				}
			}

			.lore-login-logo {
				display: flex;
				justify-content: center;

				img {
					width: 12.5rem;
					height: 3.875rem;
				}
			}

			.lore-login-main {
				border: 1px solid #fff;
				border-radius: 0.9375rem;
				background: rgba(243, 247, 255, 0.6);
				box-shadow: 0 1px 0.1875rem rgba(75, 106, 184, 0.2);
				padding: 1.875rem 1.375rem 2.5rem;
				margin-top: 0.75rem;

				.lore-login-tab {
					display: flex;
					padding-bottom: 1.25rem;
					position: relative;

					.lore-login-tab-item {
						width: 50%;
						height: 1.25rem;
						line-height: 1.25rem;
						font-size: 0.9375rem;
						font-weight: 700;
						text-align: center;
						text-decoration: none;
						color: #8394c4;
					}

					.login-tab-act {
						color: #2472fc;
					}

					&::before {
						content: "";
						position: absolute;
						top: -0.25rem;
						left: 50%;
						transform: translateX(-50%);
						width: 1px;
						height: 1.6875rem;
						background: #e9e9e9;
					}
				}

				.lore-login-form {
					.lore-login-field {
						box-shadow: 0 1px 0.1875rem rgba(75, 106, 184, 0.2);
						position: relative;
						margin-top: 0.625rem;
						border-radius: 1.25rem;
						background: #ffffff;

						&::before {
							content: "";
							position: absolute;
							top: 0.8125rem;
							left: 1.125rem;
							width: 1rem;
							height: 1rem;
							background-repeat: no-repeat;
							background-size: cover;
							background-position: 0 0;
						}

						input {
							background: #fff !important;
							color: #3b3b3b;
							box-sizing: border-box;
							border: 0;
							border-radius: 1.25rem;
							padding: 0 4.375rem 0 2.875rem;
							width: 100%;
							height: 2.6875rem;
							outline: 0;
							font-size: 0.875rem;
						}

						input:placeholder-shown {
							text-overflow: ellipsis;
						}

						.unicode {
							color: #a4aabb;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							right: 1.125rem;
							font-size: 0.875rem;

							.password-toggle {
								display: inline-block;
								vertical-align: middle;
								width: 1.625rem;
								height: 1.625rem;
								background-image: url("/static/image/eye-close.png");
								background-repeat: no-repeat;
								background-size: cover;
								background-position: 50% 50%;
							}

							.password-toggle-act {
								background-image: url("/static/image/eye-open.png");
							}
						}
					}

					.username {
						&::before {
							background-image: url("/static/image/person-icon.png");
						}
					}

					.password {
						&::before {
							background-image: url("/static/image/suo.png");
						}
					}

					.lore-login-remember {
						margin-top: 0.9375rem;
						padding: 0 1.1875rem;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.lore-login-remember-check {
							color: #aaa;
							display: flex;
							justify-content: flex-start;
							align-items: center;

							.lore-login-remember-checkbox {
								display: inline-block;
								vertical-align: middle;
								margin-right: 0.375rem;
								width: 1.0625rem;
								height: 1.0625rem;
								background-repeat: no-repeat;
								background-size: 100%;
								background-position: 0 0;
								background-image: url("/static/image/check.png");
							}

							.checkbox-act {
								background-image: url("/static/image/checked.png");
							}

							span {
								font-size: 0.875rem;
							}
						}

						.password-forget {
							color: #ef423a;
							font-size: 0.875rem;
						}
					}

					.lore-login-submit {
						border-radius: 10rem;
						background: #2472fc;
						color: #fff;
						margin-top: 1.875rem;
						padding: 0.9375rem 0 0.875rem;
						text-align: center;
						font-size: 0.875rem;
					}
				}

				.extra-btns-wrap {
					margin-top: 0.625rem;
					font-size: 0.875rem;

					.go-app {
						border-radius: 10rem;
						background: #8394c4;
						color: #fff;
						margin-right: 0.625rem;
						display: inline-block;
						vertical-align: middle;
						box-sizing: border-box;
						padding: 0.8125rem 0 0.875rem;
						width: calc(50% - 0.3125rem);
						text-align: center;
						text-decoration: none;
					}

					.go-ubbrowser {
						border-radius: 10rem;
						background: #8394c4;
						color: #fff;
						display: inline-block;
						vertical-align: middle;
						box-sizing: border-box;
						padding: 0.8125rem 0 0.875rem;
						width: calc(50% - 0.3125rem);
						text-align: center;
						text-decoration: none;
					}
				}
			}

			.login-footer {
				border-radius: 0.625rem 0.625rem 0 0;
				background-color: #fff;
				box-shadow: 0 -0.125rem 2.0625rem rgba(75, 106, 184, 0.08);
				position: fixed;
				bottom: 0;
				right: 0;
				left: 0;
				display: flex;
				height: 2.875rem;
				align-items: center;

				&::before {
					content: "";
					display: inline-block;
					vertical-align: middle;
					height: 100%;
				}

				.login-footer-btn {
					flex: 1 1 50%;
					vertical-align: middle;
					box-sizing: border-box;
					padding: 0.3125rem;
					overflow: hidden;
					font-size: 0.75rem;
					text-align: center;
					text-overflow: ellipsis;
					text-decoration: none;
					white-space: nowrap;
					color: #3b3b3b;

					.login-footer-icon {
						display: inline-block;
						vertical-align: middle;
						margin-right: 0.25rem;
						width: 1.6875rem;
						height: 1.6875rem;
						background-repeat: no-repeat;
						background-size: cover;
						background-position: 0 0;
					}
				}

				.go-home {
					.login-footer-icon {
						background-image: url("/static/image/suibian.png");
					}
				}

				.go-pc {
					border-left: 1px solid #e9e9e9;

					.login-footer-icon {
						background-image: url("/static/image/gopc.png");
					}
				}
			}
		}
	}

	.lore-register {
		background: #f3f3f3;

		.lore-register-top {
			background: #f4f6fa;
			border-bottom: 1px solid #fff;
			height: 2.9rem;
			position: relative;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;

			.lore-register-close {
				position: absolute;
				top: 50%;
				right: 1.2rem;
				transform: translateY(-50%);
				background: url("/static/image/close.png") 0 0 no-repeat;
				width: 1rem;
				height: 1rem;
				background-size: cover;
			}

			img {
				width: 7.8rem;
			}
		}

		.lore-register-tab {
			position: relative;
			padding-top: 1.2rem;
			display: flex;
			justify-content: center;
			align-items: center;

			.lore-register-tab-item {
				width: 50%;
				height: 2.5rem;
				line-height: 1.2rem;
				color: #a4aabb;
				font-size: 1.1rem;
				text-align: center;
				text-decoration: none;
			}

			.register-tab-act {
				color: #3b3b3b;
				position: relative;

				&::after {
					position: absolute;
					bottom: -1px;
					left: 50%;
					transform: translateX(-50%);
					z-index: 1;
					width: 0.7rem;
					height: 0.6rem;
					content: "";
					background: url("/static/image/tab-top.png") 0 0 no-repeat;
					background-size: cover;
					margin: 0 auto;
				}
			}
		}

		.lore-register-form {
			background: #fff;
			border: 1px solid #e9e9e9;
			border-radius: 4px;
			padding: 1.2rem 1.3rem 1.5rem;
			margin: 0 1.7rem;
			position: relative;

			.info-title {
				margin-top: 0;
				padding-left: 5px;
				border-left: 3px solid #3b3b3b;
				font-weight: 700;
				font-size: 1.1rem;
				color: #323747;
				margin-bottom: 1rem;
			}

			.field {
				position: relative;
				margin: 0 auto 12px;
				width: 98%;
				padding-left: 1rem;
				box-sizing: border-box;
				color: #979dae;

				.star {
					color: #ef423a;
					display: block;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					width: 0.6rem;
					line-height: 1rem;
					font-size: 0.875rem;
				}

				.base-input {
					background: #fff;
					border: 1px solid #e9e9e9;
					border-radius: 20px;
					position: relative;
					width: 100%;

					input {
						color: #1f285a;
						background: transparent;
						padding-left: 3%;
						width: 100%;
						height: 2.3rem;
						font-size: 0.875rem;
						outline: none;
						border: 0;
						border-radius: 0;
						box-sizing: border-box;
					}

					.text-unicode {
						position: absolute;
						top: 50%;
						right: 4.5rem;
						margin-top: -1rem;
						height: 2rem;
						line-height: 2rem;
						color: #a4aabb;
						font-size: 0.875rem;
					}

					.icon {
						right: 0.2rem;
						background-image: url("/static/image/dui.png");
						background-size: contain;
						position: absolute;
						top: 50%;
						margin-top: -1rem;
						height: 2rem;
						width: 2.2rem;
					}

					.icon-success {
						background-image: url("/static/image/dui-success.png");
					}

					.icon-unicode {
						width: 2rem;
						right: 2.2rem;
						background: url("/static/image/wenhao.png") 0 0 no-repeat;
						background-size: contain;
					}

					.noEye {
						right: 0.5rem;
						background: url("/static/image/eye-close.png") 0 0 no-repeat;
						background-size: contain;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						width: 2rem;
						height: 2rem;
						cursor: pointer;
					}

					.eye {
						background: url("/static/image/eye-open.png") 0 0 no-repeat;
						background-size: contain;
					}
				}
			}

			.separate-line {
				border-color: #e9e9e9;
				border-top: 1px solid #dadada;
				margin: 1rem 0 0.7rem;
			}

			.wallet-wrap {
				border-color: #e9e9e9;
				background: #fff;
				box-sizing: border-box;
				margin: 0 auto 0.6rem;
				border: 1px solid #ededed;
				border-radius: 4px;
				padding: 1rem 0.6rem;
				width: 98%;

				.wallet-title-wrap {
					font-size: 0.7rem;
					font-weight: 700;
					text-align: center;

					.wallet-title-bg {
						position: relative;

						&::before {
							content: "";
							position: absolute;
							top: 50%;
							left: 0;
							width: 100%;
							height: 1px;
							background: #1f285a;
						}

						.wallet-title-content {
							color: #1f285a;
							background: #fff;
							position: relative;
							display: inline-block;
							padding: 0 1.5rem;
							max-width: 70%;
							word-break: break-all;
						}
					}

					.wallet-subtitle {
						color: #4a5c95;
						margin-top: 0.6rem;
					}

					.wallet-intro-wrap {
						.wallet-intro {
							color: #1f285a;
							padding: 0.2rem 1rem 0;
							font-size: 0.7rem;
							text-align: left;
							font-weight: normal;

							p {
								padding: 0.3rem 0;
							}
						}

						.wallet-known {
							color: #fff;
							background: linear-gradient(#4eaafc, #1c5cfb);
							float: right;
							margin-top: 0.6rem;
							width: 8.4rem;
							max-width: 100%;
							min-height: 1.7rem;
							border: 0;
							border-radius: 4px;
							outline: none;
							font-size: 0.7rem;
						}
					}

					.clearfix {
						&::before {
							content: "";
							display: table;
						}

						&::after {
							clear: both;
							content: "";
							display: table;
						}
					}
				}

				.wallet-radio-wrap {
					padding-top: 0.3rem;
					font-size: 0.7rem;
					border-radius: 4px;
					text-align: center;

					.wallet-radio-item {
						color: #1f285a;
						margin: 0.6rem 0.3rem 0;
						display: inline-block;
						vertical-align: top;
						position: relative;
						max-width: 3.6rem;
						min-width: 2.4rem;
						min-height: 2.4rem;
						cursor: pointer;
						user-select: none;

						.wallet-radio-input {
							display: none;
						}

						.wallet-bank-icon {
							border-color: #e9e9e9;
							position: absolute;
							top: 0;
							left: 50%;
							margin-left: -1.2rem;
							box-sizing: border-box;
							border: 2px solid #ededed;
							border-radius: 50%;
							width: 2.4rem;
							height: 2.4rem;
							background-size: 1.4rem;
							background-color: #fff;
							background-repeat: no-repeat;
							background-position: 50%;
							background-image: url("/static/image/liandong.png");
						}

						.cg {
							background-image: url("/static/image/wallet_cgpay.png");
						}

						.os {
							background-image: url("/static/image/wallet_ospay.png");
						}

						.wallet-bank-label-wrap {
							color: #1f285a;
							position: relative;
							padding-top: 2rem;

							.wallet-bank-label {
								background: hsla(0, 0%, 100%, 0.5);
								font-weight: 700;
							}
						}
					}

					.active {
						.wallet-bank-icon {
							&::before {
								background: #2472fc;
								content: "\2713";
								position: absolute;
								top: -0.2rem;
								left: -0.2rem;
								border-radius: 50%;
								width: 1rem;
								height: 1rem;
								color: #fff;
							}
						}

						.wallet-bank-label-wrap {
							color: #2472fc;
						}
					}
				}

				.walletform {
					position: relative;
					padding-top: 1rem;

					.wallet-verify-wrap {
						text-align: right;

						.wallet-noaccount {
							color: #2472fc;
							border-right-color: rgba(36, 114, 252, 0.4);
							display: inline-block;
							border-right: 1px solid rgba(64, 78, 103, 0.4);
							padding-right: 0.6rem;
							font-size: 0.6rem;
							font-weight: 700;
							text-decoration: underline;
						}

						.field {
							width: 50%;
							display: inline-block;
							padding-left: 0.3rem;
							margin-bottom: 0.7rem;
						}

						.walletform-check-wrap {
							border: 1px solid transparent;

							.walletform-check {
								color: #fff;
								background: linear-gradient(#4eaafc, #1c5cfb);
								outline: 0;
								display: inline-block;
								text-align: center;
								width: 100%;
								min-height: 1.7rem;
								border: 0;
								border-radius: 4px;
								font-size: 0.7rem;
							}
						}
					}
				}
			}

			.join-form-agree {
				color: #a4aabb;
				margin-bottom: 1.3rem;
				line-height: 1.2rem;
				font-size: 0.875rem;

				.agree {
					margin-right: 0.6rem;
				}

				a {
					color: #3b3b3b;
				}
			}
		}

		.join-footer-content {
			padding: 0 1.8rem 1.2rem;
			margin-top: 1.3rem;

			#joinmem {
				ul {
					padding: 1.1rem 1.6rem;
					list-style-type: none;

					li {
						color: #a4aabb;
						margin-bottom: 0.6rem;
						padding: 0;
						font-size: .95rem;
					}
				}
			}
		}
	}


	.captcha-pop {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		background: rgba(0, 0, 0, .7);
	}

	.captcha-wrapper {
		position: relative;
		top: 8%;
		box-sizing: border-box;
		margin: 0 auto;
		border-radius: .625rem;
		padding: 2.1875rem .9375rem 1.25rem;
		width: 18.5rem;
		min-height: 9.375rem;
		background: #fff;
		background-color: #f5f7ff;
		color: #000;
		text-align: center;
	}

	.captcha-wrapper .captcha-close {
		position: absolute;
		bottom: -25%;
		left: 50%;
		margin-left: -.9375rem;
		width: 1.875rem;
		height: 1.875rem;
		color: #fff;
		font-size: 1.875rem;
	}

	.captcha-wrapper .captcha-title {
		margin: 0;
		padding-bottom: .875rem;
		color: #1e2745;
		font-size: .9375rem;
		font-weight: 700;
		font-family: STHeiti, Microsoft YaHei, 微软雅黑, SimSun, 宋体, arial, sans-serif;
	}

	.captcha-wrapper .captcha-field {
		position: relative;
		box-sizing: border-box;
		margin-bottom: .375rem;
		border: 1px solid #d1d3d8;
		border-radius: .25rem;
		height: 2.75rem;
		background: #fff;
	}

	[lang=zh_hk] .captcha-wrapper .captcha-field .captcha-field-placeholder,
	[lang=zh_cn] .captcha-wrapper .captcha-field .captcha-field-placeholder {
		font-size: .9375rem;
		font-family: STHeiti, Microsoft YaHei, 微软雅黑, SimSun, 宋体, arial, sans-serif;
	}

	.captcha-wrapper .captcha-field .captcha-field-placeholder {
		position: absolute;
		top: .875rem;
		left: 1rem;
		max-width: 9.0625rem;
		height: .875rem;
		line-height: .875rem;
		overflow: hidden;
		color: #9da4bc;
		font-size: .75rem;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.captcha-wrapper .captcha-field .captcha-field-input {
		box-sizing: border-box;
		border: none;
		padding-left: .875rem;
		width: 100%;
		height: 2.625rem;
		background-color: transparent;
		outline: none;
	}


	button,
	input,
	optgroup,
	select,
	textarea {
		margin: 0;
		line-height: 1.15;
		font-size: 100%;
		font-family: inherit;
	}

	.captcha-wrapper .captcha-field .captcha-img {
		position: absolute;
		top: .125rem;
		right: .25rem;
		width: 6.375rem;
		height: 2.375rem;
		background: url(https://www810046020746552.002tgpd.com:5569/infe/verify/macpic?SR=13a6e88693f0ccae7af4) 0 0 no-repeat;
		background-size: contain;
	}

	.clearfix:after {
		content: "";
		clear: both;
		display: block;
	}

	.captcha-wrapper .captcha-img-hint {
		float: right;
		padding: .1875rem 0 .75rem;
		max-width: 15.625rem;
		line-height: 1.1875rem;
		color: #8088a1;
		font-size: .75rem;
		letter-spacing: .6px;
		text-align: left;
		font-family: STHeiti, Microsoft YaHei, 微软雅黑, SimSun, 宋体, arial, sans-serif;
	}

	.captcha-wrapper .captcha-img-hint .captcha-img-refresh {
		margin-right: .375rem;
		font-size: .8125rem;
	}

	.svg-inline--fa.fa-w-16 {
		width: 1em;
	}

	.captcha-wrapper .captcha-img-submit {
		border-radius: .25rem;
		padding: .875rem 0;
		width: 16.625rem;
		background: linear-gradient(0deg, #39adfe, #7cc9fd);
		color: #fff;
		font-size: 1rem;
	}
</style>