export default {
	"APP下载": "APP下载",
	"官网首页": "官网首页",
	"优惠大厅": "优惠大厅",
	"在线客服": "在线客服",
	"代理申请": "代理申请",
	"免息借呗": "免息借呗",
	"真人升级": "真人升级",
	"电子升级": "电子升级",
	"站内信": "站内信",
	"每日签到": "每日签到",
	"抢红包": "抢红包",
	"交易记录": "交易记录",
	"投注记录": "投注记录",
	"修改密码": "修改密码",
	"修改取款密码": "修改取款密码",
	"登出": "登出",
	"首页": "首页",
	"优惠活动": "优惠活动",
	"会员中心": "会员中心",
	"充值": "充值",
	"提现": "提现",
	
	"额度转换": "额度转换",
	"总余额": "总余额",
	"公告": "公告",
	"基本资料": "基本资料",
	"优惠中心": "优惠中心",
	"操作成功！": "操作成功！",
	"操作异常！": "操作异常！",
	"时时反水": "时时反水",
	"申请提款": "申请提款",
	"银行卡管理": "银行卡管理",
	"系统公告": "系统公告",
	"余额宝": "余额宝",
	"快速充值": "快速充值",
	"充值记录": "充值记录",
	"语言选择": "语言选择",
	"您好": "您好",
	"您好, 请登入": "您好, 请登入",
	"余额与优惠": "余额与优惠",
	"登入查看": "登入查看",
	"热门": "热门",
	"最新": "最新",
	"本月推荐": "本月推荐",
	"全部": "全部",
	"请输入搜索关键词": "请输入搜索关键词",
	"搜索": "搜索",
	"参与": "参与",
	"会员登入": "会员登入",
	"注册账号": "注册账号",
	"介绍人": "介绍人",
	"帐号": "帐号",
	"密码": "密码",
	"确认密码": "确认密码",
	"会员资料": "会员资料",
	"真实姓名": "真实姓名",
	"手机号": "手机号",
	"取款密码": "取款密码",
	"确认取款密码": "确认取款密码",
	"验证码": "验证码",
	"立即注册": "立即注册",
	'1. 标记有 <span class="star">*</span> 者为必填项目。': '1. 标记有 <span class="star">*</span> 者为必填项目。',
	"2. 手机与取款密码为取款金额时的凭证，请会员务必填写详细资料。": "2. 手机与取款密码为取款金额时的凭证，请会员务必填写详细资料。",
	"忘记密码 ?": "忘记密码 ?",
	"登录": "登录",
	"试玩登录": "试玩登录",
	"随便逛逛": "随便逛逛",
	"去电脑版": "去电脑版",
	"联系管理员重置密码": "联系管理员重置密码",
	"您当前为试玩账号，只可在本站彩票试玩。": "您当前为试玩账号，只可在本站彩票试玩。",
	"注册成功，请进行登录": "注册成功，请进行登录",
	"修改登入密码": "修改登入密码",
	"修改取款密码": "修改取款密码",
	"银行资料": "银行资料",
	"旧密码": "旧密码",
	"新密码": "新密码",
	
	"确认新密码": "确认新密码",
	"请输入": "请输入",
	"取消": "取消",
	"确认": "确认",
	"密码修改成功，请重新登录": "密码修改成功，请重新登录",
	"取款密码修改成功": "取款密码修改成功",
	"账户余额": "账户余额",
	"反水账户": "反水账户",
	"切换至自动": "切换至自动",
	"切换至手动": "切换至手动",
	"转出": "转出",
	"转入": "转入",
	"金额": "金额",
	"请输入转账金额": "请输入转账金额",
	"钱包之间不能互相转换": "钱包之间不能互相转换",
	"用户名": "用户名",
	"可提款金额": "可提款金额",
	"收款银行卡": "收款银行卡",
	"请选择": "请选择",
	"银行账户": "银行账户",
	"持卡人姓名": "持卡人姓名",
	"提款金额": "提款金额",
	"请输入提款金额": "请输入提款金额",
	"温馨提示：当您的打码量不足时，提现会收取": "温馨提示：当您的打码量不足时，提现会收取",
	"%的手续费，当前剩余打码量": "%的手续费，当前剩余打码量",
	"提交": "提交",
	"取款密码": "取款密码",
	"请输入取款密码": "请输入取款密码",
	"您还未设置取款密码，请先设置取款密码再操作": "您还未设置取款密码，请先设置取款密码再操作",
	"请先绑定银行卡": "请先绑定银行卡",
	"绑定时间": "绑定时间",
	"开户行": "开户行",
	"卡号": "卡号",
	"重新绑定": "重新绑定",
	"增加绑定": "增加绑定",
	"银行卡变更": "银行卡变更",
	"开户银行": "开户银行",
	"开户人姓名": "开户人姓名",
	"银行账号": "银行账号",
	"开户网点": "开户网点",
	"返回": "返回",
	"修改成功": "修改成功",
	"添加银行卡成功": "添加银行卡成功",
	"时间": "时间",
	"接口类型": "接口类型",
	"游戏类型": "游戏类型",
	"暂无数据": "暂无数据",
	"没有更多了": "没有更多了",
	
	
	"统计": "统计",
	"总下注金额": "总下注金额",
	"总输赢": "总输赢",
	"游戏详情": "游戏详情",
	"游戏单号": "游戏单号",
	"游戏名称": "游戏名称",
	"游戏类型": "游戏类型",
	"场次信息": "场次信息",
	"下注明细": "下注明细",
	"下注金额": "下注金额",
	"有效下注": "有效下注",
	"派彩金额": "派彩金额",
	"开奖结果": "开奖结果",
	"下注时间": "下注时间",
	"派彩时间": "派彩时间",
	"账号": "账号",
	"真实姓名": "真实姓名",
	"最后登入时间": "最后登入时间",
	"变动类型": "变动类型",
	"金额类型": "金额类型",
	"流水金额": "流水金额",
	"有效金额": "有效金额",
	"交易详情": "交易详情",
	"交易金额": "交易金额",
	"转入前余额": "转入前余额",
	"转入后余额": "转入后余额",
	"钱包类型": "钱包类型",
	"转账类型": "转账类型",
	"操作描述": "操作描述",
	"交易时间": "交易时间",
	"全部": "全部",
	"反水钱包": "反水钱包",
	"中心钱包": "中心钱包",
	"今日": "今日",
	"昨日": "昨日",
	"7日内": "7日内",
	"30日内": "30日内",
	"状态": "状态",
	"支付类型": "支付类型",
	"总金额": "总金额",
	"订单号": "订单号",
	
	
	"金额": "金额",
	"账号信息": "账号信息",
	"支付方式": "支付方式",
	"支付信息": "支付信息",
	"支付状态": "支付状态",
	"失败原因": "失败原因",
	"汇款时间": "汇款时间",
	"管理员确认时间": "管理员确认时间",
	"最新消息": "最新消息",
	"返回首页": "返回首页",
	"在线客服": "在线客服",
	"APP下载": "APP下载",
	"点击申请": "点击申请",
	"免费开户": "免费开户",
	"手机投注": "手机投注",
	"电子游艺": "电子游艺",
	"线路检测": "线路检测",
	"火狐浏览器": "火狐浏览器",
	"上网导航": "上网导航",
	"如何存款": "如何存款",
	"如何取款": "如何取款",
	"代理加盟": "代理加盟",
	"恭喜": "恭喜",
	"成功办理": "成功办理",
	"会员账号": "会员账号",
	"申请活动": "申请活动",
	"申请时间": "申请时间",
	"申请状态": "申请状态",
	"备注": "备注",
	"未查询到信息": "未查询到信息",
	"官方首页": "官方首页",
	"抽奖转盘": "抽奖转盘",
	"抽奖规则": "抽奖规则",
	"输入游戏账号查询": "输入游戏账号查询",
	"查 询": "查 询",
	"当日总存款": "当日总存款",
	"当日有效投注": "当日有效投注",
	"历史中奖记录": "历史中奖记录",
	"您当前有": "您当前有",
	"次抽奖机会": "次抽奖机会",
	"当日存款": "当日存款",
	"有效流水": "有效流水",
	
	"转盘次数": "转盘次数",
	"恭喜你中奖了，奖品是 ": "恭喜你中奖了，奖品是 ",
	"段位查询": "段位查询",
	"请输入你的会员账号": "请输入你的会员账号",
	"晋升标准等级": "晋升标准等级",
	"累计打码": "累计打码",
	"等级礼金": "等级礼金",
	"周俸禄": "周俸禄",
	"月俸禄": "月俸禄",
	"借呗额度": "借呗额度",
	"累积礼金": "累积礼金",
	"时时返水": "时时返水",
	"存取款加速通道": "存取款加速通道",
	"一对一客服经理": "一对一客服经理",
	"级": "级",
	"等级": "等级",
	"按您的等级计算，距离上次登录7天后再次登录即可自动到账，等级每周俸禄详情如下：": "按您的等级计算，距离上次登录7天后再次登录即可自动到账，等级每周俸禄详情如下：",
	"按您的等级计算，距离上次登录30天后再次登录即可自动到账，等级每月俸禄详情如下：": "按您的等级计算，距离上次登录30天后再次登录即可自动到账，等级每月俸禄详情如下：",
	"如何申请每月俸禄？": "如何申请每月俸禄？",
	"如何申请每周俸禄？": "如何申请每周俸禄？",
	"玩真人视讯永久累计打码，让您的会员账号变成永久收益金管家！": "玩真人视讯永久累计打码，让您的会员账号变成永久收益金管家！",
	"的账号信息": "的账号信息",
	"会员账号": "会员账号",
	"当前等级": "当前等级",
	"累积有效投注": "累积有效投注",
	"等级彩金": "等级彩金",
	"周俸禄": "周俸禄",
	"月俸禄": "月俸禄",
	"距离晋级需有效投注": "距离晋级需有效投注",
	"距离下一级还差": "距离下一级还差",
	"注": "注",
	
	"奖励类型": "奖励类型",
	"奖励金额": "奖励金额",
	"发放时间": "发放时间",
	"喜讯：2020年7月2号起所有电子/棋牌/捕鱼永久累计打码，让您的会员账号变成永久收益金管家！": "喜讯：2020年7月2号起所有电子/棋牌/捕鱼永久累计打码，让您的会员账号变成永久收益金管家！",
	"声明：强势升级后的累积打码标准将会进行调整，等级礼金、周俸禄、月俸禄也会进行提升；特此声明所有会员的等级变动都以更新后为准！": "声明：强势升级后的累积打码标准将会进行调整，等级礼金、周俸禄、月俸禄也会进行提升；特此声明所有会员的等级变动都以更新后为准！",
	"免息借呗": "免息借呗",
	"信用额度查询": "信用额度查询",
	"信用规则": "信用规则",
	"借还款记录": "借还款记录",
	"我要借款": "我要借款",
	"我要还款": "我要还款",
	"活动详情": "活动详情",
	"信用规则": "信用规则",
	"活动细则": "活动细则",
	"借款说明": "借款说明",
	"还款说明": "还款说明",
	"会员账号": "会员账号",
	"VIP等级": "VIP等级",
	"最高借款": "最高借款",
	"已借款": "已借款",
	"总借款": "总借款",
	"总还款": "总还款",
	"借款金额": "借款金额",
	"还款金额": "还款金额",
	"借款天数": "借款天数",
	"还款倒计时": "还款倒计时",
	"审核进度": "审核进度",
	"日期": "日期",
	"暂无数据": "暂无数据",
	"温馨提示": "温馨提示",
	"注意：提交成功5分钟后请到“信用额度查询”是否借款成功！": "注意：提交成功5分钟后请到“信用额度查询”是否借款成功！",
	"我要借款": "我要借款",
	"请填写会员账号": "请填写会员账号",
	"请填写会员姓名": "请填写会员姓名",
	"请填写借款金额": "请填写借款金额",
	"请填写借款天数": "请填写借款天数",
	"确认提交": "确认提交",
	"我要还款": "我要还款",
	"查询欠款额度": "查询欠款额度",
	"还款金额": "还款金额",
	"请填写还款金额": "请填写还款金额",
	"注意：请点击“在线付款”进行付款，付款完成再来提交！": "注意：请点击“在线付款”进行付款，付款完成再来提交！",
	"提交成功5分钟，请到“信用额度查询”查询是否成功还款！": "提交成功5分钟，请到“信用额度查询”查询是否成功还款！",
	"当前欠款:": "当前欠款:",
	"请填写会员帐号": "请填写会员帐号",
	"请填写存款金额": "请填写存款金额",
	"请填写验证码": "请填写验证码",
	"存款金额": "存款金额",
	"立即提交": "立即提交",
	"优惠申请": "优惠申请",
	"查看详情": "查看详情",
	"优惠申请中心": "优惠申请中心",
	"优惠申请进度查询": "优惠申请进度查询",
	"选择查询项目": "选择查询项目",
	"点击查询": "点击查询",
	"会员账号": "会员账号",
	"活动名称": "活动名称",
	"申请时间": "申请时间",
	"申请状态": "申请状态",
	"未查询到信息": "未查询到信息",
	"您还有反水": "您还有反水",
	"可领取！": "可领取！",
	"有效投注": "有效投注",
	"返点比例": "返点比例",
	
	
	"您目前没有时时返水可以领取，快去游戏吧!": "您目前没有时时返水可以领取，快去游戏吧!",
	"领取": "领取",
	"我的奖励": "我的奖励",
	"周一": "周一",
	"周二": "周二",
	"周三": "周三",
	"周四": "周四",
	"周五": "周五",
	"周六": "周六",
	"周日": "周日",
	"连续签到礼包": "连续签到礼包",
	"天礼包": "天礼包",
	"天时可以领取": "天时可以领取",
	"领取": "领取",
	"已领取": "已领取",
	"马上签到": "马上签到",
	"已签到": "已签到",
	"暂无奖励数据": "暂无奖励数据",
	"回首页": "回首页",
	"奖品": "奖品",
	"日期": "日期",
	"暂无数据": "暂无数据",
	"返回": "返回",
	"开始游戏": "开始游戏",
	"该功能暂未开放": "该功能暂未开放",
	"一个红包也没有抢到，多多加油": "一个红包也没有抢到，多多加油",
	"发件": "发件",
	"发件箱": "发件箱",
	"收件箱": "收件箱",
	"已读": "已读",
	"未读": "未读",
	"暂无数据": "暂无数据",
	"管理员": "管理员",
	"上条站内信": "上条站内信",
	"回复": "回复",
	"标示为已读": "标示为已读",
	"标示为未读": "标示为未读",
	"删除": "删除",
	"发送站内信": "发送站内信",
	"标题": "标题",
	"内容": "内容",
	"删除成功": "删除成功",
	"我的账户": "我的账户",
	"基本资料": "基本资料",
	"银行卡": "银行卡",
	"修改密码": "修改密码",
	"自助服务": "自助服务",
	"时时返水": "时时返水",
	"余额宝": "余额宝",
	"往来记录": "往来记录",
	"投注记录": "投注记录",
	"充值记录": "充值记录",
	"信息公告": "信息公告",
	"最新公告": "最新公告",
	"站内信": "站内信",
	"充值处理时间": "充值处理时间",
	"7*24小时充值服务": "7*24小时充值服务",
	"如选择银行卡转账，请勿使用其他支付方式，否则充值将无法到账": "如选择银行卡转账，请勿使用其他支付方式，否则充值将无法到账",
	"在线支付": "在线支付",
	"公司入款": "公司入款",
	"充值金额": "充值金额",
	"请输入充值金额": "请输入充值金额",
	"单笔充值限额": "单笔充值限额",
	"最低": "最低",
	"最高": "最高",
	"*平台填写金额应当与网银汇款金额完全一致，否则将无法即时到账！": "*平台填写金额应当与网银汇款金额完全一致，否则将无法即时到账！",
	"下一步": "下一步",
	"收款方信息": "收款方信息",
	"收款账户": "收款账户",
	"收款银行": "收款银行",
	"收款姓名": "收款姓名",
	"开户行网点": "开户行网点",
	"收款二维码": "收款二维码",
	"复制": "复制",
	"您的充值信息": "您的充值信息",
	"您的账号": "您的账号",
	"请输入 您的账号": "请输入 您的账号",
	"付款姓名": "付款姓名",
	"请输入 您的名字": "请输入 您的名字",
	"汇款时间": "汇款时间",
	"提交": "提交",
	"请输入正确的存款金额！": "请输入正确的存款金额！",
	"转账信息已提交，请耐心等候客服人员核查通过！": "转账信息已提交，请耐心等候客服人员核查通过！",
	"前往支付": "前往支付",
	"遇到问题？联系客服": "遇到问题？联系客服",
	"复制成功！": "复制成功！",
	"钱包地址": "钱包地址",
	"兑换汇率": "兑换汇率",
	"usdt个数": "usdt个数",
	"我的余额宝": "我的余额宝",
	"进行中": "进行中",
	"已结束": "已结束",
	"您尚无任何方案!": "您尚无任何方案!",
	"立即购买": "立即购买",
	"买入": "买入",
	"共": "共",
	"笔": "笔",
	
	
	"总金额": "总金额",
	"总盈利/总上限": "总盈利/总上限",
	"方案选择": "方案选择",
	"销售金额": "销售金额",
	"结算周期": "结算周期",
	"循环结算": "循环结算",
	"单次结算": "单次结算",
	"小时": "小时",
	"利率": "利率",
	"利息上限": "利息上限",
	"剩余数量": "剩余数量",
	"个人购买数量": "个人购买数量",
	"再次购买时间": "再次购买时间",
	"后": "后",
	"无": "无",
	"请填写购买信息": "请填写购买信息",
	"购买金额": "购买金额",
	"请输入10的倍数": "请输入10的倍数",
	"请填写购买金额": "请填写购买金额",
	"请输入10的倍数的金额": "请输入10的倍数的金额",
	"每": "每",
	"小时利息为": "小时利息为",
	"小时后利息为": "小时后利息为",
	"利息稽核倍数": "利息稽核倍数",
	"利息试算": "利息试算",
	"购买": "购买",
	"购买数量已达上限": "购买数量已达上限",
	"已售完": "已售完",
	"切换方案": "切换方案",
	"暂无方案": "暂无方案",
	"请选择合适的方案": "请选择合适的方案",
	"结算方式": "结算方式",
	"名称": "名称",
	"金额": "金额",
	"买进时间": "买进时间",
	"结算时间": "结算时间",
	"提现时间": "提现时间",
	"利息周期": "利息周期",
	"利率": "利率",
	"目前利息/上限": "目前利息/上限",
	"利息到达上限": "利息到达上限",
	"已结算": "已结算",
	"赎回": "赎回",
	"正在赎回...": "正在赎回...",
	"历史盈利": "历史盈利",
	"利息历程": "利息历程",
	"此方案为": "此方案为",
	"模式": "模式",
	"利息稽核倍数为": "利息稽核倍数为",
	"倍": "倍",
	"第": "第",
	"次": "次",
	"确定赎回吗？": "确定赎回吗？",
	"中心钱包余额": "中心钱包余额",
	"更新余额": "更新余额",
	"余额": "余额",
	"请输入转入金额": "请输入转入金额",
	"转入": "转入",
	"去存款": "去存款",
	"正在进入游戏，请稍等 .....": "正在进入游戏，请稍等 .....",
	"正在进行免转...": "正在进行免转...",
	"请先进行登录再进入游戏": "请先进行登录再进入游戏",
	"进入游戏": "进入游戏",
	"一键归户": "一键归户",
	"修改成功！": "修改成功！",
	"请填写真实姓名！": "请填写真实姓名！",
	"真实姓名未填写，请前往个人中心填写真实姓名！": "真实姓名未填写，请前往个人中心填写真实姓名！",
	
	"利息宝":"利息宝",
	"返水":"返水",
	"福利":"福利",
	"客服":"客服",
	"总游戏余额":"总游戏余额",
	"线上存款":"线上存款",
	
	"7x24小时备用客服":"7x24小时备用客服",
	"电子钱包":"电子钱包",
	"SVIP钱包等级":"SVIP钱包等级",
	"我的优惠":"我的优惠",
	"快速存款":"快速存款",
	"线上取款":"线上取款",
	"新会员推荐":"新会员推荐",
	"安装":"安装",
	"开启":"开启",
	"寰宇浏览器":"寰宇浏览器",
	"CG钱包下载":"CG钱包下载",
	"前往电脑版":"前往电脑版",
	"选择语系":"选择语系",
	
	"点我下载":"点我下载",
	"早上好，请先登入或注册":"早上好，请先登入或注册",
	
	"注册": "注册",
	"优惠":"优惠",
	"我的":"我的"
}