<template>
	
	<div id="app" lass="page-language">
		<div class="app-header-wrap">
			<div class="app-header-left">
				<svg @click="back" class="svg-inline--fa fa-chevron-left fa-w-10 btn-back" aria-hidden="true" data-prefix="fas"
					data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
					<path class="" fill="currentColor"
						d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z">
					</path>
				</svg>
			</div>
			<div class="header-title">{{$t('选择您的语系')}}</div>
		</div>
		
		<div class="language-wrap">
			<div class="lang-item" @click="setlang(i)" v-for="(item, i) in languages" :key="i">{{ item.name }}</div>
		</div>
		
	</div>
</template>

<script>

	export default {
		name: 'language',
		data() {
			return {
				lang: '',
				languages: {
					'en_us': {
						name: 'English'
					},
					'zh_cn': {
						name: '简体中文'
					},
					'zh_hk': {
						name: '繁體中文'
					},
					// 'fr': {
					// 	name: 'Français'
					// },
					// 'ja': {
					// 	name: '日本'
					// },
					// 'it': {
					// 	name: 'Italiano'
					// },
					// 'de': {
					// 	name: 'Deutsch'
					// },
					// 'sv': {
					// 	name: 'Svenska'
					// },
				},
				index: 0
			}
		},
		
		mounted() {
			
			this.lang = localStorage.getItem('local')
			// if (this.lang === 'zh_hk') {
			// 	this.lang = 'en'
			// 	this.changeLang(this.lang)
			// }
			
		},
		methods: {
			
			back(){
				// console.log(11111111);
			    this.$router.goBack(-1);//返回上一层
			},
			
			setlang(lang) {
				
				let that = this;
				this.lang = lang;
				localStorage.setItem('local', this.lang)
				// this.$router.push({
				// 	path: '/'
				// });
				that.$parent.goNav('/')
				that.$parent.refreshPage()
				
			},
			
			// 语言切换
			changeLang() {
				localStorage.setItem('local', this.lang)
				this.$i18n.locale = this.lang;
			},
			
		}
	}
</script>

<style>
	#app.page-language {
	    background: #fff;
	}
	#app {
	    display: flex;
	    flex-direction: column;
	    width: 100%;
	    height: 100%;
	    overflow: hidden;
	}
	
	#app .app-header-wrap {
	    background: linear-gradient(#4eaafc, #1c5cfb);
	}
	#app .app-header-wrap {
	    flex: 0 0 3rem;
	    line-height: 3rem;
	}
	.app-header-wrap {
	    position: -webkit-sticky;
	    position: sticky;
	    z-index: 100;
	    top: 0;
	    flex: 0 0 2.625rem;
	    width: 100%;
	    line-height: 2.625rem;
	    text-align: center;
	}
	
	
	.app-header-wrap .app-header-left {
	    left: .875rem;
	}
	.app-header-wrap .app-header-left, .app-header-wrap .app-header-right {
	    position: absolute;
	    top: 0;
	    bottom: 0;
	}
	
	#app .app-header-wrap .header-title, #app .app-header-wrap .btn-back {
	    color: #fff;
	}
	.svg-inline--fa.fa-w-10 {
	    width: 0.625em;
	}
	.svg-inline--fa {
	    display: inline-block;
	    font-size: inherit;
	    height: 1em;
	    overflow: visible;
	    vertical-align: -.125em;
	}
	
	.btn-back {
	    vertical-align: middle;
	    font-size: 1.75rem;
	}
	
	#app .app-header-wrap .header-title, #app .app-header-wrap .btn-back {
	    color: #fff;
	}
	.header-title {
	    box-sizing: border-box;
	    padding: 0 3.25rem;
	    max-width: 100%;
	    overflow: hidden;
	    font-size: 1rem;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	}
	
	
	.language-wrap {
	    position: relative;
	    flex: 1 1 auto;
	    box-sizing: border-box;
	    overflow: auto;
	    font-size: .875rem;
	}

	.lang-item {
		border-bottom: 1px solid #f3f3f3;
		color: #868686;
	    display: block;
	    box-sizing: border-box;
	    padding: 0 7.5%;
	    height: 2.6875rem;
	    line-height: 2.6875rem;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    text-decoration: none;
	    white-space: nowrap;
	}
</style>