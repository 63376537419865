<template>

	<div>

		<div class="app-header-wrap">
			
			<div class="app-header-left">
				<div class="btn-back-home" @click="gopage('/')"></div>
			</div>
			
			<div class="header-title">{{$t('本站推荐')}}</div>
			
			<div class="app-header-right">
				<div class="app-balance-btn" v-if="isLogin" @click="setshowBalance()">{{$t('余额')}}</div>
			</div>
			
		</div>

		<div class="content-wrap">

			<div class="card-hall">

				<div class="card-landscape-inner">

					<!-- <div class="recommend-game-banner">
						<div class="game-entry">
							<div class="game-entry-inner"><img
									data-src="https://g1.cfvn66.com/game_picture/mobile/58/58071.png?v=1728639254"
									class="game-entry-image"
									src="https://g1.cfvn66.com/game_picture/mobile/58/58071.png?v=1728639254"
									data-loaded="true"><span class="game-entry-nickname">PG</span></div>
							<div class="game-entry-name">招财喵</div>
						</div>
						<div class="game-entry">
							<div class="game-entry-inner"><img
									data-src="https://g1.cfvn66.com/game_picture/mobile/39/39161.png?v=1728639254"
									class="game-entry-image"
									src="https://g1.cfvn66.com/game_picture/mobile/39/39161.png?v=1728639254"
									data-loaded="true"><span class="game-entry-nickname">JDB</span></div>
							<div class="game-entry-name">星际水果霸</div>
						</div>
						<div class="game-entry">
							<div class="game-entry-inner"><img
									data-src="https://g1.cfvn66.com/game_picture/mobile/52/52050.png?v=1728639254"
									class="game-entry-image"
									src="https://g1.cfvn66.com/game_picture/mobile/52/52050.png?v=1728639254"
									data-loaded="true"><span class="game-entry-nickname">CQ9</span></div>
							<div class="game-entry-name">单手跳高高</div>
						</div>
						<div class="game-entry">
							<div class="game-entry-inner"><img
									data-src="https://g1.cfvn66.com/game_picture/mobile/114/114030.png?v=1728639254"
									class="game-entry-image"
									src="https://g1.cfvn66.com/game_picture/mobile/114/114030.png?v=1728639254"
									data-loaded="true"><span class="game-entry-nickname">FC</span></div>
							<div class="game-entry-name">财富列车</div>
						</div>
						<div class="game-entry">
							<div class="game-entry-inner"><img
									data-src="https://g1.cfvn66.com/game_picture/mobile/37/37219.png?v=1728639254"
									class="game-entry-image"
									src="https://g1.cfvn66.com/game_picture/mobile/37/37219.png?v=1728639254"
									data-loaded="true"><span class="game-entry-nickname">PP</span></div>
							<div class="game-entry-name">奥林匹斯之门</div>
						</div>
						<div class="game-entry">
							<div class="game-entry-inner"><img
									data-src="https://g1.cfvn66.com/game_picture/mobile/50/50072.png?v=1728639254"
									class="game-entry-image"
									src="https://g1.cfvn66.com/game_picture/mobile/50/50072.png?v=1728639254"
									data-loaded="true"><span class="game-entry-nickname">WM</span></div>
							<div class="game-entry-name">太空大爆炸</div>
						</div>
						<div class="game-entry">
							<div class="game-entry-inner"><img
									data-src="https://g1.cfvn66.com/game_picture/mobile/50/50104.png?v=1728639254"
									class="game-entry-image"
									src="https://g1.cfvn66.com/game_picture/mobile/50/50104.png?v=1728639254"
									data-loaded="true"><span class="game-entry-nickname">WM</span></div>
							<div class="game-entry-name">发发致富</div>
						</div>
					</div> -->


					<!-- <div class="category-main">
						<div class="category-main-item active" style="width: 50%;"><span>推荐</span></div>
						<div class="category-main-item" style="width: 50%;"><span>全部</span></div>
						<div class="category-main-item" style="width: 20%;"><span>桌上游戏</span></div>
						<div class="category-main-item" style="width: 20%;"><span>电子</span></div>
						<div class="category-main-item" style="width: 20%;"><span>捕鱼机</span></div>
					</div> -->

					<div class="category-main">

						<!-- 推荐选项 -->
						<div class="category-main-item" :class="{ active: selectedCategory === 'recommend' }"
							@click="selectCategory('recommend')" style="width: 50%;">
							<span>{{$t('推荐')}}</span>
						</div>

						<!-- 全部选项 -->
						<div class="category-main-item" :class="{ active: selectedCategory === 'all' }"
							@click="selectCategory('all')" style="width: 50%;">
							<span>{{$t('全部')}}</span>
						</div>

					</div>


					<div class="list-filter">
						<div class="list-filter-inner">

							<input id="filter" type="text" v-model="searchQuery" :placeholder="$t('搜索')" class="">
							<span class="list-filter-placeholder enter">
								<svg class="svg-inline--fa fa-magnifying-glass fa-w-16 icon-search" aria-hidden="true"
									data-prefix="fas" data-icon="magnifying-glass" role="img"
									xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<path class="" fill="currentColor"
										d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z">
									</path>
								</svg>
							</span>

							<svg @click="clearSearch" v-if="searchQuery"
								class="svg-inline--fa fa-circle-xmark fa-w-16 list-filter-clear" aria-hidden="true"
								data-prefix="fas" data-icon="circle-xmark" role="img" xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512">
								<path class="" fill="currentColor"
									d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z">
								</path>
							</svg>


						</div>
					</div>


					<!-- <span class="list-filter-placeholder"><svg
							class="svg-inline--fa fa-magnifying-glass fa-w-16 icon-search" aria-hidden="true"
							data-prefix="fas" data-icon="magnifying-glass" role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path class="" fill="currentColor"
								d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z">
							</path>
						</svg><span>搜索</span></span> -->



					<div class="card-context">

						<!-- left -->
						<div class="vendors card-vendors" category="card">

							<!-- getcardlist -->

							<div class="" v-for="(item, index) in getcardlist" :key="index">
								<div :class="['vendor-item', { active: item.class_name === api_code }]"
									@click="checkcasinolist(index)">
									<!-- 动态绑定图片路径 -->
									<img :src="`/static/image/casino/${item.class_name}.png`" class="vendor-item-icon">
									<span>{{ item.title }}</span>
								</div>
							</div>


							<!-- <div class="">
								<div class="vendor-item active"><img
										src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/hall-vendor-icon/151_active.png?v=11"
										class="vendor-item-icon"><span>WG棋牌</span></div>
							</div>
							<div class="">
								<div class="vendor-item"><img
										src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/hall-vendor-icon/49.png?v=11"
										class="vendor-item-icon"><span>开元棋牌</span></div>
							</div>
							<div class="">
								<div class="vendor-item"><img
										src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/hall-vendor-icon/64.png?v=11"
										class="vendor-item-icon"><span>MT棋牌</span></div>
							</div>
							<div class="">
								<div class="vendor-item"><img
										src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/hall-vendor-icon/126.png?v=11"
										class="vendor-item-icon"><span>百胜棋牌</span></div>
							</div>
							<div class="">
								<div class="vendor-item"><img
										src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/hall-vendor-icon/83.png?v=11"
										class="vendor-item-icon"><span>乐游棋牌</span></div>
							</div>
							<div class="">
								<div class="vendor-item"><img
										src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/hall-vendor-icon/68.png?v=11"
										class="vendor-item-icon"><span>JDB棋牌</span></div>
							</div>
							<div class="">
								<div class="vendor-item"><img
										src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/hall-vendor-icon/69.png?v=11"
										class="vendor-item-icon"><span>FG棋牌</span></div>
							</div>
							<div class="">
								<div class="vendor-item"><img
										src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/hall-vendor-icon/81.png?v=11"
										class="vendor-item-icon"><span>幸运棋牌</span></div>
							</div> -->


						</div>

						<!-- right -->

						<!-- <div class="event-wallscape" gtm-target="44">
							<div
								class="swiper swiper-initialized swiper-horizontal swiper-ios ele-swiper swiper-backface-hidden">
								<div class="swiper-wrapper"
									style="transition-duration: 0ms; transform: translate3d(-414px, 0px, 0px);">
									<div class="swiper-slide swiper-slide-duplicate swiper-slide-next swiper-slide-duplicate-prev swiper-slide-duplicate"
										data-swiper-slide-index="1" style="width: 138px;">
										<div class="event-item"><img class="image-pic"
												src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/44/zh-cn/execute_3.png?v=1724040000">
										</div>
									</div>
									<div class="swiper-slide swiper-slide-duplicate-active"
										data-swiper-slide-index="0" style="width: 138px;">
										<div class="event-item"><img class="image-pic"
												src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/44/zh-cn/execute_1.png?v=1724040000">
										</div>
									</div>
									<div class="swiper-slide swiper-slide-prev swiper-slide-duplicate-next"
										data-swiper-slide-index="1" style="width: 138px;">
										<div class="event-item"><img class="image-pic"
												src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/44/zh-cn/execute_3.png?v=1724040000">
										</div>
									</div>
									<div class="swiper-slide swiper-slide-duplicate swiper-slide-active swiper-slide-duplicate"
										data-swiper-slide-index="0" style="width: 138px;">
										<div class="event-item"><img class="image-pic"
												src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/44/zh-cn/execute_1.png?v=1724040000">
										</div>
									</div>
								</div>
							</div>
						</div> -->


						<div class="casino-game-list" @scroll="handleScroll">
							<!-- ref="scrollDiv" -->
							<div class="game-list-inner">

								<div class="game-entry" v-for="(item, index) in caslists" :key="index">
									<div class="game-entry-inner" @click="$parent.openGamePlayb(item)">
										<img class="game-entry-image" :src="item.img_url" data-loaded="true">					
									</div>
									<div class="game-entry-name">{{item.name}}</div>

								</div>

							</div>

						</div>



					</div>

				</div>

			</div>

		</div>

		<Diyloading ref="loadmodal"></Diyloading>
		
		<!-- 右边弹出层 -->
		<Balance :showBalance="showBalance" @closeBalance="showBalance = false" />
		<!-- 右边弹出层 -->

		<!-- footer -->
		<Foot />


	</div>

</template>

<script>
	
	import Balance from './libs/Balance.vue'
	
	import Foot from './libs/Foot.vue';

	import Diyloading from "./libs/Diyloading.vue"

	export default {
		name: "casino",
		data() {
			return {
				
				showBalance: false,
				
				searchQuery: '', // 存储用户的搜索输入

				tabIndex: 0,
				selectedCategory: 'recommend', // 默认选中推荐
				casinolist: [],
				api_code: '',
				game_type: '',

				current_page: 1,
				caslists: [],
				first_page_url: "",
				from: 1,
				last_page: 0,
				last_page_url: "",
				next_page_url: "",
				path: "",
				per_page: 0,
				prev_page_url: null,
				to: 0,
				total: 0,

				isLoading: false, // 请求状态标志，控制是否在加载
				
				currentRequest: null, // 当前请求，用于取消前一个请求
			};
		},
		components: {
			Foot,
			Diyloading,
			Balance
		},
		watch: {
			
			// 监听搜索输入
			searchQuery(newQuery) {
				if (newQuery.length > 2 || newQuery === "") {
					this.current_page = 1;
					this.last_page = 1;
					this.caslists = [];
					this.fetchResults(); // 当输入字符长度大于 2 时，发起搜索请求
				}
			}
			
		},
		
		computed: {

			userinfo() {
				return this.$store.state.userInfo
			},

			//内容高度
			isLogin() {
				return this.$store.state.token;
			},
			
			getcardlist() {
				return this.$store.state.cardlist;
			}

		},

		mounted() {

			// 获取 query 中的参数
			this.current_page = 1; // 更新当前页码
			this.api_code = this.$route.query.class_name;
			this.game_type = this.$route.query.game_type;
			this.getrecommend(); // 重新获取数据
			// window.addEventListener("scroll", this.handleScroll);

		},
		
		methods: {
			
			setshowBalance() {
			
				let that = this;
			
				if (that.isLogin) {
			
					that.showBalance = true;
			
				} else {
			
					that.gopage("/login");
			
				}
			
			},

			// 发送搜索请求
			fetchResults() {
				
				let that = this;
				
				const param = {
					
					keyword: that.searchQuery,
					gameType: that.game_type,
					api_code: that.api_code,
					page: 1 ,// 使用当前页码请求数据
					
				};
				
				if (that.selectedCategory === 'recommend') {
					param.tag = 'recommend'
				} else {
					param.tag = 'all'
				}

				// 如果正在请求，则取消上一个请求
				if (that.currentRequest) {
					that.currentRequest.cancel();
				}

				// 发送新的请求
				that.$refs.loadmodal.open(); // 传入标题和消息
				
				
				that.currentRequest = that.$apiFun.get("/api/games/lists", param).then((res) => {
					that.caslists = res.data.data; // 更新结果列表
					that.$refs.loadmodal.close(); // 传入标题和消息
				
				});
				that.$refs.loadmodal.open(); // 传入标题和消息
			
			},

			// 清空搜索框
			clearSearch() {
				
				this.searchQuery = ''; // 清空输入框
				this.caslists = []; // 清空结果列表
				
				this.getrecommend()
				
			},

			// 处理滚动事件
			handleScroll(event) {

				const scrollDiv = event.target;
				// 检查滚动是否到底部，提前 50px 触发加载更多
				
				// console.log('scrollHeight',scrollDiv.scrollHeight);
				// console.log('scrollTop',scrollDiv.scrollTop);
				// console.log('clientHeight+50',scrollDiv.clientHeight + 50);
				
				// console.log('isLoading',this.isLoading);
				// console.log('last_page',this.last_page);
				// console.log('current_page',this.current_page);
				
				// 检查滚动是否到底部，提前 50px 触发加载更多
				if (scrollDiv.scrollHeight - scrollDiv.scrollTop < (scrollDiv.clientHeight + 50) && !this.isLoading && this.current_page < this.last_page ) {
					this.loadMore(); // 加载下一页
				}

			},
			
			// 左边切换

			checkcasinolist(index) {
				
				this.current_page = 1; // 更新当前页码
				this.last_page = 1; // 更新当前页码
				this.caslists = []; // 更新当前页码
				this.api_code = this.getcardlist[index].class_name;
				this.game_type = JSON.parse(this.getcardlist[index].params).gameType;
				this.getrecommend();

			},

			selectCategory(category) {

				this.selectedCategory = category; // 更新选中的选项
				
				// console.log(JSON.stringify(this.getcardlist[index]));

				this.current_page = 1; // 更新当前页码
				this.last_page = 1; // 更新当前页码
				this.caslists = []; // 更新当前页

				this.getrecommend();

			},

			gopage(url) {

				let that = this;

				that.$router.push({
					path: url
				});

			},
			changeTab(index) {

				this.tabIndex = index;
				this.last_page = 1; // 更新当前页码
				this.caslists = []; // 更新当前页码
				this.getrecommend();

			},

			loadMore() {

				let that = this;

				that.isLoading = true; // 开始请求前设置为正在加载

				that.$refs.loadmodal.open(); // 传入标题和消息

				let param = {
					gameType: that.game_type,
					api_code: that.api_code,
					page: that.current_page + 1 // 使用当前页码请求数据
				};

				if (that.selectedCategory === 'recommend') {
					param.tag = 'recommend'
				} else {
					param.tag = 'all'
				}

				that.$apiFun.get("/api/games/lists", param).then((res) => {

					// 处理响应数据
					if (res.code === 200) {

						const {
							data,
							current_page,
							last_page
						} = res.data;

						that.caslists = [...that.caslists, ...data];
						that.current_page = current_page;
						that.last_page = last_page;
						
						that.isLoading = false;
						
						that.$refs.loadmodal.close(); // 传入标题和消息
					}

				});

			},

			getrecommend() {

				let that = this;

				that.$refs.loadmodal.open(); // 传入标题和消息

				let param = {
					gameType: that.game_type,
					api_code: that.api_code,
					page: that.current_page // 使用当前页码请求数据
				};

				if (that.selectedCategory === 'recommend') {
					param.tag = 'recommend'
				} else {
					param.tag = 'all'
				}


				that.$apiFun.get("/api/games/lists", param).then((res) => {

					// 处理响应数据
					if (res.code === 200) {


						that.current_page = res.data.current_page;

						that.caslists = res.data.data; // 获取消息列表数据

						// 更新分页参数
						that.first_page_url = res.data.first_page_url;
						that.last_page = res.data.last_page;
						that.next_page_url = res.data.next_page_url;
						that.prev_page_url = res.data.prev_page_url;
						that.total = res.data.total;
						
						that.isLoading = false;

						that.$refs.loadmodal.close(); // 传入标题和消息

					}


				}).catch((err) => {
					console.error("请求失败：", err);
				});

			}

		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll);
		},
	};
</script>
<style lang="scss" scoped>
	// 引入分页

	#app {
		font-family: STHeiti, Microsoft YaHei, 微软雅黑, SimSun, 宋体, arial, sans-serif;
	}


	#app {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.app-header-wrap {
		background: linear-gradient(#4eaafc, #1c5cfb);
	}

	.app-header-wrap {
		flex: 0 0 3rem;
		line-height: 3rem;
	}

	.app-header-wrap {
		position: sticky;
		z-index: 100;
		top: 0;
		flex: 0 0 2.625rem;
		width: 100%;
		text-align: center;
	}

	.app-header-wrap .app-header-left {
		left: .875rem;
	}

	.app-header-wrap .app-header-left,
	.app-header-wrap .app-header-right {
		position: absolute;
		top: 0;
		bottom: 0;
	}

	.app-balance-btn {
		border: 1px solid #dae3ff;
		background: linear-gradient(169deg, #fdfdff, #e6ecff, #f9fbff);
		color: #4a5c95;
	}

	.app-balance-btn {
		display: inline-block;
		vertical-align: middle;
		border-radius: .25rem;
		width: 3.25rem;
		height: 1.5625rem;
		font-size: .8125rem;
		line-height: 1.5625rem;
	}

	.app-header-wrap .app-header-left:before,
	.app-header-wrap .app-header-right:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.app-header-wrap .btn-back-home {
		background-image: url("/static/image/DuvElord.png");
	}

	.btn-back-home {
		display: inline-block;
		vertical-align: middle;
		width: 1.75rem;
		height: 1.6875rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 0 0;
	}

	.app-header-wrap .header-title,
	.app-header-wrap .btn-back {
		color: #fff;
	}

	.header-title {
		box-sizing: border-box;
		padding: 0 3.25rem;
		max-width: 100%;
		overflow: hidden;
		font-size: 1rem;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.app-header-wrap .app-header-right {
		right: .875rem;
	}

	.app-header-wrap .app-header-left,
	.app-header-wrap .app-header-right {
		position: absolute;
		top: 0;
		bottom: 0;
	}

	.app-header-wrap .app-header-left:before,
	.app-header-wrap .app-header-right:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.content-wrap {
		// display: flex;
		// flex: 1 1 auto;
		// flex-direction: column;
		// overflow: hidden;
	}

	// .card-hall,
	// .card-landscape-inner {
	// 	display: flex;
	// 	flex: 1 1 auto;
	// 	flex-direction: column;
	// 	overflow: hidden;
	// }


	.card-hall {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		overflow: hidden;
	}


	// add

	.recommend-game-banner,
	.recommend-game-banner {
		background-color: #1c5cfb;
	}

	.recommend-game-banner {
		flex: 0 0 6.375rem;
		box-sizing: border-box;
		padding: .625rem 0 .625rem .6875rem;
		overflow-x: scroll;
		white-space: nowrap;
	}

	.recommend-game-banner .game-entry {
		padding-right: .6875rem;
	}

	.game-entry {
		display: inline-block;
		vertical-align: top;
		padding-right: .625rem;
		width: 3.75rem;
	}

	.recommend-game-banner .game-entry .game-entry-inner {
		width: 3.625rem;
		height: 3.625rem;
	}

	.game-entry .game-entry-nickname {
		position: absolute;
		bottom: .4375rem;
		left: 50%;
		margin-left: -1.5rem;
		border-radius: 6.25rem;
		width: 3rem;
		height: .6875rem;
		line-height: .6875rem;
		overflow: hidden;
		background-color: rgba(6, 1, 5, .6);
		color: #fff;
		font-size: .75rem;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.recommend-game-banner .game-entry .game-entry-nickname {
		bottom: .3125rem;
	}

	.recommend-game-banner .game-entry .game-entry-name,
	.recommend-game-banner .game-entry .game-entry-name {
		color: #fff;
	}

	.recommend-game-banner .game-entry .game-entry-name {
		margin-top: .125rem;
		line-height: 1.375rem;
	}

	.game-entry .game-entry-name {
		padding: 0 .3125rem;
		overflow: hidden;
		color: #989898;
		font-size: .75rem;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
	}


	.category-main,
	.category-main {
		background: #fff;
	}

	.category-main {
		display: flex;
		flex: 0 0 auto;
	}


	.category-main .category-main-item.active,
	.category-main .category-main-item.active {
		color: #2472fc;
	}

	.category-main .category-main-item,
	.category-main .category-main-item {
		color: #a4aabb;
	}

	.category-main-item,
	.category-main-item {
		position: relative;
	}

	.category-main .category-main-item {
		display: flex;
		box-sizing: border-box;
		border-bottom: .1875rem solid transparent;
		padding: .75rem 0 .5625rem;
		font-size: .875rem;
		text-align: center;
		align-items: center;
		justify-content: center;
	}

	.category-main .category-main-item.active:after,
	.category-main .category-main-item.active:after {
		background: linear-gradient(90deg, #4eaafc, #1c5cfb);
	}

	.category-main-item:after,
	.category-main-item:after {
		content: "";
		position: absolute;
		bottom: -.1875rem;
		left: 0;
		width: 100%;
		height: .1875rem;
	}

	.category-main-item:after,
	.category-main-item:after {
		content: "";
		position: absolute;
		bottom: -.1875rem;
		left: 0;
		width: 100%;
		height: .1875rem;
	}

	.list-filter,
	.list-filter {
		background: linear-gradient(#e7e7e7, #dedede);
	}

	.list-filter {
		padding: .4375rem 0;
	}

	.list-filter .list-filter-inner {
		position: relative;
		margin: 0 auto;
		border-radius: .25rem;
		padding: .125rem;
		width: 80%;
		max-width: 29.4375rem;
		background: #fff;
		font-size: .75rem;
		text-align: center;
	}

	.list-filter input {
		box-sizing: border-box;
		border: 0;
		padding: 0 1.5625rem;
		width: 100%;
		height: 1.3125rem;
		outline: none;
	}

	.list-filter .list-filter-placeholder,
	.list-filter .list-filter-placeholder {
		color: #a4aabb;
	}

	.list-filter .list-filter-placeholder.enter {
		left: .3125rem;
		margin-left: 0;
		width: .9375rem;
		height: .9375rem;
	}

	.list-filter .list-filter-placeholder {
		position: absolute;
		top: .25rem;
		left: 50%;
		margin-left: -3.125rem;
		width: 6.25rem;
		color: #989898;
		letter-spacing: 1px;
	}

	.list-filter .list-filter-placeholder .icon-search {
		margin-right: .625rem;
		color: #e9e9e9;
		font-size: .875rem;
	}

	.list-filter .list-filter-clear {
		position: absolute;
		top: 50%;
		right: .875rem;
		margin-top: -.5625rem;
		color: #9f9f9f;
		font-size: 1.125rem;
	}

	.card-context {
		display: flex;
		flex: 1 1 auto;
		overflow: hidden;
		height: calc(100vh - 11rem);
	}

	.vendors,
	.vendors {
		color: #5c6687;
	}

	.vendors,
	.vendors {
		box-sizing: border-box;
		padding-top: .8125rem;
		text-align: right;
	}

	.card-vendors {
		flex: 0 0 19%;
	}

	.vendors {
		position: relative;
		width: 19%;
		overflow: auto;
		font-size: .8125rem;
		// height: calc(100% - 6.5rem);
	}

	.vendors .vendor-item.active {
		background-image: url("/static/image/woUh0Ir8.png");
		color: #fff;
	}

	.vendors .vendor-item {
		background-image: url("/static/image/CZ1THCV0.png");
	}


	.vendors .vendor-item {
		padding: 1rem 10%;
		line-height: 1.125rem;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.vendors .vendor-item {
		position: relative;
		display: inline-block;
		box-sizing: border-box;
		margin-bottom: .125rem;
		padding: .25rem 0 .5rem;
		width: 4.0625rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 50% 50%;
		font-size: .75rem;
		text-align: center;
	}

	.vendors .vendor-item {
		padding: 0;
		height: 3.5625rem;
	}

	.vendors .vendor-item .vendor-item-icon {
		display: block;
		margin: 0 auto;
		max-width: 68%;
	}

	.vendors .vendor-item span {
		position: absolute;
		bottom: .375rem;
		left: 0;
		box-sizing: border-box;
		padding: 0 .25rem;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	// casino add

	.casino-game-list {
		position: relative;
		flex: 0 0 81%;
		overflow-y: auto;
	}

	.game-list-inner {
		display: grid;
		margin: 0 auto;
		padding-bottom: 1.875rem;
		grid-template-columns: repeat(auto-fill, 4.375rem);
		justify-content: center;
	}

	.event-wallscape {
		display: inline-block;
		box-sizing: border-box;
		margin-top: .9375rem;
		padding: .125rem .25rem 0;
		width: 8.75rem;
	}

	.game-list-inner .event-wallscape {
		display: flex;
		grid-column: 1 / 3;
		border-radius: .25rem;
		align-items: center;
		background-color: #e9e9e9;
	}

	// swiper

	// swiper

	.game-entry {
		display: inline-block;
		vertical-align: top;
		padding-right: .625rem;
		width: 3.75rem;
	}

	.game-list-inner .game-entry {
		margin-top: .9375rem;
		padding-right: 0;
		width: 4.375rem;
		font-size: .75rem;
	}

	.game-entry .game-entry-inner {
		position: relative;
		margin: 0 auto;
		width: 3.75rem;
		height: 3.75rem;
	}

	.game-entry .game-entry-inner .game-entry-image {
		display: block;
		width: 100%;
		height: 100%;
	}

	.game-entry .game-entry-name {
		padding: 0 .3125rem;
		overflow: hidden;
		color: #989898;
		font-size: .75rem;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.casino-game-list .game-entry .game-entry-name,
	.card-game-list .game-entry .game-entry-name {
		color: #3b3b3b;
	}

	.game-list-inner .game-entry .game-entry-name {
		line-height: 1.375rem;
		font-size: .875rem;
	}

	// casino end
</style>