import axios from 'axios' //引入
import store from '@/store/index.js'
import {
	Dialog
} from 'vant';
import router from './../router'

// let baseURL = 'http://waplin.java-nb.top/'
let baseURL = 'https://apiv.hy3915.cc/'

let local = localStorage.getItem("local") || 'zh_cn'

localStorage.setItem("baseURL", baseURL)

localStorage.setItem("local", local)

//这一步的目的是判断出当前是开发环境还是生成环境，方法不止一种，达到目的就行
// if(process.env.NODE_ENV=="development"){
//   baseURL=''
// }

let config = {
	baseURL: baseURL,
	timeout: 30000 //设置最大请求时间
}

const _axios = axios.create(config)

/* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(

	config => {
		//如果有需要在这里开启请求时的loading动画效果
		// Authorization:Bearer hPxf6a8TQnCkExTYxceujYgXoW9GXB9909zsZtpvabw6yO9ngcMVKgLx4mzD
		let token = localStorage.getItem('token') ? localStorage.getItem('token') : ''
		config.headers.Authorization = 'Bearer ' + token; //添加token,需要结合自己的实际情况添加，
		return config;
	},
	
	err => Promise.reject(err)

);


/* 请求之后的操作 */

_axios.interceptors.response.use(

	res => {
		// 在这里关闭请求时的loading动画效果
		// console.log('Response Status Code:', res.status); // 获取状态码
		// 处理特定状态码的逻辑
		if (res.status === 401) {
			Dialog.alert({
				title: '认证失败',
				message: '您的账号登陆过期，请重新登陆1',
			}).then(() => {
				
				localStorage.clear();
				// sessionStorage.clear();
				router.replace('/login');
			});
		}
		// 正常返回
		return res;
	},
	
	err => {
		if (err.response) {
			
			// 处理 422 状态码
			if (err.response.status === 422) {
				// console.error(err.response.data.message); // 打印错误消息
				return Promise.resolve(err.response); // 正常返回数据
			}
			
			if (err.response.status === 400) {
				// console.error(err.response.data.message); // 打印错误消息
				return Promise.resolve(err.response); // 正常返回数据
			}
			
			if (err.response.status === 401) {
				
				// 如果等于定时请求
				if(err.config.url == '/api/game/balance?lang=zh_cn'){
					
					return Promise.resolve(err.response); // 正常返回数据
					
				}else{
					Dialog.alert({
						title: '认证失败',
						message: '您的账号登陆过期，请重新登陆2',
					}).then(() => {
						localStorage.clear();
						// sessionStorage.clear();
						router.replace('/login');
					});
				}
				
			}

			// 处理其他状态码
			if (err.response.status === 404) {
				
				console.error('请求的资源未找到');
				
			} else if (err.response.status === 500) {
				
				console.error('服务器内部错误');
				
			}
			
		} else {
			
			console.error('请求网络失败');
			
		}
		return Promise.reject(err);
	}
);




//封装post,get方法
const http = {

	get(url = '', params = {}) {

		// console.log(JSON.stringify(url));

		url + '&lang=' + localStorage.getItem('local') || 'zh_cn';

		return new Promise((resolve, reject) => {

			_axios({
				url,
				params,
				headers: {
					'Content-Type': 'application/json;charset=UTF-8'
				},
				method: 'GET'
			}).then(res => {

				resolve(res.data)
				// return res

			}).catch(error => {

				reject(error)

			})

		})

	},

	post(url = '', params = {}) {
		
		// if (url == '/api/auth/login' || url == '/api/login_pc') {
		// 	sessionStorage.setItem("baseURL", baseURL)
		// }
		
		url + '&lang=' + localStorage.getItem('local') || 'zh_cn';
		
		return new Promise((resolve, reject) => {

			_axios({

				url,
				data: params,
				headers: {
					'Content-Type': 'application/json;charset=UTF-8'
				},
				method: 'POST',

			}).then(res => {

				resolve(res.data)
				// return res

			}).catch(error => {

				reject(error)

			})

		})

	}

}
export default http