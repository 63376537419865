<template>


	<div v-if="isVisible" class="popmsg-dialogbox">
		<div class="dialogbox-wrapper">
			<div class="dialogbox-header">{{title}}</div>
			<div class="dialogbox-content">{{message}}</div>
			<div class="dialogbox-footer" :class="{'prompt':prompt}">
				<div v-if="prompt" class="dialogbox-button btn-cancel" @click="close">{{cancel}}</div>
				<div class="dialogbox-button btn-confirm" @click="close">{{confirm}}</div>
			</div>
		</div>
	</div>


</template>

<script>
	export default {
		data() {
			return {
				isVisible: false,
				title: '', // 用于存储传入的标题
				message: '', // 用于存储传入的消息
				confirm: '', // 用于存储传入的消息
				cancel: '', // 用于存储传入的消息
				prompt: false
			};
		},
		methods: {

			open(title = 'Default Title', message = 'Default Message', confirm = 'confirm', prompt = false, cancel = '') {
				this.title = title;
				this.message = message;
				this.confirm = confirm;
				this.prompt = prompt;
				this.cancel = cancel;
				this.isVisible = true; // 打开弹窗
			},
			close() {
				this.isVisible = false; // 关闭弹窗
			}
		}
	};
</script>

<style scoped>
	/* 添加样式 */
	.popmsg-dialogbox {
		position: fixed;
		z-index: 2000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #000;
		background: rgba(0, 0, 0, .7);
		text-align: center;
	}

	.popmsg-dialogbox:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.popmsg-dialogbox .dialogbox-wrapper {
		background: #fff;
	}

	.popmsg-dialogbox .dialogbox-wrapper {
		display: inline-flex;
		flex-direction: column;
		vertical-align: middle;
		width: 88%;
		max-width: 31.25rem;
		max-height: 85%;
	}

	.popmsg-dialogbox .dialogbox-header {
		background: #2472fc;
		color: #fff;
	}

	.popmsg-dialogbox .dialogbox-header {
		padding: .625rem 1.25rem;
		font-size: 1.25rem;
	}

	.popmsg-dialogbox .dialogbox-content {
		color: #323747;
	}

	.popmsg-dialogbox .dialogbox-content {
		flex: 1 1 auto;
		box-sizing: border-box;
		padding: .9375rem 1.25rem;
		overflow-y: scroll;
		font-size: 1rem;
		font-weight: 700;
		word-break: break-word;
	}

	.popmsg-dialogbox .dialogbox-footer {
		padding: 0;
	}


	.popmsg-dialogbox .dialogbox-footer .dialogbox-button {
		border-radius: 1px;
		padding: .6875rem 0;
		width: 100%;
		line-height: 1.4375rem;
	}

	.popmsg-dialogbox .dialogbox-footer .dialogbox-button {
		margin: 1.25rem auto;
		width: 45%;
	}

	.popmsg-dialogbox .dialogbox-footer .dialogbox-button {
		border-radius: .3125rem;
		background: linear-gradient(#4eaafc, #1c5cfb);
		color: #fff;
	}


	.popmsg-dialogbox .dialogbox-footer.prompt {
		display: flex;
	}

	.popmsg-dialogbox .dialogbox-footer.prompt .btn-cancel {
		background: linear-gradient(#c2cae2, #8394c4);
	}

	.popmsg-dialogbox .dialogbox-footer.prompt .dialogbox-button {
		margin: 1rem 3%;
		width: 44%;
	}
</style>