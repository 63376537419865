<template>

	<div>

		<div class="index">

			<!-- 下载 -->
			<div class="app-download-banner" v-show="showDown">

				<svg @click="setshowDown()" class="svg-inline--fa fa-xmark fa-w-10 app-download-close-btn"
					aria-hidden="true" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 320 512">

					<path class="" fill="currentColor"
						d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z">
					</path>

				</svg>

				<img class="app-download-icon" src="down.png">
				<div class="app-download-text">最顶尖的APP 方寸之间 从容尽显</div>

				<a class="app-download-btn" href="/web/simple.php#/aioDownload">{{$t('点我下载')}}</a>

			</div>

			<!-- 头部 -->

			<div class="index-head-box" ref="headFloat">

				<div :class="['index-head', isHeadFloating ? ' index-head-float' : '']">
					<div @click="changeShowPop">
						<svg class="svg-inline--fa fa-bars fa-w-14 app-sidemenu-btn" aria-hidden="true"
							data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 448 512">
							<path class="" fill="currentColor"
								d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z">
							</path>
						</svg>
					</div>
					<img src="/static/image/big-logo.png" alt="" />
					<div class="index-head-right" @click="setshowBalance()">{{$t('余额')}}</div>
				</div>

			</div>

			<!-- 首页内容 -->
			<div class="index-main">

				<!-- 轮播 -->
				<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">

					<van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="bannerClick(item)">
						<img :src="item.url" alt="" />
					</van-swipe-item>

				</van-swipe>


				<!-- 通知栏 -->
				<div class="notice-box">
					<!-- homenoticelis -->
					<van-notice-bar left-icon="/static/image/notice.png" scrollable :text="homenoticelistext" />
					<div class="notice-shortener">
						<span>HY3915.CC</span>
					</div>
				</div>

				<!-- 账户信息 -->
				<div class="index-card">

					<div class="index-card-left" v-if="isLogin">
						<p class="index-card-left-name">{{userinfo.name}}</p>
						<p class="index-card-left-num">￥{{userinfo.money}}</p>
					</div>

					<div class="index-card-login-box" v-if="!isLogin">

						<div class="index-card-login-top">
							<div class="index-card-login-btn index-card-login" @click="$parent.goNav('/login?type=0')">
								{{$t('登录')}}
							</div>
							<div class="index-card-login-btn index-card-register"
								@click="$parent.goNav('/login?type=1')">{{$t('注册')}}
							</div>
						</div>
						<span>{{$t('早上好，请先登入或注册')}}</span>

					</div>

					<div class="index-card-right">

						<div class="index-card-item" @click="$parent.goNav('/withdrawal')">
							<img src="/static/image/card-withdrawal.png" alt="" />
							<p v-if="getlanguage != 'en_us'">{{$t('提现')}}</p>
						</div>
						<div class="index-card-item" @click="$parent.goNav('/fanshui')">
							<img src="/static/image/card-my_rakeback.png" alt="" />
							<p v-if="getlanguage != 'en_us'">{{$t('返水')}}</p>
						</div>
						<div class="index-card-item" @click="$parent.goNav('/promotion/222')">
							<img src="/static/image/card-offer.png" alt="" />
							<p v-if="getlanguage != 'en_us'">{{$t('福利')}}</p>
						</div>
						<div class="index-card-item" @click="openlocalurl('/netkf.html')">
							<img src="/static/image/card-service.png" alt="" />
							<p v-if="getlanguage != 'en_us'">{{$t('客服')}}</p>
						</div>

					</div>

				</div>

				<!-- 选项卡 -->


				<!-- <div class="platform-category-selector" ref="tabbarFloat">
					
					<div class="category-item active">
						<img src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/platform_popular_active.png?v=35">
					</div>
					
					<div class="category-item"><img
							src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/platform_casino.png?v=35">
					</div>
					<div class="category-item"><img
							src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/platform_live.png?v=35">
					</div>
					<div class="category-item"><img
							src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/platform_fisharea.png?v=35">
					</div>
					<div class="category-item"><img
							src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/platform_card.png?v=35">
					</div>
					
					<div class="category-item"><img
							src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/platform_ball.png?v=35">
					</div>
					
					<div class="category-item">
						<img
							src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/platform_lottery.png?v=35">
					</div>
					
				</div> -->


				<div class="index-tabbar-box" ref="tabbarFloat">

					<div :class="['index-tabbar',isTabbarFloating ? 'index-tabbar-float' : '']">

						<div v-if="getlanguage == 'en_us'"
							:class="['index-tabbar-item',tabbarIndex == index ? 'index-tabbar-act' : '']"
							@click="changeTabbar(index)" v-for="(item, index) in gametype" :key="index">
							<img v-show="tabbarIndex != index" style="width: 1.6875rem;height: 1.6875rem;"
								:src="item.icon_before" alt="" />
							<img v-show="tabbarIndex == index" style="width: 1.6875rem;height: 1.6875rem;"
								:src="item.icon_after" alt="" />


						</div>


						<div v-if="getlanguage != 'en_us'"
							:class="['index-tabbar-item',tabbarIndex == index ? 'index-tabbar-act' : '']"
							@click="changeTabbar(index)" v-for="(item, index) in gametype" :key="index">

							<!-- <img v-show="tabbarIndex != index" :src="gametypepic[index].img1" alt="" />
							<img v-show="tabbarIndex == index" :src="gametypepic[index].img2" alt="" /> -->
							<img v-show="tabbarIndex != index" :src="item.icon_before" alt="" />
							<img v-show="tabbarIndex == index" :src="item.icon_after" alt="" />
							<span>{{item.title}}</span>

						</div>



					</div>
				</div>


				<!-- 选项内容 -->
				<div class="index-content">

					<!-- 热门 -->
					<div class="index-content-box">

						<div class="index-content-scroll" ref="hot"></div>

						<div class="index-first">

							<div class="index-first-item" v-for="(item, index) in gamelist[0]" :key="index">

								<div class="index-first-img-box" @click="$parent.openGamePlay(item)">
									<!-- <img src="/static/image/hotimg.png" alt="" /> -->
									<img style="width: 3.85rem;height: 3.85rem;" :src="item.mobile_pic" alt="" />
									<span>{{item.class_name.toUpperCase()}}</span>
								</div>

								<div class="index-first-title">{{item.title}}</div>


								<!-- <div :class="['index-first-btn', isShouCang ? 'first-btn-add' : '']" @click="shouCang">
									
									<svg class="svg-inline--fa fa-star fa-w-18" aria-hidden="true" data-prefix="fas"
										data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 576 512">
										<path class="" fill="currentColor"
											d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z">
										</path>
									</svg>
									
								</div> -->



							</div>

						</div>

					</div>
					<!-- 电子 -->
					<div class="index-content-box">

						<div class="index-content-scroll" ref="dianzi"></div>

						<div class="index-secend">

							<!-- 本站推荐 -->
							<div class="platform-item" v-for="(item, index) in gamelist[1].slice(0,1)" :key="index">
								<div class="platform-item-placeholder" @click="gopage('/recommend')">
									<div class="platform-item-wrapper"
										:style="{ backgroundImage: `url(${item.mobile_pic})` }">
										<span>{{ item.title }}</span>
									</div>

								</div>
							</div>

							<div class="platform-item" v-for="(item, index) in gamelist[1].slice(1,gamelist[1].length)"
								:key="index">

								<div class="platform-item-placeholder" @click="gocasino('/casino',item)">
									<div class="platform-item-wrapper"
										:style="{ backgroundImage: `url(${item.mobile_pic})` }">
										<span>{{ item.title }}</span>
									</div>
								</div>
							</div>

						</div>

					</div>

					<!-- 视讯 -->
					<div class="index-content-box">

						<div class="index-content-scroll" ref="shixun"></div>

						<div class="index-secend">
							<!-- 这里直接进游戏 -->
							<div class="platform-item" v-for="(item, index) in gamelist[2]" :key="index">
								<div class="platform-item-placeholder" @click="$parent.openGamePlay(item)">
									<div class="platform-item-wrapper"
										:style="{ backgroundImage: `url(${item.mobile_pic})` }">
										<span>{{ item.title }}</span>
									</div>
								</div>
							</div>

						</div>

					</div>

					<!-- 捕鱼 -->

					<div class="index-content-box">
						<div class="index-content-scroll" ref="buyu"></div>
						<div class="index-first">

							<div class="index-first-item" v-for="(item, index) in gamelist[3]" :key="index">

								<div class="index-first-img-box" @click="$parent.openGamePlay(item)">
									<!-- <img src="/static/image/hotimg.png" alt="" /> -->
									<img style="width: 3.85rem;height: 3.85rem;" :src="item.mobile_pic" alt="" />
									<span>{{item.class_name.toUpperCase()}}</span>

								</div>

								<div class="index-first-title">{{item.title}}</div>

								<!-- <div :class="['index-first-btn', isShouCang ? 'first-btn-add' : '']" @click="shouCang">
									<svg class="svg-inline--fa fa-star fa-w-18" aria-hidden="true" data-prefix="fas"
										data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 576 512">
										<path class="" fill="currentColor"
											d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z">
										</path>
									</svg>
								</div> -->

							</div>


						</div>
					</div>


					<!-- 棋牌 -->

					<div class="index-content-box">
						<div class="index-content-scroll" ref="qipai"></div>
						<div class="index-secend">

							<div class="platform-item" v-for="(item, index) in gamelist[4]" :key="index">
								<div class="platform-item-placeholder" @click="gocate('/card',item)">

									<div class="platform-item-wrapper"
										:style="{ backgroundImage: `url(${item.mobile_pic})` }">
										<span>{{ item.title }}</span>
									</div>

								</div>

							</div>


						</div>
					</div>

					<!-- 体育 -->
					<div class="index-content-box">

						<div class="index-content-scroll" ref="tiyu"></div>

						<div class="index-secend">

							<div class="platform-item" v-for="(item, index) in gamelist[5]" :key="index">
								<div class="platform-item-placeholder" @click="$parent.openGamePlay(item)">
									<div class="platform-item-wrapper"
										:style="{ backgroundImage: `url(${item.mobile_pic})` }">
										<span>{{ item.title }}</span>
									</div>
								</div>
							</div>

							<!-- <div class="index-secend-item secend-activity">
								<span>JDB电子</span>
							</div>
							<div class="index-secend-item">
								<span>JDB电子</span>
							</div>
							<div class="index-secend-item secend-activity">
								<span>JDB电子</span>
							</div> -->

						</div>

					</div>
					<!-- 彩票 -->

					<div class="index-content-box">

						<div class="index-content-scroll" ref="caipiao"></div>

						<div class="index-secend">

							<div class="platform-item" v-for="(item, index) in gamelist[6]" :key="index">
								<div class="platform-item-placeholder" @click="$parent.openGamePlay(item)">
									<div class="platform-item-wrapper"
										:style="{ backgroundImage: `url(${item.mobile_pic})` }">
										<span>{{ item.title }}</span>
									</div>
								</div>
							</div>

							<!-- <div class="index-secend-item secend-activity">
								<span>JDB电子</span>
							</div>
							<div class="index-secend-item">
								<span>JDB电子</span>
							</div>
							<div class="index-secend-item secend-activity">
								<span>JDB电子</span>
							</div> -->

						</div>

					</div>

				</div>
			</div>

		</div>

		<!-- 回到顶部 -->
		<div v-show="goTopShow" class="scroll-top" @click="scrollToTop">Top</div>


		<!-- 左边弹出层 -->
		<van-popup v-model="showPop" position="left">
			<!-- 额度相关 -->
			<div class="pop-box">
				<div class="pop-top">
					<div class="pop-top-click">
						<div class="pop-top-close" @click="showPop = false">
							<svg class="svg-inline--fa fa-xmark fa-w-10 close-btn" aria-hidden="true" data-prefix="fas"
								data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
								<path class="" fill="currentColor"
									d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z">
								</path>
							</svg>
						</div>
						<div class="pop-top-gofankui">
							<img src="/static/image/fankui.png" @click="gopage('/feedback')" />
						</div>
					</div>

					<div class="pop-top-name" v-if="isLogin">{{userinfo.name}}</div>

				</div>

				<div class="pop-list">

					<!-- <div class="pop-list-item" @click="gopage('/language')">{{$t('安装')}}PG电子-官方直营</div> -->
					<div class="pop-list-item" @click="openlocalurl('/kf.html')">{{$t('7x24小时备用客服')}}</div>
					<div class="pop-list-item" @click="gopage('/recharge')">{{$t('电子钱包')}}</div>

					<div class="pop-list-item" @click="openlocalurl('/findsviplevel.html')">{{$t('SVIP钱包等级')}}</div>

					<div class="pop-list-item" @click="gopage('/promotion/2')">{{$t('我的优惠')}}</div>
					<div class="pop-list-item" @click="gopage('/recharge')">{{$t('快速存款')}}</div>
					<div class="pop-list-item" @click="gopage('/withdrawal')">{{$t('线上取款')}}</div>
					<div class="pop-list-item" @click="gopage('/mine')">{{$t('会员中心')}}</div>
					<div class="pop-list-item" @click="gopage('/promotion/recommend')">{{$t('新会员推荐')}}</div>
					<div class="pop-list-item" @click="openlocalurl('/netkf.html')">{{$t('在线客服')}}</div>

					<div class="pop-list-item pop-list-app">
						{{$t('APP下载')}}
						<div class="pop-app-download">
							<div class="pop-app-download-item" @click="gopage(false)">{{$t('安装')}}</div>
							<div class="pop-app-download-item" @click="gopage(false)">{{$t('开启')}}</div>
						</div>
					</div>

					<div class="pop-list-item" @click="openlocalurl('/ub.html')">{{$t('寰宇浏览器')}}</div>
					<!-- <div class="pop-list-item" @click="gopage(false)">{{$t('CG钱包下载')}}</div> -->
					<div class="pop-list-item" @click="gopage(false)">{{$t('前往电脑版')}}</div>

					<!-- <div class="pop-list-item" @click="gopage('/language')">{{$t('选择语系')}}</div> -->

					<div class="pop-btn-box" v-if="isLogin">
						<div class="pop-btn" @click="$parent.outLogin">{{$t('登出')}}</div>
					</div>

				</div>
			</div>

		</van-popup>

		<!-- 右边弹出层 -->
		<Balance :showBalance="showBalance" @closeBalance="showBalance = false" />
		<!-- 右边弹出层 -->

		<!-- v-if="$store.state.appInfo.index_modal == 1 && tanshow" -->
		<div class="bulletin" v-if="tanshow">

			<div class="bulletin-wrapper keep-px">
				<div class="bulletin-header">平台公告</div>
				<div class="bulletin-body">
					<div class="bulletin-title">🌕【中秋月圆 礼遇丰盈 】五大钜惠已火热进行中🌕</div>
					<div class="bulletin-content">
						<div>
							<p><img alt="" src="http://2015885.com/55/zhongqiutan.jpg" style="width:100%"></p>
						</div>
					</div>
				</div>

				<div class="bulletin-more" @click="$parent.goNav('/message')">查看更多</div>

				<svg class="svg-inline--fa fa-circle-xmark fa-w-16 bulletin-close" aria-hidden="true" data-prefix="far"
					data-icon="circle-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
					@click="changtanshow">
					<path class="" fill="currentColor"
						d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z">
					</path>
				</svg>

			</div>

		</div>
		<!-- 官网弹窗 -->

		<!-- 红包 -->
		<RedBall />

		<!-- footer -->
		<Foot />

	</div>
</template>
<script>
	import RedBall from '../libs/RedBall.vue';
	import Foot from '../libs/Foot.vue';
	import Balance from '../libs/Balance.vue'
	import {
		Dialog
	} from 'vant';

	export default {
		name: "index",
		data() {
			return {

				// 轮播图
				bannerList: [],
				// 公告
				homenoticelis: [],
				// 新加
				homenoticelistext: '',

				gametype: [],

				gamelist: [],

				gametypesort: ["热门", "电子", "真人", "捕鱼", "棋牌", "体育", "彩票"],

				gametypepic: [{
						img1: '/static/image/platform_popular.png',
						img2: '/static/image/platform_popular_active.png',
					},
					{
						img1: '/static/image/platform_casino.png',
						img2: '/static/image/platform_casino_active.png',
					},
					{
						img1: '/static/image/platform_live.png',
						img2: '/static/image/platform_live_active.png',
					},
					{
						img1: '/static/image/platform_fisharea.png',
						img2: '/static/image/platform_fisharea_active.png',
					},
					{
						img1: '/static/image/platform_card.png',
						img2: '/static/image/platform_card_active.png',
					},
					{
						img1: '/static/image/platform_ball.png',
						img2: '/static/image/platform_ball_active.png',
					},
					{
						img1: '/static/image/platform_lottery.png',
						img2: '/static/image/platform_lottery_active.png',
					},

					{
						img1: '/static/image/platform_lottery.png',
						img2: '/static/image/platform_lottery_active.png',
					},
					{
						img1: '/static/image/platform_lottery.png',
						img2: '/static/image/platform_lottery_active.png',
					}, {
						img1: '/static/image/platform_lottery.png',
						img2: '/static/image/platform_lottery_active.png',
					},
				],

				showDown: true,
				isHeadFloating: false,
				headHeight: 0,
				isTabbarFloating: false,
				tabbarHeight: 0,
				tabbarIndex: 0,
				adeventScroll: true,
				scrollOut: "",
				isShouCang: false,
				goTopShow: false,
				showPop: false,
				showBalance: false,
				tanshow: false,
			};
		},
		components: {
			RedBall,
			Foot,
			Balance
		},

		computed: {
			userinfo() {
				return this.$store.state.userInfo
			},
			//内容高度
			isLogin() {
				return this.$store.state.token;
			},

			getlanguage() {
				return localStorage.getItem('local');
			},
		},

		created() {

			let that = this;

			that.getBanList(); //获取banner
			that.homenotice(); //获取公告
			that.getGameList(); //获取游戏列表和数据

			// 中秋平台公告
			var tanshows = localStorage.getItem('tanshow');
			if (tanshows) {
				that.tanshow = false;
			} else {
				that.tanshow = true;
			}

			var showDown = localStorage.getItem('showDown');
			if (showDown) {
				that.showDown = false;
			} else {
				that.showDown = true;
			}

		},

		mounted() {

			window.addEventListener("scroll", this.handleScroll);
			this.headHeight = this.$refs.headFloat.offsetHeight;
			this.tabbarHeight = this.$refs.tabbarFloat.offsetHeight;

		},

		methods: {
			// 直接进入游戏
			gogameplay(item) {

				let that = this;

				that.$apiFun
					.post('/api/getGameUrl', {
						plat_name: name,
						game_type: type || 0,
						game_code: code,
						is_mobile_url: 1
					})
					.then(res => {
						// console.log(res);
						if (res.code != 200) {
							that.$parent.showTost(0, res.message);
						}
						if (res.code == 200) {
							that.url = res.data.url;
						}
					})
					.catch(res => {});

			},

			setshowBalance() {

				let that = this;

				if (that.isLogin) {

					that.showBalance = true;

				} else {

					that.gopage("/login");

				}

			},

			setshowDown() {

				this.showDown = false;
				localStorage.setItem('showDown', false);

			},

			openlocalurl(url) {

				window.location.href = url; // 访问 public 文件夹中的 myfile.html

			},

			loginout() {

				var that = this;

				that.$apiFun.post('/api/auth/logout', {}).then((res) => {

					if (res.code !== 200) {

						that.$refs.globalmodal.open('讯息', res.message, '确定'); // 传入标题和消息
						return;

					}

					if (res.code === 200) {

						localStorage.removeItem("token")

						localStorage.removeItem("userInfo")

						that.$store.commit("changToken", false)

						// 刷新当前页面
						that.$parent.refreshPage()

					}

					that.$parent.hideLoading()

				})

			},

			// 轮播图跳转
			bannerClick(item) {

				if (item.jump_link) {

					if (item.is_new_window) {

						window.open(item.jump_link, '_blank'); // 在新窗口中打开链接

					} else {

						window.location.href = item.jump_link; // 在当前窗口中打开链接

					}

				}

			},
			// casino
			gocasino(url, item) {
				let that = this;
				// 使用 query 传递 item 参数
				that.$router.push({
					path: url,
					query: {
						class_name: item.class_name, // 传递 class_name 参数
						game_type: JSON.parse(item.params).gameType, // 传递其他参数
					}
				});
			},
			// 
			gocate(url, item) {
				let that = this;
				// 使用 query 传递 item 参数
				that.$router.push({
					path: url,
					query: {
						class_name: item.class_name, // 传递 class_name 参数
						game_type: JSON.parse(item.params).gameType, // 传递其他参数
					}
				});
			},

			gopage(url) {

				let that = this;

				if (url == false) {

					Dialog.alert({
						title: '提示🔔',
						message: '当前暂未开放',
					}).then(() => {

						// localStorage.clear();
						// sessionStorage.clear();
						// router.replace('/login');

					});
					return;

				}

				if (url == this.$route.fullPath) {
					return;
				}

				that.$router.push({
					path: url
				});

			},

			changtanshow() {
				this.tanshow = false;
				localStorage.setItem('tanshow', false);
			},

			getBanList() {
				let that = this;
				that.$parent.showLoading();
				that.$apiFun.get("/api/banners", {
						group: 'mobile1'
					})
					.then((res) => {
						// console.log(JSON.stringify(res));
						// if (res.code != 200) {
						// 	that.showTost(0, res.message);
						// }
						if (res.code == 200) {
							that.bannerList = res.data;
						}
						// that.$parent.hideLoading();
					})
					.catch((res) => {
						// that.$parent.hideLoading();
					});
			},

			// 公告接口

			homenotice() {

				let that = this;

				that.$apiFun.get("/api/system/notices", {}).then((res) => {
					// console.log(JSON.stringify(res));
					// if (res.code != 200) {
					// 	that.showTost(0, res.message);
					// }

					if (res.code == 200) {
						that.homenoticelis = res.data;
						that.homenoticelistext = res.data[0].content;
					}

				});

			},
			// 获取游戏列表
			getGameList() {

				let that = this;

				that.$apiFun.get('/api/games', {}).then(res => {

					if (res.code == 200) {

						const gametypearr = [];

						const gamelistarr = [];

						const result = res.data;

						const cardlist = [];

						const casinolist = [];

						const gsort = ["热门", "电子", "真人", "捕鱼", "棋牌", "体育", "彩票"];

						gsort.map((itemt, index1) => {

							console.log(itemt + '/' + gsort[index1]);

							result.map((itemn, index2) => {

								// console.log(gsort[index2]);

								if (itemt == itemn.title) {
									// console.log(gametypesort[index]);
									gametypearr.push({
										title: itemn.title,
										icon_before: itemn.icon_before,
										icon_after: itemn.icon_after,
									});
									gamelistarr.push(itemn.child);

								}

								if (itemt == itemn.title && itemt == '电子') {

									itemn.child.map((item, index) => {
										casinolist.push({
											title: item.title,
											game_type: item.game_type,
											mobile_pic: item.mobile_pic,
											tags: item.tags,
											params: item.params,
											api_name: item.api_name,
											class_name: item.class_name,
											weight: item.weight,
											game_type_text: item.game_type_text,
											game_type_cn_text: item
												.game_type_cn_text,
											cimg1: ''
										});
									});

									// 需要快速存储到@store
									localStorage.setItem('casinolist', JSON.stringify(casinolist));
									that.$store.commit('changcasinolist');

								}

								if (itemt == itemn.title && itemt == '棋牌') {

									itemn.child.map((item, index) => {
										cardlist.push({
											title: item.title,
											game_type: item.game_type,
											mobile_pic: item.mobile_pic,
											tags: item.tags,
											params: item.params,
											api_name: item.api_name,
											class_name: item.class_name,
											weight: item.weight,
											game_type_text: item.game_type_text,
											game_type_cn_text: item
												.game_type_cn_text,
											cimg1: ''
										});
									});

									// 需要快速存储到@store
									localStorage.setItem('cardlist', JSON.stringify(cardlist));
									that.$store.commit('changcardlist');

								}



							})

						})


						that.gametype = gametypearr;
						// console.log(JSON.stringify(that.gametype));
						that.gamelist = gamelistarr;
						// console.log(JSON.stringify(that.gamelist));

					}

				});

			},


			// 监听滚动
			handleScroll() {

				const headElement = this.$refs.headFloat;
				const scrollTop =
					window.pageYOffset || document.documentElement.scrollTop;
				if (scrollTop > headElement.offsetTop) {
					this.isHeadFloating = true;
				} else {
					this.isHeadFloating = false;
				}

				const tabbarElement = this.$refs.tabbarFloat;
				if (tabbarElement.offsetTop - scrollTop <= this.headHeight) {
					this.isTabbarFloating = true;
				} else {
					this.isTabbarFloating = false;
				}

				// console.log('top',this.$refs.caipiao.getBoundingClientRect().top);
				// console.log('bottom',this.$refs.caipiao.getBoundingClientRect().bottom);

				if (this.adeventScroll) {

					if (this.$refs.caipiao.getBoundingClientRect().top < 500) {
						this.tabbarIndex = 6;
					} else if (this.$refs.tiyu.getBoundingClientRect().top <= 5) {
						this.tabbarIndex = 5;
					} else if (this.$refs.qipai.getBoundingClientRect().top <= 5) {
						this.tabbarIndex = 4;
					} else if (this.$refs.buyu.getBoundingClientRect().top <= 5) {
						this.tabbarIndex = 3;
					} else if (this.$refs.shixun.getBoundingClientRect().top <= 5) {
						this.tabbarIndex = 2;
					} else if (this.$refs.dianzi.getBoundingClientRect().top <= 5) {
						this.tabbarIndex = 1;
					} else {
						this.tabbarIndex = 0;
					}

				}

				if (scrollTop > 100) {
					this.goTopShow = true;
				} else {
					this.goTopShow = false;
				}
			},

			changeTabbar(index) {

				clearTimeout(this.scrollOut);
				this.adeventScroll = false;
				this.tabbarIndex = index;
				let contentElement = "";

				switch (index) {

					case 0:
						contentElement = this.$refs.hot;
						contentElement.scrollIntoView({
							behavior: "smooth"
						});
						break;

					case 1:
						contentElement = this.$refs.dianzi;
						contentElement.scrollIntoView({
							behavior: "smooth"
						});
						break;

					case 2:
						contentElement = this.$refs.shixun;
						contentElement.scrollIntoView({
							behavior: "smooth"
						});
						break;

					case 3:
						contentElement = this.$refs.buyu;
						contentElement.scrollIntoView({
							behavior: "smooth"
						});
						break;

					case 4:
						contentElement = this.$refs.qipai;
						contentElement.scrollIntoView({
							behavior: "smooth"
						});
						break;

					case 5:
						contentElement = this.$refs.tiyu;
						contentElement.scrollIntoView({
							behavior: "smooth"
						});
						break;

					case 6:
						contentElement = this.$refs.caipiao;
						contentElement.scrollIntoView({
							behavior: "smooth"
						});
						break;

					default:
						break;
				}

				this.scrollOut = setTimeout(() => {
					this.adeventScroll = true;
					clearTimeout(this.scrollOut);
				}, 500);

			},
			shouCang() {
				this.isShouCang = !this.isShouCang;
			},
			scrollToTop() {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			},
			changeShowPop() {
				this.showPop = true;
			},
		},
		updated() {
			let that = this;
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll);
		},
	};
</script>
<style lang="scss" scoped>
	// @import "/style.css";

	.index {

		background: #f4f6fa;
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		overflow-y: scroll;


		.app-download-banner {
			background: #fff;
			color: #3b3b3b;
			display: flex;
			flex: 0 0 3.125rem;
			padding: 0 .6875rem 0 .5rem;
			font-size: .75rem;
			align-items: center;

			.app-download-close-btn {
				color: #3b3b3b;
				flex: 0 0 1rem;
				vertical-align: middle;
				margin-right: .4375rem;
				font-size: 1rem;
			}

			.svg-inline--fa.fa-w-10 {
				width: 0.625em;
			}

			.svg-inline--fa {
				display: inline-block;
				height: 1em;
				overflow: visible;
				vertical-align: -.125em;
			}


			.app-download-icon {
				flex: 0 0 1.75rem;
				height: 1.75rem;
			}

			img {
				border: 0;
				border-style: none;
			}

			.app-download-text {
				flex-grow: 1;
				padding: 0 0 0 .9375rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.app-download-btn {
				background: linear-gradient(#c2cae2, #8394c4);
				color: #fff;
			}

			.app-download-btn {
				flex: 0 0 4.875rem;
				border-radius: .1875rem;
				padding: 0 .625rem;
				height: 1.5rem;
				line-height: 1.5rem;
				overflow: hidden;
				font-size: .75rem;
				text-align: center;
				text-overflow: ellipsis;
				text-decoration: none;
				white-space: nowrap;
			}
		}

		.index-head-box {
			position: relative;
			height: 3rem;

			.index-head {
				position: absolute;
				z-index: 10;
				width: 100%;
				padding: 0 0.8125rem;
				height: 3rem;
				box-sizing: border-box;
				background: #f4f6fa;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.svg-inline--fa {
					color: #2472fc;
					display: inline-block;
					vertical-align: middle;
					font-size: 1.625rem;
					width: 0.875em;
					height: 1em;
				}

				img {
					vertical-align: middle;
					width: 6.25rem;
					height: 1.9375rem;
				}

				.index-head-right {
					border: 1px solid #dae3ff;
					background: linear-gradient(169deg, #fdfdff, #e6ecff, #f9fbff);
					color: #4a5c95;
					display: inline-block;
					vertical-align: middle;
					border-radius: 0.25rem;
					width: 3.25rem;
					height: 1.5625rem;
					line-height: 1.5625rem;
					max-width: 3.25rem;
					overflow: hidden;
					font-size: 0.8125rem;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-align: center;
				}
			}

			.index-head-float {
				position: fixed;
				top: 0;
				left: 0;
			}
		}

		.index-main {

			padding: 0.3125rem 0.8125rem 3.625rem 0.8125rem;

			.my-swipe {
				border-radius: 0.625rem;

				.van-swipe-item {
					width: 100%;
					color: #fff;
					font-size: 20px;
					text-align: center;

					img {
						width: 100%;
					}
				}
			}

			.notice-box {
				position: relative;

				.van-notice-bar {
					margin-top: 0.5rem;
					position: relative;
					padding: 0.25rem 0.5rem;
					background: #f4f6fa;
					box-shadow: 0 1px 0.1875rem rgba(75, 106, 184, 0.2);
					position: relative;
					flex: 0 0 auto;
					margin-top: 0.5rem;
					border: 1px solid #fff;
					border-radius: 0.625rem;
					height: auto;
					color: #1f285a;

					::v-deep .van-notice-bar__content {
						position: relative;
						font-size: 0.75rem;
						line-height: 0.75rem;
					}
				}

				.notice-shortener {
					position: absolute;
					top: -0.25rem;
					right: 0;
					padding: 0 0.625rem 0 1.375rem;
					max-width: 60%;

					&::before {
						background-image: url("/static/image/shortener-before.png");
						width: 1.375rem;
						left: 0;
						content: "";
						position: absolute;
						top: 0;
						height: 1.9375rem;
						background-repeat: no-repeat;
						background-size: cover;
					}

					span {
						background-image: url("/static/image/shortener-bg.png");
						color: #fff;
						display: block;
						height: 1.9375rem;
						line-height: 1.9375rem;
						overflow: hidden;
						background-size: auto 100%;
						font-size: 0.875rem;
						text-align: right;
						white-space: nowrap;
					}

					&::after {
						background-image: url("/static/image/shortener-after.png");
						right: 0;
						width: 0.625rem;
						content: "";
						position: absolute;
						top: 0;
						height: 1.9375rem;
						background-repeat: no-repeat;
						background-size: cover;
					}
				}
			}

			.index-card {
				border-color: #fff;
				background: #f4f6fa;
				box-shadow: 0 1px 0.1875rem rgba(75, 106, 184, 0.2);
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 0.5rem;
				border: 1px solid transparent;
				border-radius: 0.625rem;
				padding: 0.75rem 0.1875rem 0.5rem 0.5rem;

				.index-card-left {
					color: #1f285a;
					flex: 0 0 42%;
					overflow: hidden;
					font-size: 0.8125rem;

					.index-card-left-num {
						margin-top: 0.5rem;
					}
				}

				.index-card-login-box {
					color: #1f285a;
					font-size: 0.75rem;

					.index-card-login-top {
						padding: 0.3125rem 0 0.5rem;
						display: flex;
						justify-content: flex-start;
						align-items: center;

						.index-card-login-btn {
							border-color: #dae3ff;
							background: linear-gradient(169deg, #fdfdff, #e6ecff, #f9fbff);
							color: #4a5c95;
							display: inline-block;
							box-sizing: border-box;
							border: 1px solid transparent;
							border-radius: 0.375rem;
							min-width: 3.25rem;
							max-width: 3.75rem;
							height: 1.5625rem;
							line-height: 1.5625rem;
							overflow: hidden;
							font-size: 0.875rem;
							font-weight: 700;
							text-align: center;
							text-decoration: none;
						}

						.index-card-register {
							margin-left: 0.3125rem;
						}
					}

					span {}
				}

				.index-card-right {
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.index-card-item {
						display: inline-block;
						box-sizing: border-box;
						padding: 0 0.3125rem;
						height: auto;
						line-height: 1rem;
						text-align: center;

						img {
							display: block;
							margin: 0 auto 0.25rem;
							width: 2.1875rem;
							height: 2.1875rem;
						}

						p {
							color: #5c6687;
							font-size: 0.75rem;
						}
					}
				}
			}

			.index-tabbar-box {
				height: 3.1875rem;
				position: relative;
				margin-top: 0.5rem;

				.index-tabbar {
					position: absolute;
					z-index: 10;
					background-color: #f4f6fa;
					width: 100%;
					height: 3.1875rem;
					box-sizing: border-box;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.index-tabbar-item {
						width: 3.0625rem;
						height: 3.1875rem;
						background-repeat: no-repeat;
						background-size: contain;
						background-position: 50% 50%;
						font-size: 0.75rem;
						text-align: center;
						background-image: url("/static/image/tabbar-bg.png");
						color: #4a5c95;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						img {
							width: 1.125rem;
							height: 1.125rem;
						}

						span {
							color: #4a5c95;
						}
					}

					.index-tabbar-act {
						background-image: url("/static/image/tabbar-bg-active.png");

						span {
							color: #fff;
						}
					}
				}

				.index-tabbar-float {
					position: fixed;
					top: 3rem;
					left: 0;
					padding: 0 0.8125rem;
				}
			}

			.index-content {
				margin-top: 0.5rem;

				.index-content-box {
					position: relative;

					.index-content-scroll {
						position: absolute;
						width: 100%;
						height: 6.1875rem;
						bottom: 100%;
						z-index: -1;
					}
				}

				.index-first {
					display: flex;
					flex-wrap: wrap;

					.index-first-item {
						width: 20%;
						margin-bottom: 0.1rem;
						padding: 0.26rem;
						box-sizing: border-box;

						.index-first-img-box {
							width: 100%;
							position: relative;
							font-size: 0;

							img {
								width: 100%;
							}

							span {
								position: absolute;
								bottom: 0.1875rem;
								width: 3.125rem;
								height: 0.875rem;
								line-height: 0.875rem;
								left: 50%;
								transform: translateX(-50%);
								border-radius: 6.25rem;
								display: inline-block;
								width: 3rem;
								overflow: hidden;
								background-color: rgba(6, 1, 5, 0.6);
								color: #fff;
								font-size: 0.75rem;
								text-align: center;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}

						.index-first-title {
							color: #868686;
							margin-top: 0.25rem;
							padding: 0;
							font-size: 0.8125rem;
							text-align: center;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
						}

						.index-first-btn {
							margin: 0.1875rem auto 0;
							border: 1px solid #bbb;
							border-radius: 0.1875rem;
							width: 2.625rem;
							height: 1rem;
							line-height: 1rem;
							color: #bbb;
							font-size: 0.625rem;
							text-align: center;

							.fa-w-18 {
								width: 1.125em;
								display: inline-block;
								font-size: inherit;
								height: 1em;
								overflow: visible;
								vertical-align: -0.125em;
							}
						}

						.first-btn-add {
							border-color: #f2c925;
							background-color: #f2c925;
							color: #f3f3f3;
						}
					}
				}

				.index-secend {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;

					.index-secend-item {
						width: 49.5%;
						height: 5.09rem;
						border-radius: 0.3125rem;
						margin-bottom: 0.3125rem;
						background-image: url("/static/image/secendimg.png");
						background-repeat: no-repeat;
						background-size: contain;
						background-position: 0 0;
						display: flex;
						align-items: center;
					}

					.secend-activity {
						position: relative;

						&::before {
							content: "";
							background-image: url("/static/image/activity.png");
							position: absolute;
							z-index: 1;
							bottom: 0;
							left: 0;
							width: 1.875rem;
							height: 1.875rem;
							background-repeat: no-repeat;
							background-size: cover;
							background-position: 0 0;
						}
					}

					span {
						color: #4a5c95;
						display: inline-block;
						padding-left: 0.8125rem;
						max-width: 3.5625rem;
						font-size: 0.8125rem;
						font-weight: 700;
					}
				}
			}
		}
	}



	.van-popup--left {
		width: 64%;
		height: 100vh;

		.pop-box {
			width: 100%;
			height: 100vh;

			.pop-top {
				height: 15%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				background: linear-gradient(#4eaafc, #1c5cfb);
				color: #fff;
				padding-bottom: 0.65rem;

				.pop-top-click {
					padding: 0.8rem 0.75rem 0;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.pop-top-close {
						color: #fff;
						font-size: 1.5625rem;
						width: 0.625em;
					}

					.pop-top-gofankui {
						img {
							width: 1.25rem;
							height: 1.25rem;
						}
					}
				}

				.pop-top-name {
					padding: 0 0.8125rem 0;
					font-size: 1rem;
				}
			}

			.pop-list {
				height: 85%;
				overflow-y: scroll;

				.pop-list-item {
					border-bottom: 1px solid #e9e9e9;
					color: #a4aabb;
					display: block;
					box-sizing: border-box;
					padding-left: 1.5625rem;
					width: 100%;
					height: 2.6875rem;
					line-height: 2.6875rem;
					overflow: hidden;
					text-overflow: ellipsis;
					text-decoration: none;
					white-space: nowrap;
					font-size: 0.875rem;
				}

				.pop-list-app {
					position: relative;

					.pop-app-download {
						position: absolute;
						top: 0;
						right: 0;
						width: 48%;

						&::before {
							content: "";
							display: inline-block;
							vertical-align: middle;
							height: 100%;
						}

						.pop-app-download-item {
							background: linear-gradient(#4eaafc, #1c5cfb);
							color: #fff;
							display: inline-block;
							vertical-align: middle;
							margin-right: 10%;
							border-radius: 0.1875rem;
							width: 40%;
							height: 1.375rem;
							line-height: 1.375rem;
							overflow: hidden;
							text-align: center;
							text-overflow: ellipsis;
							text-decoration: none;
							white-space: nowrap;
						}
					}
				}

				.pop-btn-box {
					text-align: center;
					padding: 1.5rem 0;

					&::before {
						content: "";
						display: inline-block;
						vertical-align: middle;
						height: 100%;
					}

					.pop-btn {
						border-radius: 1.25rem;
						background: #2472fc;
						color: #fff;
						display: inline-block;
						width: 80%;
						height: 2.25rem;
						line-height: 2.25rem;
						text-align: center;
						text-decoration: none;
						font-size: 0.875rem;
					}
				}
			}
		}
	}


	.scroll-top {
		background: #4ca7fe;
		color: #fff;
		position: fixed;
		z-index: 200;
		bottom: 5rem;
		right: 1.375rem;
		box-sizing: border-box;
		border-radius: 6.1875rem;
		padding: 0.4375rem 0.5625rem;
		width: 2.625rem;
		height: 2.625rem;
		line-height: 0.75rem;
		opacity: 0.9;
		font-size: 0.75rem;
		font-weight: 700;
		text-align: center;

		&::before {
			content: "";
			display: inline-block;
			border-style: solid;
			border-width: 0 1px 1px 0;
			border-color: #fff;
			width: 0.4375rem;
			height: 0.4375rem;
			text-align: center;
			transform-origin: center;
			transform: rotate(-135deg);
			color: #fff;
		}
	}

	// 新选项卡

	.platform-category-selector {
		position: sticky;
		z-index: 10;
		top: 3rem;
		display: flex;
		flex: 0 0 3.1875rem;
		margin-top: .5rem;
		justify-content: space-between;
	}

	.platform-category-selector {
		background: #f4f6fa;
	}

	.platform-category-selector .category-item {
		width: 3.0625rem;
		height: 3.1875rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 50% 50%;
		font-size: .75rem;
		text-align: center;
	}

	.platform-category-selector .category-item {
		background-image: url("/static/image/DYR6trVi.png");
		color: #4a5c95;
	}

	.platform-category-selector .category-item.active {
		background-image: url("/static/image/CxD26bAe.png");
		color: #fff;
	}

	.platform-category-selector .category-item img {
		display: block;
		margin: .625rem auto 0;
		width: 1.6875rem;
		height: 1.6875rem;
	}




	// 弹窗

	.bulletin .bulletin-wrapper {
		background: linear-gradient(#fff, #edf1fe);
	}

	.bulletin .bulletin-wrapper .bulletin-more {
		background: linear-gradient(#4eaafc, #1c5cfb);
		color: #fff
	}

	.bulletin {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		box-sizing: border-box;
		padding: 0 .625rem;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .85);
		text-align: center;
		font-family: STHeiti, Microsoft YaHei, 微软雅黑, SimSun, 宋体, arial, sans-serif;
	}

	.bulletin:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%
	}

	.bulletin-wrapper {
		position: relative;
		top: 8%;
		left: 0;
		display: inline-flex;
		flex-direction: column;
		vertical-align: top;
		width: 100%;
		max-width: 31.25rem;
		max-height: 80%
	}

	.bulletin-header {
		padding: .875rem 0;
		font-size: 1.25rem
	}

	.bulletin-body {
		flex: 1;
		overflow: auto
	}

	.bulletin-body html {
		font-family: sans-serif;
		-webkit-text-size-adjust: 100%;
		-moz-text-size-adjust: 100%;
		text-size-adjust: 100%
	}

	.bulletin-body body {
		margin: 0
	}

	.bulletin-body article,
	.bulletin-body aside,
	.bulletin-body details,
	.bulletin-body figcaption,
	.bulletin-body figure,
	.bulletin-body footer,
	.bulletin-body header,
	.bulletin-body hgroup,
	.bulletin-body main,
	.bulletin-body menu,
	.bulletin-body nav,
	.bulletin-body section,
	.bulletin-body summary {
		display: block
	}

	.bulletin-body audio,
	.bulletin-body canvas,
	.bulletin-body progress,
	.bulletin-body video {
		display: inline-block;
		vertical-align: baseline
	}

	.bulletin-body audio:not([controls]) {
		display: none;
		height: 0
	}

	.bulletin-body [hidden],
	.bulletin-body template {
		display: none
	}

	.bulletin-body a {
		background-color: transparent
	}

	.bulletin-body a:active,
	.bulletin-body a:hover {
		outline: 0
	}

	.bulletin-body abbr[title] {
		border-bottom: 1px dotted
	}

	.bulletin-body b,
	.bulletin-body strong {
		font-weight: 700
	}

	.bulletin-body dfn {
		font-style: italic
	}

	.bulletin-body h1 {
		margin: .67em 0;
		font-size: 2em
	}

	.bulletin-body mark {
		background: #ff0;
		color: #000
	}

	.bulletin-body small {
		font-size: 80%
	}

	.bulletin-body sub,
	.bulletin-body sup {
		position: relative;
		vertical-align: baseline;
		line-height: 0;
		font-size: 75%
	}

	.bulletin-body sup {
		top: -.5em
	}

	.bulletin-body sub {
		bottom: -.25em
	}

	.bulletin-body img {
		border: 0
	}

	.bulletin-body svg:not(:root) {
		overflow: hidden
	}

	.bulletin-body figure {
		margin: 1em 2.5rem
	}

	.bulletin-body hr {
		box-sizing: content-box;
		height: 0
	}

	.bulletin-body pre {
		overflow: auto
	}

	.bulletin-body code,
	.bulletin-body kbd,
	.bulletin-body pre,
	.bulletin-body samp {
		font-family: monospace, monospace;
		font-size: 1em
	}

	.bulletin-body button,
	.bulletin-body input,
	.bulletin-body optgroup,
	.bulletin-body select,
	.bulletin-body textarea {
		font: inherit;
		margin: 0;
		color: inherit
	}

	.bulletin-body button {
		overflow: visible
	}

	.bulletin-body button,
	.bulletin-body select {
		text-transform: none
	}

	.bulletin-body button,
	.bulletin-body html input[type=button],
	.bulletin-body input[type=reset],
	.bulletin-body input[type=submit] {
		cursor: pointer;
		-webkit-appearance: button;
		-moz-appearance: button;
		appearance: button
	}

	.bulletin-body button[disabled],
	.bulletin-body html input[disabled] {
		cursor: default
	}

	.bulletin-body button::-moz-focus-inner,
	.bulletin-body input::-moz-focus-inner {
		border: 0;
		padding: 0
	}

	.bulletin-body input {
		line-height: normal
	}

	.bulletin-body input[type=checkbox],
	.bulletin-body input[type=radio] {
		box-sizing: border-box;
		padding: 0
	}

	.bulletin-body input[type=number]::-webkit-inner-spin-button,
	.bulletin-body input[type=number]::-webkit-outer-spin-button {
		height: auto
	}

	.bulletin-body input[type=search] {
		box-sizing: content-box;
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
		appearance: textfield
	}

	.bulletin-body input[type=search]::-webkit-search-cancel-button,
	.bulletin-body input[type=search]::-webkit-search-decoration {
		-webkit-appearance: none;
		appearance: none
	}

	.bulletin-body fieldset {
		margin: 0 .125rem;
		border: 1px solid silver;
		padding: .35em .625em .75em
	}

	.bulletin-body legend {
		border: 0;
		padding: 0
	}

	.bulletin-body textarea {
		overflow: auto
	}

	.bulletin-body optgroup {
		font-weight: 700
	}

	.bulletin-body table {
		border-spacing: 0;
		border-collapse: collapse
	}

	.bulletin-body td,
	.bulletin-body th {
		padding: 0
	}

	.bulletin-body.bulletin-body-list {
		box-sizing: border-box;
		padding: 0 1rem
	}

	.bulletin-body .bulletin-title {
		padding: .9375rem;
		line-height: 1.125rem;
		font-size: 1rem;
		letter-spacing: 1px
	}

	.bulletin-body .bulletin-content {
		padding: 0 .3125rem .9375rem;
		line-height: 1.5rem;
		font-size: .875rem;
		font-weight: 700;
		text-align: left;
		letter-spacing: 1px
	}

	.bulletin-body .bulletin-content a {
		text-decoration: underline
	}

	// .bulletin-item {
	// 	display: flex;
	// 	padding: .625rem 0 .625rem 1.5rem;
	// 	line-height: 1.625rem;
	// 	min-height: 1.625rem;
	// 	background: url(./B7DMAqqy.png) 0 50% no-repeat;
	// 	font-size: 1rem;
	// 	text-align: left;
	// 	align-items: center;
	// 	letter-spacing: 1px
	// }

	.bulletin-item:last-child {
		border-bottom: 0
	}

	.bulletin-item .bulletin-item-title {
		flex: 1 1 auto;
		box-sizing: border-box;
		padding-right: .3125rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
	}

	.bulletin-item .bulletin-item-open {
		flex: 0 0 4.375rem;
		border-radius: .8125rem;
		font-size: .9375rem;
		text-align: center;
		cursor: pointer
	}

	.bulletin-more {
		flex: 0 0 2.8125rem;
		box-sizing: border-box;
		border-radius: 1px 1px 0 0;
		padding: .875rem 0
	}

	.bulletin-close {
		position: absolute;
		bottom: -3.125rem;
		left: 50%;
		margin-left: -.9375rem;
		width: 1.875rem;
		height: 1.875rem;
		color: #fff
	}

	@media screen and (orientation: landscape) {
		.bulletin-close {
			position: fixed;
			top: 5%;
			bottom: auto;
			right: .75rem;
			left: auto;
			margin-left: 0
		}
	}

	// 本站推荐

	.platform-item {
		position: relative;
		display: inline-block;
		margin-bottom: .3125rem;
		border-radius: .3125rem;
		width: 49.5%;
	}

	.platform-item .platform-item-placeholder {
		position: relative;
		padding-top: 49.2%;
	}

	.platform-item .platform-item-placeholder .platform-item-wrapper {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 0 0;
	}

	.platform-item .platform-item-placeholder .platform-item-wrapper:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}
</style>