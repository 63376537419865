<template>

	<div class="effective">

		<BankHeader title="有效投注额" :isShow="false" />

		<MineHeader />

		<div class="effective-betting-wrap">

			<div class="effective-betting">

				<div class="search-bar clearfix">

					<div class="select-date-wrap">

						<!-- 开始时间选择器 -->
						<div class="select-date-picker select-date-first" @click="openPicker('start')">
							<div class="react-datepicker-wrapper">
								<div class="react-datepicker__input-container">
									<div class="select-date">{{ created_at ? created_at : '开始时间' }}</div>
								</div>
							</div>
						</div>

						<!-- 结束时间选择器 -->
						<div class="select-date-picker" @click="openPicker('end')">
							<div class="react-datepicker-wrapper">
								<div class="react-datepicker__input-container">
									<div class="select-date">{{ end_at ? end_at : '结束时间' }}</div>
								</div>
							</div>
						</div>

					</div>


					<button :class="[isValidp2 ? 'search-btn' : 'search-btn2']" :disabled="!isValidp2"
						@click="chaxunlist">查询</button>


				</div>
				<div class="info-table">
					<table class="total">
						<thead>
							<tr>
								<th></th>
								<th>{{$t('有效投注')}}</th>
								<th>{{$t('派彩')}}</th>
							</tr>
							
						</thead>
						<tbody>
							<tr>
								<td>{{$t('总计')}}</td>
								<td>{{total_valid}}</td>
								<td>{{total_fs_money}}</td>
							</tr>
						</tbody>
					</table>
					<table>
						
						<thead>
							<tr>
								<th>{{$t('游戏大厅')}}</th>
								<th>{{$t('有效投注')}}</th>
								<th>{{$t('派彩')}}</th>
							</tr>
						</thead>
						
						<tbody>
							
							<tr v-if="data.length <= 0">
								<td class="no-data" colspan="3">查无任何资料</td>
							</tr>
							<tr v-else v-for="(item, index) in data" :key="index">
								
								<th style="background: #fff;color: #000;">{{item.api_names}}</th>
								<th style="background: #fff;color: #000;">{{item.total_valid}}</th>
								<th style="background: #fff;color: #000;">{{item.fs_money}}</th>
								
							</tr>
							
						</tbody>
						
					</table>
				</div>
			</div>
		</div>

		<!-- 使用 van-datetime-picker -->
		<van-popup v-model="showPicker" position="bottom">
			<van-datetime-picker v-model="tempDate" type="date" title="选择日期" :min-date="minDate" :max-date="maxDate"
				@confirm="onConfirm" @cancel="onCancel" />
		</van-popup>
		
		<Diyloading ref="loadmodal"></Diyloading>

	</div>

</template>
<script>
	import {
		DatetimePicker,
		Popup
	} from 'vant';
	import BankHeader from "./libs/BankHeader.vue";
	import MineHeader from "./libs/MineHeader.vue";
	import Diyloading from "./libs/Diyloading.vue";
	export default {
		name: "effectiveBetting",

		components: {
			BankHeader,
			MineHeader,
			Diyloading,
			'van-datetime-picker': DatetimePicker,
			'van-popup': Popup
		},
		data() {
			return {
				created_at: '', // 开始时间
				end_at: '', // 结束时间
				showPicker: false, // 控制弹出层显示
				tempDate: '', // 临时存储的日期
				currentPicker: '', // 当前选择的是开始时间还是结束时间
				minDate: new Date(2000, 0, 1), // 设置最小可选择的日期
				maxDate: new Date(2030, 11, 31), // 设置最大可选择的日期
				data:[],
				total_fs_money: 0,
				total_valid:0
			};
		},
		computed: {

			userinfo() {
				return this.$store.state.userInfo
			},

			//内容高度
			isLogin() {
				return this.$store.state.token;
			},

			getcasinolist() {
				return this.$store.state.casinolist;
			},

			isValidp2() {
				// 检查 p1 和 p2 是否都没有 '0'
				const allSelectedP2 = (this.created_at && this.end_at);
				return allSelectedP2;
			}

		},
		methods: {

			chaxunlist() {
				
				let that = this;

				that.$refs.loadmodal.open(); // 传入标题和消息

				let param = [];
				
				param = {
					created_at: that.created_at + ' 00:00:00',
					end_at: that.end_at + ' 23:59:59',
				};

				that.$apiFun.post("/api/game/truebet", param).then((res) => {

					// console.log(JSON.stringify(res));
					
					that.data = res.data;
					that.total_fs_money = res.total_fs_money;
					that.total_valid = res.total_valid;
					

					that.$refs.loadmodal.close(); // 传入标题和消息

				}).catch((err) => {
					console.error("请求失败：", err);
				});
				
			},

			// 打开日期选择器
			openPicker(type) {
				this.currentPicker = type;
				this.tempDate = this[type === 'start' ? 'created_at' : 'end_at'] || new Date();
				this.showPicker = true;
			},
			// 确认选择时间
			onConfirm(value) {
				const formattedDate = this.formatDate(value);
				if (this.currentPicker === 'start') {
					this.created_at = formattedDate;
				} else if (this.currentPicker === 'end') {
					this.end_at = formattedDate;
				}
				this.showPicker = false;
			},
			// 取消选择
			onCancel() {
				this.showPicker = false;
			},
			// 格式化日期为 yyyy-mm-dd
			formatDate(date) {
				const year = date.getFullYear();
				const month = (date.getMonth() + 1).toString().padStart(2, '0');
				const day = date.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			}
		},

	}
</script>
<style lang="scss" scoped>
	.select-date {
		color: #666;
	}


	.effective {
		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				content: "";
				display: table;
				clear: both;
			}
		}

		.effective-betting-wrap {
			box-sizing: border-box;
			padding: 2.1875rem 1.5rem 2.438rem;
		}

		.search-bar {
			margin-bottom: 1.5rem;

			.select-date-wrap {
				width: 100%;
			}

			.select-date-picker {
				margin-bottom: 0.625rem;
				clear: both;
				position: relative;
				padding: 0;
				height: 38px;
				line-height: 38px;
				color: #999;
				font-size: 15px;
				clear: none;
				background: url("/static/image/ic_select_arrow_dropdown.png") 98% no-repeat #fff;
				background-size: 16px 16px;

				.react-datepicker-wrapper,
				.react-datepicker__input-container {
					display: block;
				}
			}

			.select-date-picker>div {
				width: 96%;
				padding-left: 3%;
				border: 1px solid #ccc;
				border-radius: 4px;
			}

			.select-date {
				height: 38px;
			}

			.search-btn {
				background-color: #59bafc;
				margin: 0;
				padding: 0;
				border: none;
				min-width: 8rem;
				height: 2.5rem;
				line-height: 2.5rem;
				border-radius: 2.5rem;
				font-size: 1rem;
				color: #fff;
				text-align: center;
				cursor: pointer;
				width: 100%;
				float: right;
			}

			.search-btn2 {
				background: #ccc;
				margin: 0;
				padding: 0;
				border: none;
				min-width: 8rem;
				height: 2.5rem;
				line-height: 2.5rem;
				border-radius: 2.5rem;
				font-size: 1rem;
				color: #fff;
				text-align: center;
				cursor: pointer;
				width: 100%;
				float: right;
			}
		}

		.info-table {
			table {
				width: 100%;
				border-collapse: collapse;
				border-spacing: 0;

				th {
					width: 33%;
					height: 32px;
					font-size: 0.9375rem;
					padding: 5px;
					text-align: center;
					background: #ccc;
					color: #fff;
				}

				td {
					width: 33%;
					height: 32px;
					font-size: 0.9375rem;
					padding: 5px;
					text-align: center;
					background: #fff;
					border: 1px solid #eee;
				}
			}

			.total {
				margin-bottom: 15px;

				th {
					background: #ececec;
					color: #313f52;
				}

				td {
					background: #fff;
					border: 1px solid #eee;
				}
			}

			.no-data {
				padding: 56px 0;
				color: #c1c5cc;
			}
		}
	}
</style>