<template>
  <div class="zoneHistory">
    <BankHeaderVue title="最近10次登入记录" :isSHow="false" />
    <MineHeader />
    <div class="member-data-container clearfix">
      <div class="member-children-wrap">
        <div class="zone-data-wrap">
          <table class="zone-info">
            <thead>
              <tr>
                <th>登入时间</th>
                <th>登入来源</th>
                <th>厂牌</th>
                <th>装置名称</th>
              </tr>
            </thead>
            <tbody>
              <tr class="zone-list-0 history">
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入时间</span><span class="item-content">2024/08/08 08:46:28</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入来源</span><span class="item-content">手机网页版</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">厂牌</span><span class="item-content">无</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">装置名称</span><span class="item-content">无</span></div>
                </td>
              </tr>
              <tr class="zone-list-1 history">
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入时间</span><span class="item-content">2024/08/08 03:04:00</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入来源</span><span class="item-content">手机网页版</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">厂牌</span><span class="item-content">无</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">装置名称</span><span class="item-content">无</span></div>
                </td>
              </tr>
              <tr class="zone-list-2 history">
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入时间</span><span class="item-content">2024/08/07 08:11:42</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入来源</span><span class="item-content">手机网页版</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">厂牌</span><span class="item-content">无</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">装置名称</span><span class="item-content">无</span></div>
                </td>
              </tr>
              <tr class="zone-list-3 history">
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入时间</span><span class="item-content">2024/08/07 03:04:16</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入来源</span><span class="item-content">手机网页版</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">厂牌</span><span class="item-content">无</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">装置名称</span><span class="item-content">无</span></div>
                </td>
              </tr>
              <tr class="zone-list-4 history">
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入时间</span><span class="item-content">2024/08/06 10:23:54</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入来源</span><span class="item-content">手机网页版</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">厂牌</span><span class="item-content">无</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">装置名称</span><span class="item-content">无</span></div>
                </td>
              </tr>
              <tr class="zone-list-5 history">
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入时间</span><span class="item-content">2024/08/05 09:00:44</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入来源</span><span class="item-content">手机网页版</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">厂牌</span><span class="item-content">无</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">装置名称</span><span class="item-content">无</span></div>
                </td>
              </tr>
              <tr class="zone-list-6 history">
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入时间</span><span class="item-content">2024/08/05 04:55:20</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入来源</span><span class="item-content">手机网页版</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">厂牌</span><span class="item-content">无</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">装置名称</span><span class="item-content">无</span></div>
                </td>
              </tr>
              <tr class="zone-list-7 history">
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入时间</span><span class="item-content">2024/08/04 09:21:07</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入来源</span><span class="item-content">手机网页版</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">厂牌</span><span class="item-content">无</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">装置名称</span><span class="item-content">无</span></div>
                </td>
              </tr>
              <tr class="zone-list-8 history">
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入时间</span><span class="item-content">2024/08/04 08:46:13</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入来源</span><span class="item-content">网页版</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">厂牌</span><span class="item-content">无</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">装置名称</span><span class="item-content">无</span></div>
                </td>
              </tr>
              <tr class="zone-list-9 history">
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入时间</span><span class="item-content">2024/08/04 03:38:29</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">登入来源</span><span class="item-content">网页版</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">厂牌</span><span class="item-content">无</span></div>
                </td>
                <td class="clearfix item-wrap">
                  <div class="item-table"><span class="item-title">装置名称</span><span class="item-content">无</span></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BankHeaderVue from "./libs/BankHeader.vue"
import MineHeader from "./libs/MineHeader.vue"
export default {
  name: "zoneHistory",
  components: {
    BankHeaderVue,
    MineHeader,
  },
}
</script>
<style lang="scss" scoped>
.zoneHistory {
  .clearfix {
    &::before {
      content: "";
      display: table;
    }
    &::after {
      clear: both;
      content: "";
      display: table;
    }
  }
.member-children-wrap {
    padding-left: 10px;
    padding-right: 10px;
}
.zone-data-wrap {
    position: relative;
    margin: 0 auto;
    padding-bottom: 1.25rem;
    font-size: .938rem;
        margin-top: 1.25rem;
}
.zone-info {
    width: 100%;
    box-sizing: border-box;
        border-collapse: collapse;
    border-spacing: 0;
     thead {
    display: none;
    background: #ccc;
    color: #fff;
}
 tr {
    border: 1px solid #eee;
    display: block;
    margin-bottom: .625rem;
}
 th {
    padding: 1rem 0;
    line-height: 1.25rem;
}
 td {
    display: block;
    background: #ccc;
    font-size: .938rem;
}
 .item-wrap .item-table {
    display: table;
    width: 100%;
}
.item-wrap .item-title {
    display: table-cell;
    width: 40%;
    background-color: #ccc;
    padding: 1rem 1rem 1rem 0;
    color: #fff;
    text-align: right;
    box-sizing: border-box;
    word-break: break-all;
}
 .item-wrap .item-content {
    display: table-cell;
    width: 60%;
    background: #fff;
    padding: 1rem .875rem;
    line-height: 1.125rem;
    border-bottom: 1px solid #eee;
    color: #313f52;
    box-sizing: border-box;
    word-break: break-all;
}
}
}
</style>
