export default {
	"APP下载": "Download APP",
	"官网首页": "Official Homepage",
	"优惠大厅": "Promotion Hall",
	"在线客服": "Online Customer Service",
	"代理申请": "Agent Application",
	"免息借呗": "Interest-free Loan",
	"真人升级": "Live Casino Upgrade",
	"电子升级": "Electronic Games Upgrade",
	"站内信": "Inbox",
	"每日签到": "Daily Check-in",
	"抢红包": "Grab Red Packet",
	"交易记录": "Transaction History",
	"投注记录": "Betting History",
	"修改密码": "Change Password",
	"修改取款密码": "Change Withdrawal Password",
	"登出": "Logout",
	"首页": "Home",
	"优惠活动": "Promotions",
	"会员中心": "Member Center",
	"充值": "Deposit",
	"提现": "Withdraw",

	"额度转换": "Balance Conversion",
	"总余额": "Total Balance",
	"公告": "Announcement",
	"基本资料": "Basic Information",
	"优惠中心": "Promotion Center",
	"操作成功！": "Operation Successful!",
	"操作异常！": "Operation Error!",
	"时时反水": "Real-time Rebate",
	"申请提款": "Request Withdrawal",
	"银行卡管理": "Bank Card Management",
	"系统公告": "System Announcement",
	"余额宝": "Balance Treasure",
	"快速充值": "Quick Deposit",
	"充值记录": "Deposit Records",
	"语言选择": "Language Selection",
	"您好": "Hello",
	"您好, 请登入": "Hello, Please Log In",
	"余额与优惠": "Balance and Promotions",
	"登入查看": "Log In to View",
	"热门": "Popular",
	"最新": "Latest",
	"本月推荐": "This Month's Recommendation",
	"全部": "All",
	"请输入搜索关键词": "Please Enter Search Keywords",
	"搜索": "Search",
	"参与": "Participate",
	"会员登入": "Member Login",
	"注册账号": "Register Account",
	"介绍人": "Referrer",
	"帐号": "Account",
	"密码": "Password",
	"确认密码": "Confirm Password",
	"会员资料": "Member Information",
	"真实姓名": "Real Name",
	"手机号": "Phone Number",
	"取款密码": "Withdrawal Password",
	"确认取款密码": "Confirm Withdrawal Password",
	"验证码": "Verification Code",
	"立即注册": "Register Now",
	'1. 标记有 <span class="star">*</span> 者为必填项目。': '1. Items marked with <span class="star">*</span> are required fields.',
	"2. 手机与取款密码为取款金额时的凭证，请会员务必填写详细资料。": "2. Phone and withdrawal password serve as credentials for withdrawals, please ensure to fill in accurate details.",
	"忘记密码 ?": "Forgot Password?",
	"登录": "Log In",
	"试玩登录": "Demo Login",
	"随便逛逛": "Browse Around",
	"去电脑版": "Go to Desktop Version",
	"联系管理员重置密码": "Contact Admin to Reset Password",
	"您当前为试玩账号，只可在本站彩票试玩。": "You are currently using a demo account, you can only play lottery games on this site.",
	"注册成功，请进行登录": "Registration Successful, Please Log In",
	"修改登入密码": "Change Login Password",
	"修改取款密码": "Change Withdrawal Password",
	"银行资料": "Bank Information",
	"旧密码": "Old Password",
	"新密码": "New Password",


	"确认新密码": "Confirm New Password",
	"请输入": "Please Enter",
	"取消": "Cancel",
	"确认": "Confirm",
	"密码修改成功，请重新登录": "Password changed successfully, please log in again",
	"取款密码修改成功": "Withdrawal password changed successfully",
	"账户余额": "Account Balance",
	"反水账户": "Rebate Account",
	"切换至自动": "Switch to Automatic",
	"切换至手动": "Switch to Manual",
	"转出": "Transfer Out",
	"转入": "Transfer In",
	"金额": "Amount",
	"请输入转账金额": "Please Enter Transfer Amount",
	"钱包之间不能互相转换": "Wallets cannot be converted between each other",
	"用户名": "Username",
	"可提款金额": "Withdrawable Amount",
	"收款银行卡": "Receiving Bank Card",
	"请选择": "Please Select",
	"银行账户": "Bank Account",
	"持卡人姓名": "Cardholder Name",
	"提款金额": "Withdrawal Amount",
	"请输入提款金额": "Please Enter Withdrawal Amount",
	"温馨提示：当您的打码量不足时，提现会收取": "Kind Reminder: When your wagering amount is insufficient, a withdrawal fee of",
	"%的手续费，当前剩余打码量": "% will be charged. Current remaining wagering amount",
	"提交": "Submit",
	"取款密码": "Withdrawal Password",
	"请输入取款密码": "Please Enter Withdrawal Password",
	"您还未设置取款密码，请先设置取款密码再操作": "You have not set a withdrawal password yet, please set it before proceeding",
	"请先绑定银行卡": "Please bind your bank card first",
	"绑定时间": "Binding Time",
	"开户行": "Bank Branch",
	"卡号": "Card Number",
	"重新绑定": "Rebind",
	"增加绑定": "Add Binding",
	"银行卡变更": "Bank Card Change",
	"开户银行": "Bank Name",
	"开户人姓名": "Account Holder Name",
	"银行账号": "Bank Account Number",
	"开户网点": "Bank Branch Location",
	"返回": "Return",
	"修改成功": "Modification Successful",
	"添加银行卡成功": "Bank Card Added Successfully",
	"时间": "Time",
	"接口类型": "Interface Type",
	"游戏类型": "Game Type",
	"暂无数据": "No Data Available",
	"没有更多了": "No More Available",


	"统计": "Statistics",
	"总下注金额": "Total Bet Amount",
	"总输赢": "Total Win/Loss",
	"游戏详情": "Game Details",
	"游戏单号": "Game Order Number",
	"游戏名称": "Game Name",
	"游戏类型": "Game Type",
	"场次信息": "Match Information",
	"下注明细": "Bet Details",
	"下注金额": "Bet Amount",
	"有效下注": "Valid Bet",
	"派彩金额": "Payout Amount",
	"开奖结果": "Result",
	"下注时间": "Bet Time",
	"派彩时间": "Payout Time",
	"账号": "Account",
	"真实姓名": "Real Name",
	"最后登入时间": "Last Login Time",
	"变动类型": "Change Type",
	"金额类型": "Amount Type",
	"流水金额": "Turnover Amount",
	"有效金额": "Valid Amount",
	"交易详情": "Transaction Details",
	"交易金额": "Transaction Amount",
	"转入前余额": "Balance Before Transfer",
	"转入后余额": "Balance After Transfer",
	"钱包类型": "Wallet Type",
	"转账类型": "Transfer Type",
	"操作描述": "Operation Description",
	"交易时间": "Transaction Time",
	"全部": "All",
	"反水钱包": "Rebate Wallet",
	"中心钱包": "Center Wallet",
	"今日": "Today",
	"昨日": "Yesterday",
	"7日内": "Within 7 Days",
	"30日内": "Within 30 Days",
	"状态": "Status",
	"支付类型": "Payment Type",
	"总金额": "Total Amount",
	"订单号": "Order Number",


	"金额": "Amount",
	"账号信息": "Account Information",
	"支付方式": "Payment Method",
	"支付信息": "Payment Information",
	"支付状态": "Payment Status",
	"失败原因": "Failure Reason",
	"汇款时间": "Remittance Time",
	"管理员确认时间": "Admin Confirmation Time",
	"最新消息": "Latest News",
	"返回首页": "Return to Homepage",
	"在线客服": "Online Customer Service",
	"APP下载": "APP Download",
	"点击申请": "Click to Apply",
	"免费开户": "Free Account Opening",
	"手机投注": "Mobile Betting",
	"电子游艺": "Electronic Games",
	"线路检测": "Line Check",
	"火狐浏览器": "Firefox Browser",
	"上网导航": "Internet Navigation",
	"如何存款": "How to Deposit",
	"如何取款": "How to Withdraw",
	"代理加盟": "Agent Enrollment",
	"恭喜": "Congratulations",
	"成功办理": "Successfully Processed",
	"会员账号": "Member Account",
	"申请活动": "Apply for Activity",
	"申请时间": "Application Time",
	"申请状态": "Application Status",
	"备注": "Remarks",
	"未查询到信息": "No Information Found",
	"官方首页": "Official Homepage",
	"抽奖转盘": "Prize Wheel",
	"抽奖规则": "Lottery Rules",
	"输入游戏账号查询": "Enter Game Account to Query",
	"查 询": "Query",
	"当日总存款": "Total Deposit of the Day",
	"当日有效投注": "Valid Bets of the Day",
	"历史中奖记录": "Historical Winning Records",
	"您当前有": "You currently have",
	"次抽奖机会": "prize draw chances",
	"当日存款": "Deposit of the Day",
	"有效流水": "Valid Turnover",


	"转盘次数": "Spin Count",
	"恭喜你中奖了，奖品是 ": "Congratulations, you've won a prize: ",
	"段位查询": "Rank Inquiry",
	"请输入你的会员账号": "Please enter your member account",
	"晋升标准等级": "Promotion Standard Level",
	"累计打码": "Cumulative Wagering",
	"等级礼金": "Level Bonus",
	"周俸禄": "Weekly Salary",
	"月俸禄": "Monthly Salary",
	"借呗额度": "Borrowing Limit",
	"累积礼金": "Accumulated Bonus",
	"时时返水": "Instant Rebate",
	"存取款加速通道": "Deposit and Withdrawal Fast Track",
	"一对一客服经理": "One-on-One Customer Manager",
	"级": "Level",
	"等级": "Rank",
	"按您的等级计算，距离上次登录7天后再次登录即可自动到账，等级每周俸禄详情如下：": "Based on your level, you can automatically receive your salary after logging in again 7 days after your last login. The weekly salary details for your level are as follows:",
	"按您的等级计算，距离上次登录30天后再次登录即可自动到账，等级每月俸禄详情如下：": "Based on your level, you can automatically receive your salary after logging in again 30 days after your last login. The monthly salary details for your level are as follows:",
	"如何申请每月俸禄？": "How to apply for monthly salary?",
	"如何申请每周俸禄？": "How to apply for weekly salary?",
	"玩真人视讯永久累计打码，让您的会员账号变成永久收益金管家！": "Play live video and accumulate wagering permanently, turning your member account into a permanent income manager!",
	"的账号信息": "'s account information",
	"会员账号": "Member Account",
	"当前等级": "Current Level",
	"累积有效投注": "Cumulative Valid Bets",
	"等级彩金": "Level Bonus",
	"距离晋级需有效投注": "Valid bets needed to level up",
	"距离下一级还差": "Distance to the next level: ",
	"注": "Bet",


	"奖励类型": "Reward Type",
	"奖励金额": "Reward Amount",
	"发放时间": "Distribution Time",
	"喜讯：2020年7月2号起所有电子/棋牌/捕鱼永久累计打码，让您的会员账号变成永久收益金管家！": "Good news: Starting from July 2, 2020, all electronic/board/card/fishing games will permanently accumulate wagering, turning your member account into a permanent income manager!",
	"声明：强势升级后的累积打码标准将会进行调整，等级礼金、周俸禄、月俸禄也会进行提升；特此声明所有会员的等级变动都以更新后为准！": "Statement: The accumulation of wagering standards will be adjusted after the upgrade, and the level bonuses, weekly salaries, and monthly salaries will also be increased; hereby it is declared that all member level changes will be subject to the updated standards!",
	"免息借呗": "Interest-Free Borrowing",
	"信用额度查询": "Credit Limit Inquiry",
	"信用规则": "Credit Rules",
	"借还款记录": "Loan and Repayment Records",
	"我要借款": "I Want to Borrow",
	"我要还款": "I Want to Repay",
	"活动详情": "Event Details",
	"活动细则": "Event Rules",
	"借款说明": "Loan Instructions",
	"还款说明": "Repayment Instructions",
	"会员账号": "Member Account",
	"VIP等级": "VIP Level",
	"最高借款": "Maximum Loan",
	"已借款": "Amount Borrowed",
	"总借款": "Total Borrowed",
	"总还款": "Total Repaid",
	"借款金额": "Loan Amount",
	"还款金额": "Repayment Amount",
	"借款天数": "Loan Days",
	"还款倒计时": "Repayment Countdown",
	"审核进度": "Review Progress",
	"日期": "Date",
	"暂无数据": "No Data Available",
	"温馨提示": "Kind Reminder",
	"注意：提交成功5分钟后请到“信用额度查询”是否借款成功！": "Note: Please check the 'Credit Limit Inquiry' after 5 minutes of successful submission to see if the loan was successful!",
	"请填写会员账号": "Please enter your member account",
	"请填写会员姓名": "Please enter your member name",
	"请填写借款金额": "Please enter the loan amount",
	"请填写借款天数": "Please enter the loan days",
	"确认提交": "Confirm Submission",
	"查询欠款额度": "Check Loan Amount",
	"请填写还款金额": "Please enter the repayment amount",
	"注意：请点击“在线付款”进行付款，付款完成再来提交！": "Note: Please click 'Online Payment' to make the payment, and submit again after payment is completed!",
	"提交成功5分钟，请到“信用额度查询”查询是否成功还款！": "After successful submission, please check 'Credit Limit Inquiry' after 5 minutes to see if the repayment was successful!",
	"当前欠款:": "Current Debt:",
	"请填写会员帐号": "Please enter your member account",
	"请填写存款金额": "Please enter the deposit amount",
	"请填写验证码": "Please enter the verification code",
	"存款金额": "Deposit Amount",
	"立即提交": "Submit Now",
	"优惠申请": "Bonus Application",
	"查看详情": "View Details",
	"优惠申请中心": "Bonus Application Center",
	"优惠申请进度查询": "Bonus Application Progress Inquiry",
	"选择查询项目": "Select Inquiry Item",
	"点击查询": "Click to Inquire",
	"活动名称": "Event Name",
	"申请时间": "Application Time",
	"申请状态": "Application Status",
	"未查询到信息": "No Information Found",
	"您还有反水": "You still have rebates",
	"可领取！": "Available for Claim!",
	"有效投注": "Valid Bets",
	"返点比例": "Rebate Ratio",


	"您目前没有时时返水可以领取，快去游戏吧!": "You currently have no instant rebates to claim, go play some games!",
	"领取": "Claim",
	"我的奖励": "My Rewards",
	"周一": "Monday",
	"周二": "Tuesday",
	"周三": "Wednesday",
	"周四": "Thursday",
	"周五": "Friday",
	"周六": "Saturday",
	"周日": "Sunday",
	"连续签到礼包": "Consecutive Sign-in Gift Pack",
	"天礼包": "Day Gift Pack",
	"天时可以领取": "Can be claimed in days",
	"已领取": "Claimed",
	"马上签到": "Sign in now",
	"已签到": "Signed in",
	"暂无奖励数据": "No reward data available",
	"回首页": "Return to Home",
	"奖品": "Prize",
	"日期": "Date",
	"暂无数据": "No data available",
	"返回": "Back",
	"开始游戏": "Start Game",
	"该功能暂未开放": "This feature is not yet available",
	"一个红包也没有抢到，多多加油": "You didn't grab any red packets, keep trying!",
	"发件": "Send",
	"发件箱": "Sent Items",
	"收件箱": "Inbox",
	"已读": "Read",
	"未读": "Unread",
	"暂无数据": "No data available",
	"管理员": "Administrator",
	"上条站内信": "Previous Message",
	"回复": "Reply",
	"标示为已读": "Mark as Read",
	"标示为未读": "Mark as Unread",
	"删除": "Delete",
	"发送站内信": "Send Internal Message",
	"标题": "Title",
	"内容": "Content",
	"删除成功": "Deleted Successfully",
	"我的账户": "My Account",
	"基本资料": "Basic Information",
	"银行卡": "Bank Card",
	"修改密码": "Change Password",
	"自助服务": "Self-Service",
	"时时返水": "Instant Rebates",
	"余额宝": "Balance Treasure",
	"往来记录": "Transaction History",
	"投注记录": "Betting Records",
	"充值记录": "Recharge Records",
	"信息公告": "Information Announcement",
	"最新公告": "Latest Announcement",
	"站内信": "Internal Message",
	"充值处理时间": "Recharge Processing Time",
	"7*24小时充值服务": "24/7 Recharge Service",
	"如选择银行卡转账，请勿使用其他支付方式，否则充值将无法到账": "If you choose bank transfer, do not use other payment methods; otherwise, the recharge may not be credited.",
	"在线支付": "Online Payment",
	"公司入款": "Company Deposit",
	"充值金额": "Recharge Amount",
	"请输入充值金额": "Please enter the recharge amount",
	"单笔充值限额": "Single Transaction Limit",
	"最低": "Minimum",
	"最高": "Maximum",
	"*平台填写金额应当与网银汇款金额完全一致，否则将无法即时到账！": "*The amount filled in on the platform must match the amount wired; otherwise, it will not be credited immediately!",
	"下一步": "Next Step",
	"收款方信息": "Payee Information",
	"收款账户": "Receiving Account",
	"收款银行": "Receiving Bank",
	"收款姓名": "Payee Name",
	"开户行网点": "Bank Branch",
	"收款二维码": "Receiving QR Code",
	"复制": "Copy",
	"您的充值信息": "Your Recharge Information",
	"您的账号": "Your Account",
	"请输入 您的账号": "Please enter your account",
	"付款姓名": "Payer Name",
	"请输入 您的名字": "Please enter your name",
	"汇款时间": "Remittance Time",
	"提交": "Submit",
	"请输入正确的存款金额！": "Please enter the correct deposit amount!",
	"转账信息已提交，请耐心等候客服人员核查通过！": "Transfer information has been submitted, please wait for customer service to verify!",
	"前往支付": "Go to Payment",
	"遇到问题？联系客服": "Encountering issues? Contact customer service",
	"复制成功！": "Copied Successfully!",
	"钱包地址": "Wallet Address",
	"兑换汇率": "Exchange Rate",
	"usdt个数": "Number of USDT",
	"我的余额宝": "My Balance Treasure",
	"进行中": "In Progress",
	"已结束": "Ended",
	"您尚无任何方案!": "You do not have any plans!",
	"立即购买": "Buy Now",
	"买入": "Buy",
	"共": "Total",
	"笔": "Items",

	"总金额": "Total Amount",
	"总盈利/总上限": "Total Profit / Total Limit",
	"方案选择": "Plan Selection",
	"销售金额": "Sales Amount",
	"结算周期": "Settlement Cycle",
	"循环结算": "Cyclic Settlement",
	"单次结算": "Single Settlement",
	"小时": "Hour",
	"利率": "Interest Rate",
	"利息上限": "Interest Limit",
	"剩余数量": "Remaining Quantity",
	"个人购买数量": "Individual Purchase Quantity",
	"再次购买时间": "Time to Re-Purchase",
	"后": "Later",
	"无": "None",
	"请填写购买信息": "Please fill in purchase information",
	"购买金额": "Purchase Amount",
	"请输入10的倍数": "Please enter a multiple of 10",
	"请填写购买金额": "Please fill in the purchase amount",
	"请输入10的倍数的金额": "Please enter an amount that is a multiple of 10",
	"每": "Each",
	"小时利息为": "Hourly Interest is",
	"小时后利息为": "Interest after hours is",
	"利息稽核倍数": "Interest Audit Multiple",
	"利息试算": "Interest Calculation",
	"购买": "Purchase",
	"购买数量已达上限": "Purchase quantity has reached the limit",
	"已售完": "Sold Out",
	"切换方案": "Switch Plan",
	"暂无方案": "No Plans Available",
	"请选择合适的方案": "Please select an appropriate plan",
	"结算方式": "Settlement Method",
	"名称": "Name",
	"金额": "Amount",
	"买进时间": "Purchase Time",
	"结算时间": "Settlement Time",
	"提现时间": "Withdrawal Time",
	"利息周期": "Interest Cycle",
	"目前利息/上限": "Current Interest / Limit",
	"利息到达上限": "Interest has reached the limit",
	"已结算": "Settled",
	"赎回": "Redemption",
	"正在赎回...": "Redeeming...",
	"历史盈利": "Historical Profit",
	"利息历程": "Interest History",
	"此方案为": "This plan is",
	"模式": "Mode",
	"利息稽核倍数为": "Interest Audit Multiple is",
	"倍": "Times",
	"第": "The",
	"次": "Time",
	"确定赎回吗？": "Are you sure you want to redeem?",
	"中心钱包余额": "Central Wallet Balance",
	"更新余额": "Update Balance",
	"余额": "Balance",
	"请输入转入金额": "Please enter the transfer amount",
	"转入": "Transfer In",
	"去存款": "Go to Deposit",
	"正在进入游戏，请稍等 .....": "Entering the game, please wait .....",
	"正在进行免转...": "Processing exemption...",
	"请先进行登录再进入游戏": "Please log in before entering the game",
	"进入游戏": "Enter Game",
	"一键归户": "One-Click Repatriation",
	"修改成功！": "Modification Successful!",
	"请填写真实姓名！": "Please fill in your real name!",
	"真实姓名未填写，请前往个人中心填写真实姓名！": "Real name not filled in, please go to the personal center to fill in your real name!",


	"利息宝": "Interest Treasure",
	"返水": "Rebate",
	"福利": "Welfare",
	"客服": "Customer Service",
	"总游戏余额": "Total Game Balance",
	"线上存款": "Online Deposit",

	"7x24小时备用客服": "7x24 Hour Backup Customer Service",
	"电子钱包": "E-wallet",
	"SVIP钱包等级": "SVIP Wallet Level",
	"我的优惠": "My Promotions",
	"快速存款": "Quick Deposit",
	"线上取款": "Online Withdrawal",
	"新会员推荐": "New Member Recommendation",
	"安装": "Install",
	"开启": "Open",
	"寰宇浏览器": "Universal Browser",
	"CG钱包下载": "Download CG Wallet",
	"前往电脑版": "Go to Desktop Version",
	"选择语系": "Select Language",
	
	"点我下载":"Click to download",
	"早上好，请先登入或注册":"Good afternoon, please login",
	
	"注册": "Register",
	"优惠":"Promotions",
	"我的":"My"
}