<template>
	
	<div class="money-link-info-wrap">
		
		<div class="money-link-info-wrap">
			<div class="money-link-wrap clearfix">
				<a class="money-link money-link-depositPlus money-length-4"
					@click="$parent.$parent.goNav('/recharge')"><span><i class="money-icon-depositPlus"></i>
						<p>{{$t('充值')}}</p>
					</span></a><a class="money-link money-link-withdrawPlus money-length-4"
					@click="$parent.$parent.goNav('/withdrawal')"><span><i class="money-icon-withdrawPlus"></i>
						<p>{{$t('提现')}}</p>
					</span></a><a class="money-link money-link-reward money-length-4"
					@click="$parent.$parent.goNav('/reward')"><span><i class="money-icon-reward"></i>
						<p>{{$t('优惠中心')}}</p>
					</span></a><a class="money-link money-link-moneySwitch money-length-4"
					@click="$parent.$parent.goNav('/moneySwitch')"><span><i class="money-icon-moneySwitch"></i>
						<p>{{$t('额度转换')}}</p>
					</span></a>
			</div>
		</div>
		
		<div class="mem-info">
			<div class="mem-account">{{userinfo.name}}@55</div>
			<div class="mem-balance">{{userinfo.money}} RMB</div>
		</div>
		
	</div>
	
</template>
<script>
	export default {
		name: 'MineHeader',
		data() {
			return {}
		},
		computed: {
			userinfo() {
				return this.$store.state.userInfo
			},
			//内容高度
			isLogin() {
				return this.$store.state.token;
			},
		},
		created() {
			
		},
	}
</script>

<style lang="scss" scoped>
	.money-link-info-wrap {
		position: relative;
		z-index: 1;

		.money-link-wrap {
			display: table;
			width: 100%;
			table-layout: fixed;
			border-top: 1px solid #4b4b5a;
			box-sizing: border-box;
			text-align: center;

			.money-link {
				display: table-cell;
				vertical-align: middle;
				color: #d8d8d8;
				font-size: 12px;
				font-weight: 700;
				width: 25%;
				text-align: center;
				background: #34343f;
				padding: 8px 0 9px;
				border-right: 1px solid #2b2b33;
				border-left: 1px solid #4b4b5a;
				box-sizing: border-box;

				span {
					position: relative;
					display: block;

					i {
						display: inline-block;
						vertical-align: top;
						width: 27px;
						height: 27px;
					}

					.money-icon-depositPlus {
						background: url("/static/image/btn_deposit_reward.png") 0 0 no-repeat;
						background-size: contain;
					}

					.money-icon-withdrawPlus {
						background: url("/static/image/btn_withdrawal_reward.png") 0 0 no-repeat;
						background-size: contain;
					}

					.money-icon-reward {
						background: url("/static/image/btn_backwater_reward.png") 0 0 no-repeat;
						background-size: contain;
					}

					.money-icon-moneySwitch {
						background: url("/static/image/btn_trans_reward.png") 0 0 no-repeat;
						background-size: contain;
					}

					p {
						margin-top: 2px;
						margin-bottom: 0;
						word-break: break-word;
					}
				}
			}

			.money-link:first-child {
				border-left: none;
			}

			.money-link.money-length-4 {
				width: 25%;
			}
		}
	}

	.mem-info {
		color: #737a86;
		height: 1.875rem;
		line-height: 1.875rem;
		font-size: 0.75rem;
		padding: 0 0.625rem;
		background-color: #fff;
		box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
		box-sizing: border-box;

		.mem-account {
			float: left;
		}

		.mem-balance {
			float: right;
		}
	}
</style>