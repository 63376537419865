<template>
	<div class="message">

		<BankHeaderVue title="个人信息" :isShow="false" />

		<MineHeader />

		<div class="userMsg">

			<div>


				<!-- <div class="mem-info2">
					<div :class="['user-msg-toolbar-btn',!isSet ? 'user-msg-edit-btn' : 'user-msg-cancel-btn'] "
						@click="isSet = !isSet"></div>
				</div> -->


				<div class="user-msg-nav-wrap">

					<div :class="['user-msg-nav', tabIndex == 0 ? 'current' : '']" @click="settabIndex(0)">
						<div class="user-msg-nav-title">
							{{$t('个人信息')}}
							<!-- <i class="user-msg-circle-icon"></i> -->
						</div>
					</div>

					<!-- <div :class="['user-msg-nav false', tabIndex == 1 ? 'current' : '']" @click="settabIndex(1)">
						<div class="user-msg-nav-title">{{$t('我的发问')}}</div>
					</div> -->

				</div>

				<div>

					<div class="user-msg-content-wrap">

						<!-- <div class="user-msg-toolbar-wrap clearfix" v-if="tabIndex == 0">

							<div class="only-show-can-reply" @click="showCanReply">
								<div :class="[
											'user-msg-checkbox-icon',
											showReply ? 'is-active' : '',
										  ]"></div>
								{{$t('仅显示可回覆的信息')}}
							</div>

							<span
								:class="['user-msg-toolbar-btn',!isSet ? 'user-msg-edit-btn' : 'user-msg-cancel-btn'] "
								@click="isSet = !isSet"></span>

						</div> -->

						<div :class="['user-msg-table-wrap',isSet ? 'is-edit' : '']" v-if="tabIndex == 0">

							<div class="user-msg-list-wrap">

								<div class="user-msg-item" v-for="(item, index) in messagelist" :key="index"
									@click="messagedetail(index)">

									<div :class="['user-msg-checkbox-icon',setItem ? 'is-active' : ''] " v-show="isSet"
										@click="addItem"></div>

									<span class="user-msg-notread-icon" v-if="item.is_read == 0"></span>

									<div class="user-msg-title not-read">
										{{item.title}} <span
											class="user-msg-date">{{$base.formattedDate(item.created_at)}}</span>
									</div>

									<div class="user-msg-content">
										{{item.content}}
									</div>

								</div>

							</div>



							<div class="user-msg-paging-bg">

								<div class="paging-wrap">

									<span
										:class="['paging-btn', page === 1 ? 'paging-first-disabled' : 'paging-first-show']"
										@click="changePage(1)"></span>


									<span
										:class="['paging-btn', page === 1 ? 'paging-previous-disabled' : 'paging-previous-show']"
										@click="changePage(page - 1)"></span>


									<select v-model="page" @change="changePage(page)">

										<option v-for="item in last_page" :key="item" :value="item">
											{{ item }}
										</option>

									</select>

									<span class="page-text">/{{ last_page }}</span>

									<span
										:class="['paging-btn', page === last_page ? 'paging-next-disabled' : 'paging-next-show']"
										@click="changePage(page + 1)"></span>

									<span
										:class="['paging-btn', page === last_page ? 'paging-last-disabled' : 'paging-last-show']"
										@click="changePage(last_page)"></span>

								</div>

							</div>


						</div>


						<div v-if="tabIndex == 1">

							<div class="m-reply-list">

								<div class="m-reply-info-wrap" v-for="(item, index) in fawenlist" :key="index"
									@click="huifu(index)">

									<div class="m-reply-info-title-wrap">
										<div class="m-reply-info-title">[{{item.title}}]</div>
										<div class="m-reply-info-time">{{formatDateAlone(item.created_at)}}</div>
									</div>

									<div class="m-reply-info-content-wrap">
										<div class="m-reply-info-content">{{item.content}}</div>
										<!-- <span>已回覆</span> -->
									</div>

								</div>

							</div>

							<div class="user-msg-paging-bg">

								<div class="paging-wrap">

									<span
										:class="['paging-btn', page === 1 ? 'paging-first-disabled' : 'paging-first-show']"
										@click="changePage(1)"></span>


									<span
										:class="['paging-btn', page === 1 ? 'paging-previous-disabled' : 'paging-previous-show']"
										@click="changePage(page - 1)"></span>


									<select v-model="page" @change="changePage(page)">

										<option v-for="item in last_page" :key="item" :value="item">
											{{ item }}
										</option>

									</select>

									<span class="page-text">/{{ last_page }}</span>

									<span
										:class="['paging-btn', page === last_page ? 'paging-next-disabled' : 'paging-next-show']"
										@click="changePage(page + 1)"></span>

									<span
										:class="['paging-btn', page === last_page ? 'paging-last-disabled' : 'paging-last-show']"
										@click="changePage(last_page)"></span>

								</div>

							</div>
							
							
						</div>

						<!-- <div class="user-msg-paging-bg" v-show="!isSet">
							<PageFooter :totalPage="10" />
						</div> -->

						<!-- <PageFooter :totalPage="last_page" /> -->

						<!-- 分页部分 -->


						<!-- <PageFooter :totalPage="last_page" /> -->


						<!-- <div class="user-msg-btn-wrap clearfix" v-show="isSet">
							<div class="user-msg-btn user-msg-select-all">{{$t('全选')}}</div>
							<div class="user-msg-btn user-msg-delete-btn">
								{{$t('删除')}}(0)
							</div>
						</div> -->


					</div>

				</div>

			</div>

		</div>

		<Diyloading ref="loadmodal"></Diyloading>

	</div>

</template>

<script>
	
	// import BScroll from 'better-scroll';

	import Diyloading from "./libs/Diyloading.vue"

	import BankHeaderVue from "./libs/BankHeader.vue";

	import MineHeader from "./libs/MineHeader.vue";

	// import PageFooter from "./libs/PageFooter.vue";

	export default {
		name: "message",
		data() {
			return {

				tabIndex: 0,
				showReply: false,
				isSet: false,
				setItem: false,

				page: 1, // 当前页码

				current_page: 1,
				messagelist: [],
				fawenlist: [],
				first_page_url: "",
				from: 1,
				last_page: 11,
				last_page_url: "",
				next_page_url: "",
				path: "",
				per_page: 0,
				prev_page_url: null,
				to: 0,
				total: 0

			};
		},
		components: {
			BankHeaderVue,
			MineHeader,
			// PageFooter,
			Diyloading
		},
		
		created() {
			let that = this;
		},
		
		methods: {

			// 格式化时间
			formatDateAlone(dateString) {

				const date = new Date(dateString);
				const year = date.getFullYear();
				const month = ('0' + (date.getMonth() + 1)).slice(-2); // 补0
				const day = ('0' + date.getDate()).slice(-2); // 补0
				return `${year}-${month}-${day}`;

			},

			settabIndex(e) {
				this.tabIndex = e;

				if (this.tabIndex == 0) {
					this.page = 1; // 更新当前页码
					this.getMessageList(); // 重新获取数据
				} else {
					this.page = 1; // 更新当前页码
					this.getFawenList(); // 重新获取数据
				}

			},

			changePage(newPage) {

				if (newPage < 1 || newPage > this.last_page) {
					return; // 如果页码超出范围，不执行任何操作
				}
				this.page = newPage; // 更新当前页码

				if (this.tabIndex == 0) {
					this.getMessageList(); // 重新获取数据
				} else {
					this.getFawenList(); // 重新获取数据
				}
			},

			getMessageList() {

				let that = this;

				that.$refs.loadmodal.open(); // 传入标题和消息

				that.$apiFun.post("/api/member/message/list", {
						page: that.page // 使用当前页码请求数据
					})
					.then((res) => {
						// 处理响应数据
						if (res.code === 200) {

							that.current_page = res.data.current_page;

							that.messagelist = res.data.data; // 获取消息列表数据

							// 更新分页参数
							that.first_page_url = res.data.first_page_url;
							that.last_page = res.data.last_page;
							that.next_page_url = res.data.next_page_url;
							that.prev_page_url = res.data.prev_page_url;
							that.total = res.data.total;

						}

						// 隐藏加载动画
						that.$refs.loadmodal.close(); // 传入标题和消息
					})
					.catch((err) => {
						console.error("请求失败", err);
						// 隐藏加载动画
						that.$refs.loadmodal.close(); // 传入标题和消息
					});
			},

			
			getFawenList() {

				let that = this;

				that.$refs.loadmodal.open(); // 传入标题和消息

				that.$apiFun.post("/api/member/message/send_list", {
						page: that.page // 使用当前页码请求数据
					})
					.then((res) => {
						// 处理响应数据
						if (res.code === 200) {

							that.current_page = res.data.current_page;

							that.fawenlist = res.data.data; // 获取消息列表数据

							// 更新分页参数
							that.first_page_url = res.data.first_page_url;
							that.last_page = res.data.last_page;
							that.next_page_url = res.data.next_page_url;
							that.prev_page_url = res.data.prev_page_url;
							that.total = res.data.total;

						}

						// 隐藏加载动画
						that.$refs.loadmodal.close(); // 传入标题和消息
					})
					.catch((err) => {
						console.error("请求失败", err);
						// 隐藏加载动画
						that.$refs.loadmodal.close(); // 传入标题和消息
					});
			},



			showCanReply() {
				this.showReply = !this.showReply;
			},

			addItem() {
				this.setItem = !this.setItem
			},
		},
		mounted() {
			let that = this;
			that.getMessageList();
		},
		updated() {
			let that = this;
		},
		beforeDestroy() {
			// window.removeEventListener("scroll", this.handleScroll);
		},

	};
</script>
<style lang="scss" scoped>
	// 引入分页
	@import "/page.css";

	.message {

		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				clear: both;
				content: "";
				display: table;
			}
		}

		.user-msg-nav-wrap {
			width: 100%;
			border-bottom: 2px solid #eaeaea;
			overflow-x: scroll;
			font-size: 14px;

			.user-msg-nav {
				position: relative;
				height: 2.5rem;
				float: left;
				border-bottom: 2px solid transparent;
				box-sizing: border-box;
				width: 50%;
				text-align: center;
			}

			.user-msg-nav.current {
				color: #0089f5;
				border-bottom: 2px solid #0089f5;
			}

			.user-msg-nav-title {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				box-sizing: border-box;
				display: inline-block;
				position: relative;
				max-width: 100%;
				padding: 10px;
			}
		}

		.user-msg-circle-icon {
			width: 6px;
			height: 6px;
			background: #ff4c73;
			border-radius: 50%;
			position: absolute;
			right: 0;
			top: 5px;

		}

		// .user-msg-content-wrap{
		// 	height: 100%;
		// 	overflow-y: auto;
		// 	padding-bottom: 5.4375rem;
		// }

		.user-msg-content-wrap {
			padding-bottom: 5.4375rem;
		}

		.user-msg-toolbar-wrap {
			background-color: #f3f3f3;
			padding: 0.4375rem 0.875rem;
		}

		.only-show-can-reply {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 0.8125rem;
			box-sizing: border-box;
			position: relative;
			display: inline-block;
			line-height: 25px;
			padding-left: 35px;
			max-width: calc(100% - 25px);
			vertical-align: middle;

			.user-msg-checkbox-icon {
				left: 0;
			}
		}

		.user-msg-checkbox-icon {
			position: absolute;
			top: 50%;
			left: .875rem;
			width: 1.5625rem;
			height: 1.5625rem;
			background-image: url("/static/image/ic_msg_checkbox_n.png");
			background-size: cover;
			background-position: 50% 50%;
			margin-top: -0.78125rem;
		}

		.user-msg-checkbox-icon.is-active {
			background-image: url("/static/image/ic_msg_checkbox_s.png");
		}

		.user-msg-toolbar-btn {
			float: right;
			background-size: cover;
			background-position: 50% 50%;
			width: 1.5625rem;
			height: 1.5625rem;
		}

		.user-msg-edit-btn {
			background-image: url("/static/image/btn_edit_n.png");
		}

		.user-msg-cancel-btn {
			background-image: url("/static/image/btn_cancel_h.png");
		}

		.user-msg-item {
			position: relative;
			color: #999;
			font-size: 0.875rem;
			border-bottom: 1px solid #d8d8d8;
			padding: 0.625rem 0.9375rem 0.625rem 1.75rem;
		}

		.user-msg-notread-icon {
			position: absolute;
			top: 50%;
			width: 0.5625rem;
			height: 0.5625rem;
			left: 0.8125rem;
			background-color: #f85f4c;
			border-radius: 50%;
			margin-top: -0.28125rem;
		}

		.user-msg-title {
			position: relative;
			line-height: 1.3125rem;
			color: #666;
			font-size: 1rem;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			padding-right: 6.375rem;
			margin-bottom: 0.4375rem;
		}

		.user-msg-title.not-read {
			font-weight: 700;
		}

		.user-msg-date {
			position: absolute;
			top: 0;
			right: 0;
			color: #999;
			font-weight: 400;
		}

		.user-msg-content {
			height: 1.25rem;
			line-height: 1.25rem;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.user-msg-paging-bg {
			// position: fixed;
			// bottom: 0;
			// left: 0;
			// width: 100%;
			// background-color: #fff;
			// box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.05);
			// padding: 0 0 1rem;
		}

		.is-edit {
			.user-msg-item {
				padding-left: 3.125rem;
			}

			.user-msg-notread-icon {
				top: 0.8125rem;
				left: 2.375rem;
				margin-top: 0;
			}
		}

		.user-msg-btn-wrap {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
		}

		.user-msg-btn {
			display: inline-block;
			width: 50%;
			height: 2.5625rem;
			line-height: 2.5625rem;
			color: #fff;
			font-size: 1rem;
			text-align: center;
		}

		.user-msg-select-all {
			background-color: #7e7e7e;
		}

		.user-msg-delete-btn {
			background-color: #f85f4c;
		}
	}


	.m-reply-list {
		padding-bottom: 72px;
	}

	.m-reply-info-wrap {
		position: relative;
		padding: 10px 10px 10px 28px;
		border-bottom: 1px solid #d8d8d8;
	}

	.m-reply-info-title-wrap {
		position: relative;
		width: 100%;
		color: #666;
		margin-bottom: 7px;
		line-height: 20px;
		font-size: 0;
		padding-right: 5.625rem;
	}

	.m-reply-info-title {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 14px;
		font-size: .875rem;
		position: relative;
		max-width: 100%;
		padding-right: 25px;
	}

	.m-reply-info-time {
		font-size: 14px;
		font-size: .875rem;
		text-align: right;
		color: #999;
	}

	.m-reply-info-time,
	.m-reply-info-title span {
		position: absolute;
		right: 0;
		top: 0;
	}

	.m-reply-info-content,
	.m-reply-info-time,
	.m-reply-info-title,
	.m-reply-info-title-wrap {
		box-sizing: border-box;
		display: inline-block;
		vertical-align: middle;
	}

	.m-reply-info-content-wrap {
		position: relative;
		font-size: 0;
		line-height: 18px;
	}

	.m-reply-info-content {
		font-size: 13px;
		font-size: .8125rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
		color: #999;
		height: 18px;
	}

	.m-reply-info-content,
	.m-reply-info-time,
	.m-reply-info-title,
	.m-reply-info-title-wrap {
		box-sizing: border-box;
		display: inline-block;
		vertical-align: middle;
	}

	.m-reply-info-content-wrap span {
		font-size: 13px;
		font-size: .8125rem;
		width: 60px;
		width: 3.75rem;
		box-sizing: border-box;
		position: absolute;
		right: 0;
		top: -3px;
		display: none;
		padding: 3px;
		border-radius: 4px;
		color: #fff;
		background: #5dba2c;
		text-align: center;
	}

	.mem-info2 {
		color: #737a86;
		height: 1.875rem;
		line-height: 1.875rem;
		font-size: 0.75rem;
		padding: 0 0.625rem;
		background-color: #fff;
		box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
		box-sizing: border-box;
		display: flex;
		width: 100%;
		justify-content: flex-end;
	}

	.mem-info2 .mem-balance {
		float: right;
	}
</style>