<template>
	<div class="betInfo-box">
		<BankHeader title="个人游戏限额" :isShow="false" />
		<MineHeader />
		<div class="betInfo">
			<div class="m-bi-nav">
				
				<div>
					<span :class="[tabIndex == 0 ? 'current' : '']" @click="tabIndex = 0">BB视讯</span>
					<span :class="[tabIndex == 1 ? 'current' : '']" @click="tabIndex = 1">BB彩票</span>
				</div>
				
			</div>
			<div class="m-bi-wrap">
				<div class="bet-info-wrap live-info" v-show="tabIndex == 0">
					<div class="data-wrap">
						<table>
							<thead>
								<tr>
									<th colspan="2">百家乐</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="data-title">单注最低限额</td>
									<td class="data-value align-right">20</td>
								</tr>
								<tr>
									<td class="data-title">单注最高限额</td>
									<td class="data-value align-right">200000</td>
								</tr>
								<tr>
									<td class="data-title">大小最高限额</td>
									<td class="data-value align-right">100000</td>
								</tr>
							</tbody>
						</table>
						<table>
							<thead>
								<tr>
									<th colspan="2">龙虎斗</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="data-title">单注最低限额</td>
									<td class="data-value align-right">20</td>
								</tr>
								<tr>
									<td class="data-title">单注最高限额</td>
									<td class="data-value align-right">200000</td>
								</tr>
								<tr>
									<td class="data-title">和注最高限额</td>
									<td class="data-value align-right">20000</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="bet-info-wrap lottery-info" v-show="tabIndex == 1">
					<div class="data-wrap">
						<select>
							<option value="HKLT">香港六合彩</option>
							<option value="BBPO">BB 弹珠台</option>
							<option value="MADR">BB 马力欧梯子</option>
							<option value="BJ3D">3D彩</option>
						</select>
						<table>
							<thead>
								<tr>
									<th class="col-left">玩法</th>
									<th class="col-mid align-right">单项限额</th>
									<th class="col-right align-right">单注限额</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>特别号</td>
									<td class="align-right">4160</td>
									<td class="align-right">2080</td>
								</tr>
								<tr>
									<td>特別号和单双</td>
									<td class="align-right">100000</td>
									<td class="align-right">50000</td>
								</tr>
								<tr>
									<td>特別号和大小</td>
									<td class="align-right">100000</td>
									<td class="align-right">50000</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import BankHeader from "./libs/BankHeader.vue"
	import MineHeader from "./libs/MineHeader.vue"
	export default {
		name: "betInfo",
		data() {
			return {
				tabIndex: 0,
			}
		},
		components: {
			BankHeader,
			MineHeader,
		},
	}
</script>
<style lang="scss" scoped>
	.betInfo-box {
		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				content: "";
				display: table;
				clear: both;
			}
		}

		.betInfo {
			.m-bi-nav {
				margin-bottom: 1rem;
				width: 100%;
				border-bottom: 2px solid #eaeaea;
				overflow-x: auto;
				font-size: 14px;
				white-space: nowrap;

				div {
					height: 2.625rem;
				}

				span {
					display: inline-block;
					border-bottom: 2px solid transparent;
					min-width: 90px;
					padding: 0.625rem;
					box-sizing: border-box;
					text-align: center;
				}

				span.current {
					color: #0089f5;
					border-bottom: 2px solid #0089f5;
				}
			}

			.m-bi-wrap {
				padding: 0 0.625rem;
			}

			.bet-info-wrap {
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 24px;

				&::after {
					content: " ";
					display: block;
					clear: both;
				}

				table {
					margin: 0 auto 6px;
					width: 96%;
					border-collapse: collapse;
					border-spacing: 0;

					tr {
						border: 1px solid #eee;
					}

					th {
						background: #ccc;
						padding: 1rem 2%;
						color: #fff;
						font-size: 0.938rem;
					}

					td {
						color: #313f52;
						padding: 0.625rem 2%;
						line-height: 1.25rem;
						font-size: 0.813rem;
					}

					.align-right {
						text-align: right;
					}
				}
			}

			.live-info {
				.data-title {
					width: 60%;
				}

				.data-value {
					width: 40%;
				}
			}

			.bet-info-wrap {
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 24px;

				&::after {
					content: " ";
					display: block;
					clear: both;
				}

				select {
					margin-bottom: 1.5rem;
					padding-left: 1rem;
					width: 100%;
					height: 2.5rem;
					border: 1px solid #ccc;
					font-size: .938rem;
					color: #313f52;
					outline: none;
				}
			}

			.lottery-info {
				.col-left {
					margin-left: 4%;
					width: 28%;
				}

				.col-mid {
					width: 24%;
				}

				.col-right {
					margin-right: 4%;
					width: 36%;
				}
			}
		}
	}
</style>