<template>

	<div>

		<div class="app-header-wrap">
			<div class="app-header-left">
				<div class="btn-back-home" @click="gopage('/')"></div>
			</div>
			<div class="header-title">{{$t('本站推荐')}}</div>
			<div class="app-header-right">
				<div class="app-balance-btn" v-if="isLogin" @click="setshowBalance()">{{$t('余额')}}</div>
			</div>
		</div>

		<div class="content-wrap">

			<!-- <div class="recommend-tabs">

				<div class="recommend-tab" :class="[tabIndex == 0 ? 'active' : '']" @click="changeTab(0)"
					style="width: 50%;">
					<i class="recommend-icon recommend-icon-site"></i><span>Recommend</span>
				</div>

				<div class="recommend-tab" :class="[tabIndex == 1 ? 'active' : '']" @click="changeTab(1)"
					style="width: 50%;">
					<i class="recommend-icon recommend-icon-activity"></i><span>Events</span>
				</div>

			</div> -->


			<div class="inner-view">

				<div class="recommend-body" v-if="tabIndex == 0">

					<div class="recommend-vendor-tab">


						<div class="recommend-vendor"
							v-for="(item, index) in recommendlists.slice(1,recommendlists.length)" :key="index">

							<div class="recommend-vendor-top">
								<div class="recommend-vendor-title">{{item.title}}</div>
								<div class="recommend-vendor-more" @click="gocasino('/casino',item)">{{$t('更多')}} <svg
										class="svg-inline--fa fa-angle-right fa-w-8 icon-right" aria-hidden="true"
										data-prefix="fas" data-icon="angle-right" role="img"
										xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
										<path class="" fill="currentColor"
											d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z">
										</path>
									</svg></div>
							</div>

							<div class="recommend-vendor-wrapper">

								<div class="recommend-vendor-games">

									<div class="game-entry" v-for="(item2, index2) in item.child" :key="index2">
										<div class="game-entry-inner" @click="$parent.openGamePlayb(item2)"><img
												class="game-entry-image" :src="item2.img_url" data-loaded="true"></div>
										<div class="game-entry-name">{{item2.name}}</div>
									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

				<!-- <div class="recommend-body" v-if="tabIndex == 1">

					<div data-v-2b101f22="" class="recommend-body">
						<div data-v-2b101f22="" class="recommend-vendor-tab activity">
							<div class="recommend-vendor">
								<div class="recommend-vendor-top">
									<div class="recommend-vendor-title">JDB Casino1</div>
									<div class="recommend-vendor-more" @click="gopage('/casino/233')">More Games <svg
											class="svg-inline--fa fa-angle-right fa-w-8 icon-right" aria-hidden="true"
											data-prefix="fas" data-icon="angle-right" role="img"
											xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
											<path class="" fill="currentColor"
												d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z">
											</path>
										</svg></div>
								</div>
								<div class="recommend-vendor-wrapper">

									<div class="event-wallscape">
										<div
											class="swiper swiper-initialized swiper-horizontal swiper-ios ele-swiper swiper-backface-hidden">
											<div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
												<div class="swiper-slide swiper-slide-active" style="width: 134px;">
													<div class="event-item"><img class="image-pic"
															src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/39/en/execute.png?v=1726804800">
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="recommend-vendor-games">
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39161.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39161.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Fruity Bonanza</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39110.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39110.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">SuperNiubiDeluxe</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39093.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39093.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">BIRDS PARTY</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39113.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39113.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Moneybags Man</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39014.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39014.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Open Sesame</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39124.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39124.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Kong</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39088.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39088.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">TripleKingKong</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39003.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39003.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Winning Mask</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39135.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39135.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Blossom Of Wealth</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39173.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39173.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">POP POP CANDY</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39143.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39143.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">TrumpCard</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39107.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39107.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Treasure Bowl</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39083.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39083.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">OpenSesameII</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39098.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39098.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">SuperNuibi</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39144.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39144.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Moneybags Man 2</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39070.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39070.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">WinningMask II</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39314.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39314.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Dragon Soar - Hyper Wild
											</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39079.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Kingsman</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39108.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Mjolnir</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39119.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Spindrift2</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39160.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">CAISHEN COMING</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39283.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Wealthway</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39271.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Drago Soar</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39313.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">MAGIC ACE</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39094.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">OrientAnimals</div>
										</div>
									</div>
								</div>
							</div>
							<div class="recommend-vendor">
								<div class="recommend-vendor-top">
									<div class="recommend-vendor-title">JDB Casino2</div>
									<div class="recommend-vendor-more" @click="gopage('/casino/233')">More Games <svg
											class="svg-inline--fa fa-angle-right fa-w-8 icon-right" aria-hidden="true"
											data-prefix="fas" data-icon="angle-right" role="img"
											xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
											<path class="" fill="currentColor"
												d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z">
											</path>
										</svg></div>
								</div>
								<div class="recommend-vendor-wrapper">
									<div class="event-wallscape">
										<div
											class="swiper swiper-initialized swiper-horizontal swiper-ios ele-swiper swiper-backface-hidden">
											<div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
												<div class="swiper-slide swiper-slide-active" style="width: 134px;">
													<div class="event-item"><img class="image-pic"
															src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/39/en/execute_1.png?v=1726804800">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="recommend-vendor-games">
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39064.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39064.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">CaiShenFinshing</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/39/39099.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/39/39099.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Shade Dragons Fishing</div>

										</div>
									</div>
								</div>
							</div>
							<div class="recommend-vendor">
								<div class="recommend-vendor-top">
									<div class="recommend-vendor-title">CQ9 Casino</div>
									<div class="recommend-vendor-more" @click="gopage('/casino/233')">More Games <svg
											class="svg-inline--fa fa-angle-right fa-w-8 icon-right" aria-hidden="true"
											data-prefix="fas" data-icon="angle-right" role="img"
											xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
											<path class="" fill="currentColor"
												d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z">
											</path>
										</svg></div>
								</div>
								<div class="recommend-vendor-wrapper">
									<div class="event-wallscape">
										<div
											class="swiper swiper-initialized swiper-horizontal swiper-ios ele-swiper swiper-backface-hidden">
											<div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
												<div class="swiper-slide swiper-slide-active" style="width: 134px;">
													<div class="event-item"><img class="image-pic"
															src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/52/en/execute_1.png?v=1724299200">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="recommend-vendor-games">
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52031.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/52/52031.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Jump High</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52032.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/52/52032.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">RaveJump</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52037.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/52/52037.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Good Fortune</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52038.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Zeus</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52033.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">LuckyBats</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52040.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">FaCaiShen</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52039.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">JumpHigher</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52043.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">GuGuGu</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52036.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">God of War</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52042.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Fire Chibi</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52052.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">FruitKing</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52051.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">SoSweet</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52045.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">ZhongKui</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52062.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Golden Egg</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52070.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Funny Alpaca</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52044.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Fire Queen</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52041.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">DiscoNight</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52229.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Cricket Fever</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52191.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Jump High 2</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52153.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Snow Queen</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52121.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">SakuraLegend</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52194.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Wolf Disco</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52143.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Flying Cai Shen</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52307.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">888 Cai Shen</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/52/52048.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">THOR</div>
										</div>
									</div>
								</div>
							</div>
							<div class="recommend-vendor">
								<div class="recommend-vendor-top">
									<div class="recommend-vendor-title">MG Casino</div>
									<div class="recommend-vendor-more" @click="gopage('/casino/233')">More Games <svg
											class="svg-inline--fa fa-angle-right fa-w-8 icon-right" aria-hidden="true"
											data-prefix="fas" data-icon="angle-right" role="img"
											xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
											<path class="" fill="currentColor"
												d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z">
											</path>
										</svg></div>
								</div>
								<div class="recommend-vendor-wrapper">
									<div class="event-wallscape">
										<div
											class="swiper swiper-initialized swiper-horizontal swiper-ios ele-swiper swiper-backface-hidden">
											<div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
												<div class="swiper-slide swiper-slide-active" style="width: 134px;">
													<div class="event-item"><img class="image-pic"
															src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/82/en/execute_1.png?v=1727020800">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="recommend-vendor-games">
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/82/82519.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/82/82519.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Lucky Twins PowerClusters
											</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/82/82512.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/82/82512.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Almighty Zeus Wilds</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/82/82489.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/82/82489.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Breakaway Max</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/82/82102.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Immortal Romance</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/82/82490.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Pong Pong Mahjong</div>

										</div>
									</div>
								</div>
							</div>
							<div class="recommend-vendor">
								<div class="recommend-vendor-top">
									<div class="recommend-vendor-title">FG Casino</div>
									<div class="recommend-vendor-more" @click="gopage('/casino/233')">More Games <svg
											class="svg-inline--fa fa-angle-right fa-w-8 icon-right" aria-hidden="true"
											data-prefix="fas" data-icon="angle-right" role="img"
											xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
											<path class="" fill="currentColor"
												d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z">
											</path>
										</svg></div>
								</div>
								<div class="recommend-vendor-wrapper">
									<div class="event-wallscape">
										<div
											class="swiper swiper-initialized swiper-horizontal swiper-ios ele-swiper swiper-backface-hidden">
											<div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
												<div class="swiper-slide swiper-slide-active" style="width: 134px;">
													<div class="event-item"><img class="image-pic"
															src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/59/en/execute.png?v=1727150400">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="recommend-vendor-games">
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/59/59116.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/59/59116.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Daily Fishing</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/59/59112.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/59/59112.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Beauty Fishing</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/59/59113.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/59/59113.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">X-Men</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/59/59115.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Fish Reef</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/59/59159.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">The legend of Mermaid</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/59/59153.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">The Magicians</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/59/59170.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">World of hunter</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/59/59114.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Birds Hunter</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/59/59117.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Power fishing 3D</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/59/59165.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Mole Rat Catcher3D</div>

										</div>
									</div>
								</div>
							</div>
							<div class="recommend-vendor">
								<div class="recommend-vendor-top">
									<div class="recommend-vendor-title">MT Casino</div>
									<div class="recommend-vendor-more" @click="gopage('/casino/233')">More Games <svg
											class="svg-inline--fa fa-angle-right fa-w-8 icon-right" aria-hidden="true"
											data-prefix="fas" data-icon="angle-right" role="img"
											xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
											<path class="" fill="currentColor"
												d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z">
											</path>
										</svg></div>
								</div>
								<div class="recommend-vendor-wrapper">
									<div class="event-wallscape">
										<div
											class="swiper swiper-initialized swiper-horizontal swiper-ios ele-swiper swiper-backface-hidden">
											<div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
												<div class="swiper-slide swiper-slide-active" style="width: 134px;">
													<div class="event-item"><img class="image-pic"
															src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/71/en/execute.png?v=1725422400">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="recommend-vendor-games">
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/71/71065.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/71/71065.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Fortune Cat 2</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/71/71066.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/71/71066.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Gold City Treasure Hunt
											</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/71/71061.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/71/71061.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Dragon World</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/71/71060.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Dragon Of The Eastern Ocean
											</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/71/71048.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Water Margin DELUXE</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/71/71046.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Captain's Bounty</div>

										</div>
									</div>
								</div>
							</div>
							<div class="recommend-vendor">
								<div class="recommend-vendor-top">
									<div class="recommend-vendor-title">PP Casino</div>
									<div class="recommend-vendor-more" @click="gopage('/casino/233')">More Games <svg
											class="svg-inline--fa fa-angle-right fa-w-8 icon-right" aria-hidden="true"
											data-prefix="fas" data-icon="angle-right" role="img"
											xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
											<path class="" fill="currentColor"
												d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z">
											</path>
										</svg></div>
								</div>
								<div class="recommend-vendor-wrapper">
									<div class="event-wallscape">
										<div
											class="swiper swiper-initialized swiper-horizontal swiper-ios ele-swiper swiper-backface-hidden">
											<div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
												<div class="swiper-slide swiper-slide-active" style="width: 134px;">
													<div class="event-item"><img class="image-pic"
															src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/37/en/execute.png?v=1727690400">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="recommend-vendor-games">
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37482.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/37/37482.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Gates of Olympus 1000</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37219.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/37/37219.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Gates of Olympus</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37518.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/37/37518.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Sugar Rush 1000</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37336.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">极速糖果 ™</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37539.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Sweet Bonanza 1000</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37134.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Sweet Bonanza</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37565.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Dragon Gold 88</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37418.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Zeus vs Hades - Gods of War
											</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37546.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Mahjong Wins 2</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37238.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">5 Lions Megaways™</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37261.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Starlight Princess™</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37512.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Zombie Train</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37437.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Starlight Princess 1000
											</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37378.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">5 Rabbits Megaways™</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37428.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Wisdom of Athena</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37529.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Wukong Rush</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37478.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Sugar Rush Xmas</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37451.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Saiyan Mania</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37259.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Pyramid Bonanza™</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37226.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Buffalo King Megaways</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37447.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Mahjong Wins</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37136.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">5 Lions Gold</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37475.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Mahjong X</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37148.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Fire Strike</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/37/37150.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Sweet Bonanza Xmas</div>

										</div>
									</div>
								</div>
							</div>
							<div class="recommend-vendor">
								<div class="recommend-vendor-top">
									<div class="recommend-vendor-title">SG Casino1</div>
									<div class="recommend-vendor-more" @click="gopage('/casino/233')">More Games <svg
											class="svg-inline--fa fa-angle-right fa-w-8 icon-right" aria-hidden="true"
											data-prefix="fas" data-icon="angle-right" role="img"
											xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
											<path class="" fill="currentColor"
												d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z">
											</path>
										</svg></div>
								</div>
								<div class="recommend-vendor-wrapper">
									<div class="event-wallscape">
										<div
											class="swiper swiper-initialized swiper-horizontal swiper-ios ele-swiper swiper-backface-hidden">
											<div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
												<div class="swiper-slide swiper-slide-active" style="width: 134px;">
													<div class="event-item"><img class="image-pic"
															src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/44/en/execute_1.png?v=1724040000">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="recommend-vendor-games">
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44192.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/44/44192.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Fury Max Lucky Road</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44191.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/44/44191.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Alchemy Quest Level Up</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44190.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/44/44190.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Secrets of Anubis</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44174.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Legacy of Kong Maxways</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44167.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Caishen</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44046.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Golden Lotus SE</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44188.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Dragon Wish</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44189.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Brothers Kingdom 2</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44185.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Clash of the Giants</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44172.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Captain Golds Fortune</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44187.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Royale Vegas</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44169.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Gold Panther Maxways</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44157.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Wild Wet Win</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44064.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Fafafa</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44164.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Fiery Sevens Exclusive</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44162.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Royale House</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44163.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Tiger Dance</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44001.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">First Love</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44156.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Royal Katt</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44061.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">5 Fortune Dragons</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44138.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Fiery Sevens</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44158.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Gold Rush Cowboys</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44155.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Muay Thai Fighter</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44137.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Mega 7</div>
										</div>
									</div>
								</div>
							</div>
							<div class="recommend-vendor">
								<div class="recommend-vendor-top">
									<div class="recommend-vendor-title">SG Casino2</div>
									<div class="recommend-vendor-more" @click="gopage('/casino/233')">More Games <svg
											class="svg-inline--fa fa-angle-right fa-w-8 icon-right" aria-hidden="true"
											data-prefix="fas" data-icon="angle-right" role="img"
											xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
											<path class="" fill="currentColor"
												d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z">
											</path>
										</svg></div>
								</div>
								<div class="recommend-vendor-wrapper">
									<div class="event-wallscape">
										<div
											class="swiper swiper-initialized swiper-horizontal swiper-ios ele-swiper swiper-backface-hidden">
											<div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
												<div class="swiper-slide swiper-slide-active" style="width: 134px;">
													<div class="event-item"><img class="image-pic"
															src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/44/en/execute_3.png?v=1724040000">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="recommend-vendor-games">
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44120.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/44/44120.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Fishing God</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44132.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/44/44132.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Fishing War</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44146.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/44/44146.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Alien Hunter</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/44/44148.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Zombie Party</div>
										</div>
									</div>
								</div>
							</div>
							<div class="recommend-vendor">
								<div class="recommend-vendor-top">
									<div class="recommend-vendor-title">NE Casino</div>
									<div class="recommend-vendor-more" @click="gopage('/casino/233')">More Games <svg
											class="svg-inline--fa fa-angle-right fa-w-8 icon-right" aria-hidden="true"
											data-prefix="fas" data-icon="angle-right" role="img"
											xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
											<path class="" fill="currentColor"
												d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z">
											</path>
										</svg></div>
								</div>
								<div class="recommend-vendor-wrapper">
									<div class="event-wallscape">
										<div
											class="swiper swiper-initialized swiper-horizontal swiper-ios ele-swiper swiper-backface-hidden">
											<div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
												<div class="swiper-slide swiper-slide-active" style="width: 134px;">
													<div class="event-item"><img class="image-pic"
															src="https://g1.cfvn66.com/web/mobile/backend-image/wall-scape/120/en/execute.png?v=1722470400">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="recommend-vendor-games">
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120181.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/120/120181.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">101 Candies</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120017.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/120/120017.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Starburst</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120176.png?v=1727399345"
													class="game-entry-image"
													src="https://g1.cfvn66.com/game_picture/mobile/120/120176.png?v=1727399345"
													data-loaded="true"></div>
											<div class="game-entry-name">Twin Spin XXXtreme</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120018.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Twin Spin</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120188.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">FINN AND THE CANDY SPIN
											</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120012.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Finn and the Swirly Spin
											</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120013.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Fruit Shop</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120087.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Gonzo’s Gold</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120014.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Gonzo's Quest</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120016.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Pyramid: Quest For
												Immortality</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120204.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">JUNGLE SPIRIT MEGAWAYS™
											</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120021.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Reel Rush</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120200.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">ROYAL NUTS</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120071.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Space Wars</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120030.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Steam Tower</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120184.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">THRILL TO GRILL</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120019.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Twin Spin Megaways</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120182.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">BIG BANK BOOM</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120032.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Blood Suckers II</div>

										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120127.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Cornelius</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120210.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">CURSED TREASURE</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120024.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Dead or Alive</div>
										</div>
										<div class="game-entry">
											<div class="game-entry-inner"><img
													data-src="https://g1.cfvn66.com/game_picture/mobile/120/120064.png?v=1727399345"
													class="game-entry-image">
											</div>
											<div class="game-entry-name">Jungle Spirit: Call of the
												Wild</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div> -->

			</div>



		</div>

		<!-- 右边弹出层 -->
		<Balance :showBalance="showBalance" @closeBalance="showBalance = false" />
		<!-- 右边弹出层 -->

		<!-- footer -->
		<Foot />

	</div>

</template>

<script>
	import Balance from './libs/Balance.vue'

	import Foot from './libs/Foot.vue';

	export default {
		name: "recmmend",
		data() {
			return {
				
				showBalance: false,
				
				tabIndex: 0,
				
				recommendlists: [],
				
			};
		},
		components: {
			Foot,
			Balance
		},
		computed: {

			userinfo() {
				return this.$store.state.userInfo
			},

			//内容高度
			isLogin() {
				return this.$store.state.token;
			},

			getcasinolist() {
				return this.$store.state.casinolist;
			}

		},
		created() {
			let that = this;
			// 获取本站推荐的数据
			that.getrecommend();
		},
		methods: {

			setshowBalance() {

				let that = this;

				if (that.isLogin) {

					that.showBalance = true;

				} else {

					that.gopage("/login");

				}

			},


			gopage(url) {

				let that = this;

				that.$router.push({
					path: url
				});

			},

			gocasino(url, item) {
				let that = this;
				// 使用 query 传递 item 参数
				that.$router.push({
					path: url,
					query: {

						class_name: item.class_name, // 传递 class_name 参数
						game_type: JSON.parse(item.params).gameType, // 传递其他参数

					}
				});
			},

			changeTab(index) {
				this.tabIndex = index
			},

			getrecommend() {

				let that = this;

				that.$apiFun.get("/api/getrecommend", {}).then((res) => {

					// 请求成功，假设 res.data.money 包含返回的余额信息
					if (res.code === 200) {

						that.recommendlists = res.data;

						let casino = [];
						res.data.map((item, index) => {
							casino.push({
								title: item.title,
								game_type: item.game_type,
								mobile_pic: item.mobile_pic,
								tags: item.tags,
								params: item.params,
								api_name: item.api_name,
								class_name: item.class_name,
								weight: item.weight,
								game_type_text: item.game_type_text,
								game_type_cn_text: item.game_type_cn_text,
								cimg1: ''
							});
						});

						// 需要快速存储到@store
						localStorage.setItem('casinolist', JSON.stringify(casino));
						that.$store.commit('changcasinolist');

					}

				}).catch((err) => {
					console.error("请求失败：", err);
				});

			}
		}
	};
</script>

<style lang="scss" scoped>
	.app-header-wrap {
		background: linear-gradient(#4eaafc, #1c5cfb);
	}

	.app-header-wrap {
		flex: 0 0 3rem;
		line-height: 3rem;
	}

	.app-header-wrap {
		// position: sticky;
		position: fixed;
		z-index: 100;
		top: 0;
		// flex: 0 0 2.625rem;
		width: 100%;
		text-align: center;
	}

	.app-header-wrap .app-header-left {
		left: .875rem;
	}

	.app-header-wrap .app-header-left,
	.app-header-wrap .app-header-right {
		position: absolute;
		top: 0;
		bottom: 0;
	}

	.app-header-wrap .app-header-left:before,
	.app-header-wrap .app-header-right:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}


	.app-balance-btn {
		border: 1px solid #dae3ff;
		background: linear-gradient(169deg, #fdfdff, #e6ecff, #f9fbff);
		color: #4a5c95;
	}

	.app-balance-btn {
		display: inline-block;
		vertical-align: middle;
		border-radius: .25rem;
		width: 3.25rem;
		height: 1.5625rem;
		font-size: .8125rem;
		line-height: 1.5625rem;
	}

	.app-header-wrap .btn-back-home {
		background-image: url("/static/image/DuvElord.png");
	}

	.btn-back-home {
		display: inline-block;
		vertical-align: middle;
		width: 1.75rem;
		height: 1.6875rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 0 0;
	}

	.app-header-wrap .header-title,
	.app-header-wrap .btn-back {
		color: #fff;
	}

	.header-title {
		box-sizing: border-box;
		padding: 0 3.25rem;
		max-width: 100%;
		overflow: hidden;
		font-size: 1rem;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.app-header-wrap .app-header-right {
		right: .875rem;
	}

	.app-header-wrap .app-header-left,
	.app-header-wrap .app-header-right {
		position: absolute;
		top: 0;
		bottom: 0;
	}

	.app-header-wrap .app-header-left:before,
	.app-header-wrap .app-header-right:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.content-wrap {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		overflow: hidden;
	}

	.recommend-tabs {
		// display: flex;
		// flex: 0 0 2.625rem;
		// overflow: hidden;
		display: flex;
		position: fixed;
		width: 100%;
		height: 2.625rem;
		background: #fff;
		z-index: 100;
		top: 3rem;
	}


	.recommend-tabs .recommend-tab {
		display: inline-block;
		vertical-align: middle;
		box-sizing: border-box;
		font-size: .75rem;
		text-align: center;
	}

	.recommend-tabs .recommend-tab {
		background-color: #fff;
		color: #a4aabb;
	}

	.recommend-tabs .recommend-tab.active {
		border-bottom: .1875rem solid #1c5cfb;
		color: #2472fc;
	}

	.recommend-tabs .recommend-tab:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.recommend-tabs .recommend-tab .recommend-icon {
		display: inline-block;
		vertical-align: middle;
		margin-right: .3125rem;
		width: 1.5625rem;
		height: 1.5rem;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}

	.recommend-tabs .recommend-tab .recommend-icon {
		display: none;
	}

	.recommend-tabs .recommend-tab span {
		display: inline-block;
		vertical-align: middle;
		max-width: calc(100% - 1.875rem);
		text-align: left;
	}

	.inner-view {
		flex: 1 1 auto;
		overflow: auto;
		// padding-top: 5.6rem;
		padding-top: 3.6rem;
	}

	.recommend-body {
		padding-bottom: 4rem;
		font-size: .75rem;
	}

	.recommend-vendor {
		background-color: #fff;
	}

	.recommend-vendor {
		margin: .375rem 0;
		padding-bottom: .3125rem;
	}

	.recommend-vendor-tab .recommend-vendor {
		padding-top: .3125rem;
	}

	.recommend-vendor .recommend-vendor-top {
		margin: 0;
		padding: 0 .375rem .5rem .6875rem;
		height: 1.4375rem;
		line-height: 1.4375rem;
		background: 0 0;
	}

	.recommend-vendor-top {
		position: relative;
		margin-bottom: .5rem;
		height: 1.125rem;
		line-height: 1.125rem;
		background-repeat: repeat-x;
		background-size: 60%;
		background-position: 0 50%;
	}

	.recommend-vendor .recommend-vendor-top .recommend-vendor-title {
		border-left: .25rem solid #3b3b3b;
		color: #3b3b3b;
	}

	.recommend-vendor .recommend-vendor-top .recommend-vendor-title {
		float: left;
		position: static;
		padding-left: .4375rem;
		font-size: .875rem;
	}

	.recommend-vendor-top .recommend-vendor-title {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 .3125rem 0 .6875rem;
	}

	.recommend-vendor .recommend-vendor-top .recommend-vendor-more {
		background: linear-gradient(#4eaafc, #1c5cfb);
		color: #fff;
	}

	.recommend-vendor .recommend-vendor-top .recommend-vendor-more {
		float: right;
		position: static;
		border-radius: .75rem;
		padding: 0 .6875rem;
	}

	.recommend-vendor-top .recommend-vendor-more {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0 .6875rem 0 .3125rem;
	}

	.recommend-vendor .recommend-vendor-top:after {
		content: "";
		clear: both;
	}

	.recommend-vendor .recommend-vendor-top .recommend-vendor-more .icon-right {
		display: none;
	}

	.recommend-vendor-top .recommend-vendor-more .icon-right {
		vertical-align: middle;
		margin-left: .125rem;
	}

	svg:not(:root).svg-inline--fa {
		overflow: visible;
	}

	.svg-inline--fa.fa-w-8 {
		width: 0.5em;
	}

	.svg-inline--fa {
		display: inline-block;
		font-size: inherit;
		height: 1em;
		overflow: visible;
		vertical-align: -.125em;
	}

	.recommend-vendor-tab .recommend-vendor-wrapper {
		padding-left: .625rem;
	}

	.recommend-vendor-tab.activity .recommend-vendor-wrapper {
		display: flex;
		padding-left: .6875rem;
	}

	.recommend-vendor-tab.activity .event-wallscape {
		flex: 0 0 8.0625rem;
		box-sizing: content-box;
		margin: 0;
		padding: 0 .625rem 0 0;
	}

	.event-wallscape {
		display: inline-block;
		box-sizing: border-box;
		margin-top: .9375rem;
		padding: .125rem .25rem 0;
		width: 8.75rem;
	}

	.swiper {
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: hidden;
		list-style: none;
		padding: 0;
		z-index: 1;
	}

	.swiper-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		transition-property: transform;
		box-sizing: content-box;
	}

	.swiper-backface-hidden .swiper-slide {
		transform: translateZ(0);
		backface-visibility: hidden;
	}

	.swiper-slide {
		flex-shrink: 0;
		width: 100%;
		height: 100%;
		position: relative;
		transition-property: transform;
	}

	.recommend-vendor-tab.activity .event-wallscape .event-item {
		width: 8.0625rem;
	}

	.event-wallscape .event-item {
		box-sizing: border-box;
		width: 8.25rem;
	}

	.event-wallscape .event-item .image-pic {
		display: block;
		width: 100%;
	}

	.recommend-vendor-games {
		box-sizing: border-box;
		width: 100%;
		overflow-x: scroll;
		white-space: nowrap;
	}

	.recommend-vendor-games .game-entry {
		padding-right: .625rem;
	}

	.game-entry {
		display: inline-block;
		vertical-align: top;
		padding-right: .625rem;
		width: 3.75rem;
	}

	.recommend-vendor-games .game-entry .game-entry-inner {
		width: 3.625rem;
		height: 3.625rem;
	}

	.game-entry .game-entry-inner {
		position: relative;
		margin: 0 auto;
		width: 3.75rem;
		height: 3.75rem;
	}

	.game-entry .game-entry-inner .game-entry-image {
		display: block;
		width: 100%;
		height: 100%;
	}

	.recommend-vendor .game-entry .game-entry-name {
		color: #3b3b3b;
	}

	.recommend-vendor-games .game-entry .game-entry-name {
		margin-top: 1px;
		padding: 0;
	}

	.game-entry .game-entry-name {
		padding: 0 .3125rem;
		overflow: hidden;
		color: #989898;
		font-size: .75rem;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>