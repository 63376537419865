<template>

	<div>

		<div class="lore-register" v-if="!unicode && !agreement">

			<div class="lore-register-top">
				<div class="lore-register-close" @click="goindex"></div>
				<img src="/static/image/big-logo.png" alt="" />
			</div>
			<div class="lore-register-tab">
				<div class="lore-register-tab-item" @click="gopage('/login')">会员登入</div>
				<div class="lore-register-tab-item register-tab-act">注册账号</div>
			</div>


			<div class="lore-register-form">
				
				<h2 class="info-title">注册账号</h2>

				<div class="field">

					<span class="star is-required"> ★ </span>
					<div class="base-input">
						<input type="text" name="account" placeholder="账号" maxlength="12" v-model="account"
							@focus="accountfocus" @blur="accountblur" @input="accountInput" />
						<span class="text-unicode">@55</span>
						<a class="icon icon-unicode" @click="unicode = true"><i></i></a>
						<div class="icon" :class="{'icon-success':accountsuc}"></div>
					</div>

					<div class="msg" v-if="accountmsg">{{ accountmsgMessage }}</div>
					<div class="error" v-if="accounterror">{{ accounterrorMessage }}</div>

				</div>

				<div class="field">

					<span class="star is-required"> ★ </span>
					<div class="base-input password-input">
						<input :type="isShowRePass ? 'text' : 'password'" name="password" placeholder="密码"
							maxlength="12" v-model="password" @focus="passwordfocus" @blur="passwordblur"
							@input="passwordInput" />
						<a :class="['noEye', isShowRePass ? 'eye' : '']" title="是否显示密码"
							@click="isShowRePass = !isShowRePass"><i></i></a>
					</div>

					<div class="msg" v-if="passwordmsg">{{ passwordmsgMessage }}</div>
					<div class="error" v-if="passworderror">{{ passworderrorMessage }}</div>
					<div class="msg-item msg-strong bad" v-if="passwordStrengthMessage">{{passwordStrengthMessage}}
					</div>

				</div>


				<div class="field">

					<span class="star is-required"> ★ </span>
					<div class="base-input password-input">
						<input :type="isSHowSurePass ? 'text' : 'password'" name="passwd" placeholder="密码"
							maxlength="12" v-model="password2" @focus="password2focus" @blur="password2blur"
							@input="password2Input" />
						<a :class="['noEye', isSHowSurePass ? 'eye' : '']" title="是否显示密码"
							@click="isSHowSurePass = !isSHowSurePass"><i></i></a>
					</div>

					<div class="error" v-if="password2error">{{ password2errorMessage }}</div>

				</div>

				<!-- <div class="separate-line"></div>
				<div class="wallet-wrap" v-show="!showNoAC">
					
					<div class="wallet-title-wrap">
						<div class="wallet-title-bg"><span class="wallet-title-content">第三方连动注册</span></div>
						<div class="wallet-subtitle">连动注册即绑定，享受多元钱包服务</div>
					</div>
					<div class="wallet-radio-wrap">
						<label :class="['wallet-radio-item', lianIndex == 'no' ? 'active' : '']"
							for="linkAccountType-"><input v-model="lianIndex" type="radio" name="linkAccountType"
								value="no" id="linkAccountType-" class="wallet-radio-input" />
							<div class="wallet-bank-icon"></div>
							<div class="wallet-bank-label-wrap"><span class="wallet-bank-label">不连动</span></div>
						</label><label :class="['wallet-radio-item', lianIndex == 'cg' ? 'active' : '']"
							for="linkAccountType-cg"><input v-model="lianIndex" type="radio" name="linkAccountType"
								value="cg" id="linkAccountType-cg" class="wallet-radio-input" />
							<div class="wallet-bank-icon cg"></div>
							<div class="wallet-bank-label-wrap"><span class="wallet-bank-label">CG Pay</span></div>
						</label><label :class="['wallet-radio-item', lianIndex == 'os' ? 'active' : '']"
							for="linkAccountType-os"><input v-model="lianIndex" type="radio" name="linkAccountType"
								value="os" id="linkAccountType-os" class="wallet-radio-input" />
							<div class="wallet-bank-icon os"></div>
							<div class="wallet-bank-label-wrap"><span class="wallet-bank-label">OS Pay</span></div>
						</label>
					</div>
					
					<div v-show="lianIndex == 'cg'" class="walletform">
						<div class="field">
							<span class="star is-required"> ★ </span>
							<div class="base-input">
								<input type="text" name="linkAccountEmail" placeholder="CG Pay 登入邮箱" maxlength="50" />
								<div class="icon"></div>
							</div>
						</div>
						<div class="field">
							<span class="star is-required"> ★ </span>
							<div class="base-input">
								<input type="password" name="linkAccountAuthCode" placeholder="CG Pay 2FA 验证码"
									maxlength="50" />
								<div class="icon"></div>
							</div>
						</div>
						<div class="wallet-verify-wrap">
							<a class="wallet-noaccount" @click="showNoAC = true">没有CG Pay账号？</a>
							<div class="field">
								<div class="walletform-check-wrap"><button name="linkAccountVerified"
										class="base-btn walletform-check" type="button">连动验证</button></div>
							</div>
						</div>
					</div>
					
					<div v-show="lianIndex == 'os'" class="walletform">
						<div class="field">
							<span class="star is-required"> ★ </span>
							<div class="base-input">
								<input type="text" name="linkAccountEmail" placeholder="OS Pay 登入邮箱" maxlength="50" />
								<div class="icon"></div>
							</div>
						</div>
						<div class="field">
							<span class="star is-required"> ★ </span>
							<div class="base-input">
								<input type="password" name="linkAccountAuthCode" placeholder="OS Pay 2FA 验证码"
									maxlength="50" />
								<div class="icon"></div>
							</div>
						</div>
						<div class="wallet-verify-wrap">
							<a class="wallet-noaccount" @click="showNoAC = true">没有OS Pay账号？</a>
							<div class="field">
								<div class="walletform-check-wrap"><button name="linkAccountVerified"
										class="base-btn walletform-check" type="button">连动验证</button></div>
							</div>
						</div>
					</div>
				</div> -->

				<!-- <div class="wallet-wrap" v-show="showNoAC">
					<div class="wallet-title-wrap">
						<div class="wallet-title-bg">
							<span
								class="wallet-title-content">{{ `还没有 ${lianIndex == "cg" ? "CG Pay" : "OS Pay"} 账号？` }}
							</span>
						</div>
						<div class="wallet-intro-wrap clearfix">
							<div class="wallet-intro">
								<p>{{ `若您现在还没有 ${lianIndex == "cg" ? "CG Pay" : "OS Pay"} 账号，请不用担心。` }}</p>
								<p>{{ `您现在仍可完成会员注册，并于日后有 ${lianIndex == "cg" ? "CG Pay" : "OS Pay"} 账号时再绑定连动。` }}</p>
								<p class="list-prefix">填妥标记<span class="wallet-intro-star"> ★ </span>的资料后即可完成注册</p>
							</div>
							<button class="wallet-known" @click="showNoAC = false" type="button">我知道了</button>
						</div>
					</div>
				</div> -->

				<h2 class="info-title" v-show="lianIndex == 'no'">会员资料</h2>

				<div class="field">

					<span class="star is-required"> ★ </span>

					<div class="base-input is-focus">
						<input type="text" name="realname" placeholder="真实姓名" maxlength="30" v-model="realname"
							@focus="realnamefocus" @blur="realnameblur" @input="realnameInput" />
						<div class="icon" :class="{'icon-success':realnamesuc}"></div>
					</div>

					<div class="msg" v-if="realnamemsg">{{ realnamemsgMessage }}</div>
					<div class="error" v-if="realnameerror">{{ realnameerrorMessage }}</div>

				</div>

				<!-- <div class="separate-line"></div>
				<h2 class="info-title">安全检查</h2> -->

				<div class="separate-line"></div>
				<div class="join-form-agree">
					<input type="checkbox" id="agree" class="agree" name="agree" v-model="isChecked" />
					<label for="agree">我已届满合法博彩年龄，且同意各项开户条约。</label><a @click="agreement = true">开户协议</a>
				</div>

				<button type="submit" class="join-form-btn submit-btn" @click="register()">确认送出</button>

			</div>

			<div class="join-footer-content">
				<div id="joinmem">
					<div>
						<ul>
							<li>标记有 * 者为必填项目。</li>
							<li>请您牢记注册的账号，密码，注册的姓名必须真实有效且与需绑定的出款银行户名一致；</li>
							<li>遇到任何问题，请您联系24小时在线客服。</li>
						</ul>
					</div>
				</div>
			</div>

		</div>


		<!-- 验证码 -->
		<div class="captcha-pop" v-show="showcheckyzm">
			<div class="captcha-wrapper" style="display: block;">

				<svg @click="showcheckyzm = false" class="svg-inline--fa fa-circle-xmark fa-w-16 captcha-close"
					aria-hidden="true" data-prefix="far" data-icon="circle-xmark" role="img"
					xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
					<path class="" fill="currentColor"
						d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z">
					</path>
				</svg>


				<div class="captcha-title">请进行验证</div>

				<div class="captcha-field field-checkcode">

					<input id="rmNum" name="rmNum" placeholder="请输入验证码" class="captcha-field-input" type="text"
						maxlength="6" autocomplete="off"><img class="captcha-img"
						src="https://www810046020746552.002tgpd.com:5569/infe/verify/macpic?SR=13a6e88693f0ccae7af4">

				</div>
				<div class="clearfix">
					<div class="captcha-img-hint"><svg
							class="svg-inline--fa fa-rotate-right fa-w-16 captcha-img-refresh" aria-hidden="true"
							data-prefix="fas" data-icon="rotate-right" role="img" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512">
							<path class="" fill="currentColor"
								d="M468.9 32.11c13.87 0 27.18 10.77 27.18 27.04v145.9c0 10.59-8.584 19.17-19.17 19.17h-145.7c-16.28 0-27.06-13.32-27.06-27.2c0-6.634 2.461-13.4 7.96-18.9l45.12-45.14c-28.22-23.14-63.85-36.64-101.3-36.64c-88.09 0-159.8 71.69-159.8 159.8S167.8 415.9 255.9 415.9c73.14 0 89.44-38.31 115.1-38.31c18.48 0 31.97 15.04 31.97 31.96c0 35.04-81.59 70.41-147 70.41c-123.4 0-223.9-100.5-223.9-223.9S132.6 32.44 256 32.44c54.6 0 106.2 20.39 146.4 55.26l47.6-47.63C455.5 34.57 462.3 32.11 468.9 32.11z">
							</path>
						</svg><span>点击上图更新验证码显示</span></div>
				</div>

				<div class="captcha-img-submit" @click="checkyzm()">确认</div>

			</div>
			<div></div>
		</div>


		<div id="unicode" class="unicode" v-show="unicode">
			<div class="unicode-wrap">
				<!-- <a class="close-btn"><i></i>
				</a> -->
				<div class="content-header">
					<a class="return-btn" @click="unicode = false"><i></i></a>
					<div id="js-unicode-title" class="unicode-title">靠谱登入码</div>
				</div>
				<div class="unicode-content-wrap">
					<p>当您输入自订的账号，系统会在您的账号后方，自动加上<span class="uni-code-dia2">@55</span></p>
					<p><span>例如：您自订账号是kwa2，登入码即为：kwa2@55</span><br><span>这是加强保护会员资料的安全机制！</span></p>
					<p><span class="list-prefix">登入网站时，在「账号」栏位，输入您自订的账号；</span><br><span
							class="list-prefix">登入App时，在「账号」栏位，输入包含@的登入码就可以了。</span></p>
				</div>
				<!-- <div class="footer"><a class="footer-btn">我知道了</a></div> -->
			</div>
		</div>


		<div id="agreement" class="agreement" v-show="agreement">
			<div class="content-header">
				<a class="return-btn" @click="agreement = false"><i></i></a>
				<div id="js-agreement-title" class="agreement-title">开户协议</div>
			</div>
			<div id="js-agreement-content" class="agreement-content">
				<div id="joinmem">
					<div>
						<p style="margin-left:10px; margin-right:0px">立即开通澳门娱乐场账户，享受最优惠的各项红利!</p>
						<ul style="margin-left:0px; margin-right:0px">
							<li>澳门娱乐场只接受合法博彩年龄的客户申请。同时我们保留要求客户提供其年龄证明的权利。</li>
							<li>在澳门娱乐场进行注册时所提供的全部信息必须在各个方面都是准确和完整的。在使用借记卡或信用卡时，持卡人的姓名必须与在网站上注册时的一致。</li>
							<li>在开户后进行一次有效存款，恭喜您成为澳门娱乐场有效会员!</li>
							<li>成为澳门娱乐场有效会员后，客户有责任以电邮、联系在线客服、在澳门娱乐场网站上留言等方式，随时向本公司提供最新的个人资料。</li>
							<li>经澳门娱乐场发现会员有重复申请账号行为时，有权将这些账户视为一个联合账户。我们保留取消、收回会员所有优惠红利，以及优惠红利所产生的盈利之权利。每位玩家、每一住址、每一电子邮箱、每一电话号码、相同支付卡/信用卡号码，以及共享计算机环境
								(例如:网吧、其他公共用计算机等)只能够拥有一个会员账号，各项优惠只适用于每位客户在澳门娱乐场唯一的账户。</li>
							<li>澳门娱乐场是提供互联网投注服务的机构。请会员在注册前参考当地政府的法律，在博彩不被允许的地区，如有会员在澳门娱乐场注册、下注，为会员个人行为，澳门娱乐场不负责、承担任何相关责任。
							</li>
							<li>无论是个人或是团体，如有任何威胁、滥用澳门娱乐场优惠的行为，澳门娱乐场保留杈利取消、收回由优惠产生的红利，并保留权利追讨最高50%手续费。</li>
							<li>所有澳门娱乐场的优惠是特别为玩家而设，在玩家注册信息有争议时，为确保双方利益、杜绝身份盗用行为，澳门娱乐场保留权利要求客户向我们提供充足有效的文件，
								并以各种方式辨别客户是否符合资格享有我们的任何优惠。</li>
							<li>客户一经注册开户，将被视为接受所有颁布在澳门娱乐场网站上的规则与条例。</li>
						</ul>
						<p style="margin-left:10px; margin-right:0px">
							本公司是使用BBIN所提供的在线娱乐软件，若发现您在同系统的娱乐城上开设多个会员账户，并进行套利下注；本公司有权取消您的会员账号并将所有下注营利取消！</p>
					</div>
				</div>
			</div>

			<div class="footer"><a class="footer-btn" @click="setagreement()">我已届满合法博彩年龄，且同意各项开户条约。</a></div>

		</div>


		<!-- 公共弹窗 -->
		<GlobalModal ref="globalmodal"></GlobalModal>

	</div>

</template>

<script>
	export default {
		name: "joinus",
		data() {
			return {
				registerInfo: {},
				loginInfo: {},
				imgLis: ["2PYL", "6AQ5", "8PHD", "21I7", "69HM", "ACWA", "DUZ7", "IY98", "K647", "M52T", "NY52", "NZFA",
					"SN76", "SP4D", "VAEO", "YFQM", "ZZU5", "7GQT", "LFW3", "NU2T", "UAE3"
				],
				index: 0,
				infoType: 1, //0 是登陆 1是注册
				psw1: true,
				psw2: true,
				psw3: true,
				
				invite_code: "",
				// 新加
				// 登录
				isRemeber: false,
				isShowPass: false,
				// 注册
				isShowRePass: false,
				isSHowSurePass: false,
				// 连动注册
				lianIndex: "no",
				// 没有连动
				showNoAC: false,

				account: '',
				password: '',

				showcheckyzm: false, // 验证码验证功能

				account: '', // 用户名输入值
				accountsuc: false, // 错误状态
				accountmsg: false, // 错误状态
				accounterror: false, // 错误状态
				accountmsgMessage: '', // 错误提示信息
				accounterrorMessage: '', // 错误提示信息


				password: '', // 密码输入值
				passwordmsg: false, // 错误状态
				passworderror: false, // 错误状态
				passwordmsgMessage: '', // 错误提示信息
				passworderrorMessage: '', // 错误提示信息
				passwordStrengthMessage: '', // 密码强度提示信息
				passwordStrengthClass: '', // 密码强度样式


				password2: '', // 密码输入值
				password2error: false, // 错误状态
				password2errorMessage: '密码不符，请重新输入', // 错误提示信息


				realname: '',
				realnamesuc: '',
				realnamemsg: false, // 错误状态
				realnameerror: false, // 错误状态
				realnamemsgMessage: '', // 错误提示信息
				realnameerrorMessage: '', // 错误提示信息

				unicode: false,
				agreement: false,

				isChecked: false // 复选框的状态
			}
		},

		created() {

			let that = this
			var query = that.$route.query
			if (query.type) {
				// console.log(query)
				that.infoType = query.type
			}
			if (query.invite_code) {
				that.invite_code = query.invite_code
			}
			that.changIndex();

		},

		methods: {
			setagreement() {
				this.agreement = false;
				this.isChecked = true;
			},
			// 获取焦点
			accountfocus() {
				this.accountmsg = true; // 获取焦点时隐藏错误信息
				this.accountmsgMessage = '请输入4-12码英文或数字且符合0~9及a~z字元'; // 错误提示信息
			},

			// 失去焦点
			accountblur() {

				const regex = /^[a-z0-9]{4,12}$/; // 4-12位数字或小写字母
				
				if (this.account.trim() === '') {
					
					this.accountsuc = false; // 如果输入为空，显示错误提示
					this.accountmsg = false; // 如果输入为空，显示错误提示
					this.accounterror = true; // 如果输入为空，显示错误提示
					this.accountmsgMessage = ''; // 错误提示信息
					this.accounterrorMessage = '该栏位不得为空！' // 错误提示信息
					
				} else if (!regex.test(this.account)) {

					this.accountsuc = false; // 说明账号是错误的
					this.accountmsg = false; // 如果输入为空，显示错误提示
					this.accounterror = true;
					this.accounterrorMessage = '请输入4-12码英文小写及数字！';


				} else {
					// 在这里判断账号是否已经被人使用 api检查
					// if(checkUser === false){

					// }
					this.accountsuc = true; // 说明账号是正确的
					this.accountmsg = false; // 如果输入为空，显示错误提示
					this.accounterror = false; // 清除错误信息

				}

			},

			accountInput() {
				const regex = /^[a-z0-9]{4,12}$/; // 4-12位数字或小写字母
				if (this.account.trim() === '') {

					this.accounterror = true;
					this.accounterrorMessage = '该栏位不得为空！';

				} else if (!regex.test(this.account)) {

					this.accountsuc = false; // 说明账号是错误
					this.accounterror = true;
					this.accounterrorMessage = '请输入4-12码英文小写及数字！';

				} else {

					this.accountsuc = true; // 说明账号是正确的
					this.accountmsg = true; // 如果输入为空，显示错误提示
					this.accounterror = false; // 清除错误信息

				}
			},

			passwordfocus() {
				this.passwordmsg = true; // 获取焦点时隐藏错误信息
				this.passwordmsgMessage = '6-12 位字符（至少包含１个大写字母、１个小写字母、１个数字组合)'; // 错误提示信息
			},


			passwordblur() {

				const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,12}$/; // 至少包含1个大写字母、1个小写字母、1个数字，6-12位

				if (this.password.trim() === '') {

					this.passwordmsg = false; // 如果输入为空，显示错误提示
					this.passworderror = true; // 如果输入为空，显示错误提示
					this.passwordmsgMessage = ''; // 错误提示信息
					this.passworderrorMessage = '该栏位不得为空！' // 错误提示信息

					this.passwordStrengthMessage = '';
					this.passwordStrengthClass = '';

				} else if (!regex.test(this.password)) {

					this.passwordmsg = false; // 如果输入为空，显示错误提示
					this.passworderror = true;
					this.passworderrorMessage = '请输入6-12 码且需含有英文大小写及数字';

					this.passwordStrengthMessage = '';
					this.passwordStrengthClass = '';

					this.checkPasswordStrength();

				} else {

					this.passwordmsg = false; // 如果输入为空，显示错误提示
					this.passworderror = false; // 清除错误信息

					this.passwordStrengthMessage = '';
					this.passwordStrengthClass = '';

				}

			},

			// 实时验证密码
			passwordInput() {

				const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,12}$/; // 至少包含1个大写字母、1个小写字母、1个数字，6-12位
				// 用户名必须以小写字母开头并且只能包含小写字母和数字
				if (!regex.test(this.password)) {
					this.passwordmsg = true; // 获取焦点时隐藏错误信息
					this.passwordmsgMessage = '6-12 位字符（至少包含１个大写字母、１个小写字母、１个数字组合)'; // 错误提示信息
				} else {
					this.passwordmsg = false; // 获取焦点时隐藏错误信息
					this.passwordmsgMessage = '6-12 位字符（至少包含１个大写字母、１个小写字母、１个数字组合)'; // 错误提示信息
				}

				this.checkPasswordStrength();

			},

			// 检查密码强度
			checkPasswordStrength() {

				if (this.password.length < 6) {
					this.passwordStrengthMessage = '密码强度：弱';
					this.passwordStrengthClass = 'weak';
				} else if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,8}$/.test(this.password)) {
					this.passwordStrengthMessage = '密码强度：中等';
					this.passwordStrengthClass = 'medium';
				} else if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{9,12}$/.test(this.password)) {
					this.passwordStrengthMessage = '密码强度：强';
					this.passwordStrengthClass = 'strong';
				} else {
					this.passwordStrengthMessage = '';
					this.passwordStrengthClass = '';
				}

			},



			password2focus() {

				this.password2error = true; // 如果输入为空，显示错误提示
				this.password2errorMessage = '该栏位不得为空！' // 错误提示信息

			},

			password2blur() {

				if (this.password2.trim() === '') {
					this.password2error = true; // 如果输入为空，显示错误提示
					this.password2errorMessage = '该栏位不得为空！' // 错误提示信息
				} else if (this.password2 !== this.password) {
					this.password2error = true;
					this.password2errorMessage = '密码不符，请重新输入';
				} else {
					this.password2error = false; // 清除错误信息
				}

			},

			// 实时验证密码
			password2Input() {

				if (this.password2.trim() === '') {

					this.password2error = true; // 如果输入为空，显示错误提示
					this.password2errorMessage = '该栏位不得为空！' // 错误提示信息


				} else if (this.password2 !== this.password) {

					this.password2error = true;
					this.password2errorMessage = '密码不符，请重新输入';

				} else {

					this.password2error = false; // 清除错误信息

				}

			},


			realnamefocus() {

				this.realnamemsg = true; // 错误状态
				this.realnameerror = true; // 错误状态
				this.realnamemsgMessage = '必须与您的银行账户名称相同，否则不能出款！'; // 错误提示信息
				this.realnameerrorMessage = '该栏位不得为空！'; // 错误提示信息

			},

			realnameblur() {

				const regex = /^[A-Za-z\u4e00-\u9fa5]+$/;

				if (this.realname.trim() === '') {

					this.realnamemsg = false; // 错误状态
					this.realnameerror = true; // 错误状态
					this.realnamemsgMessage = '必须与您的银行账户名称相同，否则不能出款！'; // 错误提示信息
					this.realnameerrorMessage = '该栏位不得为空！'; // 错误提示信息

				} else if (!regex.test(this.realname)) {

					this.realnamemsg = false; // 错误状态
					this.realnameerror = true; // 错误状态
					this.realnamemsgMessage = '必须与您的银行账户名称相同，否则不能出款！'; // 错误提示信息
					this.realnameerrorMessage = '请勿输入数字、空白及特殊字元'; // 错误提示信息

				} else {
					this.realnamemsg = false; // 错误状态
					this.realnameerror = true; // 错误状态
					this.realnameerrorMessage = '请勿输入数字、空白及特殊字元'; // 错误提示信息
				}

			},

			// 实时验证密码
			realnameInput() {
				
				const regex = /^[A-Za-z\u4e00-\u9fa5]+$/;

				if (this.realname.trim() === '') {

					this.realnamemsg = true; // 错误状态
					this.realnameerror = true; // 错误状态
					this.realnamemsgMessage = '必须与您的银行账户名称相同，否则不能出款！'; // 错误提示信息
					this.realnameerrorMessage = '该栏位不得为空！'; // 错误提示信息

				} else if (!regex.test(this.realname)) {

					this.realnamemsg = true; // 错误状态
					this.realnameerror = true; // 错误状态
					this.realnamemsgMessage = '必须与您的银行账户名称相同，否则不能出款！'; // 错误提示信息
					this.realnameerrorMessage = '请勿输入数字、空白及特殊字元'; // 错误提示信息

				} else {
					this.realnamemsg = true; // 错误状态
					this.realnameerror = true; // 错误状态
					// this.realnameerrorMessage = '请勿输入数字、空白及特殊字元'; // 错误提示信息
				}
			},


			gopage(path) {
				this.$router.push({
					path: path
				});
			},

			goindex() {
				this.$router.push({
					path: '/'
				});
			},

			golanguage() {
				this.$router.push({
					path: '/language'
				});
			},

			changPsw(name) {
				this[name] = !this[name]
			},

			checkyzm() {

				const title = "Custom Title";
				const message = "This is a custom message for the modal.";
				const confirm = "confirm";
				this.$refs.globalmodal.open(title, message, confirm); // 传入标题和消息

			},

			changInfoType(type) {

				let that = this
				if (that.infoType == type) {
					return
				}
				that.infoType = type
				that.changIndex()
				that.loginInfo = {}
				that.registerInfo = {}
				this.psw1 = true
				this.psw2 = true
				this.psw3 = true

			},

			changIndex() {
				this.index = parseInt(20 * Math.random())
			},


			register() {

				let that = this
				
				if (that.account.trim() === '') {
					that.accounterror = true; // 如果输入为空，显示错误提示
					that.accounterrorMessage = '该栏位不得为空！' // 错误提示信息
				}

				if (that.password.trim() === '') {
					that.passworderror = true; // 如果输入为空，显示错误提示
					that.passworderrorMessage = '该栏位不得为空！' // 错误提示信息
				}

				if (that.password2.trim() === '') {
					that.password2error = true; // 如果输入为空，显示错误提示
					that.password2errorMessage = '该栏位不得为空！' // 错误提示信息
				}

				if (that.realname.trim() === '') {
					that.realnameerror = true; // 如果输入为空，显示错误提示
					that.realnameerrorMessage = '该栏位不得为空！' // 错误提示信息
				}

				// if (that.account.trim() === '' || that.password.trim() === '' || that.password2.trim() === '' || that
				// 	.realname.trim() === '') {
				// 	return;
				// }

				if (that.isChecked == false) {
					alert('請勾選同意條款！');
					return;
				}

				that.$parent.showLoading()

				var params = {
					key:"",
					name: that.account,
					password: that.password,
					password_confirmation: that.password2,
					phone: "13888888888",
					qk_pwd: "123456",
					realname: that.realname,
					captcha:"",
					register_site: "app"
				}

				if (that.invite_code) {
					params.invite_code = that.invite_code
				}

				// api/auth/register


				that.$apiFun.post('api/auth/register', params).then((res) => {

					// that.$apiFun.register(info).then((res) => {
					// that.$parent.showTost(1, res.message)
					
					if (res.code !== 200) {
						
						that.$refs.globalmodal.open('讯息', res.message, '确定'); // 传入标题和消息
						return;
						
					}
					
					if (res.code == 200) {
						
						localStorage.setItem("token", res.token)
						
						that.$store.commit("changToken",res.token)
						
						that.$parent.getUserInfo()
						
						// that.$parent.openDaoTime()
						
						that.$parent.goNav("/")
					}
					
					that.$parent.hideLoading()

				})

			},
		},
		mounted() {
			let that = this
		},
		updated() {
			let that = this
		},
		beforeDestroy() {},
	}
</script>
<style lang="scss" scoped>
	
	// .lore-login {
		
	// 	background-color: #f9faff;
	// 	height: 100%;

	// 	.lore-login-box {
			
	// 		height: 100%;
	// 		padding: 0.6rem 4% 0;
	// 		position: relative;
	// 		background: #fff;

	// 		.lore-login-top {
	// 			padding-left: 0.3rem;
	// 			display: flex;
	// 			justify-content: space-between;
	// 			align-items: center;

	// 			.lore-login-top-left {
	// 				display: flex;
	// 				align-items: center;

	// 				.lore-login-lang {
	// 					width: 1.25rem;
	// 					height: 1.25rem;
	// 					background-image: url("/static/image/lang.png");
	// 					background-repeat: no-repeat;
	// 					background-size: 100%;
	// 					background-position: 0 0;
	// 				}

	// 				.lore-login-service {
	// 					margin-left: 1.5625rem;
	// 					display: inline-block;
	// 					width: 1.25rem;
	// 					height: 1.25rem;
	// 					background-image: url("/static/image/service.png");
	// 					background-repeat: no-repeat;
	// 					background-size: 100%;
	// 					background-position: 0 0;
	// 				}
	// 			}

	// 			.lore-login-top-right {
	// 				display: flex;
	// 				align-items: center;

	// 				.login-close {
	// 					color: #a4aabb;
	// 					right: 0.75rem;
	// 					width: 1.875rem;
	// 					height: 1.875rem;
	// 				}
	// 			}
	// 		}

	// 		.lore-login-logo {
	// 			display: flex;
	// 			justify-content: center;

	// 			img {
	// 				width: 12.5rem;
	// 				height: 3.875rem;
	// 			}
	// 		}

	// 		.lore-login-main {
	// 			border: 1px solid #fff;
	// 			border-radius: 0.9375rem;
	// 			background: rgba(243, 247, 255, 0.6);
	// 			box-shadow: 0 1px 0.1875rem rgba(75, 106, 184, 0.2);
	// 			padding: 1.875rem 1.375rem 2.5rem;
	// 			margin-top: 0.75rem;

	// 			.lore-login-tab {
	// 				display: flex;
	// 				padding-bottom: 1.25rem;
	// 				position: relative;

	// 				.lore-login-tab-item {
	// 					width: 50%;
	// 					height: 1.25rem;
	// 					line-height: 1.25rem;
	// 					font-size: 0.9375rem;
	// 					font-weight: 700;
	// 					text-align: center;
	// 					text-decoration: none;
	// 					color: #8394c4;
	// 				}

	// 				.login-tab-act {
	// 					color: #2472fc;
	// 				}

	// 				&::before {
	// 					content: "";
	// 					position: absolute;
	// 					top: -0.25rem;
	// 					left: 50%;
	// 					transform: translateX(-50%);
	// 					width: 1px;
	// 					height: 1.6875rem;
	// 					background: #e9e9e9;
	// 				}
	// 			}

	// 			.lore-login-form {
	// 				.lore-login-field {
	// 					box-shadow: 0 1px 0.1875rem rgba(75, 106, 184, 0.2);
	// 					position: relative;
	// 					margin-top: 0.625rem;
	// 					border-radius: 1.25rem;
	// 					background: #ffffff;

	// 					&::before {
	// 						content: "";
	// 						position: absolute;
	// 						top: 0.8125rem;
	// 						left: 1.125rem;
	// 						width: 1rem;
	// 						height: 1rem;
	// 						background-repeat: no-repeat;
	// 						background-size: cover;
	// 						background-position: 0 0;
	// 					}

	// 					input {
	// 						background: #fff !important;
	// 						color: #3b3b3b;
	// 						box-sizing: border-box;
	// 						border: 0;
	// 						border-radius: 1.25rem;
	// 						padding: 0 4.375rem 0 2.875rem;
	// 						width: 100%;
	// 						height: 2.6875rem;
	// 						outline: 0;
	// 						font-size: 0.875rem;
	// 					}

	// 					input:placeholder-shown {
	// 						text-overflow: ellipsis;
	// 					}

	// 					.unicode {
	// 						color: #a4aabb;
	// 						position: absolute;
	// 						top: 50%;
	// 						transform: translateY(-50%);
	// 						right: 1.125rem;
	// 						font-size: 0.875rem;

	// 						.password-toggle {
	// 							display: inline-block;
	// 							vertical-align: middle;
	// 							width: 1.625rem;
	// 							height: 1.625rem;
	// 							background-image: url("/static/image/eye-close.png");
	// 							background-repeat: no-repeat;
	// 							background-size: cover;
	// 							background-position: 50% 50%;
	// 						}

	// 						.password-toggle-act {
	// 							background-image: url("/static/image/eye-open.png");
	// 						}
	// 					}
	// 				}

	// 				.username {
	// 					&::before {
	// 						background-image: url("/static/image/person-icon.png");
	// 					}
	// 				}

	// 				.password {
	// 					&::before {
	// 						background-image: url("/static/image/suo.png");
	// 					}
	// 				}

	// 				.lore-login-remember {
	// 					margin-top: 0.9375rem;
	// 					padding: 0 1.1875rem;
	// 					display: flex;
	// 					justify-content: space-between;
	// 					align-items: center;

	// 					.lore-login-remember-check {
	// 						color: #aaa;
	// 						display: flex;
	// 						justify-content: flex-start;
	// 						align-items: center;

	// 						.lore-login-remember-checkbox {
	// 							display: inline-block;
	// 							vertical-align: middle;
	// 							margin-right: 0.375rem;
	// 							width: 1.0625rem;
	// 							height: 1.0625rem;
	// 							background-repeat: no-repeat;
	// 							background-size: 100%;
	// 							background-position: 0 0;
	// 							background-image: url("/static/image/check.png");
	// 						}

	// 						.checkbox-act {
	// 							background-image: url("/static/image/checked.png");
	// 						}

	// 						span {
	// 							font-size: 0.875rem;
	// 						}
	// 					}

	// 					.password-forget {
	// 						color: #ef423a;
	// 						font-size: 0.875rem;
	// 					}
	// 				}

	// 				.lore-login-submit {
	// 					border-radius: 10rem;
	// 					background: #2472fc;
	// 					color: #fff;
	// 					margin-top: 1.875rem;
	// 					padding: 0.9375rem 0 0.875rem;
	// 					text-align: center;
	// 					font-size: 0.875rem;
	// 				}
	// 			}

	// 			.extra-btns-wrap {
	// 				margin-top: 0.625rem;
	// 				font-size: 0.875rem;

	// 				.go-app {
	// 					border-radius: 10rem;
	// 					background: #8394c4;
	// 					color: #fff;
	// 					margin-right: 0.625rem;
	// 					display: inline-block;
	// 					vertical-align: middle;
	// 					box-sizing: border-box;
	// 					padding: 0.8125rem 0 0.875rem;
	// 					width: calc(50% - 0.3125rem);
	// 					text-align: center;
	// 					text-decoration: none;
	// 				}

	// 				.go-ubbrowser {
	// 					border-radius: 10rem;
	// 					background: #8394c4;
	// 					color: #fff;
	// 					display: inline-block;
	// 					vertical-align: middle;
	// 					box-sizing: border-box;
	// 					padding: 0.8125rem 0 0.875rem;
	// 					width: calc(50% - 0.3125rem);
	// 					text-align: center;
	// 					text-decoration: none;
	// 				}
	// 			}
	// 		}

	// 		.login-footer {
	// 			border-radius: 0.625rem 0.625rem 0 0;
	// 			background-color: #fff;
	// 			box-shadow: 0 -0.125rem 2.0625rem rgba(75, 106, 184, 0.08);
	// 			position: fixed;
	// 			bottom: 0;
	// 			right: 0;
	// 			left: 0;
	// 			display: flex;
	// 			height: 2.875rem;
	// 			align-items: center;

	// 			&::before {
	// 				content: "";
	// 				display: inline-block;
	// 				vertical-align: middle;
	// 				height: 100%;
	// 			}

	// 			.login-footer-btn {
	// 				flex: 1 1 50%;
	// 				vertical-align: middle;
	// 				box-sizing: border-box;
	// 				padding: 0.3125rem;
	// 				overflow: hidden;
	// 				font-size: 0.75rem;
	// 				text-align: center;
	// 				text-overflow: ellipsis;
	// 				text-decoration: none;
	// 				white-space: nowrap;
	// 				color: #3b3b3b;

	// 				.login-footer-icon {
	// 					display: inline-block;
	// 					vertical-align: middle;
	// 					margin-right: 0.25rem;
	// 					width: 1.6875rem;
	// 					height: 1.6875rem;
	// 					background-repeat: no-repeat;
	// 					background-size: cover;
	// 					background-position: 0 0;
	// 				}
	// 			}

	// 			.go-home {
	// 				.login-footer-icon {
	// 					background-image: url("/static/image/suibian.png");
	// 				}
	// 			}

	// 			.go-pc {
	// 				border-left: 1px solid #e9e9e9;

	// 				.login-footer-icon {
	// 					background-image: url("/static/image/gopc.png");
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	.lore-register {
		
		background: #f3f3f3;

		.lore-register-top {
			// background: #f4f6fa;
			background: #ffffff;
			border-bottom: 1px solid #fff;
			height: 2.9rem;
			position: relative;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;

			.lore-register-close {
				position: absolute;
				top: 50%;
				right: 1.2rem;
				transform: translateY(-50%);
				background: url("/static/image/close.png") 0 0 no-repeat;
				width: 1rem;
				height: 1rem;
				background-size: cover;
			}

			img {
				width: 7.8rem;
			}
		}

		.lore-register-tab {
			position: relative;
			padding-top: 1.2rem;
			display: flex;
			justify-content: center;
			align-items: center;

			.lore-register-tab-item {
				width: 50%;
				height: 2.5rem;
				line-height: 1.2rem;
				color: #a4aabb;
				font-size: 1.1rem;
				text-align: center;
				text-decoration: none;
			}

			.register-tab-act {
				color: #3b3b3b;
				position: relative;

				&::after {
					position: absolute;
					bottom: -1px;
					left: 50%;
					transform: translateX(-50%);
					z-index: 1;
					width: 0.7rem;
					height: 0.6rem;
					content: "";
					background: url("/static/image/tab-top.png") 0 0 no-repeat;
					background-size: cover;
					margin: 0 auto;
				}
			}
		}

		.lore-register-form {
			background: #fff;
			border: 1px solid #e9e9e9;
			border-radius: 4px;
			padding: 1.2rem 1.3rem 1.5rem;
			margin: 0 1.7rem;
			position: relative;

			.info-title {
				margin-top: 0;
				padding-left: 5px;
				border-left: 3px solid #3b3b3b;
				font-weight: 700;
				font-size: 1.1rem;
				color: #323747;
				margin-bottom: 1rem;
			}

			.field {
				position: relative;
				margin: 0 auto 12px;
				width: 98%;
				padding-left: 1rem;
				box-sizing: border-box;
				color: #979dae;

				.star {
					color: #ef423a;
					display: block;
					position: absolute;
					top: 20px;
					left: 0;
					transform: translateY(-50%);
					width: 10px;
					line-height: 1rem;
					font-size: 0.875rem;
				}

				.base-input {
					background: #fff;
					border: 1px solid #e9e9e9;
					border-radius: 20px;
					position: relative;
					width: 100%;

					input {
						color: #1f285a;
						background: transparent;
						padding-left: 3%;
						width: 100%;
						height: 36px;
						font-size: 0.875rem;
						outline: none;
						border: 0;
						border-radius: 0;
						box-sizing: border-box;
					}

					.text-unicode {
						position: absolute;
						top: 50%;
						right: 4.5rem;
						margin-top: -1rem;
						height: 2rem;
						line-height: 2rem;
						color: #a4aabb;
						font-size: 0.875rem;
					}

					.icon {
						right: 0.2rem;
						background-image: url("/static/image/dui.png");
						background-size: contain;
						position: absolute;
						top: 50%;
						margin-top: -1rem;
						height: 2rem;
						width: 2.2rem;
					}

					.icon-success {
						background-image: url("/static/image/dui-success.png");
					}

					.icon-unicode {
						width: 2rem;
						right: 2.2rem;
						background: url("/static/image/wenhao.png") 0 0 no-repeat;
						background-size: contain;
					}

					.noEye {
						right: 0.5rem;
						background: url("/static/image/eye-close.png") 0 0 no-repeat;
						background-size: contain;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						width: 2rem;
						height: 2rem;
						cursor: pointer;
					}

					.eye {
						background: url("/static/image/eye-open.png") 0 0 no-repeat;
						background-size: contain;
					}
				}
			}

			.separate-line {
				border-color: #e9e9e9;
				border-top: 1px solid #dadada;
				margin: 1rem 0 0.7rem;
			}

			.wallet-wrap {
				border-color: #e9e9e9;
				background: #fff;
				box-sizing: border-box;
				margin: 0 auto 0.6rem;
				border: 1px solid #ededed;
				border-radius: 4px;
				padding: 1rem 0.6rem;
				width: 98%;

				.wallet-title-wrap {
					font-size: 0.7rem;
					font-weight: 700;
					text-align: center;

					.wallet-title-bg {
						position: relative;

						&::before {
							content: "";
							position: absolute;
							top: 50%;
							left: 0;
							width: 100%;
							height: 1px;
							background: #1f285a;
						}

						.wallet-title-content {
							color: #1f285a;
							background: #fff;
							position: relative;
							display: inline-block;
							padding: 0 1.5rem;
							max-width: 70%;
							word-break: break-all;
						}
					}

					.wallet-subtitle {
						color: #4a5c95;
						margin-top: 0.6rem;
					}

					.wallet-intro-wrap {
						.wallet-intro {
							color: #1f285a;
							padding: 0.2rem 1rem 0;
							font-size: 0.7rem;
							text-align: left;
							font-weight: normal;

							p {
								padding: 0.3rem 0;
							}
						}

						.wallet-known {
							color: #fff;
							background: linear-gradient(#4eaafc, #1c5cfb);
							float: right;
							margin-top: 0.6rem;
							width: 8.4rem;
							max-width: 100%;
							min-height: 1.7rem;
							border: 0;
							border-radius: 4px;
							outline: none;
							font-size: 0.7rem;
						}
					}

					.clearfix {
						&::before {
							content: "";
							display: table;
						}

						&::after {
							clear: both;
							content: "";
							display: table;
						}
					}
				}

				.wallet-radio-wrap {
					padding-top: 0.3rem;
					font-size: 0.7rem;
					border-radius: 4px;
					text-align: center;

					.wallet-radio-item {
						color: #1f285a;
						margin: 0.6rem 0.3rem 0;
						display: inline-block;
						vertical-align: top;
						position: relative;
						max-width: 3.6rem;
						min-width: 2.4rem;
						min-height: 2.4rem;
						cursor: pointer;
						user-select: none;

						.wallet-radio-input {
							display: none;
						}

						.wallet-bank-icon {
							border-color: #e9e9e9;
							position: absolute;
							top: 0;
							left: 50%;
							margin-left: -1.2rem;
							box-sizing: border-box;
							border: 2px solid #ededed;
							border-radius: 50%;
							width: 2.4rem;
							height: 2.4rem;
							background-size: 1.4rem;
							background-color: #fff;
							background-repeat: no-repeat;
							background-position: 50%;
							background-image: url("/static/image/liandong.png");
						}

						.cg {
							background-image: url("/static/image/wallet_cgpay.png");
						}

						.os {
							background-image: url("/static/image/wallet_ospay.png");
						}

						.wallet-bank-label-wrap {
							color: #1f285a;
							position: relative;
							padding-top: 2rem;

							.wallet-bank-label {
								background: hsla(0, 0%, 100%, 0.5);
								font-weight: 700;
							}
						}
					}

					.active {
						.wallet-bank-icon {
							&::before {
								background: #2472fc;
								content: "\2713";
								position: absolute;
								top: -0.2rem;
								left: -0.2rem;
								border-radius: 50%;
								width: 1rem;
								height: 1rem;
								color: #fff;
							}
						}

						.wallet-bank-label-wrap {
							color: #2472fc;
						}
					}
				}

				.walletform {
					position: relative;
					padding-top: 1rem;

					.wallet-verify-wrap {
						text-align: right;

						.wallet-noaccount {
							color: #2472fc;
							border-right-color: rgba(36, 114, 252, 0.4);
							display: inline-block;
							border-right: 1px solid rgba(64, 78, 103, 0.4);
							padding-right: 0.6rem;
							font-size: 0.6rem;
							font-weight: 700;
							text-decoration: underline;
						}

						.field {
							width: 50%;
							display: inline-block;
							padding-left: 0.3rem;
							margin-bottom: 0.7rem;
						}

						.walletform-check-wrap {
							border: 1px solid transparent;

							.walletform-check {
								color: #fff;
								background: linear-gradient(#4eaafc, #1c5cfb);
								outline: 0;
								display: inline-block;
								text-align: center;
								width: 100%;
								min-height: 1.7rem;
								border: 0;
								border-radius: 4px;
								font-size: 0.7rem;
							}
						}
					}
				}
			}

			.join-form-agree {
				color: #a4aabb;
				margin-bottom: 24px;
				line-height: 20px;
				// font-size: 0.875rem;

				.agree {
					margin-right: 10px;
				}

				a {
					color: #3b3b3b;
				}
			}
		}

		.join-footer-content {
			padding: 0 1.8rem 1.2rem;
			margin-top: 1.3rem;

			#joinmem {
				ul {
					padding: 1.1rem 1.6rem;
					list-style-type: none;

					li {
						color: #a4aabb;
						margin-bottom: 0.6rem;
						padding: 0;
						font-size: .95rem;
					}
				}
			}
		}
	}


	.field .error,
	.field .msg {
		background-color: #eee;
		line-height: 24px;
		font-size: 14px;
		color: #666;
		padding: 6px 18px;
	}

	.field .error {
		position: relative;
		color: #b41101;
		padding-left: 40px;
	}


	.field .error {
		color: #ef423a;
	}

	.field .msg-item {
		background-color: #eee;
		line-height: 18px;
		font-size: 14px;
		padding: 6px 18px;
	}

	.field .error,
	.field .msg,
	.field .msg-item {
		background: #fff;
	}

	.field .msg-strong.bad {
		color: #ff4c72;
	}

	.field .error+.msg-item,
	.field .msg+.msg-item {
		padding-top: 0;
	}


	.field .error:before {
		position: absolute;
		top: 10px;
		left: 18px;
		content: url(https://g1.cfvn66.com/infe/user/join/images/join_worn_icon.png);
		width: 16px;
		height: 17px;
	}


	.captcha-pop {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		background: rgba(0, 0, 0, .7);
	}

	.captcha-wrapper {
		position: relative;
		top: 8%;
		box-sizing: border-box;
		margin: 0 auto;
		border-radius: .625rem;
		padding: 2.1875rem .9375rem 1.25rem;
		width: 18.5rem;
		min-height: 9.375rem;
		background: #fff;
		background-color: #f5f7ff;
		color: #000;
		text-align: center;
	}

	.captcha-wrapper .captcha-close {
		position: absolute;
		bottom: -25%;
		left: 50%;
		margin-left: -.9375rem;
		width: 1.875rem;
		height: 1.875rem;
		color: #fff;
		font-size: 1.875rem;
	}

	.captcha-wrapper .captcha-title {
		margin: 0;
		padding-bottom: .875rem;
		color: #1e2745;
		font-size: .9375rem;
		font-weight: 700;
		font-family: STHeiti, Microsoft YaHei, 微软雅黑, SimSun, 宋体, arial, sans-serif;
	}

	.captcha-wrapper .captcha-field {
		position: relative;
		box-sizing: border-box;
		margin-bottom: .375rem;
		border: 1px solid #d1d3d8;
		border-radius: .25rem;
		height: 2.75rem;
		background: #fff;
	}

	// [lang=zh_hk] .captcha-wrapper .captcha-field .captcha-field-placeholder,
	// [lang=zh_cn] .captcha-wrapper .captcha-field .captcha-field-placeholder {
	// 	font-size: .9375rem;
	// 	font-family: STHeiti, Microsoft YaHei, 微软雅黑, SimSun, 宋体, arial, sans-serif;
	// }

	.captcha-wrapper .captcha-field .captcha-field-placeholder {
		position: absolute;
		top: .875rem;
		left: 1rem;
		max-width: 9.0625rem;
		height: .875rem;
		line-height: .875rem;
		overflow: hidden;
		color: #9da4bc;
		font-size: .75rem;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.captcha-wrapper .captcha-field .captcha-field-input {
		box-sizing: border-box;
		border: none;
		padding-left: .875rem;
		width: 100%;
		height: 2.625rem;
		background-color: transparent;
		outline: none;
	}


	button,
	input,
	optgroup,
	select,
	textarea {
		margin: 0;
		line-height: 1.15;
		font-size: 100%;
		font-family: inherit;
	}

	.captcha-wrapper .captcha-field .captcha-img {
		position: absolute;
		top: .125rem;
		right: .25rem;
		width: 6.375rem;
		height: 2.375rem;
		background: url(https://www810046020746552.002tgpd.com:5569/infe/verify/macpic?SR=13a6e88693f0ccae7af4) 0 0 no-repeat;
		background-size: contain;
	}

	.clearfix:after {
		content: "";
		clear: both;
		display: block;
	}

	.captcha-wrapper .captcha-img-hint {
		float: right;
		padding: .1875rem 0 .75rem;
		max-width: 15.625rem;
		line-height: 1.1875rem;
		color: #8088a1;
		font-size: .75rem;
		letter-spacing: .6px;
		text-align: left;
		font-family: STHeiti, Microsoft YaHei, 微软雅黑, SimSun, 宋体, arial, sans-serif;
	}

	.captcha-wrapper .captcha-img-hint .captcha-img-refresh {
		margin-right: .375rem;
		font-size: .8125rem;
	}

	.svg-inline--fa.fa-w-16 {
		width: 1em;
	}

	.captcha-wrapper .captcha-img-submit {
		border-radius: .25rem;
		padding: .875rem 0;
		width: 16.625rem;
		background: linear-gradient(0deg, #39adfe, #7cc9fd);
		color: #fff;
		font-size: 1rem;
	}


	.join-form-btn {
		display: block;
		margin: 0 auto;
		width: 200px;
		background: #4d83b2;
		height: 36px;
		text-align: center;
		font-size: 14px;
		color: #fff;
		outline: 0;
		cursor: pointer;
		border: 1px solid #4d83b2;
	}

	.join-form-btn {
		border: 0;
		border-radius: 20px;
		background: #2472fc;
		color: #fff;
	}



	// 页面那弹窗

	.unicode {
		width: 100%;
		min-height: 100vh;
		background-color: #f3f3f3;
		box-sizing: border-box;
		color: #a4aabb;
	}

	.unicode {
		padding: 60px 5% 0;
		font-size: 13px;
	}

	.unicode-wrap {
		margin: 0 auto;
		max-width: 500px;
	}

	.close-btn {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		background: url(https://g1.cfvn66.com/infe/user/join/images/pro_close_btn.png) no-repeat;
		width: 60px;
		height: 60px;
		cursor: pointer;
	}

	.close-btn {
		display: none;
	}

	a {
		text-decoration: none;
		color: #0570e1;
		cursor: pointer;
	}

	.content-header {
		background: linear-gradient(#4eaafc, #1c5cfb);
	}

	.content-header {
		position: fixed;
		z-index: 3;
		top: 0;
		left: 0;
		width: 100%;
		height: 43px;
		color: #fff;
		text-align: center;
	}

	.content-header .return-btn {

		display: block;
		position: absolute;
		top: 13px;
		left: 15px;
		width: 8px;
		height: 15px;
		background: url(https://g1.cfvn66.com/infe/user/join/images/theme/10/join_back.png) 0 50% no-repeat;
		background-size: contain;
	}



	.content-header .agreement-title,
	.content-header .unicode-title {
		height: 43px;
		line-height: 43px;
		font-size: 15px;
	}

	.unicode .unicode-wrap .unicode-content-wrap {
		line-height: 22px;
	}

	.unicode .unicode-wrap .uni-code-dia2 {
		font-size: 16px;
		line-height: 22px;
		color: #ef423a;
	}


	.agreement {
		box-sizing: border-box;
		padding: 60px 7% 40px;
		width: auto;
		min-height: 100vh;
		font-size: 15px;
		line-height: 20px;
	}


	.agreement-content {
		color: #979dae;
	}

	.agreement .footer {
		text-align: center;
	}

	.agreement .footer .footer-btn {
		display: block;
		margin: 0 auto;
		color: #fff;
		line-height: 24px;
		max-width: 50%;
	}

	.agreement .footer .footer-btn {
		border-radius: 4px;
		min-width: auto;
		max-width: 100%;
		background: linear-gradient(#4eaafc, #1c5cfb);
		padding: 6px 10px;
		color: #fff;
		font-size: 12px;
	}

	.agreement p {
		margin: 8px 0;
	}

	.agreement p {
		margin: 0;
		padding: 8px 0;
		font-size: 13px;
	}


	.agreement ul li {
		list-style-type: decimal;
	}

	.agreement ul li {
		margin-bottom: 16px;
	}
</style>