<template>
	<div>
		
		<div class="ele-loading-mask" v-if="isVisible">
			<div class="loading-svg"></div>
		</div>
		
	</div>
	
</template>

<script>
	export default {
		name: 'diyloading',
		data() {
			return {
				isVisible: false,
			};
		},
		methods: {
		
			open() {
				this.isVisible = true; // 打开弹窗
			},
			
			close() {
				this.isVisible = false; // 关闭弹窗
			}
			
		}
	};
</script>


<style lang="scss" scoped>
	.ele-loading-mask {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		width: 100%;
		height: 100%;
		text-align: center;
		align-items: center;
		justify-content: center;
	}
	
	.ele-loading-mask {
		z-index: 3000;
		background: rgba(0, 0, 0, .4);
	}
	
	.loading-svg {
		width: 100%;
		height: 100%;
		background: url(https://g1.cfvn66.com/web/mobile/build/asset/Z7myMQMn.svg) 50% 50% no-repeat;
		background-size: 20%;
	}
</style>