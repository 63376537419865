<template>
	<div class="email">
		<BankHeaderVue title="电子邮箱" :isSHow="false" />
		<MineHeader />
		<div class="member-data-container clearfix">
			<div class="member-children-wrap">
				<div class="email-data-wrap">
					<form>
						<div class="mail-input-wrap">
							<input type="text" name="email" value="" placeholder="输入邮箱地址" />
							<div class="mail-state"></div>
						</div>
						<div class="mail-input-wrap"><input type="text" name="confirm-email" value=""
								placeholder="确认邮箱地址" /></div>
						<button type="submit" class="is-disabled" disabled="">发出认证信</button>
					</form>
					<ul class="email-tips">
						<li>您的邮箱地址将被用来发送账户重要信息及账户问题处理回复。</li>
						<li>请注意:有些邮箱可能因系统机制造成您无法顺利收到认证信，若没收到信件请至垃圾箱寻找</li>
						<li>若一直无法收到认证信，请联系客服，谢谢</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import BankHeaderVue from "./libs/BankHeader.vue"
	import MineHeader from "./libs/MineHeader.vue"
	export default {
		name: "email",
		data() {
			return {}
		},
		components: {
			BankHeaderVue,
			MineHeader,
		},
	}
</script>
<style lang="scss" scoped>
	.email {
		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				clear: both;
				content: "";
				display: table;
			}
		}

		.member-children-wrap {
			padding-left: 10px;
			padding-right: 10px;

		}

		.email-data-wrap {
			position: relative;
			margin: 0 auto;
			padding-bottom: 1.25rem;
			font-size: .938rem;
			margin-top: 1.25rem;
		}

		.mail-input-wrap {
			position: relative;
			border: 1px solid #ccc;
			margin-bottom: 1.625rem;
			min-height: 2.5rem;

			input {
				border: none;
				background: none;
				min-height: 2.5rem;
				padding-left: 2%;
				width: 85%;
				outline: none;
			}

			.mail-state {
				background: url('/static/image/icon-mail-chk.png') 0 100% no-repeat;
				width: 20px;
				height: 20px;
				vertical-align: middle;
				position: absolute;
				top: .625rem;
				right: 12px;
			}
		}

		.email-data-wrap button {
			padding: 0;
			border: none;
			min-width: 15.625rem;
			height: 2.5rem;
			line-height: 2.5rem;
			border-radius: 2.5rem;
			font-size: 1.125rem;
			color: #fff;
			text-align: center;
			cursor: pointer;
			display: block;
			margin: 0 auto;
			outline: none;
		}

		.email-data-wrap button.is-disabled {
			background-color: #ccc;
			color: #fff;
		}

		.email-data-wrap button,
		.email-data-wrap button:hover {
			background-color: #59bafc;
		}

		.email-tips {
			line-height: 1.875rem;
			padding-left: 1.25rem;
			color: #868686;
			margin: 1rem 0;
			list-style-type: disc;
		}
	}
</style>