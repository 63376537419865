<template>


	<div class="ele-promotion-layout ele-mobile">

		<div class="ele-mobile-wrap" style="height: 667px;">

			<div class="ele-header">
				<div class="ele-header-back" @click="gopage('/')"></div>
				<div class="ele-header-title"><span>优惠活动</span></div>
			</div>

			<div class="ele-user-info-wrap clearfix" v-if="isLogin">
				<div class="ele-user-info"><span>{{userinfo.name}}@55</span></div>
				<div class="ele-user-info ele-user-amount"><span>{{userinfo.money}} RMB</span></div>
			</div>

			<div class="ele-home-wrap">


				<div class="ele-login-wrap clearfix" v-if="!isLogin">
					<div class="ele-not-login-icon"></div>
					<div class="ele-login-link" @click="gopage('/login')">登入 / 注册新会员</div>
				</div>

				<!-- feedback -->
				<Feedback showtype="2" />
				<!-- <div class="ele-float-icon-wrap">
					<div class="ele-float-icon ele-cash-record"></div>
					<div class="ele-float-icon ele-feedback"></div>
				</div> -->
				<!-- feedback -->

				<!-- 左边红包 -->
				<!-- <div>
					<div class="ele-drag-dom ele-float-promo"><img
							src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/lang/zh-cn/btn_draw.png"
							alt="">
						<div class="ele-btn-close"></div>
					</div>
				</div> -->

				<div class="ele-vip-wrap clearfix" v-if="isLogin">
					<div class="ele-vip-icon"><img
							src="https://g1.cfvn66.com/web/static/image/hall/promotion/icon_vip.png" alt=""></div>
					<div class="ele-vip-center">
						<div>
							<div class="ele-reward-title">您的奖励</div>
							<div class="ele-reward-tag">去查看</div>
						</div>
						<div class="ele-reward-bottom">超值奖励等你拿</div>
					</div>
					<div class="ele-right-tag-wrap">
						<div class="ele-tag-block">
							<div class="ele-right-tag ele-heart">
								<div class="ele-tag-name">好友推荐</div>
							</div>
							<div class="ele-right-tag ele-star" @click="gopage('/sign')">
								<div class="ele-hint-dot"></div>
								<div class="ele-tag-name">签到送</div>
							</div>
						</div>
					</div>
				</div>

				<div class="ele-home-content">

					<div class="ele-tag-tab-wrap clearfix ele-type-a ele-home" v-if="isLogin">

						<div class="ele-tab-item-wrap clearfix">


							<!-- <div :class="['ele-tab-bg', topaction == 1 ? 'current' : '']" @click="settopaction(1)"
								style="width: 50%;">
								<div class="ele-tab-item">
									<div class="ele-item-name">任务</div>
									<div class="ele-item-amount">
										(17)
									</div>
								</div>
							</div> -->

							<!-- <div :class="['ele-tab-bg', topaction == 2 ? 'current' : '']" @click="settopaction(2)"
								style="width: 50%;">
								<div class="ele-tab-item">
									<div class="ele-item-name">活动</div>
								</div>
							</div> -->

							<div :class="['ele-tab-bg', topaction == 2 ? 'current' : '']" @click="settopaction(2)"
								style="width: 100%;">
								<div class="ele-tab-item">
									<div class="ele-item-name">活动</div>
								</div>
							</div>

						</div>

					</div>

					<!--  任务列表 -->
					<!-- <div class="ele-activity-list-wrap" v-if="topaction == 1">

						<div class="ele-tag-tab-wrap clearfix ele-type-b ele-home">

							<div class="ele-tab-item-wrap clearfix">

								<div :class="['ele-tab-bg', reulistindex == i ? 'current' : '']"
									@click="setreulistcheck(i)" v-for="(item, i) in reulist" :key="i"
									style="width: 25%;">
									<div class="ele-tab-item">
										<div class="ele-item-name">{{item.text}}</div>
										<div class="ele-item-amount">
											({{item.num}})
										</div>
									</div>
								</div>


							</div>
						</div>

						<div class="ele-scroll-block">
							
							<div class="ele-tag-tab-wrap clearfix ele-type-c">
								
								<div class="ele-tab-item-wrap clearfix">
									
									<div class="ele-tab-bg current">
										<div class="ele-tab-item">
											<div class="ele-item-name">全部</div>
										</div>
									</div>
									<div class="ele-tab-bg">
										<div class="ele-tab-item">
											<div class="ele-item-name">USDT/电子钱包</div>
										</div>
									</div>
									<div class="ele-tab-bg">
										<div class="ele-tab-item">
											<div class="ele-item-name">电子</div>
										</div>
									</div>
									<div class="ele-tab-bg">
										<div class="ele-tab-item">
											<div class="ele-item-name">棋牌</div>
										</div>
									</div>
									<div class="ele-tab-bg">
										<div class="ele-tab-item">
											<div class="ele-item-name">捕鱼</div>
										</div>
									</div>
									
								</div>
								
								<div class="ele-burger-btn" @click="setshowtablist"></div>
								
								<div class="ele-burger-container clearfix" style="height: 0px;">
									
									<div class="ele-burger-item current">
										全部
									</div>
									
									<div class="ele-burger-item">
										USDT/电子钱包
									</div>
									
									<div class="ele-burger-item">
										电子
									</div>
									
									<div class="ele-burger-item">
										棋牌
									</div>
									
									<div class="ele-burger-item">
										捕鱼
									</div>
									
								</div>
								
							</div>
							<div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-odd-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">澳门娱乐场 2024 恋爱津贴 让恋爱成本更轻松</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2024/08/28 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												立即抢
											</div>
											<div class="ele-mission-btn-dot">抢</div>
										</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-even-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">USDT/钱包存款回归第一礼</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2024/07/28 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												申请
											</div>
										</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-odd-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">首存激活礼金 USDT/电子钱包</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2024/05/18-2024/11/13
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												申请
											</div>
										</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-even-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">亿元红包天天抢</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2024/03/17 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												立即抢
											</div>
											<div class="ele-mission-btn-dot">抢</div>
										</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-odd-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">PG幸运注单 百倍大丰收</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2024/06/09 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												申请
											</div>
										</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-even-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">无上限救援金【电子、棋牌、捕鱼】</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2021/07/21 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-status">审核中</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-odd-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">消除彩金 每款游戏申请5次 无上限</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2022/01/08 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												申请
											</div>
										</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-even-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">55注单尾数 100倍彩金秒到账</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2022/01/07 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												申请
											</div>
										</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-odd-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">激情七天乐</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2020/04/20 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-status">审核中</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-even-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">【JDB、MG、CQ9】好运金</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2020/04/20 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-status">审核中</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-odd-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">电子加赠彩金</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2022/04/28 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												申请
											</div>
										</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-even-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">糖果派对彩球加赠</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2022/04/12 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												申请
											</div>
										</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-odd-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">老虎机88注单 可领取300倍彩金</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2022/11/29 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												申请
											</div>
										</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-even-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">BB周周捕鱼</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2020/05/11 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-status">审核中</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-odd-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">积分争霸榜 登顶巅峰</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2024/06/24-2024/09/23
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												申请
											</div>
											<div class="ele-mission-btn-dot">1</div>
										</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-even-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">USDT/钱包存款回归第二礼</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2024/07/28 起
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												申请
											</div>
										</div>
									</div>
								</div>
								<div class="ele-mission-wrap clearfix ele-mission-home ele-odd-item">
									<div class="ele-mission-left">
										<div class="ele-middle-block">
											<div class="ele-title-wrap ele-lang-style">
												<div class="ele-title">首提激活礼金 USDT/电子钱包</div>
												<div class="ele-detail">详情</div>
											</div>
											<div class="ele-time">
												活动时间：2024/06/19-2024/12/09
											</div>
										</div>
									</div>
									<div class="ele-mission-right">
										<div class="ele-mission-btn">
											<div class="ele-mission-btn-status">
												申请
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
 -->

					<!--  活动列表 -->
					<div class="ele-copy-list-wrap" v-if="topaction == 2">

						<div class="ele-scroll-block">

							<!-- <div class="ele-tag-tab-wrap clearfix ele-type-c">

								<div class="ele-tab-item-wrap clearfix">

									<div v-if="!showtablist" :class="['ele-tab-bg', actlistindex == i ? 'current' : '']"
										@click="setactlistcheck(i)" v-for="(item, i) in actlist" :key="i">
										<div class="ele-tab-item">
											<div class="ele-item-name">{{item.text}}</div>
										</div>
									</div>

									<div class="ele-burger-hint" v-if="showtablist">请选择</div>


								</div>

								<div class="ele-burger-btn" @click="setshowtablist"></div>

								<div v-if="showtablist" class="ele-burger-container clearfix"
									:class="{'ele-is-opened':showtablist}" style="height: 7.3125rem;">
									<div :class="['ele-burger-item', actlistindex == i ? 'current' : '']"
										@click="setactlistcheck(i)" v-for="(item, i) in actlist" :key="i">
										{{item.text}}
									</div>
								</div>
								
							</div> -->


							<div class="ele-copy-wrap clearfix ele-column-1 ele-interval-2">

								<!-- <div class="ele-copy-inner">
									<img src="https://cdn.cfvn66.com/tpl/copy/3820004/17264571587356.png" alt="" class="ele-copy-img">
									<div class="ele-copy-tag ele-position-1">
										<div class="ele-copy-status-wrap">
											<div class="ele-copy-status-name">
												说明
											</div>
										</div>
									</div>
								</div> -->


								<!-- <div class="ele-copy-inner" v-for="(item, index) in activities" :key="index"
									@click="activitiesdetails(item)">
									<img src="https://cdn.cfvn66.com/tpl/copy/3820004/16395622692082.png" alt=""
										class="ele-copy-img">
									<div class="ele-copy-tag ele-position-1">
										<div class="ele-copy-status-wrap">
											<div class="ele-copy-status-name">
												说明
											</div>
										</div>
									</div>
								</div> -->

							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="ele-loading-mask" style="display: none;"></div>

			<div class="ele-server-message mobile-server-message"></div>

		</div>

	</div>


</template>

<script>
	import Feedback from "./libs/Feedback.vue"
	export default {
		name: 'promotion',
		components: {
			Feedback
		},
		data() {
			return {

				topaction: 2,

				lang: '',

				actlistindex: 0,
				reulistindex: 0,

				reulist: [{
						text: '全部',
						num: 17,
					},
					{
						text: '申请',
						num: 17,
					},
					{
						text: '审核',
						num: 17,
					},
					{
						text: '立即领',
						num: 17,
					}
				],


				actlist: [{
						text: '全部',
					},
					{
						text: 'USDT/电子钱包',
					},
					{
						text: '电子',
					},
					{
						text: '棋牌',
					},
					{
						text: '捕鱼',
					},
					{
						text: '视讯',
					},
					{
						text: '教程',
					},
					{
						text: '资讯',
					},
				],

				index: 0,
				showtablist: false,
				activities: [],
			}

		},

		mounted() {

			this.lang = localStorage.getItem('local')
			// if (this.lang === 'zh_hk') {
			// 	this.lang = 'en'
			// 	this.changeLang(this.lang)
			// }

			// 获取活动地址
			this.getactivities();
		},
		computed: {
			userinfo() {
				return this.$store.state.userInfo
			},
			//内容高度
			isLogin() {
				return this.$store.state.token;
			},
		},
		created() {
			let that = this;
			if (!that.isLogin) {
				that.topaction = 2;
			}
		},

		methods: {

			getactivities() {
				let that = this;
				that.$apiFun.get('/api/activities', {})
					.then(res => {
						console.log(res);
						if (res.code != 200) {
							that.$parent.showTost(0, res.message);
						}
						if (res.code == 200) {
							that.activities = res.data;
						}
					})
					.catch(res => {});
			},

			setshowtablist() {

				this.showtablist = !this.showtablist;

			},

			//返回
			goBack() {
				this.$router.isBack = true
				// this.$router.push({
				// 	path: this.$route.query.redirect ? this.$route.query.redirect : window.history.go(-1)
				// })
				this.$router.goBack(-1); //返回上一层
			},
			gopage(url) {
				let that = this;
				this.$router.push({
					path: url
				});
			},
			settopaction(i) {
				this.topaction = i;
			},
			setactlistcheck(i) {
				this.actlistindex = i;
				this.showtablist = false;
			},
			setreulistcheck(i) {
				this.reulistindex = i;
			},

			setlang(lang) {
				this.lang = lang;
				localStorage.setItem('local', this.lang)
				this.$router.push({
					path: '/'
				});
			},

			// 语言切换
			changeLang() {
				localStorage.setItem('local', this.lang)
				this.$i18n.locale = this.lang;
			},

		}
	}
</script>

<style lang="scss" scoped>
	#app {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.ele-mission-wrap.ele-mission-redpacket-processing {
		animation: processBox .8s ease-in-out infinite alternate;
	}

	@keyframes processBox {
		0% {
			box-shadow: 0 0 .625rem 0 rgba(239, 66, 58, .6)
		}

		to {
			box-shadow: 0 0 1.125rem 0 #ef423a
		}
	}

	.ele-promotion-layout {
		margin: 0 auto;
		max-width: 1200px;
		font-size: 1rem;
		color: #000;
		font-family: Microsoft YaHei, Helvetica, STHeiti, arial, sans-serif;
	}

	.ele-promotion-layout .ele-mobile-wrap {
		background: linear-gradient(90deg, #3473ea, #00adee);
	}

	.ele-mobile-wrap {
		position: relative;
		padding-top: 3rem;
		background: linear-gradient(90deg, #f97a4b, #fa8f31);
	}

	.ele-promotion-layout .ele-mobile-wrap .ele-header {
		background: #286ceb;
	}

	.ele-mobile-wrap .ele-header {
		position: fixed;
		z-index: 400;
		left: auto;
		top: 0;
		margin: 0 auto;
		/* padding: 0 3rem; */
		background: #ea5c00;
		width: 100%;
		max-width: 1200px;
	}

	.ele-mobile-wrap .ele-header-back {
		position: absolute;
		left: 0;
		top: 0;
		width: 3rem;
		height: 3rem;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/btn_back.png) 50% 50% no-repeat;
		background-size: .625rem 1.125rem;
	}

	.ele-mobile-wrap .ele-header-title {
		text-align: center;
		height: 3rem;
	}

	.ele-mobile-wrap .ele-header-title:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-mobile-wrap .ele-header-title span {
		color: #fff;
		font-size: 1.125rem;
		display: inline-block;
		display: -webkit-inline-box;
		vertical-align: middle;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}


	.ele-home-wrap .ele-login-wrap {
		padding: 0 .9375rem;
		height: 5rem;
	}

	.ele-promotion-layout .ele-home-wrap .ele-not-login-icon {
		border-color: #fff;
	}

	.ele-home-wrap .ele-not-login-icon {
		float: left;
		margin-top: .75rem;
		border-radius: 50%;
		border: .125rem solid #fff;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/icon_photo_none.png) 0 0 no-repeat;
		background-size: contain;
		width: 3.75rem;
		height: 3.75rem;
	}


	.ele-home-wrap .ele-login-link {
		float: left;
		margin: 1.875rem 0 0 .8125rem;
		padding-right: 1rem;
		font-size: 1.0625rem;
		color: #fff;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/icon_arrow_bigwhite.png) 100% 50% no-repeat;
		background-size: .375rem .625rem;
		line-height: 1.5625rem;
	}




	.ele-promotion-layout .ele-mobile-wrap .ele-user-info-wrap {
		background: linear-gradient(90deg, #397dff, #31b9f6);
	}

	.ele-mobile-wrap .ele-user-info-wrap {
		padding: 0 .625rem;
		font-size: .75rem;
		color: #fff;
		background: linear-gradient(90deg, #fa8a61, #f89d4d);
		height: 1.875rem;
	}

	.ele-mobile-wrap .ele-user-info {
		float: left;
		width: 50%;
		height: 100%;
		word-break: break-all;
	}

	.ele-mobile-wrap .ele-user-info:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-mobile-wrap .ele-user-info span {
		display: inline-block;
		display: -webkit-inline-box;
		vertical-align: middle;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}

	.ele-mobile-wrap .ele-user-info.ele-user-amount {
		text-align: right;
	}

	.ele-mobile-wrap .ele-user-info {
		float: left;
		width: 50%;
		height: 100%;
		word-break: break-all;
	}

	.ele-mobile-wrap .ele-user-info span {
		display: inline-block;
		display: -webkit-inline-box;
		vertical-align: middle;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}

	.clearfix:after {
		content: "";
		display: block;
		clear: both;
	}

	.ele-home-wrap {
		height: calc(100% - 1.875rem);
	}

	.ele-home-wrap {
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/image_gift.png) 92.5% .75rem no-repeat;
		background-size: 5.9375rem 5.9375rem;
		height: 100%;
	}

	.ele-drag-dom {
		top: auto;
		right: auto;
	}

	.ele-float-promo {
		position: fixed;
		left: 0;
		bottom: 3.75rem;
		width: 4.8125rem;
		height: 4.8125rem;
		z-index: 400;
	}

	.ele-float-promo img {
		width: 4.8125rem;
		height: 4.8125rem;
	}

	.ele-float-promo .ele-btn-close {
		position: absolute;
		bottom: -1.25rem;
		left: 50%;
		margin-left: -.625rem;
		width: 1.25rem;
		height: 1.25rem;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/float_btn_hide.png) 0 0 no-repeat;
		background-size: contain;
	}





	.ele-home-wrap .ele-vip-wrap {
		height: 5rem;
	}

	.ele-home-wrap .ele-vip-icon {
		float: left;
		margin: .25rem .4375rem 0 .5625rem;
		width: 4.5rem;
		height: 4.5rem;
	}

	.ele-home-wrap .ele-vip-icon img {
		display: block;
		width: 100%;
		height: 100%;
	}

	.ele-home-wrap .ele-vip-center {
		float: left;
		margin-top: 1.125rem;
		font-weight: 700;
		max-width: calc(100% - 10.6875rem);
	}

	.ele-home-wrap .ele-reward-tag,
	.ele-home-wrap .ele-reward-title {
		display: inline-block;
		vertical-align: middle;
		color: #fff;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-home-wrap .ele-reward-title {
		font-size: 1.0625rem;
		max-width: 6.875rem;
	}

	.ele-home-wrap .ele-reward-tag {
		margin-left: .375rem;
		border-radius: .6875rem;
		padding: 0 .3125rem;
		font-size: .75rem;
		text-align: center;
		background: #333;
		width: 3.8125rem;
		height: 1.375rem;
		line-height: 1.375rem;
	}

	.ele-home-wrap .ele-reward-tag,
	.ele-home-wrap .ele-reward-title {
		display: inline-block;
		vertical-align: middle;
		color: #fff;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-home-wrap .ele-reward-bottom {
		margin-top: .3125rem;
		font-size: .9375rem;
		font-weight: 700;
		color: #ffee70;
		word-break: break-all;
		display: inline-block;
		display: -webkit-inline-box;
		vertical-align: middle;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}

	.ele-home-wrap .ele-right-tag-wrap {
		float: right;
		height: 100%;
	}

	.ele-home-wrap .ele-right-tag-wrap:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-home-wrap .ele-tag-block {
		display: inline-block;
		vertical-align: middle;
	}

	.ele-home-wrap .ele-right-tag.ele-heart {
		margin-bottom: .5625rem;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/icon_heart.png) .3125rem 50% no-repeat #fff;
		background-size: .9375rem .9375rem;
	}

	.ele-home-wrap .ele-right-tag {
		position: relative;
		padding-left: 1.5625rem;
		border-radius: .8125rem 0 0 .8125rem;
		width: 5.1875rem;
	}

	.ele-home-wrap .ele-right-tag.ele-heart .ele-tag-name {
		color: #fc744d;
	}

	.ele-home-wrap .ele-tag-name {
		font-size: .75rem;
		font-weight: 700;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		height: 1.5625rem;
		line-height: 1.5625rem;
	}

	.ele-home-wrap .ele-right-tag.ele-star {
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/icon_star.png) .3125rem 50% no-repeat #fff;
		background-size: .9375rem .9375rem;
	}

	.ele-home-wrap .ele-right-tag {
		position: relative;
		padding-left: 1.5625rem;
		border-radius: .8125rem 0 0 .8125rem;
		width: 5.1875rem;
	}

	.ele-home-wrap .ele-hint-dot {
		position: absolute;
		right: .625rem;
		top: -.1875rem;
		width: .4375rem;
		height: .4375rem;
		border-radius: 50%;
		background: #ea1f49;
	}

	.ele-home-wrap .ele-right-tag.ele-star .ele-tag-name {
		color: #fe9e28;
	}

	.ele-home-wrap .ele-tag-name {
		font-size: .75rem;
		font-weight: 700;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		height: 1.5625rem;
		line-height: 1.5625rem;
	}


	/* 内容部分 */
	.ele-home-wrap .ele-home-content {
		padding-top: 1.1875rem;
	}

	.ele-home-wrap .ele-home-content {
		position: relative;
		height: calc(100% - 5rem);
	}

	.ele-type-a.ele-home {
		top: 0;
	}

	.ele-type-a {
		position: absolute;
		z-index: 1;
		top: 4.1875rem;
		left: 4.5%;
		border-radius: 1.25rem;
		padding: .125rem .1875rem;
		width: 91%;
		background: #fff;
		box-shadow: 0 .125rem .1875rem 0 rgba(0, 0, 0, .2);
	}

	.ele-type-a .ele-tab-bg {
		float: left;
		border-radius: 1.125rem;
		text-align: center;
		font-size: .9375rem;
		font-weight: 700;
		color: #202020;
	}

	.ele-type-a .ele-tab-item {
		height: 2.1875rem;
	}

	.ele-type-a .ele-tab-item:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-type-a .ele-item-name {
		display: inline-block;
		vertical-align: middle;
		max-width: calc(100% - 2.375rem);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-type-a .ele-item-amount {
		display: inline-block;
		vertical-align: middle;
		max-width: 2.375rem;
	}

	.ele-promotion-layout .ele-tag-tab-wrap.ele-type-a .ele-tab-bg.current {
		background: linear-gradient(180deg, #fc0, #f90);
	}

	.ele-type-a .ele-tab-bg.current {
		color: #fff;
		background: linear-gradient(90deg, #fe7d54, #ffbb03);
	}


	.ele-type-c .ele-burger-hint {
		padding-left: .1875rem;
		font-size: .875rem;
		font-weight: 700;
		color: #202020;
		height: 1.9375rem;
		line-height: 1.5rem;
	}

	.ele-copy-list-wrap {
		padding-top: 1.4375rem;
		position: relative;
		border-radius: 1.875rem 1.875rem 0 0;
		background: #fff;
		height: 100%;
	}

	.ele-copy-list-wrap .ele-scroll-block {
		position: relative;
		padding-bottom: .625rem;
		height: 100%;
		overflow-y: auto;
	}

	.ele-type-c {
		position: relative;

	}

	.ele-type-c .ele-tab-item-wrap {
		margin: 0 2.0625rem 0 .625rem;
		overflow-x: auto;
		white-space: nowrap;
	}

	.ele-type-c .ele-tab-bg.current {
		color: #202020;
		font-weight: 700;
		border-bottom: .1875rem solid #fe9e28;
	}

	.ele-type-c .ele-tab-bg {
		display: inline-block;
		vertical-align: top;
		text-align: center;
		font-size: .875rem;
		color: #707070;
		min-width: 3.5625rem;
	}

	.ele-type-c .ele-tab-item .ele-item-name {
		padding: 0 .3125rem .25rem;
		height: 1.5rem;
		line-height: 1.5rem;
	}

	.ele-type-c .ele-burger-btn {
		position: absolute;
		right: 0;
		top: 0;
		width: 2.125rem;
		height: 100%;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme1/btn_navbar_function_n.png) 50% .5rem no-repeat;
		background-size: .75rem .75rem;
	}

	.ele-promotion-layout .ele-tag-tab-wrap.ele-type-c .ele-burger-btn {
		background-image: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme3/btn_navbar_function_n.png);
	}

	.ele-type-c .ele-burger-btn:before {
		content: "";
		position: absolute;
		left: 1px;
		top: .125rem;
		width: 1px;
		height: 1.5rem;
		background: #e9e9e9;
	}

	.ele-type-c .ele-burger-container {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 200;
		text-align: center;
		overflow: hidden;
		transition: height .2s linear;
		background: #fff;
		width: 100%;
	}

	.ele-type-c .ele-burger-container.ele-is-opened {
		border-top: 1px solid #e9e9e9;
	}


	.ele-type-c .ele-burger-item {
		float: left;
		margin: .625rem 0 0 2.5%;
		border-radius: .8125rem;
		border: 1px solid transparent;
		padding: 0 .1875rem;
		font-size: .75rem;
		font-weight: 700;
		color: #707070;
		background: #f3f3f3;
		width: 28%;
		height: 1.5625rem;
		line-height: 1.4375rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-type-c .ele-burger-item.current {
		border-color: #f8be45;
		color: #fe9e28;
		background: #fff9e8;
	}

	.ele-promotion-layout .ele-tag-tab-wrap.ele-type-c .ele-burger-item {
		color: #707070;
		background: #f3f3f3;
	}


	.ele-promotion-layout .ele-tag-tab-wrap.ele-type-c .ele-burger-item.current,
	.ele-promotion-layout .ele-tag-tab-wrap.ele-type-d .ele-tab-bg.current .ele-tab-item .ele-item-name,
	.ele-promotion-layout .ele-tag-tab-wrap.ele-type-d .ele-tab-bg:active .ele-tab-item .ele-item-name {
		border-color: #d4deff;
		background: #edf1fe;
		color: #4380f1;
	}


	.ele-copy-wrap.ele-column-1 .ele-copy-inner {
		width: calc(100% - 1.25rem);
		margin-left: .625rem;
		margin-right: .625rem;
	}

	.ele-copy-wrap.ele-interval-2 .ele-copy-inner {
		// margin-top: 1.5625rem;
		margin-top: .5625rem;
	}

	.ele-copy-wrap .ele-copy-inner {
		min-height: 1.8125rem;
	}

	.ele-copy-wrap .ele-copy-inner {
		position: relative;
		cursor: pointer;
	}

	.ele-copy-wrap .ele-copy-img {
		display: block;
		width: 100%;
	}

	.ele-copy-wrap .ele-copy-tag.ele-position-1 {
		right: 0;
		bottom: 0;
		border-radius: 1.0625rem 0 0 0;
	}

	.ele-promotion-layout .ele-copy-wrap .ele-copy-tag {
		color: #fff;
		background: linear-gradient(90deg, #fc0, #f90);
	}

	.ele-copy-wrap .ele-copy-tag {
		width: 6.25rem;
		min-height: 1.8125rem;
		line-height: 1.8125rem;
	}

	.ele-copy-wrap .ele-copy-tag {
		position: absolute;
		padding: 0 .3125rem;
		font-size: .8125rem;
		font-weight: 700;
		color: #fff;
		text-align: center;
		background: linear-gradient(90deg, #ffca3e, #f97a4b);
	}

	.ele-copy-wrap .ele-copy-tag:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-promotion-layout .ele-copy-wrap .ele-copy-tag .ele-copy-status-wrap {
		background-image: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme3/icon_arrow_2.png);
	}

	.ele-copy-wrap .ele-copy-tag .ele-copy-status-wrap {
		display: inline-block;
		vertical-align: middle;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme1/icon_arrow_2.png) 100% 50% no-repeat;
		max-width: 100%;
		margin: .25rem 0;
	}

	.ele-copy-wrap .ele-copy-tag .ele-copy-status-wrap,
	.ele-copy-wrap .ele-copy-tag span {
		padding-right: .5rem;
		background-size: .25rem .4375rem;
	}

	.ele-copy-wrap .ele-copy-tag .ele-copy-status-name {
		line-height: 1rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-copy-wrap .ele-copy-tag.ele-position-1 .ele-copy-tag-dot {
		right: -.125rem;
		top: -.625rem;
	}

	.ele-copy-wrap .ele-copy-tag .ele-copy-tag-dot {
		font-size: .75rem;
		width: 1.5rem;
		height: 1.5rem;
		line-height: 1.5rem;
	}

	.ele-copy-wrap .ele-copy-tag .ele-copy-tag-dot {
		position: absolute;
		border-radius: 50%;
		font-weight: 700;
		color: #fff;
		background: #ea1f49;
	}


	.ele-activity-list-wrap {
		position: relative;
		border-radius: 1.875rem 1.875rem 0 0;
		padding-top: 1.25rem;
		background: #fff;
		height: 100%;
	}

	.ele-type-b.ele-home {
		padding-bottom: .3125rem;
	}

	.ele-type-b {
		padding: .625rem 0 .5625rem;
		border-radius: .75rem;
	}

	.ele-type-b .ele-tab-bg.current {
		color: #fe9e28;
		font-weight: 700;
	}

	.ele-type-b .ele-tab-bg {
		float: left;
		text-align: center;
		font-size: .75rem;
		color: #202020;
	}


	.ele-type-b .ele-item-amount,
	.ele-type-b .ele-item-name {
		display: inline-block;
		vertical-align: middle;
		height: 1.625rem;
		line-height: 1.625rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-type-b .ele-item-name {
		max-width: calc(100% - 1.875rem);
	}

	.ele-type-b .ele-item-amount {
		max-width: 1.875rem;
	}

	.ele-type-b .ele-item-amount,
	.ele-type-b .ele-item-name {
		display: inline-block;
		vertical-align: middle;
		height: 1.625rem;
		line-height: 1.625rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-mission-wrap {
		margin: .5625rem .625rem 0;
		border-radius: 1.0625rem;
		height: 3.625rem;
		display: flex;
		padding-left: .875rem;
		/* padding-right: .575rem; */
	}


	.ele-mission-wrap .ele-mission-left:after {
		content: "";
		position: absolute;
		right: 0;
		top: .625rem;
		bottom: .625rem;
		width: 1px;
		background: #e5e5e5;
	}


	.ele-mission-wrap {
		background: #f3f3f3;
	}

	.ele-mission-wrap .ele-mission-left {
		/* padding-left: .875rem; */
		width: calc(100% - 5rem);
	}

	.ele-mission-wrap .ele-mission-left {
		position: relative;
		float: left;
		height: 100%;
	}

	.ele-mission-wrap .ele-mission-left:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-mission-wrap .ele-middle-block {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
	}

	.ele-mission-wrap .ele-title-wrap {
		font-size: .875rem;
		font-weight: 700;
	}

	.ele-mission-wrap .ele-lang-style {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.ele-mission-wrap .ele-title {
		max-width: calc(100% - 4.6875rem);
	}

	.ele-mission-wrap .ele-title {
		margin-right: .5625rem;
		color: #202020;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-promotion-layout .ele-mission-wrap .ele-detail {
		color: #4380f1;
		background: #d4deff;
	}

	.ele-mission-wrap .ele-detail {
		margin-right: .6875rem;
		border-radius: .6875rem;
		min-width: 3.75rem;
		max-width: 4.875rem;
		height: 1.375rem;
		line-height: 1.375rem;
	}

	.ele-mission-wrap .ele-detail {
		padding: 0 .3125rem;
		color: #fe9e28;
		text-align: center;
		background: #ffe4bf;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-mission-wrap .ele-time {
		font-size: .75rem;
		line-height: 1rem;
	}

	.ele-mission-wrap .ele-time {
		margin-top: .125rem;
		color: #ababab;
		word-break: break-all;
	}

	.ele-mission-wrap .ele-mission-right {
		width: 5rem;
	}

	.ele-mission-wrap .ele-mission-right {
		float: right;
		text-align: center;
		height: 100%;
	}

	.ele-mission-wrap .ele-mission-right:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-mission-wrap .ele-mission-btn {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		max-width: 95%;
	}

	.ele-promotion-layout .ele-mission-wrap .ele-mission-btn-status {
		background: linear-gradient(180deg, #53abfd, #286ceb);
	}

	.ele-mission-wrap .ele-mission-btn-status {
		border-radius: .6875rem;
		padding: 0 .1875rem;
		font-size: .75rem;
		font-weight: 700;
		min-width: 3.75rem;
		max-width: 4.375rem;
		height: 1.375rem;
		line-height: 1.375rem;
	}

	.ele-mission-wrap .ele-mission-btn-status {
		color: #fff;
		background: #fe9e28;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}


	.ele-mission-wrap .ele-mission-btn-dot {
		font-size: .75rem;
		width: 1.25rem;
		height: 1.25rem;
		line-height: 1.25rem;
	}

	.ele-mission-wrap .ele-mission-btn-dot {
		position: absolute;
		top: -.625rem;
		right: -.6875rem;
		border-radius: 50%;
		font-weight: 700;
		color: #fff;
		background: #ea1f49;
	}

	.ele-mission-wrap .ele-mission-status,
	.ele-mission-wrap .ele-mission-time {
		font-size: .75rem;
	}

	.ele-mission-wrap .ele-mission-status {
		color: #707070;
		display: inline-block;
		display: -webkit-inline-box;
		vertical-align: middle;
		-webkit-line-clamp: 3;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}
	
</style>