export default {
	"APP下载": "APP下載",
	"官网首页": "官網首頁",
	"优惠大厅": "優惠大廳",
	"在线客服": "在線客服",
	"代理申请": "代理申請",
	"免息借呗": "免息借唄",
	"真人升级": "真人升級",
	"电子升级": "電子升級",
	"站内信": "站內信",
	"每日签到": "每日簽到",
	"抢红包": "搶紅包",
	"交易记录": "交易記錄",
	"投注记录": "投注記錄",
	"修改密码": "修改密碼",
	"修改取款密码": "修改取款密碼",
	"登出": "登出",
	"首页": "首頁",
	"优惠活动": "優惠活動",
	"会员中心": "會員中心",
	"充值": "充值",
	"提现": "提現",

	"额度转换": "額度轉換",
	"总余额": "總餘額",
	"公告": "公告",
	"基本资料": "基本資料",
	"优惠中心": "優惠中心",
	"操作成功！": "操作成功！",
	"操作异常！": "操作異常！",
	"时时反水": "實時返水",
	"申请提款": "申請提款",
	"银行卡管理": "銀行卡管理",
	"系统公告": "系統公告",
	"余额宝": "餘額寶",
	"快速充值": "快速充值",
	"充值记录": "充值記錄",
	"语言选择": "語言選擇",
	"您好": "您好",
	"您好, 请登入": "您好, 請登入",
	"余额与优惠": "餘額與優惠",
	"登入查看": "登入查看",
	"热门": "熱門",
	"最新": "最新",
	"本月推荐": "本月推薦",
	"全部": "全部",
	"请输入搜索关键词": "請輸入搜索關鍵詞",
	"搜索": "搜索",
	"参与": "參與",
	"会员登入": "會員登入",
	"注册账号": "註冊帳號",
	"介绍人": "介紹人",
	"帐号": "帳號",
	"密码": "密碼",
	"确认密码": "確認密碼",
	"会员资料": "會員資料",
	"真实姓名": "真實姓名",
	"手机号": "手機號",
	"取款密码": "取款密碼",
	"确认取款密码": "確認取款密碼",
	"验证码": "驗證碼",
	"立即注册": "立即註冊",
	'1. 标记有 <span class="star">*</span> 者为必填项目。': '1. 標記有 <span class="star">*</span> 者為必填項目。',
	"2. 手机与取款密码为取款金额时的凭证，请会员务必填写详细资料。": "2. 手機與取款密碼為取款金額時的憑證，請會員務必填寫詳細資料。",
	"忘记密码 ?": "忘記密碼？",
	"登录": "登入",
	"试玩登录": "試玩登入",
	"随便逛逛": "隨便逛逛",
	"去电脑版": "去電腦版",
	"联系管理员重置密码": "聯繫管理員重置密碼",
	"您当前为试玩账号，只可在本站彩票试玩。": "您當前為試玩帳號，只可在本站彩票試玩。",
	"注册成功，请进行登录": "註冊成功，請進行登入",
	"修改登入密码": "修改登入密碼",
	"修改取款密码": "修改取款密碼",
	"银行资料": "銀行資料",
	"旧密码": "舊密碼",
	"新密码": "新密碼",


	"确认新密码": "確認新密碼",
	"请输入": "請輸入",
	"取消": "取消",
	"确认": "確認",
	"密码修改成功，请重新登录": "密碼修改成功，請重新登錄",
	"取款密码修改成功": "提款密碼修改成功",
	"账户余额": "賬戶餘額",
	"反水账户": "反水賬戶",
	"切换至自动": "切換至自動",
	"切换至手动": "切換至手動",
	"转出": "轉出",
	"转入": "轉入",
	"金额": "金額",
	"请输入转账金额": "請輸入轉賬金額",
	"钱包之间不能互相转换": "錢包之間不能互相轉換",
	"用户名": "用戶名",
	"可提款金额": "可提款金額",
	"收款银行卡": "收款銀行卡",
	"请选择": "請選擇",
	"银行账户": "銀行賬戶",
	"持卡人姓名": "持卡人姓名",
	"提款金额": "提款金額",
	"请输入提款金额": "請輸入提款金額",
	"温馨提示：当您的打码量不足时，提现会收取": "溫馨提示：當您的打碼量不足時，提款會收取",
	"%的手续费，当前剩余打码量": "%的手續費，當前剩餘打碼量",
	"提交": "提交",
	"取款密码": "提款密碼",
	"请输入取款密码": "請輸入提款密碼",
	"您还未设置取款密码，请先设置取款密码再操作": "您還未設置提款密碼，請先設置提款密碼再操作",
	"请先绑定银行卡": "請先綁定銀行卡",
	"绑定时间": "綁定時間",
	"开户行": "開戶行",
	"卡号": "卡號",
	"重新绑定": "重新綁定",
	"增加绑定": "增加綁定",
	"银行卡变更": "銀行卡變更",
	"开户银行": "開戶銀行",
	"开户人姓名": "開戶人姓名",
	"银行账号": "銀行賬號",
	"开户网点": "開戶網點",
	"返回": "返回",
	"修改成功": "修改成功",
	"添加银行卡成功": "添加銀行卡成功",
	"时间": "時間",
	"接口类型": "接口類型",
	"游戏类型": "遊戲類型",
	"暂无数据": "暫無數據",
	"没有更多了": "沒有更多了",


	"统计": "統計",
	"总下注金额": "總下注金額",
	"总输赢": "總輸贏",
	"游戏详情": "遊戲詳情",
	"游戏单号": "遊戲單號",
	"游戏名称": "遊戲名稱",
	"游戏类型": "遊戲類型",
	"场次信息": "場次信息",
	"下注明细": "下注明細",
	"下注金额": "下注金額",
	"有效下注": "有效下注",
	"派彩金额": "派彩金額",
	"开奖结果": "開獎結果",
	"下注时间": "下注時間",
	"派彩时间": "派彩時間",
	"账号": "賬號",
	"真实姓名": "真實姓名",
	"最后登入时间": "最後登錄時間",
	"变动类型": "變動類型",
	"金额类型": "金額類型",
	"流水金额": "流水金額",
	"有效金额": "有效金額",
	"交易详情": "交易詳情",
	"交易金额": "交易金額",
	"转入前余额": "轉入前餘額",
	"转入后余额": "轉入後餘額",
	"钱包类型": "錢包類型",
	"转账类型": "轉賬類型",
	"操作描述": "操作描述",
	"交易时间": "交易時間",
	"全部": "全部",
	"反水钱包": "反水錢包",
	"中心钱包": "中心錢包",
	"今日": "今日",
	"昨日": "昨日",
	"7日内": "7日內",
	"30日内": "30日內",
	"状态": "狀態",
	"支付类型": "支付類型",
	"总金额": "總金額",
	"订单号": "訂單號",


	"金额": "金額",
	"账号信息": "賬號信息",
	"支付方式": "支付方式",
	"支付信息": "支付信息",
	"支付状态": "支付狀態",
	"失败原因": "失敗原因",
	"汇款时间": "匯款時間",
	"管理员确认时间": "管理員確認時間",
	"最新消息": "最新消息",
	"返回首页": "返回首頁",
	"在线客服": "在線客服",
	"APP下载": "APP下載",
	"点击申请": "點擊申請",
	"免费开户": "免費開戶",
	"手机投注": "手機投注",
	"电子游艺": "電子遊藝",
	"线路检测": "線路檢測",
	"火狐浏览器": "火狐瀏覽器",
	"上网导航": "上網導航",
	"如何存款": "如何存款",
	"如何取款": "如何取款",
	"代理加盟": "代理加盟",
	"恭喜": "恭喜",
	"成功办理": "成功辦理",
	"会员账号": "會員賬號",
	"申请活动": "申請活動",
	"申请时间": "申請時間",
	"申请状态": "申請狀態",
	"备注": "備註",
	"未查询到信息": "未查詢到信息",
	"官方首页": "官方首頁",
	"抽奖转盘": "抽獎轉盤",
	"抽奖规则": "抽獎規則",
	"输入游戏账号查询": "輸入遊戲賬號查詢",
	"查 询": "查詢",
	"当日总存款": "當日總存款",
	"当日有效投注": "當日有效投注",
	"历史中奖记录": "歷史中獎記錄",
	"您当前有": "您當前有",
	"次抽奖机会": "次抽獎機會",
	"当日存款": "當日存款",
	"有效流水": "有效流水",


	"转盘次数": "轉盤次數",
	"恭喜你中奖了，奖品是 ": "恭喜你中了獎，獎品是 ",
	"段位查询": "段位查詢",
	"请输入你的会员账号": "請輸入你的會員賬號",
	"晋升标准等级": "晉升標準等級",
	"累计打码": "累計打碼",
	"等级礼金": "等級禮金",
	"周俸禄": "周俸祿",
	"月俸禄": "月俸祿",
	"借呗额度": "借呗額度",
	"累积礼金": "累積禮金",
	"时时返水": "隨時返水",
	"存取款加速通道": "存取款加速通道",
	"一对一客服经理": "一對一客服經理",
	"级": "級",
	"等级": "等級",
	"按您的等级计算，距离上次登录7天后再次登录即可自动到账，等级每周俸禄详情如下：": "按您的等級計算，距離上次登錄7天後再次登錄即可自動到賬，等級每周俸祿詳情如下：",
	"按您的等级计算，距离上次登录30天后再次登录即可自动到账，等级每月俸禄详情如下：": "按您的等級計算，距離上次登錄30天後再次登錄即可自動到賬，等級每月俸祿詳情如下：",
	"如何申请每月俸禄？": "如何申請每月俸祿？",
	"如何申请每周俸禄？": "如何申請每周俸祿？",
	"玩真人视讯永久累计打码，让您的会员账号变成永久收益金管家！": "玩真人視頻永久累計打碼，讓您的會員賬號變成永久收益金管家！",
	"的账号信息": "的賬號信息",
	"会员账号": "會員賬號",
	"当前等级": "當前等級",
	"累积有效投注": "累積有效投注",
	"等级彩金": "等級彩金",
	"距离晋级需有效投注": "距離晉級需有效投注",
	"距离下一级还差": "距離下一级還差",
	"注": "注",


	"奖励类型": "獎勵類型",
	"奖励金额": "獎勵金額",
	"发放时间": "發放時間",
	"喜讯：2020年7月2号起所有电子/棋牌/捕鱼永久累计打码，让您的会员账号变成永久收益金管家！": "喜訊：2020年7月2號起所有電子/棋牌/捕魚永久累計打碼，讓您的會員賬號變成永久收益金管家！",
	"声明：强势升级后的累积打码标准将会进行调整，等级礼金、周俸禄、月俸禄也会进行提升；特此声明所有会员的等级变动都以更新后为准！": "聲明：強勢升級後的累積打碼標準將會進行調整，等級禮金、周俸祿、月俸祿也會進行提升；特此聲明所有會員的等級變動都以更新後為準！",
	"免息借呗": "免息借呗",
	"信用额度查询": "信用額度查詢",
	"信用规则": "信用規則",
	"借还款记录": "借還款記錄",
	"我要借款": "我要借款",
	"我要还款": "我要還款",
	"活动详情": "活動詳情",
	"活动细则": "活動細則",
	"借款说明": "借款說明",
	"还款说明": "還款說明",
	"会员账号": "會員賬號",
	"VIP等级": "VIP等級",
	"最高借款": "最高借款",
	"已借款": "已借款",
	"总借款": "總借款",
	"总还款": "總還款",
	"借款金额": "借款金額",
	"还款金额": "還款金額",
	"借款天数": "借款天數",
	"还款倒计时": "還款倒計時",
	"审核进度": "審核進度",
	"日期": "日期",
	"暂无数据": "暫無數據",
	"温馨提示": "溫馨提示",
	"注意：提交成功5分钟后请到“信用额度查询”是否借款成功！": "注意：提交成功5分鐘後請到“信用額度查詢”是否借款成功！",
	"请填写会员账号": "請填寫會員賬號",
	"请填写会员姓名": "請填寫會員姓名",
	"请填写借款金额": "請填寫借款金額",
	"请填写借款天数": "請填寫借款天數",
	"确认提交": "確認提交",
	"查询欠款额度": "查詢欠款額度",
	"请填写还款金额": "請填寫還款金額",
	"注意：请点击“在线付款”进行付款，付款完成再来提交！": "注意：請點擊“在線付款”進行付款，付款完成再來提交！",
	"提交成功5分钟，请到“信用额度查询”查询是否成功还款！": "提交成功5分鐘，請到“信用額度查詢”查詢是否成功還款！",
	"当前欠款:": "當前欠款:",
	"请填写会员帐号": "請填寫會員賬號",
	"请填写存款金额": "請填寫存款金額",
	"请填写验证码": "請填寫驗證碼",
	"存款金额": "存款金額",
	"立即提交": "立即提交",
	"优惠申请": "優惠申請",
	"查看详情": "查看詳情",
	"优惠申请中心": "優惠申請中心",
	"优惠申请进度查询": "優惠申請進度查詢",
	"选择查询项目": "選擇查詢項目",
	"点击查询": "點擊查詢",
	"活动名称": "活動名稱",
	"申请时间": "申請時間",
	"申请状态": "申請狀態",
	"未查询到信息": "未查詢到信息",
	"您还有反水": "您還有返水",
	"可领取！": "可領取！",
	"有效投注": "有效投注",
	"返点比例": "返点比例",

	"您目前没有时时返水可以领取，快去游戏吧!": "您目前沒有隨時返水可以領取，快去遊戲吧！",
	"领取": "領取",
	"我的奖励": "我的獎勵",
	"周一": "週一",
	"周二": "週二",
	"周三": "週三",
	"周四": "週四",
	"周五": "週五",
	"周六": "週六",
	"周日": "週日",
	"连续签到礼包": "連續簽到禮包",
	"天礼包": "天禮包",
	"天时可以领取": "天時可以領取",
	"已领取": "已領取",
	"马上签到": "馬上簽到",
	"已签到": "已簽到",
	"暂无奖励数据": "暫無獎勵數據",
	"回首页": "回首頁",
	"奖品": "獎品",
	"日期": "日期",
	"暂无数据": "暫無數據",
	"返回": "返回",
	"开始游戏": "開始遊戲",
	"该功能暂未开放": "該功能暫未開放",
	"一个红包也没有抢到，多多加油": "一個紅包也沒有搶到，多多加油",
	"发件": "發件",
	"发件箱": "發件箱",
	"收件箱": "收件箱",
	"已读": "已讀",
	"未读": "未讀",
	"暂无数据": "暫無數據",
	"管理员": "管理員",
	"上条站内信": "上條站內信",
	"回复": "回覆",
	"标示为已读": "標示為已讀",
	"标示为未读": "標示為未讀",
	"删除": "刪除",
	"发送站内信": "發送站內信",
	"标题": "標題",
	"内容": "內容",
	"删除成功": "刪除成功",
	"我的账户": "我的賬戶",
	"基本资料": "基本資料",
	"银行卡": "銀行卡",
	"修改密码": "修改密碼",
	"自助服务": "自助服務",
	"时时返水": "隨時返水",
	"余额宝": "餘額寶",
	"往来记录": "往來記錄",
	"投注记录": "投注記錄",
	"充值记录": "充值記錄",
	"信息公告": "信息公告",
	"最新公告": "最新公告",
	"站内信": "站內信",
	"充值处理时间": "充值處理時間",
	"7*24小时充值服务": "7*24小時充值服務",
	"如选择银行卡转账，请勿使用其他支付方式，否则充值将无法到账": "如選擇銀行卡轉帳，請勿使用其他支付方式，否則充值將無法到賬",
	"在线支付": "線上支付",
	"公司入款": "公司入款",
	"充值金额": "充值金額",
	"请输入充值金额": "請輸入充值金額",
	"单笔充值限额": "單筆充值限額",
	"最低": "最低",
	"最高": "最高",
	"*平台填写金额应当与网银汇款金额完全一致，否则将无法即时到账！": "*平台填寫金額應當與網銀匯款金額完全一致，否則將無法即時到賬！",
	"下一步": "下一步",
	"收款方信息": "收款方信息",
	"收款账户": "收款賬戶",
	"收款银行": "收款銀行",
	"收款姓名": "收款姓名",
	"开户行网点": "開戶行網點",
	"收款二维码": "收款二維碼",
	"复制": "複製",
	"您的充值信息": "您的充值信息",
	"您的账号": "您的賬號",
	"请输入 您的账号": "請輸入您的賬號",
	"付款姓名": "付款姓名",
	"请输入 您的名字": "請輸入您的名字",
	"汇款时间": "匯款時間",
	"提交": "提交",
	"请输入正确的存款金额！": "請輸入正確的存款金額！",
	"转账信息已提交，请耐心等候客服人员核查通过！": "轉賬信息已提交，請耐心等候客服人員核查通過！",
	"前往支付": "前往支付",
	"遇到问题？联系客服": "遇到問題？聯繫客服",
	"复制成功！": "複製成功！",
	"钱包地址": "錢包地址",
	"兑换汇率": "兌換匯率",
	"usdt个数": "usdt個數",
	"我的余额宝": "我的餘額寶",
	"进行中": "進行中",
	"已结束": "已結束",
	"您尚无任何方案!": "您尚無任何方案！",
	"立即购买": "立即購買",
	"买入": "買入",
	"共": "共",
	"笔": "筆",

	"总金额": "總金額",
	"总盈利/总上限": "總盈利/總上限",
	"方案选择": "方案選擇",
	"销售金额": "銷售金額",
	"结算周期": "結算週期",
	"循环结算": "循環結算",
	"单次结算": "單次結算",
	"小时": "小時",
	"利率": "利率",
	"利息上限": "利息上限",
	"剩余数量": "剩餘數量",
	"个人购买数量": "個人購買數量",
	"再次购买时间": "再次購買時間",
	"后": "後",
	"无": "無",
	"请填写购买信息": "請填寫購買信息",
	"购买金额": "購買金額",
	"请输入10的倍数": "請輸入10的倍數",
	"请填写购买金额": "請填寫購買金額",
	"请输入10的倍数的金额": "請輸入10的倍數的金額",
	"每": "每",
	"小时利息为": "小時利息為",
	"小时后利息为": "小時後利息為",
	"利息稽核倍数": "利息稽核倍數",
	"利息试算": "利息試算",
	"购买": "購買",
	"购买数量已达上限": "購買數量已達上限",
	"已售完": "已售完",
	"切换方案": "切換方案",
	"暂无方案": "暫無方案",
	"请选择合适的方案": "請選擇合適的方案",
	"结算方式": "結算方式",
	"名称": "名稱",
	"金额": "金額",
	"买进时间": "買進時間",
	"结算时间": "結算時間",
	"提现时间": "提現時間",
	"利息周期": "利息週期",
	"目前利息/上限": "目前利息/上限",
	"利息到达上限": "利息到達上限",
	"已结算": "已結算",
	"赎回": "贖回",
	"正在赎回...": "正在贖回...",
	"历史盈利": "歷史盈利",
	"利息历程": "利息歷程",
	"此方案为": "此方案為",
	"模式": "模式",
	"利息稽核倍数为": "利息稽核倍數為",
	"倍": "倍",
	"第": "第",
	"次": "次",
	"确定赎回吗？": "確定贖回嗎？",
	"中心钱包余额": "中心錢包餘額",
	"更新余额": "更新餘額",
	"余额": "餘額",
	"请输入转入金额": "請輸入轉入金額",
	"转入": "轉入",
	"去存款": "去存款",
	"正在进入游戏，请稍等 .....": "正在進入遊戲，請稍等.....",
	"正在进行免转...": "正在進行免轉...",
	"请先进行登录再进入游戏": "請先進行登錄再進入遊戲",
	"进入游戏": "進入遊戲",
	"一键归户": "一鍵歸戶",
	"修改成功！": "修改成功！",
	"请填写真实姓名！": "請填寫真實姓名！",
	"真实姓名未填写，请前往个人中心填写真实姓名！": "真實姓名未填寫，請前往個人中心填寫真實姓名！",

	"利息宝": "利息寶",
	"返水": "返水",
	"福利": "福利",
	"客服": "客服",
	"总游戏余额": "總遊戲餘額",
	"线上存款": "線上存款",

	"7x24小时备用客服": "7x24小時備用客服",
	"电子钱包": "電子錢包",
	"SVIP钱包等级": "SVIP錢包等級",
	"我的优惠": "我的優惠",
	"快速存款": "快速存款",
	"线上取款": "線上取款",
	"新会员推荐": "新會員推薦",
	"安装": "安裝",
	"开启": "開啟",
	"寰宇浏览器": "寰宇瀏覽器",
	"CG钱包下载": "CG錢包下載",
	"前往电脑版": "前往電腦版",
	"选择语系": "選擇語系",
	
	"点我下载":"點我下載",
	"早上好，请先登入或注册":"早上好，請先登入或註冊",
	
	"注册": "註冊",
	"优惠":"優惠",
	"我的":"我的"
}