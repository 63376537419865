<template>

	<div>

		<div v-if="$store.state.appInfo.site_state">
			<div class="ele-loading-mask" v-if="loading">
				<div class="loading-svg"></div>
			</div>
		</div>

		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" :key="$route.name" />
		</keep-alive>

		<router-view v-if="!$route.meta.keepAlive" :key="$route.name" />


		<div v-if="$store.state.appInfo.site_state == 0" style="box-sizing: border-box; padding: 30px; fong-size: 26px">
			{{ $store.state.appInfo.repair_tips }}
		</div>

	</div>

</template>

<script>
	export default {

		name: 'App',
		data() {

			return {
				daoTime: null,
				loading: false,
				pid: '',
				intervalId: null
			};

		},
		mounted() {
			this.startTokenExpirationCheck();
		},
		created() {

			let that = this;

			var query = that.$route.query;
			// console.log(that.$route.name)

			// 获取配置文件

			// that.getApp();

			// 获取配置文件
			that.getConfig();

			// 获取游戏分类
			// that.getGameType();

			// 获取游戏列表
			// that.getGameList();

			if (localStorage.getItem('token')) {

				// that.openDaoTime(); //倒计时获取用户余额

				that.getUserInfo();

			}

			if (query.pid) {
				that.pid = query.pid;
				that.$router.push({
					path: `/login?type=1&pid=${query.pid}`
				});
			}

			// that.getVisitUrl();

		},

		methods: {
			startTokenExpirationCheck() {
				// 每隔1分钟检查一次token
				this.intervalId = setInterval(() => {
					const token = localStorage.getItem('access_token'); // 获取token
					const expiresAt = localStorage.getItem('expires_at'); // 获取过期时间

					if (token && expiresAt) {
						const expirationTime = new Date(expiresAt).getTime();
						const currentTime = Date.now();

						if (currentTime >= expirationTime) {
							this.handleTokenExpiration();
						}
					}
				}, 60000); // 1分钟检查一次
			},
			handleTokenExpiration() {
				// 清除token并跳转到登录页
				localStorage.removeItem('token');
				localStorage.removeItem('expires_at');
				localStorage.removeItem('expires_in');
				localStorage.removeItem('token_type');
				router.push('/login');
			},
			getVisitUrl() {

				let that = this;

				that.$apiFun.get('/api/getVisitUrl', {}).then(res => {

					if (res.code == 200) {

						if (process.env.NODE_ENV == 'development') {
							//开发环境
							return;
						}

						let url = that.pid ? res.data.url + 'register?pid=' + that.pid : res.data.url;
						window.open(url, '_self');

					}

				}).catch(res => {
					// console.log(res)
				});

			},

			// 获取游戏分类
			getGameType() {

				let that = this;

				that.$apiFun.post('/api/game/type', {}).then(res => {

					if (res.code == 200) {

						// console.log(JSON.stringify(res.data));
						let list = res.data;

						localStorage.setItem('gametype', JSON.stringify(list));

						// 			let list = res.data;
						// 			let realbetList = [];
						// 			let jokerList = [];
						// 			let gamingList = [];
						// 			let sportList = [];
						// 			let lotteryList = [];
						// 			let conciseList = [];
						// 			list.forEach(el => {

						// 				if (el.category_id == 'realbet' && el.app_state == 1) {
						// 					realbetList.push(el);
						// 				}
						// 				if (el.category_id == 'joker' && el.app_state == 1) {
						// 					jokerList.push(el);
						// 				}
						// 				if (el.category_id == 'gaming' && el.app_state == 1) {
						// 					gamingList.push(el);
						// 				}
						// 				if (el.category_id == 'sport' && el.app_state == 1) {
						// 					sportList.push(el);
						// 				}
						// 				if (el.category_id == 'lottery' && el.app_state == 1) {
						// 					lotteryList.push(el);
						// 				}
						// 				if (el.category_id == 'concise' && el.app_state == 1) {
						// 					conciseList.push(el);
						// 				}

						// 				localStorage.setItem('realbetList', JSON.stringify(realbetList));
						// 				localStorage.setItem('jokerList', JSON.stringify(jokerList));
						// 				localStorage.setItem('gamingList', JSON.stringify(gamingList));
						// 				localStorage.setItem('sportList', JSON.stringify(sportList));
						// 				localStorage.setItem('lotteryList', JSON.stringify(lotteryList));
						// 				localStorage.setItem('conciseList', JSON.stringify(conciseList));
						// 				that.$store.commit('changGameList');
						// 			});


					}

				});

			},


			// 获取游戏列表
			getGameList() {

				let that = this;

				that.$apiFun.get('/api/games', {}).then(res => {

					if (res.code == 200) {

						// console.log(JSON.stringify(res));

						// 	let list = res.data;
						// 	let realbetList = [];
						// 	let jokerList = [];
						// 	let gamingList = [];
						// 	let sportList = [];
						// 	let lotteryList = [];
						// 	let conciseList = [];
						// 	list.forEach(el => {
						// 		if (el.category_id == 'realbet' && el.app_state == 1) {
						// 			realbetList.push(el);
						// 		}
						// 		if (el.category_id == 'joker' && el.app_state == 1) {
						// 			jokerList.push(el);
						// 		}
						// 		if (el.category_id == 'gaming' && el.app_state == 1) {
						// 			gamingList.push(el);
						// 		}
						// 		if (el.category_id == 'sport' && el.app_state == 1) {
						// 			sportList.push(el);
						// 		}
						// 		if (el.category_id == 'lottery' && el.app_state == 1) {
						// 			lotteryList.push(el);
						// 		}
						// 		if (el.category_id == 'concise' && el.app_state == 1) {
						// 			conciseList.push(el);
						// 		}

						// 		localStorage.setItem('realbetList', JSON.stringify(realbetList));
						// 		localStorage.setItem('jokerList', JSON.stringify(jokerList));
						// 		localStorage.setItem('gamingList', JSON.stringify(gamingList));
						// 		localStorage.setItem('sportList', JSON.stringify(sportList));
						// 		localStorage.setItem('lotteryList', JSON.stringify(lotteryList));
						// 		localStorage.setItem('conciseList', JSON.stringify(conciseList));
						// 		that.$store.commit('changGameList');
						// 	});


					}

				});

			},

			// 获取app
			// getApp() {

			// 	let that = this;
			// 	that.$apiFun.post('/api/app', {}).then(res => {
			// 		if (res.code == 200) {
			// 			localStorage.setItem('appInfo', JSON.stringify(res.data));
			// 			that.$store.commit('changappInfo');
			// 			document.getElementsByTagName('title')[0].innerText = that.$store.state.appInfo.title;
			// 		}
			// 	});

			// },

			// 获取cinfig
			getConfig() {

				let that = this;

				let params = {
					group: "system",
					url: "",
					is_mobile: 1,
				};

				that.$apiFun.get("/api/system/configs", params).then((res) => {

					if (res.code == 200) {

						localStorage.setItem('systemInfo', JSON.stringify(res.data));

						that.$store.commit('changsystemInfo');

					}


				});
			},


			// 退出登录
			outLogin() {

				let that = this;


				that.$apiFun.post('/api/auth/logout', {}).then((res) => {

					if (res.code === 200) {

						localStorage.removeItem("token")

						localStorage.removeItem("userInfo")

						that.$store.commit("changToken", false)

						// 刷新当前页面
						that.refreshPage()

					}

					that.hideLoading()

				})



				// that.$dialog
				// 	.confirm({
				// 		title: '提示',
				// 		message: '您确定要退出登录吗?',
				// 	})
				// 	.then(() => {

				// 		that.showLoading();

				// 		that.$apiFun
				// 			.post('/api/logoff', {})
				// 			.then(res => {
				// 				localStorage.clear();
				// 				// sessionStorage.clear();
				// 				that.$store.commit('changUserInfo');
				// 				that.$store.commit('changToken');
				// 				that.closeDaoTime();
				// 				that.hideLoading();
				// 				that.$router.push({
				// 					path: '/login'
				// 				});
				// 			})
				// 			.catch(() => {
				// 				localStorage.clear();
				// 				// sessionStorage.clear();
				// 				// that.$cookies.remove('token' )/
				// 				that.$store.commit('changUserInfo');
				// 				that.$store.commit('changToken');
				// 				that.closeDaoTime();
				// 				that.hideLoading();
				// 				that.$router.push({
				// 					path: '/login'
				// 				});
				// 			});
				// 	})
				// 	.catch(() => {});

			},

			// 打开游戏
			openGamePage(name, type, code) {

				console.log(JSON.stringify(name));

				// let that = this;
				// let token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
				// if (!token) {
				// 	that.showTost(0, '请登录！');
				// 	return;
				// }
				// that.goNav(`/gamePage?name=${name}&type=${type}&code=${code}`);

			},

			openGamePlay(item) {
				let that = this;
				let params = JSON.parse(item.params);
				let token = localStorage.getItem('token') ? localStorage.getItem('token') : that.$store.state.token;
				if (!token) {

					that.$router.push({
						path: '/login'
					});
					return;

				} else {
					// api/game/login?gameCode=0&gameType=1&api_code=ag&isMobile=1&money_type=money
					that.goNav(
						`/gamePlay?name=${item.title}&gamecode=${params.gameCode}&gametype=${params.gameType}&apicode=${item.class_name}`
					);

				}


			},


			openGamePlayb(item) {
				let that = this;
				let token = localStorage.getItem('token') ? localStorage.getItem('token') : that.$store.state.token;
				if (!token) {
					that.$router.push({
						path: '/login'
					});
					return;
				} else {

					that.goNav(
						`/gamePlay?name=${item.name}&gamecode=${item.game_code}&gametype=${item.game_type}&apicode=${item.api_name}`
					);

				}


			},


			doCopy(msg) {

				let cInput = document.createElement('input');
				cInput.style.opacity = '0';
				cInput.value = msg;
				document.body.appendChild(cInput);
				// 选取文本框内容
				cInput.select();
				document.execCommand('copy');
				this.showTost(1, '复制成功！');

			},

			// 重新刷新当前页面
			refreshPage() {
				this.$router.go(0);
			},

			goNav(url) {

				let that = this;

				if (url == '/mine') {
					if (!that.$store.state.token) {

						this.$router.push({
							path: '/login'
						});

					}
				}
				if (url == '/hongbao' || url == '/transfer') {
					if (!that.$store.state.token) {
						that.$dialog
							.confirm({
								message: '精彩内容等你来体验，快来登录吧！',
							})
							.then(() => {
								this.$router.push({
									path: '/login'
								});
							});

						return;
					}
					if (url == '/hongbao' && that.$store.state.appInfo.redpacket_switch == 0) {

						that.showTost(0, '红包已关闭');
						return;

					}
				}

				if (url == this.$route.fullPath) {
					that.showTost(0, '已在当前页面！');
					return;
				}

				this.$router.push({
					path: url
				});

			},

			closeDaoTime() {

				let that = this;
				if (that.daoTime != null) {
					clearInterval(that.daoTime);
				}
				that.daoTime = null;

			},

			openDaoTime() {
				let that = this;
				that.daoTime = setInterval(() => {
					that.getBalance();
				}, 4000);
			},

			// 不刷新页面更新用户余额
			getBalance() {

				// let that = this;
				// that.$apiFun
				// 	.post('/api/balance', {})
				// 	.then(res => {
				// 		if (res.code == 200) {
				// 			let userInfo = JSON.parse(localStorage.getItem('userInfo'));
				// 			userInfo.balance = res.data.balance;
				// 			localStorage.setItem('userInfo', JSON.stringify(userInfo));
				// 			that.$store.commit('changUserInfo');
				// 		}
				// 		if (res.code == 401) {
				// 			localStorage.clear();
				// 			// sessionStorage.clear();
				// 			that.$store.commit('changUserInfo');
				// 			that.$store.commit('changToken');
				// 			that.closeDaoTime();
				// 			that.$router.push({
				// 				path: '/login'
				// 			});
				// 		}
				// 	})
				// 	.catch(res => {});

			},

			// 不刷新页面跟新用户信息
			getUserInfo() {
				
				let that = this;
				that.$apiFun.post('/api/auth/me', {}).then(res => {
					if (res.code === 200) {
						let userInfo = res.data;
						// let str = userInfo.current_vip;
						// let index = str.indexOf('P');
						// let vip = str.substr(index + 1, str.length); //04
						userInfo.nextvip = userInfo.level + 1;
						localStorage.setItem('userInfo', JSON.stringify(userInfo));
						that.userInfo = userInfo;
						that.$store.commit('changUserInfo');
					}
				});
				
			},

			// 刷新页面更新信息
			getUserInfoShowLoding() {

				let that = this;
				that.showLoading();
				that.$apiFun.post('/api/auth/me', {}).then(res => {

					if (res.code === 200) {

						let userInfo = res.data;
						// let str = userInfo.current_vip;
						// let index = str.indexOf('P');
						// let vip = str.substr(index + 1, str.length); //04
						userInfo.nextvip = userInfo.level + 1;
						localStorage.setItem('userInfo', JSON.stringify(userInfo));
						that.userInfo = userInfo;

						that.$store.commit('changUserInfo');
						that.hideLoading();
					}

				});

			},

			// 获取代理
			getAgentLoginUrl() {
				let that = this;
				that.$parent.goNav(`/gamePage?dailiD=1`);
			},

			openKefu() {
				let that = this;
				that.goNav(`/kefu`);
			},

			showTost(type, title) {
				let str = type ? 'success' : 'danger';
				this.$notify({
					type: str,
					message: title
				});
			},

			showLoading() {
				this.loading = true;
			},

			hideLoading() {
				this.loading = false;
			},

		},

		beforeDestroy() {

			// let that = this;
			// if (that.daoTime) {
			// 	clearInterval(that.daoTime);
			// }
			// that.daoTime = null;

			if (this.intervalId) {
				clearInterval(this.intervalId);
				// clearInterval(that.daoTime);
			}
			// that.daoTime = null;

		},

	};
</script>

<style>
	.meLoading {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.3);
	}

	.loading-mask-wrap {
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		align-items: center;
		justify-content: center;
		z-index: 999;
	}

	.ele-loading-mask {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		width: 100%;
		height: 100%;
		text-align: center;
		align-items: center;
		justify-content: center;
	}

	.ele-loading-mask {
		z-index: 3000;
		/* background: rgba(0, 0, 0, .4); */
		background: #fff;
	}

	.loading-svg {
		width: 100%;
		height: 100%;
		background: url(https://g1.cfvn66.com/web/mobile/build/asset/Z7myMQMn.svg) 50% 50% no-repeat;
		background-size: 20%;
	}

	.van-dialog__confirm,
	.van-dialog__confirm:active {
		color: #069b71;
	}

	.step .van-tab--active {
		color: #fff;
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUwIiBoZWlnaHQ9IjM2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wIDB2MzZoMjQwLjgzN0wyNTAgMThsLTkuMTYzLTE4eiIgZmlsbD0iIzA2OUI3MSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) no-repeat 100% / cover;
	}

	.step .van-tabs--line .van-tabs__wrap {
		height: 27px;
	}

	.metransRecord .van-tabs__nav--card .van-tab.van-tab--active {
		color: #fff;
		background-color: #069b71;
		border-radius: 22px;
	}

	.metransRecord .van-tabs__nav--card {
		border: none;
	}

	.metransRecord .van-tabs__nav--card .van-tab {
		border: none;
	}

	.metransRecord .van-tabs__nav--card .van-tab {
		color: #000;
	}

	/* 页眉 */

	.pageTop {
		background-color: rgb(237, 242, 253);
		color: rgb(0, 0, 0);
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		height: 40px;
		line-height: 40px;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 200;
	}

	.acts .van-tabs__line {
		background-color: #597ef7 !important;
	}

	.van-nav-bar .van-icon {
		color: #000;
	}

	.van-nav-bar {
		background-color: #edf2fd;
	}

	.van-nav-bar__arrow {
		font-size: 24px;
	}

	.van-nav-bar__title {
		font-weight: 700;
	}

	.bancgs {
		position: fixed;
		top: 10px;
		left: 10px;
		width: 30px;
		opacity: 0.8;
		z-index: 200;
	}

	p {
		margin-block-start: 5px !important;
		margin-block-end: 5px !important;
	}

	.inputsw {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: 999;
		cursor: pointer;
	}

	.van-tab--active {
		color: #108ee9;
	}

	.van-tabs__line {
		background-color: #108ee9;
	}

	.van-cell {
		padding: 5px 8px;
	}

	.sdg .van-field__label {
		width: 0.2rem;
	}

	[class*='van-hairline']:after {
		border: none;
	}

	.sdgg .van-popup {
		border-radius: 15px 15px 0 0;
	}

	.stddss .van-field__control {
		font-size: 0.5rem;
	}
</style>