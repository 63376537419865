var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gameNews"},[_c('BankHeaderVue',{attrs:{"title":"游戏公告","isSHow":false}}),_c('MineHeader'),_c('div',{staticClass:"gamenews-page-wrap"},[_c('div',{staticClass:"m-gn-nav"},[_c('div',[_c('span',{class:[_vm.tabIndex == 0 ? 'current': ''],on:{"click":function($event){return _vm.changeTab(0)}}},[_vm._v("BB视讯")]),_c('span',{class:[_vm.tabIndex == 1 ? 'current': ''],on:{"click":function($event){return _vm.changeTab(1)}}},[_vm._v("BB电子")]),_c('span',{class:[_vm.tabIndex == 2 ? 'current': ''],on:{"click":function($event){return _vm.changeTab(2)}}},[_vm._v("BB彩票")])])]),_c('div',{staticClass:"m-gn-wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 0),expression:"tabIndex == 0"}],staticClass:"bet-info-wrap live-info clearfix"},[_vm._m(0),_vm._m(1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 1),expression:"tabIndex == 1"}],staticClass:"bet-info-wrap casino-info clearfix"},[_vm._m(2),_vm._m(3)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 2),expression:"tabIndex == 2"}],staticClass:"bet-info-wrap lottery-info clearfix"},[_vm._m(4),_vm._m(5)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-select clearfix"},[_c('select',{staticClass:"select-date"},[_c('option',{attrs:{"value":"2024-08-08"}},[_vm._v("2024-08-08")]),_c('option',{attrs:{"value":"2024-08-07"}},[_vm._v("2024-08-07")]),_c('option',{attrs:{"value":"2024-08-06"}},[_vm._v("2024-08-06")])]),_c('select',{staticClass:"select-live"},[_c('option',{attrs:{"value":""}},[_vm._v("全部")]),_c('option',{attrs:{"value":"3001"}},[_vm._v("百家乐")]),_c('option',{attrs:{"value":"3003"}},[_vm._v("龙虎斗")]),_c('option',{attrs:{"value":"3007"}},[_vm._v("轮盘")]),_c('option',{attrs:{"value":"3008"}},[_vm._v("骰宝")]),_c('option',{attrs:{"value":"3011"}},[_vm._v("色碟")]),_c('option',{attrs:{"value":"3012"}},[_vm._v("牛牛")]),_c('option',{attrs:{"value":"3015"}},[_vm._v("番摊")]),_c('option',{attrs:{"value":"3023"}},[_vm._v("抢庄牛牛")]),_c('option',{attrs:{"value":"3025"}},[_vm._v("区块链百家乐")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("日期")]),_c('th',[_vm._v("内容")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"td-head"},[_vm._v("2024-08-08 08:00:36")]),_c('td',{staticClass:"td-body"},[_vm._v("敬请客户留意: 因例行性维护，本游戏将会更换新的游戏赌具并开始新局，不便之处敬请见谅。")])]),_c('tr',[_c('td',{staticClass:"td-head"},[_vm._v("2024-08-08 04:58:01")]),_c('td',{staticClass:"td-body"},[_vm._v("敬请客户留意: 香港时间 2024/08/08 桌号: 骰宝 A 局号: 551172364 因该局翘(叠)骰,造成无法辨识,本局将会重新进行摇骰,不便之处敬请见谅.")])]),_c('tr',[_c('td',{staticClass:"td-head"},[_vm._v("2024-08-08 04:24:19")]),_c('td',{staticClass:"td-body"},[_vm._v("敬请客户留意!! 【 香港时间 2024-08-14 06:00:00~2024-08-14 09:00:00 游戏： 百家乐 桌号： AS7 】因系统优化将进行维护，不便之处敬请见谅。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-select clearfix"},[_c('select',{staticClass:"select-date"},[_c('option',{attrs:{"value":"2024-08-08"}},[_vm._v("2024-08-08")]),_c('option',{attrs:{"value":"2024-08-07"}},[_vm._v("2024-08-07")]),_c('option',{attrs:{"value":"2024-08-06"}},[_vm._v("2024-08-06")]),_c('option',{attrs:{"value":"2024-08-05"}},[_vm._v("2024-08-05")]),_c('option',{attrs:{"value":"2024-08-04"}},[_vm._v("2024-08-04")]),_c('option',{attrs:{"value":"2024-08-03"}},[_vm._v("2024-08-03")]),_c('option',{attrs:{"value":"2024-08-02"}},[_vm._v("2024-08-02")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"no-data-wrap"},[_c('img',{attrs:{"src":"/static/image/bg_no_announcement.png","alt":""}}),_vm._v("无游戏公告 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-select clearfix"},[_c('select',{staticClass:"select-date"},[_c('option',{attrs:{"value":"2024-08-08"}},[_vm._v("2024-08-08")]),_c('option',{attrs:{"value":"2024-08-07"}},[_vm._v("2024-08-07")]),_c('option',{attrs:{"value":"2024-08-06"}},[_vm._v("2024-08-06")]),_c('option',{attrs:{"value":"2024-08-05"}},[_vm._v("2024-08-05")]),_c('option',{attrs:{"value":"2024-08-04"}},[_vm._v("2024-08-04")]),_c('option',{attrs:{"value":"2024-08-03"}},[_vm._v("2024-08-03")]),_c('option',{attrs:{"value":"2024-08-02"}},[_vm._v("2024-08-02")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"no-data-wrap"},[_c('img',{attrs:{"src":"/static/image/bg_no_announcement.png","alt":""}}),_vm._v("无游戏公告 ")])
}]

export { render, staticRenderFns }