<template>

	<div class="Balance">

		<van-popup v-model="isShow" position="right" @click-overlay="closePop">

			<!-- <van-popup v-model="isShow" position="center" @click-overlay="closePop"> -->

			<div style="height: 100vh;"
				:class="['ele-side-drawer ele-side-drawer-right',colorStyle == 'black' ? 'ele-side-black' : '']">

				<div class="app-balance-header">

					<div class="balance-header-top clearfix">
						<svg @click="closePop" class="svg-inline--fa fa-xmark fa-w-10 close-btn" aria-hidden="true"
							data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 320 512">
							<path class="" fill="currentColor"
								d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z">
							</path>
						</svg>
					</div>

					<div class="app-balance-group">
						<div class="app-balance-summary clearfix"><span class="summary-title">{{$t('总余额')}}</span><span
								class="summary-amount">{{userinfo.money.toFixed(2)}} RMB</span></div>
						<div class="app-balance-summary clearfix"><span class="summary-title">{{$t('利息宝')}}</span><span
								class="summary-amount">0.00 RMB</span></div>
						<div class="app-balance-summary clearfix"><span
								class="summary-title">{{$t('总游戏余额')}}</span><span class="summary-amount">{{totalMoney}}
								RMB</span>
						</div>
					</div>
				</div>

				<!-- <div class="app-balance-func clearfix" v-show="showBtn">

					<a class="btn-func btn-trans" href="/infe/mcenter/#/mobile/moneySwitch">{{$t('一键归户')}}</a>
					<a class="btn-func btn-deposit" href="/infe/mcenter/#/mobile/deposit">{{$t('线上存款')}}</a>

				</div> -->

				<!-- <div class="app-balance-update clearfix">
					<div class="btn-update">更新所有余额</div>
				</div> -->

				<div class="app-balance-body">


					<div class="balance-item clearfix" v-for="(item, index) in gamecodelists" :key="index"
						@click="bannerClick(item)">
						<span class="balance-item-title">{{item.name.toUpperCase()}}{{$t('余额')}}</span><span
							class="balance-item-value">{{item.money || '0.00'}}</span>
					</div>


					<!-- <div class="balance-item clearfix"><span class="balance-item-title">PG电子余额</span><span
							class="balance-item-value">--</span></div> -->


					<!-- <div class="balance-item clearfix"><span class="balance-item-title">JDB电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">CQ9电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">AG电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">MG电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">大满贯电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">FG钱包余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">KA电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">MT电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">FC电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">PP钱包余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">TP电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">SW电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">WM电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">PS电子余额</span><span
							class="balance-item-value maintain">游戏维护中</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">SG电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">RSG电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">NE电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">RT电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">GNS电子余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">AG视讯余额</span><span
							class="balance-item-value">0.00</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">BG视讯余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">EVO视讯余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">WG棋牌余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">开元棋牌余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">欢乐棋牌余额</span><span
							class="balance-item-value">0.00</span></div>
					<div class="balance-item clearfix"><i class="balance-item-last"></i><span
							class="balance-item-title">波音体育余额</span><span class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">VR彩票余额</span><span
							class="balance-item-value">--</span></div>
					<div class="balance-item clearfix"><span class="balance-item-title">CC钱包余额</span><span
							class="balance-item-value">0.00</span></div>
					<div class="balance-remark">为最近一次余额流向平台</div> -->

				</div>


			</div>

		</van-popup>
	</div>
</template>

<script>
	export default {
		name: "Balance",
		props: {
			showBalance: {
				type: Boolean,
				default: false,
			},
			showBtn: {
				type: Boolean,
				default: true
			},
			colorStyle: {
				type: String,
				default: 'blue'
			}
		},
		data() {
			return {

				daoTime2: null,

				isShow: this.showBalance,

				// lists: ["fc", "ag", "bbin", "bg", "fg", "mt", "pp", "pg", "cq9", 'jdb', "mg", "leg", "ky"],
				gamecodelists: [{
						"name": "fc",
						"money": 0,
					},
					{
						"name": "ag",
						"money": 0,
					},
					{
						"name": "bbin",
						"money": 0,
					},
					{
						"name": "bg",
						"money": 0,
					},
					{
						"name": "fg",
						"money": 0,
					},
					{
						"name": "mt",
						"money": 0,
					},
					{
						"name": "pp",
						"money": 0,
					},
					{
						"name": "pg",
						"money": 0,
					},
					{
						"name": "cq9",
						"money": 0,
					},
					{
						"name": "jdb",
						"money": 0,
					},
					{
						"name": "mg",
						"money": '0.00'
					},
					{
						"name": "ky",
						"money": 0,
					}
				]
			}
		},
		computed: {
			userinfo() {
				return this.$store.state.userInfo
			},
			//内容高度
			isLogin() {
				return this.$store.state.token;
			},

			getlanguage() {
				return localStorage.getItem('local');
			},
			totalMoney() {
				// 遍历 gamecodelists 数组，统计所有 money 的总和
				let total = this.gamecodelists.reduce((total, item) => {
					return total + parseFloat(item.money || 0);
				}, 0);

				// 返回保留两位小数的总金额
				return total.toFixed(2);
			}
		},
		watch: {
			showBalance(newValue) {
				this.isShow = newValue
			}
		},

		mounted() {
			
			if (localStorage.getItem('token')) {
				this.openDaoTime();
			}

		},
		methods: {

			closeDaoTime() {

				let that = this;
				if (that.daoTime2 != null) {
					clearInterval(that.daoTime2);
				}
				that.daoTime2 = null;

			},

			openDaoTime() {

				let that = this;

				// const lists = ["fc", "ag", "bbin", "bg", "fg", "mt", "pp", "pg", "cq9", 'jdb', "mg", "leg", "ky"];
				const lists = that.gamecodelists;

				let index = 0;

				function requestBalance() {

					// 获取当前的列表元素
					that.getBalanceone(lists[index].name, index);

					// 更新 index，超过数组长度则重置为 0
					index = (index + 1) % lists.length;

					// 每 3 秒调用一次
					that.daoTime2 = setTimeout(requestBalance, 4000);

				}

				// 开始请求
				requestBalance();
			},

			getBalanceone(code, index) {

				let that = this;

				let params = {
					api_code: code,
				};

				that.$apiFun.post("/api/game/balance", params).then((res) => {
					
					console.log(JSON.stringify(res));
					// 请求成功，假设 res.data.money 包含返回的余额信息
					if (res.code === 200) {
						const newBalance = res.money.toFixed(2);
						// 直接使用传递进来的索引进行更新
						that.$set(that.gamecodelists, index, {
							...that.gamecodelists[index],
							money: newBalance
						});
						// 如果需要输出查看更新后的 gamecodelists
						// console.log(that.gamecodelists);
					}
					if (res.error == 'Unauthenticated.') {
						that.closeDaoTime();
					}

				}).catch((err) => {
					console.error("请求失败：", err);
				});
				
			},

			closePop() {
				this.$emit('closeBalance')
			}

		},
		beforeDestroy() {
			let that = this;
			if (that.daoTime2) {
				clearInterval(that.daoTime2);
			}
			that.daoTime2 = null;
		},
	}
</script>
<style lang="scss" scoped>
	.Balance {
		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				clear: both;
				content: "";
				display: table;
			}
		}

		.ele-side-drawer {
			width: 64vw;
			background: #fff;
			display: flex;
			flex-direction: column;
			// height: 100vh;
			text-align: left;
		}

		.app-balance-header {
			display: flex;
			flex: 0 0 6.25rem;
			flex-direction: column;
			box-sizing: border-box;
			padding: 0.5rem 0.8125rem 1.0625rem;
			font-size: 0.875rem;

			.balance-header-top {
				padding-bottom: 0.3125rem;
			}

			.svg-inline--fa {
				display: inline-block;
				font-size: inherit;
				height: 1em;
				overflow: visible;
				vertical-align: -0.125em;
			}

			svg:not(:root).svg-inline--fa {
				overflow: visible;
			}

			.svg-inline--fa.fa-w-10 {
				width: 0.625em;
			}

			.close-btn {
				float: right;
				font-size: 1.5625rem;
			}
		}

		.app-sidemenu-header,
		.app-balance-header {
			background: linear-gradient(#4eaafc, #1c5cfb);
			color: #fff;
		}

		.app-balance-group {
			display: flex;
			flex: 1 0 auto;
			flex-direction: column;
			justify-content: flex-end;
		}

		.app-balance-summary {
			.summary-title {
				float: left;
			}

			.summary-amount {
				float: right;
			}
		}

		.app-balance-func {
			flex: 0 0 auto;
			padding: 1rem 1.1875rem;
			font-size: 0.875rem;
			background: #e9e9e9;

			.btn-func {
				float: left;
				box-sizing: border-box;
				border-radius: 0.1875rem;
				padding: 0.625rem 0.3125rem 0.6875rem;
				width: 45%;
				text-align: center;
				text-decoration: none;
			}

			.btn-trans,
			.btn-deposit {
				color: #fff;
				width: 100%;
			}

			.btn-trans {
				background: linear-gradient(#c2cae2, #8394c4);
				float: left;
			}

			.btn-deposit {
				background: linear-gradient(#4eaafc, #1c5cfb);
				float: right;
			}
		}

		.app-balance-update {
			flex: 0 0 auto;
			padding: 0 0.9375rem 0 1.125rem;
			background: #e9e9e9;
			color: #ef423a;

			.btn-update {
				float: right;
				padding: 0.9375rem 0 0.9375rem 1.4375rem;
				background-repeat: no-repeat;
				background-size: 0.9375rem 0.9375rem;
				background-position: 0 50%;
				font-size: 0.8125rem;
				background-image: url("/static/image/7d89ca66.png");
				color: #ef423a;
			}
		}

		.app-balance-body {
			flex: 1 1 auto;
			box-sizing: border-box;
			padding: 0.4375rem 0.9375rem 0.4375rem 1.125rem;
			overflow-y: scroll;
			font-size: 0.8125rem;
			// border-bottom: 1px solid #982925;
			color: #333;

			.balance-item {
				line-height: 1.875rem;
				min-height: 1.875rem;

				.balance-item-title {
					float: left;
				}

				.balance-item-value {
					float: right;
				}

				.balance-item-value.maintain {
					font-size: 0.8125rem;
				}

				.balance-item-last {
					float: left;
					margin: 0.625rem 0.1875rem 0 0;
					width: 0.5625rem;
					height: 0.5625rem;
					background-repeat: no-repeat;
					background-size: contain;
					background-position: 0 0;
					background-image: url("/static/image/2cae959c.png");
				}
			}

			.balance-item-value {
				color: #999;
			}

			.balance-item-value.maintain {
				color: #c82721;
			}

			.balance-remark {
				float: left;
				padding-left: 0.9375rem;
				height: 1.4375rem;
				line-height: 1.4375rem;
				background-repeat: no-repeat;
				background-size: 0.5625rem;
				background-position: 0 50%;
				font-size: 0.8125rem;
				background-image: url("/static/image/2cae959c.png");
				color: #3b3b3b;
			}
		}

		.ele-side-black {
			width: 77vw;

			.app-balance-header {
				background: #3d3d4a;
			}

			.btn-update {
				background-image: url("/static/image/ic_updated.png");
				color: #a4a4a4;
			}

			.balance-item-last {
				background-image: url("/static/image/last_marked_icon.png") !important;
			}

			.balance-remark {
				background-image: url("/static/image/last_marked_icon.png")
			}
		}
	}
</style>