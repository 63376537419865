<template>

	<div id="app" class="page-forget-password">
		<div class="app-header-wrap">
			<div class="app-header-left">
				<svg @click="back" class="svg-inline--fa fa-chevron-left fa-w-10 btn-back" aria-hidden="true"
					data-prefix="fas" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 320 512">
					<path class="" fill="currentColor"
						d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z">
					</path>
				</svg>
			</div>
			<div class="header-title">重置密码</div>
		</div>

		<div class="forget-password-wrap">

			<div class="verification-tab">
				<div class="tab" :class="[topactive == 1 ? 'active' : '']" @click="topactive = 1">短信验证</div>
				<div class="tab" :class="[topactive == 2 ? 'active' : '']" @click="topactive = 2">电子邮箱</div>
			</div>

			<div class="forget-password">

				<div class="field-item"><input type="text" maxlength="15" placeholder="会员帐号">
					<div class="prefix unicode"><span>@55</span></div>
				</div>

				<div class="field-error">取款密码格式错误，请重新输入!!</div>

				<div class="field-item"><input type="password" maxlength="4" placeholder="取款密码">
					<div class="prefix toggle-visible"></div>
				</div>

				<div class="field-error">取款密码格式错误，请重新输入!!</div>

				<div class="field-item"><input type="text" maxlength="4" placeholder="验证码"><img class="prefix mkcode"
						src="https://www810046020746552.002tgpd.com:5569/m/verify/forgetPwdMacpic?SR=eb4d63287e21a22e7af4">
				</div>

				<div class="field-error">请输入验证码!!</div>

				<div class="clearfix">
					<div class="forget-password-refresh"><svg
							class="svg-inline--fa fa-rotate-right fa-w-16 captcha-img-refresh" aria-hidden="true"
							data-prefix="fas" data-icon="rotate-right" role="img" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512">
							<path class="" fill="currentColor"
								d="M468.9 32.11c13.87 0 27.18 10.77 27.18 27.04v145.9c0 10.59-8.584 19.17-19.17 19.17h-145.7c-16.28 0-27.06-13.32-27.06-27.2c0-6.634 2.461-13.4 7.96-18.9l45.12-45.14c-28.22-23.14-63.85-36.64-101.3-36.64c-88.09 0-159.8 71.69-159.8 159.8S167.8 415.9 255.9 415.9c73.14 0 89.44-38.31 115.1-38.31c18.48 0 31.97 15.04 31.97 31.96c0 35.04-81.59 70.41-147 70.41c-123.4 0-223.9-100.5-223.9-223.9S132.6 32.44 256 32.44c54.6 0 106.2 20.39 146.4 55.26l47.6-47.63C455.5 34.57 462.3 32.11 468.9 32.11z">
							</path>
						</svg><span>点击上图更新验证码显示</span></div>
				</div>

				<div class="secure-wrap">
					<div class="secure">
						<div>SECURE</div>
						<div>安全加密</div>
					</div>
				</div>

				<div class="forget-password-btn">确认送出</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'language',
		data() {
			return {
				lang: '',
				languages: {
					'us_en': {
						name: 'English'
					},
					'zh_cn': {
						name: '简体中文'
					},
					'zh_hk': {
						name: '繁體中文'
					},
					// 'fr': {
					// 	name: 'Français'
					// },
					// 'ja': {
					// 	name: '日本'
					// },
					// 'it': {
					// 	name: 'Italiano'
					// },
					// 'de': {
					// 	name: 'Deutsch'
					// },
					// 'sv': {
					// 	name: 'Svenska'
					// },
				},
				index: 0,
				topactive: 1
			}
		},

		mounted() {

			this.lang = localStorage.getItem('local')
			// if (this.lang === 'zh_hk') {
			// 	this.lang = 'en'
			// 	this.changeLang(this.lang)
			// }

		},
		methods: {

			back() {
				// console.log(11111111);
				this.$router.goBack(-1); //返回上一层
			},

			setlang(lang) {
				this.lang = lang;
				localStorage.setItem('local', this.lang)
				this.$router.push({
					path: '/'
				});
			},

			// 语言切换
			changeLang() {
				localStorage.setItem('local', this.lang)
				this.$i18n.locale = this.lang;
			},

		}
	}
</script>

<style>
	
	/* [lang=zh_cn] #app,
	[lang=zh_hk] #app {
		font-family: STHeiti, Microsoft YaHei, 微软雅黑, SimSun, 宋体, arial, sans-serif;
	} */

	body {
		background: #f4f6fa;
	}

	#app {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	#app .app-header-wrap {
		background: linear-gradient(#4eaafc, #1c5cfb);
	}

	#app .app-header-wrap {
		flex: 0 0 3rem;
		line-height: 3rem;
	}

	.app-header-wrap {
		position: -webkit-sticky;
		position: sticky;
		z-index: 100;
		top: 0;
		flex: 0 0 2.625rem;
		width: 100%;
		line-height: 2.625rem;
		text-align: center;
	}


	.app-header-wrap .app-header-left {
		left: .875rem;
	}

	.app-header-wrap .app-header-left,
	.app-header-wrap .app-header-right {
		position: absolute;
		top: 0;
		bottom: 0;
	}

	#app .app-header-wrap .header-title,
	#app .app-header-wrap .btn-back {
		color: #fff;
	}

	.svg-inline--fa.fa-w-10 {
		width: 0.625em;
	}

	.svg-inline--fa {
		display: inline-block;
		font-size: inherit;
		height: 1em;
		overflow: visible;
		vertical-align: -.125em;
	}

	.btn-back {
		vertical-align: middle;
		font-size: 1.75rem;
	}

	#app .app-header-wrap .header-title,
	#app .app-header-wrap .btn-back {
		color: #fff;
	}

	.header-title {
		box-sizing: border-box;
		padding: 0 3.25rem;
		max-width: 100%;
		overflow: hidden;
		font-size: 1rem;
		text-overflow: ellipsis;
		white-space: nowrap;
	}


	/* ----------------分割线------------- */


	.forget-password-wrap {
		overflow: auto;
	}

	.forget-password-wrap .verification-tab {
		display: flex;
	}

	.verification-tab .tab.active {
		background: 0 0;
		color: #2472fc;
	}

	.forget-password-wrap .verification-tab .tab {
		width: 50%;
		height: 2.9375rem;
		line-height: 2.9375rem;
		font-size: .8125rem;
		text-align: center;
	}


	.verification-tab .tab {
		background: #fff;
		color: #a4aabb;
	}

	.forget-password-wrap .forget-password {
		padding: 1.4375rem 6% 3.125rem;
		overflow: auto;
	}

	#app.page-forget-password .field-item {
		border: 1px solid #ddd;
		border-radius: 3.125rem;
		background: #fff;
	}


	.forget-password-wrap .field-item~.field-item {
		margin-top: .6875rem;
	}

	.forget-password-wrap .field-item {
		position: relative;
		padding: .25rem .5rem;
		font-size: .875rem;
	}

	.forget-password-wrap .field-item:before {
		content: "*";
		position: absolute;
		top: .875rem;
		left: .5rem;
		line-height: 1.375rem;
		font-size: 1.375rem;
	}

	#app.page-forget-password .field-item:before {
		color: #ef423a;
	}

	.field-item input {
		color: #3b3b3b;
	}

	.forget-password-wrap .field-item input {
		box-sizing: border-box;
		border: none;
		padding: 0 7.625rem 0 1.0625rem;
		width: 100%;
		height: 1.875rem;
		line-height: 1rem;
		background: 0 0;
		outline: none;
	}

	input:placeholder-shown {
		text-overflow: ellipsis;
	}

	button,
	input {
		overflow: visible;
	}

	button,
	input,
	optgroup,
	select,
	textarea {
		margin: 0;
		line-height: 1.15;
		font-size: 100%;
		font-family: inherit;
	}


	#app.page-forget-password .field-item .unicode {
		color: #a4aabb;
	}

	.forget-password-wrap .field-item .unicode {
		top: 0;
		bottom: 0;
	}

	.forget-password-wrap .field-item .prefix {
		position: absolute;
		right: .6875rem;
	}

	.forget-password-wrap .field-item .unicode:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.field-item .unicode {
		color: #a4aabb;
	}

	.forget-password-wrap .field-item .unicode span {
		vertical-align: middle;
	}


	#app.page-forget-password .field-item .toggle-visible {
		background-image: url(https://g1.cfvn66.com/web/mobile/build/asset/DGhygXUK.png);
	}

	#app.page-forget-password .field-item .toggle-visible.active {
		background-image: url(https://g1.cfvn66.com/web/mobile/build/asset/BCdHQnOu.png);
	}

	.forget-password-wrap .field-item .toggle-visible {
		top: 50%;
		margin-top: -.8125rem;
		width: 1.6875rem;
		height: 1.6875rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 0 0;
	}

	.forget-password-wrap .field-item .mkcode {
		top: .3125rem;
		width: 3.75rem;
	}


	.forget-password-wrap .forget-password-refresh {
		float: right;
		margin-top: .5rem;
		max-width: 15.625rem;
		line-height: 1.1875rem;
		color: #8088a1;
		font-size: .75rem;
		letter-spacing: .6px;
	}

	.clearfix:after {
		content: "";
		clear: both;
		display: block;
	}

	.forget-password-wrap .forget-password-refresh .captcha-img-refresh {
		margin-right: .375rem;
		font-size: .8125rem;
	}

	.forget-password-wrap .secure-wrap {
		margin: 1.6875rem 0 3.125rem;
		text-align: center;
	}

	.forget-password-btn {
		border-radius: .25rem;
		background: linear-gradient(#4eaafc, #1c5cfb);
		color: #fff;
	}

	.forget-password-wrap .forget-password-btn {
		box-sizing: border-box;
		width: 100%;
		height: 2.25rem;
		line-height: 2.25rem;
		font-size: .9375rem;
		text-align: center;
		text-decoration: none;
	}

	.forget-password-wrap .secure-wrap .secure {
		position: relative;
		display: inline-block;
		padding-left: 2.75rem;
		height: 2.1875rem;
		line-height: 1.125rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 0 0;
		font-size: .875rem;
		font-weight: 700;
		text-align: left;
	}

	#app.page-forget-password .secure-wrap .secure {
		background-image: url(https://g1.cfvn66.com/web/mobile/build/asset/Djc6RGEm.png);
		color: #3b3b3b;
	}


	#app.page-forget-password .secure-wrap .secure:before {
		background: #3b3b3b;
	}

	.forget-password-wrap .secure-wrap .secure:before {
		content: "";
		position: absolute;
		top: .125rem;
		left: 2.4375rem;
		width: 1px;
		height: 1.875rem;
	}
	
	#app.page-forget-password .field-error {
	    color: #ef423a;
	}
	.forget-password-wrap .field-error {
	    margin-top: .4375rem;
	    line-height: 1rem;
	    font-size: .75rem;
	}
</style>