<template>

	<div class="ele-promotion-layout ele-mobile">

		<!-- style="height: 667px;" -->
		<div class="ele-mobile-wrap">

			<div class="ele-header" v-if="!opensign">
				<div class="ele-header-back" @click="goBack"></div>
				<div class="ele-header-title"><span>签到送({{activityList.length}})</span></div>
			</div>


			<div class="ele-user-info-wrap sign clearfix" v-if="!opensign">
				<div class="ele-user-info"><span>{{userinfo.name}}</span></div>
				<div class="ele-user-info ele-user-amount"><span>{{userinfo.money}} RMB</span>
				</div>
			</div>

			<div class="ele-sign-wrap" v-if="!opensign">


				<!-- <div class="ele-mission-wrap clearfix ele-mission-sign" v-for="(item, i) in activityList" :key="i">
					<div class="ele-mission-left" @click="setsign(i)">
						<div class="ele-middle-block">
							<div class="ele-title-wrap ele-lang-style">
								<div class="ele-title">{{item.title}}</div>
								<div class="ele-detail">详情</div>
							</div>
							<div class="ele-time">
								活动时间：2024/07/01-2025/06/30
							</div>
						</div>
					</div>
					<div class="ele-mission-right">
						<div class="ele-mission-status">审核中</div>
					</div>
				</div> -->

				<!-- activityList -->
				<div class="ele-mission-wrap clearfix ele-mission-sign" v-for="(item, i) in activityList" :key="i">
					<div class="ele-mission-left" @click="setsign(i)">
						<div class="ele-middle-block">
							<div class="ele-title-wrap ele-lang-style">
								<div class="ele-title">{{item.title}}</div>
								<div class="ele-detail">{{$t('详情')}}</div>
							</div>
							<div class="ele-time">
								{{$t('活动时间')}}：{{$t('无限制')}}
								<!-- 2024/07/01-2025/06/30 -->
							</div>
						</div>
					</div>
					<div class="ele-mission-right">
						<div class="ele-mission-status">进行中</div>
					</div>
				</div>

				<!-- <div class="ele-mission-wrap clearfix ele-mission-sign">
					<div class="ele-mission-left">
						<div class="ele-middle-block">
							<div class="ele-title-wrap ele-lang-style">
								<div class="ele-title">电子/捕鱼签到有礼</div>
								<div class="ele-detail">详情</div>
							</div>
							<div class="ele-time">
								活动时间：2024/07/01-2025/06/30
							</div>
						</div>
					</div>
					<div class="ele-mission-right">
						<div class="ele-mission-btn">
							<div class="ele-mission-btn-status">
								签到
							</div>
							<div class="ele-mission-btn-dot">1</div>
						</div>
					</div>
				</div>
				<div class="ele-mission-wrap clearfix ele-mission-sign">
					<div class="ele-mission-left">
						<div class="ele-middle-block">
							<div class="ele-title-wrap ele-lang-style">
								<div class="ele-title">棋牌签到有礼</div>
								<div class="ele-detail">详情</div>
							</div>
							<div class="ele-time">
								活动时间：2024/07/01-2025/06/30
							</div>
						</div>
					</div>
					<div class="ele-mission-right">
						<div class="ele-mission-btn">
							<div class="ele-mission-btn-status">
								签到
							</div>
							<div class="ele-mission-btn-dot">1</div>
						</div>
					</div>
				</div>
				<div class="ele-mission-wrap clearfix ele-mission-sign">
					<div class="ele-mission-left">
						<div class="ele-middle-block">
							<div class="ele-title-wrap ele-lang-style">
								<div class="ele-title">大额 USDT热门钱包 天天签到领豪礼</div>
								<div class="ele-detail">详情</div>
							</div>
							<div class="ele-time">
								活动时间：2024/07/01-2025/06/30
							</div>
						</div>
					</div>
					<div class="ele-mission-right">
						<div class="ele-mission-btn">
							<div class="ele-mission-btn-status">
								签到
							</div>
							<div class="ele-mission-btn-dot">1</div>
						</div>
					</div>
				</div>
 -->

			</div>


			<div class="ele-float-wrap ele-float-sign-wrap" v-if="opensign">


				<div class="ele-float-title">

					<span>{{$t("签到有礼")}}</span>

				</div>

				<div class="ele-tag-tab-wrap clearfix ele-type-a">

					<div class="ele-tab-item-wrap clearfix">

						<div :class="['ele-tab-bg', topaction == 1 ? 'current' : '']" @click="settopaction(1)"
							style="width: 50%;">

							<div class="ele-tab-item">
								<div class="ele-item-name">{{$t("每日签到")}}</div>
							</div>

						</div>

						<div :class="['ele-tab-bg', topaction == 2 ? 'current' : '']" @click="settopaction(2)"
							style="width: 50%;">

							<div class="ele-tab-item">
								<div class="ele-item-name">{{$t("奖励领取")}}</div>
								<!-- <div class="ele-item-amount">
									(1)
								</div> -->
							</div>
						</div>

						<!-- <div :class="['ele-tab-bg', topaction == 3 ? 'current' : '']" @click="settopaction(3)"
							style="width: 33.3333%;">
							<div class="ele-tab-item">
								<div class="ele-item-name">{{$t("说明")}}</div>
							</div>
						</div> -->

					</div>
				</div>

				<div class="ele-float-container">

					<div class="ele-sign-check-in-wrap" v-if="topaction == 1">

						<!-- <div class="ele-float-sign-date-wrap">
							<div class="ele-float-sign-date">
								签到日期：2024/09/01-2024/09/30
							</div>
							<div class="ele-float-sign-date">
								每日签到时间：02:00:00 ~ 23:59:59
							</div>
						</div> -->

						<div class="ele-scroll-block" @scroll="handleScroll">

							<!-- <div class="ele-sign-bar-wrap">
								<div class="ele-sign-bar-bg">
									<div class="ele-sign-bar-left">
										<div class="ele-left-top">
											09/22 签到奖励
										</div>
										<div class="ele-left-bottom">
											5
										</div>
									</div>
									<div class="ele-sign-bar-btn">
										签到
									</div>
								</div>
							</div> -->

							<!-- <div class="ele-sign-history-wrap">
								<div class="ele-history-status clearfix">
									<div class="ele-interrupt-hint">天天签到拿彩金</div>
								</div>
								<div class="ele-bonus-hint">每连续签 <span>7</span>天可获得额外嘉奖</div>
							</div> -->


							<div class="ele-sign-calendar-wrap ele-daily-bonus-calendar">

								<!-- <div class="ele-sign-calendar-header clearfix">
									<div class="ele-sign-calendar-header-icon">补</div>
									<div class="ele-sign-calendar-header-hint">您可补签<span>0</span>次
									</div>
								</div> -->


								<div class="ele-sign-calendar-day-wrap">

									<!-- 判断是否签到，根据签到状态修改按钮显示 -->
									<!-- <div class="ele-sign-calendar-day-btn"
										:class="isChecked(index + 1) ? 'ele-status-signed' : 'ele-status-not-signed'">
										<div v-if="isChecked(index + 1)">
											{{$t("已签")}}
										</div>
										<div v-else @click="signIn(index + 1)">
											{{$t("签")}}
										</div>
									</div> -->

									<!-- <div class="ele-sign-calendar-day" v-for="(status, index) in daysInMonth"
										:key="index">

										<div class="ele-sign-calendar-day-num">{{ index + 1 }}</div>

										<div class="ele-sign-calendar-day-btn ele-status-6" @click="signIn(index + 1)">
											<div>
												{{$t("签")}}
											</div>
										</div>

										<div class="ele-sign-calendar-day-btn ele-status-4">
											<div>
												{{$t("审")}}
											</div>
										</div>

										<div class="ele-sign-calendar-day-btn ele-status-7">
											<div>
												{{$t("签")}}
											</div>
										</div>

										<div class="ele-sign-calendar-day-amount">
											+5
										</div>

									</div> -->

									<div class="ele-sign-calendar-day" v-for="(status, index) in daysInMonth"
										:key="index">

										<div class="ele-sign-calendar-day-num">{{ index + 1 }}</div>

										<!-- 动态判断每一天的签到状态 -->
										<div v-if="isChecked(index + 1)" class="ele-sign-calendar-day-btn ele-status-6">
											<div>
												{{$t("签")}}
											</div>
										</div>

										<div v-else-if="isUnderReview(index + 1)"
											class="ele-sign-calendar-day-btn ele-status-4">
											<div>
												{{$t("审")}} <!-- 待审核状态 -->
											</div>
										</div>

										<div v-else class="ele-sign-calendar-day-btn ele-status-7"
											@click="signIn(index + 1)">
											<div>
												{{$t("签")}} <!-- 可签到状态 -->
											</div>
										</div>

										<!-- 如果有奖励分数，可以启用这个部分 -->
										<!-- <div class="ele-sign-calendar-day-amount"> +5 </div> -->

									</div>

									<!-- <div class="ele-sign-calendar-day">
										<div class="ele-sign-calendar-day-num">2</div>
										<div class="ele-sign-calendar-day-btn ele-status-7">
											<div>
												签
											</div>
										</div>
										<div class="ele-sign-calendar-day-amount">
											+8
										</div>
									</div> -->

								</div>

								<!-- <div class="ele-sign-rule clearfix">
									{{$t("规则说明")}}
									<div class="ele-sign-rule-arrow"></div>
								</div> -->

							</div>

							<!-- <div>
								<div class="ele-task-bonus-wrap clearfix ele-in-progress">
									<div class="ele-task-bonus-left">
										<div class="ele-middle-block">
											<div class="ele-task-bonus-title">{{$t("连续签到")}} <span>7</span>
												{{$t("天后可领取")}}
											</div>
											<div class="ele-task-bonus-amount">88</div>
										</div>
									</div>
									<div class="ele-task-bonus-right">
										<div class="ele-task-bonus-status">{{$t("进行中")}}</div>
									</div>
								</div>
							</div> -->

						</div>

						<div :class="['ele-float-sign-scroll-hint', isFooterHidden ? ' ele-show' : '']">

							<span> {{$t("往上滑看更多")}} </span>

						</div>


					</div>


					<div class="ele-sign-commit-wrap" v-if="topaction == 2">


						<!-- <div class="ele-receive-bar-wrap ele-receive-bar-disabled ele-receive-bar-">
							<div class="ele-receive-wrap">
								<div class="ele-receive-bar-left">
									未领 0 笔：共 0 元
								</div>
							</div>
						</div> -->

						<div class="ele-tag-tab-wrap clearfix ele-type-b">

							<div class="ele-tab-item-wrap clearfix">

								<div :class="['ele-tab-bg',tabIndex == 0 ? 'current' : '']" @click="settabIndex(0)"
									style="width: 33%;">
									<div class="ele-tab-item">

										<div class="ele-item-name">全部</div>

										<div class="ele-item-amount">({{itemsall.length}})</div>

									</div>

								</div>

								<div :class="['ele-tab-bg',tabIndex == 1 ? 'current' : '']" @click="settabIndex(1)"
									style="width: 33%;">
									<div class="ele-tab-item">

										<div class="ele-item-name">签到</div>

										<div class="ele-item-amount">({{itemsqian.length}})</div>

									</div>

								</div>

								<div :class="['ele-tab-bg',tabIndex == 2 ? 'current' : '']" @click="settabIndex(2)"
									style="width: 33%;">
									<div class="ele-tab-item">

										<div class="ele-item-name">审核</div>

										<div class="ele-item-amount">({{itemsshen.length}})</div>

									</div>

								</div>

								<!-- <div class="ele-tab-bg" style="width: 25%;">
									<div class="ele-tab-item">
										<div class="ele-item-name">立即领</div>
										<div class="ele-item-amount">(0)</div>
									</div>
								</div> -->

							</div>

						</div>

						<div class="ele-float-sign-reward-wrap" v-if="tabIndex == 0">

							<!-- :style="{ height: item.isExpanded ? '164.469px' : '59.3672px' }" -->

							<!-- {
							 	"id": 18, // int: 奖励记录的唯一标识
							 	"member_id": 14, // int: 用户ID，表示该记录所属的用户
							 	"days": 0, // int: 完成任务的天数
							 	"bonus_money": "0.00", // string: 奖励金额，以字符串格式返回小数
							 	"serial_days": 1, // int: 连续签到天数
							 	"total_days": 1, // int: 累计签到总天数
							 	"type": 0, // int: 奖励类型标识 (例如0: 每日签到奖励)
							 	"state": 1, // int: 状态标识，1表示已确认
							 	"lang": "zh_cn", // string: 用户的语言偏好 (例如zh_cn代表中文)
							 	"remark": "", // string: 备注信息，可能为空
							 	"created_at": "2024-10-27 10:53:02", // string: 记录创建时间，以"YYYY-MM-DD HH:MM:SS"格式显示
							 	"updated_at": "2024-10-27 10:53:02", // string: 记录更新时间，以"YYYY-MM-DD HH:MM:SS"格式显示
							 	"times": "10/27", // string: 自定义日期格式，以"MM/DD"显示，表示记录的创建日期
							 	"state_text": "已确认" // string: 状态描述文本，根据状态返回对应的中文描述 (例如“已确认”表示状态为1)
							}, -->

							<div v-for="(item, index) in itemsall" :key="index"
								class="ele-expand-wrap ele-task-sign-wrap" @click="toggleExpand(index)">

								<div
									:class="['ele-expand-header clearfix', isExpanded == index ? 'ele-is-expand' : '']">

									<div class="ele-task-sign-left">

										<div class="ele-middle-block clearfix">

											<div class="ele-task-sign-title">
												{{ item.times }} 签到奖励
											</div>

											<!-- <div v-if="isExpanded == index" class="ele-task-sign-amount ele-reward-type-1">
												{{ item.bonus_money }}
											</div> -->

										</div>

									</div>

									<div class="ele-task-sign-right">

										<div class="ele-task-sign-status">{{ item.state_text }}</div>

									</div>

								</div>

								<div v-if="isExpanded == index" class="ele-expand-content">

									<!-- <div class="ele-task-info-wrap">
										<div>结算周期：{{ item.period }}</div>
									</div> -->

									<div class="clearfix">

										<div class="ele-task-sign-expand ele-clickable">

											<div class="ele-task-sign-expand-title">奖励</div>

											<div class="ele-task-sign-expand-content clearfix">

												<div class="ele-task-sign-expand-left">

													<div class="ele-middle-block">

														<div class="ele-expand-amount">{{ item.bonus_money }}</div>

														<!-- <div class="ele-expand-complex">1倍打码</div> -->

													</div>

												</div>

												<div class="ele-task-sign-expand-right">

													<div class="ele-task-sign-expand-btn">{{ item.state_text }}</div>

												</div>

											</div>

										</div>

									</div>

								</div>

							</div>


							<!-- <div class="ele-expand-wrap ele-task-sign-wrap" style="height: 59.3672px;">
								<div class="ele-expand-header clearfix">
									<div class="ele-task-sign-left">
										<div class="ele-middle-block clearfix">
											<div class="ele-task-sign-title">
												09/22 签到奖励
											</div>
										</div>
									</div>
									<div class="ele-task-sign-right">
										<div class="ele-task-sign-status">未通过
										</div>
									</div>
								</div>
								<div class="ele-expand-content">
									<div class="ele-task-info-wrap">
										<div>结算周期：2024/09/22 00:00:00 - 2024/09/22 23:59:59</div>
							
									</div>
									<div class="clearfix">
										<div class="ele-task-sign-expand ele-status-6 ele-clickable">
											<div class="ele-task-sign-expand-title">奖励</div>
											<div class="ele-task-sign-expand-content clearfix">
												<div class="ele-task-sign-expand-left">
													<div class="ele-middle-block">
														<div class="ele-expand-amount"></div>
														<div class="ele-expand-complex">1倍打码</div>
													</div>
												</div>
												<div class="ele-task-sign-expand-right">
													<div class="ele-task-sign-expand-btn">未通过</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
													
							<div class="ele-expand-wrap ele-task-sign-wrap" style="height: 164.469px;">
							
								<div class="ele-expand-header clearfix ele-is-expand">
								
									<div class="ele-task-sign-left">
										<div class="ele-middle-block clearfix">
											<div class="ele-task-sign-title">
												09/23 签到奖励
											</div>
											<div class="ele-task-sign-amount ele-reward-type-1">
												2
											</div>
										</div>
									</div>
									<div class="ele-task-sign-right">
										<div class="ele-task-sign-status">审核中
										</div>
									</div>
								</div>
								<div class="ele-expand-content">
									<div class="ele-task-info-wrap">
										<div>结算周期：2024/09/23 00:00:00 - 2024/09/23 23:59:59</div>

									</div>
									<div class="clearfix">
										<div class="ele-task-sign-expand ele-status-5 ele-clickable">
											<div class="ele-task-sign-expand-title">奖励</div>
											<div class="ele-task-sign-expand-content clearfix">
												<div class="ele-task-sign-expand-left">
													<div class="ele-middle-block">
														<div class="ele-expand-amount">2</div>
														<div class="ele-expand-complex">1倍打码</div>
													</div>
												</div>
												<div class="ele-task-sign-expand-right">
													<div class="ele-task-sign-expand-btn">进度</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> -->


						</div>


						<div class="ele-float-sign-reward-wrap" v-if="tabIndex == 0">

							<!-- :style="{ height: item.isExpanded ? '164.469px' : '59.3672px' }" -->

							<!-- {
							 	"id": 18, // int: 奖励记录的唯一标识
							 	"member_id": 14, // int: 用户ID，表示该记录所属的用户
							 	"days": 0, // int: 完成任务的天数
							 	"bonus_money": "0.00", // string: 奖励金额，以字符串格式返回小数
							 	"serial_days": 1, // int: 连续签到天数
							 	"total_days": 1, // int: 累计签到总天数
							 	"type": 0, // int: 奖励类型标识 (例如0: 每日签到奖励)
							 	"state": 1, // int: 状态标识，1表示已确认
							 	"lang": "zh_cn", // string: 用户的语言偏好 (例如zh_cn代表中文)
							 	"remark": "", // string: 备注信息，可能为空
							 	"created_at": "2024-10-27 10:53:02", // string: 记录创建时间，以"YYYY-MM-DD HH:MM:SS"格式显示
							 	"updated_at": "2024-10-27 10:53:02", // string: 记录更新时间，以"YYYY-MM-DD HH:MM:SS"格式显示
							 	"times": "10/27", // string: 自定义日期格式，以"MM/DD"显示，表示记录的创建日期
							 	"state_text": "已确认" // string: 状态描述文本，根据状态返回对应的中文描述 (例如“已确认”表示状态为1)
							}, -->

							<div v-for="(item, index) in itemsqian" :key="index"
								class="ele-expand-wrap ele-task-sign-wrap" @click="toggleExpand(index)">

								<div
									:class="['ele-expand-header clearfix', isExpanded == index ? 'ele-is-expand' : '']">

									<div class="ele-task-sign-left">

										<div class="ele-middle-block clearfix">

											<div class="ele-task-sign-title">
												{{ item.times }} 签到奖励
											</div>

											<!-- <div v-if="isExpanded == index" class="ele-task-sign-amount ele-reward-type-1">
												{{ item.bonus_money }}
											</div> -->

										</div>

									</div>

									<div class="ele-task-sign-right">

										<div class="ele-task-sign-status">{{ item.state_text }}</div>

									</div>

								</div>

								<div v-if="isExpanded == index" class="ele-expand-content">

									<!-- <div class="ele-task-info-wrap">
										<div>结算周期：{{ item.period }}</div>
									</div> -->

									<div class="clearfix">

										<div class="ele-task-sign-expand ele-clickable">

											<div class="ele-task-sign-expand-title">奖励</div>

											<div class="ele-task-sign-expand-content clearfix">

												<div class="ele-task-sign-expand-left">

													<div class="ele-middle-block">

														<div class="ele-expand-amount">{{ item.bonus_money }}</div>

														<!-- <div class="ele-expand-complex">1倍打码</div> -->

													</div>

												</div>

												<div class="ele-task-sign-expand-right">

													<div class="ele-task-sign-expand-btn">{{ item.state_text }}</div>

												</div>

											</div>

										</div>

									</div>

								</div>

							</div>

						</div>



						<div class="ele-float-sign-reward-wrap" v-if="tabIndex == 2">

							<!-- :style="{ height: item.isExpanded ? '164.469px' : '59.3672px' }" -->

							<!-- {
							 	"id": 18, // int: 奖励记录的唯一标识
							 	"member_id": 14, // int: 用户ID，表示该记录所属的用户
							 	"days": 0, // int: 完成任务的天数
							 	"bonus_money": "0.00", // string: 奖励金额，以字符串格式返回小数
							 	"serial_days": 1, // int: 连续签到天数
							 	"total_days": 1, // int: 累计签到总天数
							 	"type": 0, // int: 奖励类型标识 (例如0: 每日签到奖励)
							 	"state": 1, // int: 状态标识，1表示已确认
							 	"lang": "zh_cn", // string: 用户的语言偏好 (例如zh_cn代表中文)
							 	"remark": "", // string: 备注信息，可能为空
							 	"created_at": "2024-10-27 10:53:02", // string: 记录创建时间，以"YYYY-MM-DD HH:MM:SS"格式显示
							 	"updated_at": "2024-10-27 10:53:02", // string: 记录更新时间，以"YYYY-MM-DD HH:MM:SS"格式显示
							 	"times": "10/27", // string: 自定义日期格式，以"MM/DD"显示，表示记录的创建日期
							 	"state_text": "已确认" // string: 状态描述文本，根据状态返回对应的中文描述 (例如“已确认”表示状态为1)
							}, -->

							<div v-for="(item, index) in itemsshen" :key="index"
								class="ele-expand-wrap ele-task-sign-wrap" @click="toggleExpand(index)">

								<div
									:class="['ele-expand-header clearfix', isExpanded == index ? 'ele-is-expand' : '']">

									<div class="ele-task-sign-left">

										<div class="ele-middle-block clearfix">

											<div class="ele-task-sign-title">
												{{ item.times }} 签到奖励
											</div>

											<!-- <div v-if="isExpanded == index" class="ele-task-sign-amount ele-reward-type-1">
												{{ item.bonus_money }}
											</div> -->

										</div>

									</div>

									<div class="ele-task-sign-right">

										<div class="ele-task-sign-status">{{ item.state_text }}</div>

									</div>

								</div>

								<div v-if="isExpanded == index" class="ele-expand-content">

									<!-- <div class="ele-task-info-wrap">
										<div>结算周期：{{ item.period }}</div>
									</div> -->

									<div class="clearfix">

										<div class="ele-task-sign-expand ele-clickable">

											<div class="ele-task-sign-expand-title">奖励</div>

											<div class="ele-task-sign-expand-content clearfix">

												<div class="ele-task-sign-expand-left">

													<div class="ele-middle-block">

														<div class="ele-expand-amount">{{ item.bonus_money }}</div>

														<!-- <div class="ele-expand-complex">1倍打码</div> -->

													</div>

												</div>

												<div class="ele-task-sign-expand-right">

													<div class="ele-task-sign-expand-btn">{{ item.state_text }}</div>

												</div>

											</div>

										</div>

									</div>

								</div>

							</div>


						</div>



					</div>


					<div class="ele-description-wrap" v-if="topaction == 3">
						<div class="ele-description-content">
							<article class="ele-ckeditor-default-style-promotion">

								<!-- <p>
									
									<a target="_self">
									<img alt="" src="https://2015dzqb.com/55/qianbaotj.png" style="display: block; margin: 0px auto; width: 100%;"></a>
								</p> -->

								<p><span style="color: rgb(0, 174, 255);"><span
											style="font-size: 18px;"><strong>活动详情</strong></span></span><br> <span
										style="font-size: 14px;">活动对象：全体新老会员；<br>
										活动时间：2024年4月18日起<br>
										活动内容：凡是在澳门娱乐场单日使用<span
											style="color: rgb(255, 0, 0);">【USDT】【NO钱包】【购宝钱包】【CG钱包】【钱能钱包】【波币钱包】【K豆钱包】</span><span
											style="color: rgb(255, 0, 0);">【OSPAY钱包】</span>指定通道皆可参与；<br> <span
											style="color: rgb(255, 0, 0);">小额签到</span>：需当日使用指定通道存款<span
											style="color: rgb(255, 0, 0);">50+</span>，并在<span
											style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">电子/捕鱼/棋牌完成<span
												style="color: rgb(255, 0, 0);">1000+</span>
											有效投注，即可参与签到豪礼；</span><br>
										<span style="color: rgb(255, 0, 0);">大额签到</span>：需当日使用指定通道存款<span
											style="color: rgb(255, 0, 0);">500+</span>，并在<span
											style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">电子/捕鱼/棋牌完成<span
												style="color: rgb(255, 0, 0);">8000+</span>
											有效投注，即可参与签到豪礼；</span>连续一周不间断，还可获得额外嘉奖彩金，赶快参与并分享您的亲朋好友一起来领取吧。</span></p>
								<table border="1" cellpadding="1" cellspacing="1" style="width: 100%;">
									<tbody>
										<tr>
											<td style="text-align: center;">
												<p><span style="font-size: 14px;"><span
															style="color: rgb(0, 174, 255); font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">USDT+热门电子钱包</span><br>
														<span
															style="color: rgb(0, 174, 255); font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">天天签到有好礼</span></span>
												</p>
											</td>
											<td style="text-align: center;"><span style="font-size: 14px;"><span
														style="color: rgb(0, 174, 255); font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">小额签到</span></span>
											</td>
											<td colspan="2" style="text-align: center;"><span
													style="font-size: 14px;"><span
														style="color: rgb(0, 174, 255); font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">大额签到</span></span>
											</td>
										</tr>
										<tr>
											<td style="text-align: center;"><span style="font-size: 14px;"><span
														style="color: rgb(0, 174, 255); font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">第一天</span></span>
											</td>
											<td style="text-align: center;"><span style="font-size: 14px;">2元</span>
											</td>
											<td colspan="2" style="text-align: center;"><span
													style="font-size: 14px;">3元</span></td>
										</tr>
										<tr>
											<td style="text-align: center;"><span style="font-size: 14px;"><span
														style="color: rgb(0, 174, 255); font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">第二天</span></span>
											</td>
											<td style="text-align: center;"><span style="font-size: 14px;">3元</span>
											</td>
											<td colspan="2" style="text-align: center;"><span
													style="font-size: 14px;">6元</span></td>
										</tr>
										<tr>
											<td style="text-align: center;"><span style="font-size: 14px;"><span
														style="color: rgb(0, 174, 255); font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">第三天</span></span>
											</td>
											<td style="text-align: center;"><span style="font-size: 14px;">5元</span>
											</td>
											<td colspan="2" style="text-align: center;"><span
													style="font-size: 14px;">10元</span></td>
										</tr>
										<tr>
											<td style="text-align: center;"><span style="font-size: 14px;"><span
														style="color: rgb(0, 174, 255); font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">第四天</span></span>
											</td>
											<td style="text-align: center;"><span style="font-size: 14px;">8元</span>
											</td>
											<td colspan="2" style="text-align: center;"><span
													style="font-size: 14px;">17元</span></td>
										</tr>
										<tr>
											<td style="text-align: center;"><span style="font-size: 14px;"><span
														style="color: rgb(0, 174, 255); font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">第五天</span></span>
											</td>
											<td style="text-align: center;"><span style="font-size: 14px;">10元</span>
											</td>
											<td colspan="2" style="text-align: center;"><span
													style="font-size: 14px;">25元</span></td>
										</tr>
										<tr>
											<td style="text-align: center;"><span style="font-size: 14px;"><span
														style="color: rgb(0, 174, 255); font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">第六天</span></span>
											</td>
											<td style="text-align: center;"><span style="font-size: 14px;">12元</span>
											</td>
											<td colspan="2" style="text-align: center;"><span
													style="font-size: 14px;">33元</span></td>
										</tr>
										<tr>
											<td style="text-align: center;"><span style="font-size: 14px;"><span
														style="color: rgb(0, 174, 255); font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">第七天</span></span>
											</td>
											<td style="text-align: center;"><span style="font-size: 14px;">15元</span>
											</td>
											<td colspan="2" style="text-align: center;"><span
													style="font-size: 14px;">40元</span></td>
										</tr>
										<tr>
											<td style="text-align: center;"><span style="font-size: 14px;"><span
														style="color: rgb(0, 174, 255); font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">连续七天不间断</span></span>
											</td>
											<td style="text-align: center;"><span style="font-size: 14px;">嘉奖
													15元</span>
											</td>
											<td colspan="2" style="text-align: center;"><span
													style="font-size: 14px;">嘉奖
													40元</span></td>
										</tr>
										<tr>
											<td colspan="4" style="text-align: center;"><span
													style="font-size: 14px;"><span
														style="color: rgb(255, 0, 0);">特注：</span>使用
													USDT/电子钱包存款</span>
											</td>
										</tr>
										<tr>
											<td colspan="4" style="text-align: center;"><span
													style="font-size: 14px;"><span
														style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;"><a
															data-activity-id="" href="https://2015dzqb.com/"
															target="_blank"
															style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;; text-align: center;"><span
																style="color: rgb(255, 0, 0);">电子钱包教程</span></a></span><span
														style="color: rgb(255, 0, 0);">&nbsp;</span>|<span
														style="color: rgb(255, 0, 0);">&nbsp;</span><span
														style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;"><a
															data-activity-id=""
															href="javascript:window.open('/web/simple.php#/aioDownload', 'aioDownload', 'width=1100,height=758,resizable=yes').focus();"
															target="_self"
															style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;; font-size: 14px; text-align: center;"><span
																style="color: rgb(255, 0, 0);">APP下载</span></a><span
															style="color: rgb(255, 0, 0);">&nbsp;</span></span>|<span
														style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;"><span
															style="color: rgb(255, 0, 0);">&nbsp;</span></span><span
														style="background-color: transparent; font-family: arial, verdana, sans-serif;"><a
															data-activity-id=""
															href="https://www.8915x8915.com:9900/CustomBrowser/download.html?provider=k6q7xekdq8"
															target="_blank"
															style="font-size: 14px; text-align: center; background-color: transparent; font-family: arial, verdana, sans-serif; box-sizing: border-box;"><span
																style="color: rgb(255, 0, 0);">2015浏览器</span></a><span
															style="color: rgb(255, 0, 0);">&nbsp;</span></span>|<span
														style="background-color: transparent; font-family: arial, verdana, sans-serif;"><span
															style="color: rgb(255, 0, 0);">&nbsp;</span></span><span
														style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;"><a
															data-activity-id="" href="https://ub555.net" target="_blank"
															style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;; font-size: 14px; text-align: center;"><span
																style="color: rgb(255, 0, 0);">寰宇浏览器</span></a><span
															style="color: rgb(255, 0, 0);">&nbsp;</span></span>|<span
														style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;"><span
															style="color: rgb(255, 0, 0);">&nbsp;</span></span><a
														data-activity-id=""
														href="javascript:window.parent.location.href = '/infe/mcenter/#/offer?focusTab=activity',false"
														style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;; font-size: 14px; text-align: center;"><span
															style="color: rgb(255, 0, 0);">推荐好友终身奖励</span></a></span>
											</td>
										</tr>
										<tr>
											<td colspan="4" style="text-align: center;"><span
													style="color: rgb(0, 174, 255); font-family: arial, verdana, sans-serif; font-size: 14px;">易记网址：</span><a
													data-activity-id="" href="http://2015.com" target="_blank"
													style="font-family: arial, verdana, sans-serif; font-size: 14px; text-align: center;"><span
														style="color: rgb(0, 174, 255);">2015.com</span></a><span
													style="color: rgb(0, 174, 255); font-family: arial, verdana, sans-serif; font-size: 14px;">&nbsp;|&nbsp;</span><a
													data-activity-id="" href="http://2355.com" target="_blank"
													style="font-family: arial, verdana, sans-serif; font-size: 14px; text-align: center;"><span
														style="color: rgb(0, 174, 255);">2355.com</span></a><span
													style="color: rgb(0, 174, 255); font-family: arial, verdana, sans-serif; font-size: 14px;">&nbsp;|&nbsp;</span><a
													data-activity-id="" href="http://2205.com" target="_blank"
													style="font-family: arial, verdana, sans-serif; font-size: 14px; text-align: center;"><span
														style="color: rgb(0, 174, 255);">2205.com</span></a><br> <a
													data-activity-id="" href="http://2205.com" target="_blank"
													style="font-family: arial, verdana, sans-serif; font-size: 14px; text-align: center;"><span
														style="color: rgb(0, 174, 255);">2055.com</span></a><span
													style="color: rgb(0, 174, 255); font-family: arial, verdana, sans-serif; font-size: 14px;">&nbsp;|&nbsp;</span><a
													data-activity-id="" href="http://8915.com" target="_blank"
													style="font-family: arial, verdana, sans-serif; font-size: 14px; text-align: center;"><span
														style="color: rgb(0, 174, 255);">8915.com</span></a><span
													style="color: rgb(0, 174, 255); font-family: arial, verdana, sans-serif; font-size: 14px;">&nbsp;|&nbsp;</span><a
													data-activity-id="" href="http://3015.com" target="_blank"
													style="font-family: arial, verdana, sans-serif; font-size: 14px; text-align: center;"><span
														style="color: rgb(0, 174, 255);">3015.com</span></a><span
													style="color: rgb(0, 174, 255); font-family: arial, verdana, sans-serif; font-size: 14px;">&nbsp;|&nbsp;</span><a
													data-activity-id="" href="http://55.am" target="_blank"
													style="font-family: arial, verdana, sans-serif; font-size: 14px; text-align: center;"><span
														style="color: rgb(0, 174, 255);">55.am</span></a></td>
										</tr>
									</tbody>
								</table>
								<!-- <p><span style="font-size: 14px;"><span
											style="color: rgb(255, 0, 0);">例：小额签到：</span>会员当日使用指定通道存款50元+于<span
											style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">电子/捕鱼/棋牌</span>有效投注1000元，即可领取对应签到彩金，连续签到7天可获得15元嘉奖；<br>
										<span style="color: rgb(255, 0, 0);">例：大额签到：</span>会员当日使用</span><span
										style="font-size: 14px;">指定通道存款500元+于<span
											style="font-family: sans-serif, arial, verdana, &quot;trebuchet ms&quot;;">电子/捕鱼/棋牌</span>有效投注8000元，即可领取对应签到彩金，连续签到7天可获得40元嘉奖；（<span
											style="color: rgb(255, 0, 0);">大额签到 与 小额签到 </span><span
											style="color: rgb(255, 0, 0);">可一起参与</span>）；<br> <span
											style="color: rgb(255, 0, 0);">注：</span>签到满7天后，第8天则从头开始计算，视为第一天。若连续签到过程，有一天未签到成功，需重新开始累积；每个周期结束后，签到天数将重新计算&ZeroWidthSpace;！&ZeroWidthSpace;<br>
										<br> <span style="color: rgb(255, 0, 0);"><span
												style="font-family: &quot;microsoft yahei&quot;, helvetica, stheiti, arial, sans-serif;">如何签到？</span></span><br>
										会员在当日达到签到条件后，进入我的优惠-USDT+热门钱包 天天签到有好礼手动点击签到，签到彩金在次日在我的优惠领取！</span></p>
								<p><span style="color: rgb(0, 174, 255);"><span
											style="font-size: 18px;"><strong>领取方式</strong></span></span></p>
								<p><span style="font-size: 16px;"><a data-activity-id=""
											href="/web/#/simple/mobile/promotion/sign?assignPage=sign"
											target="_self"><span style="color: rgb(255, 0, 0);"><strong>登入会员账号 &gt;
													我的优惠
													&gt; 签到送 &gt; 签到/领取彩金</strong></span></a></span></p>
								<p><span style="color: rgb(0, 174, 255);"><span
											style="font-size: 18px;"><strong>活动细则</strong></span></span><br> <span
										style="font-size: 14px;">1.所获得奖金需一倍流水方可申请提款；<br>
										2.每位会员每天仅限申请一次，天数按照美东时间计算；<br>
										3.<span style="font-family: arial, verdana, sans-serif;">每7天为一个签到周期，</span><span
											style="font-family: &quot;microsoft yahei&quot;, helvetica, stheiti, arial, sans-serif;">每个周期结束后，签到天数将重新计算&ZeroWidthSpace;！</span><br>
										4.符合申请条件的会员请在次日24小时内进行申请，逾期视为自动放弃优惠；<br>
										5.参与该优惠，即表示您同意《优惠规则与条款》。</span></p> -->


							</article>
						</div>
					</div>

				</div>

				<div class="ele-float-close-bg">
					<div class="ele-float-operate-close-btn" @click="closesign">
						关闭
					</div>
					<div class="ele-float-activity-btn">
						立即游戏
					</div>
				</div>

			</div>


		</div>

		<Diyloading ref="loadmodal"></Diyloading>

	</div>

</template>


<script>
	function getDaysInCurrentMonth() {
		const date = new Date();
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // 当前月份 (0 表示 1 月)
		// 创建下个月的第一天，然后减去 1 天得到当前月的最后一天
		return new Date(year, month, 0).getDate();
	}

	import Diyloading from "./libs/Diyloading.vue"

	export default {
		name: 'sign',
		data() {
			return {

				itemsall: [],
				itemsqian: [],
				itemsshen: [],


				tabIndex: 0,
				topaction: 1,
				signid: '',
				opensign: false,
				activityList: [{
						"id": 879,
						"originalActivityId": 548,
						"status": 5,
						"offerType": 5,
						"activityType": 1,
						"receiveType": 1,
						"title": "天天签到领豪礼",
						"startDate": "2024\/07\/01",
						"endDate": "2025\/06\/30",
						"statusCount": 0,
						"statusRewardList": []
					},
					// {
					// 	"id": 546,
					// 	"originalActivityId": 546,
					// 	"status": 7,
					// 	"offerType": 5,
					// 	"activityType": 1,
					// 	"receiveType": 1,
					// 	"title": "电子\/捕鱼签到有礼",
					// 	"startDate": "2024\/07\/01",
					// 	"endDate": "2025\/06\/30",
					// 	"statusCount": 1,
					// 	"statusRewardList": [{
					// 		"eventId": 29308,
					// 		"id": 0
					// 	}]
					// },
					// {
					// 	"id": 547,
					// 	"originalActivityId": 547,
					// 	"status": 7,
					// 	"offerType": 5,
					// 	"activityType": 1,
					// 	"receiveType": 1,
					// 	"title": "棋牌签到有礼",
					// 	"startDate": "2024\/07\/01",
					// 	"endDate": "2025\/06\/30",
					// 	"statusCount": 1,
					// 	"statusRewardList": [{
					// 		"eventId": 29309,
					// 		"id": 0
					// 	}]
					// },
					// {
					// 	"id": 730,
					// 	"originalActivityId": 549,
					// 	"status": 7,
					// 	"offerType": 5,
					// 	"activityType": 1,
					// 	"receiveType": 1,
					// 	"title": "大额 USDT热门钱包 天天签到领豪礼",
					// 	"startDate": "2024\/07\/01",
					// 	"endDate": "2025\/06\/30",
					// 	"statusCount": 1,
					// 	"statusRewardList": [{
					// 		"eventId": 29339,
					// 		"id": 0
					// 	}]
					// }
				],

				lastScrollPosition: 0, // 上一次滚动位置
				isFooterHidden: false, // 是否隐藏底部栏

				checked_day: [], // 已签到的天数

				daysInMonth: Array.from({
					length: this.getDaysInMonth()
				}), // 当前月份的天数
				underReviewDays: [], // 待审核的天数
				currentDay: new Date().getDate(), // 当前日期


				current_page: 1,
				first_page_url: "",
				from: 1,
				last_page: 0,
				last_page_url: "",
				next_page_url: "",
				path: "",
				per_page: 0,
				prev_page_url: null,
				to: 0,
				total: 0,
				isLoading: false, // 请求状态标志，控制是否在加载
				currentRequest: null, // 当前请求，用于取消前一个请求
				isExpanded: '',
				total1: 0,
				total2: 0,
				total3: 0,
			};

		},
		components: {
			Diyloading,
		},
		created() {
			// 页面加载时动态生成签到数组
			// this.initializeSignInStatus();
		},
		mounted() {
			// 绑定 window 的滚动事件
			window.addEventListener('scroll', this.handleScroll);
		},
		beforeDestroy() {
			// 移除事件监听器
			window.removeEventListener('scroll', this.handleScroll);
		},
		computed: {
			userinfo() {
				return this.$store.state.userInfo
			},
			//内容高度
			isLogin() {
				return this.$store.state.token;
			},
		},
		methods: {

			toggleExpand(index) {

				this.isExpanded = index;
				// this.itemsall[index].isExpanded = !this.itemsall[index].isExpanded;
			},

			settabIndex(e) {

				let that = this;

				that.isExpanded = '';

				that.tabIndex = e;
				// 获取签到领取的奖励
				// page: 2,
				let param = {
					limit: that.getDaysInMonth(),
				};
				
				if(that.tabIndex == 1){
					param.state = 1;
				}
				if(that.tabIndex == 2){
					param.state = 0;
				}
				
				that.$refs.loadmodal.open(); // 传入标题和消息

				that.$apiFun.get("/api/dailybonus/task", param)
					.then((res) => {
						console.log(JSON.stringify(res));
						// 处理响应数据
						if (res.code === 200) {

							const {
								data,
								current_page,
								last_page,
								total
							} = res.data;
							
							
							if(that.tabIndex == 0){
								that.itemsall = data;
							}
							if(that.tabIndex == 1){
								that.itemsqian = data;
							}
							if(that.tabIndex == 2){
								that.itemsshen = data;
							}

							// that.itemsall = [...that.itemsall, ...data];
							// that.current_page = current_page;
							// that.last_page = last_page;

							// that.isLoading = false;
							
							

							that.total1 = total;

							that.$refs.loadmodal.close(); // 传入标题和消息
						}

						// that.$parent.hideLoading();
					})
					.catch((res) => {
						// that.$parent.hideLoading();
					});
			},

			// 获取签到列表
			signlist() {

				let that = this;
				let totoaldays = getDaysInCurrentMonth();
				// that.$parent.showLoading();
				that.$apiFun.get("/api/dailybonus/history", {
						limit: totoaldays
					})
					.then((res) => {
						// console.log(JSON.stringify(res));
						if (res.code == 200) {

							that.checked_day = res.data.checked_day; // 确保 res.data 是数组
							// console.log(JSON.stringify(that.checked_day));

						}
						// that.$parent.hideLoading();
					})
					.catch((res) => {
						// that.$parent.hideLoading();
					});

			},

			// 判断某天是否已经签到
			isChecked(day) {
				return Array.isArray(this.checked_day) && this.checked_day.includes(day);
			},
			// 判断某天是否正在审核
			isUnderReview(day) {
				return Array.isArray(this.underReviewDays) && this.underReviewDays.includes(day);
			},
			// 签到逻辑
			signIn(day) {

				let that = this;

				if (!that.isChecked(day)) {
					// 你可以在这里发送签到 API 请求，将签到状态同步到后台

					that.$apiFun.post("/api/dailybonus/check", {})
						.then((res) => {
							// console.log(JSON.stringify(res));
							if (res.code === 200) {
								// that.checked_day.push(day); // 将该天数加入已签到数组
								that.underReviewDays.push(day); // 将该天数加入带审核数组
								that.$forceUpdate(); // 强制刷新视图
							}
							if (res.code === 422) {

								that.$parent.showTost(0, res.message);

							}


							// that.$parent.hideLoading();
						})
						.catch((res) => {
							// that.$parent.hideLoading();
						});

				}
			},

			// 获取当前月份的天数
			getDaysInMonth() {
				const year = new Date().getFullYear();
				const month = new Date().getMonth() + 1;
				return new Date(year, month, 0).getDate();
			},

			//返回
			goBack() {
				this.$router.isBack = true
				this.$router.push({
					path: this.$route.query.redirect ? this.$route.query.redirect : window.history.go(-1)
				})
			},

			settopaction(i) {

				this.topaction = i;
				// this.tabIndex = 0;
				this.settabIndex(0);

			},

			handleScroll(event) {
				// 获取容器的滚动位置
				const scrollTop = event.target.scrollTop;
				console.log('容器滚动位置:', scrollTop);
			},

			handleScroll(event) {

				const currentScroll = event.target.scrollTop;
				// 判断滚动方向
				if (currentScroll > this.lastScrollPosition) {
					// 向下滚动，隐藏底部栏
					this.isFooterHidden = true;
				} else {
					// 向上滚动，显示底部栏
					this.isFooterHidden = false;
				}
				// 更新上一次滚动位置
				this.lastScrollPosition = currentScroll;

			},

			setsign(i) {
				this.opensign = true;
				// 获取签到列表
				this.signlist();
			},
			closesign(i) {
				this.opensign = false;
			},

		}
	};
</script>


<style lang="scss" scoped>
	.ele-promotion-layout {
		margin: 0 auto;
		max-width: 1200px;
		font-size: 1rem;
		color: #000;
		font-family: Microsoft YaHei, Helvetica, STHeiti, arial, sans-serif;
	}


	.ele-promotion-layout .ele-mobile-wrap {
		background: linear-gradient(90deg, #3473ea, #00adee);
	}

	.ele-mobile-wrap {
		position: relative;
		padding-top: 3rem;
		background: linear-gradient(90deg, #f97a4b, #fa8f31);
	}

	.ele-promotion-layout .ele-mobile-wrap .ele-header {
		background: #286ceb;
	}

	.ele-mobile-wrap .ele-header {
		position: fixed;
		z-index: 400;
		left: auto;
		top: 0;
		margin: 0 auto;
		// padding: 0 3rem;
		background: #ea5c00;
		width: 100%;
		max-width: 1200px;
	}

	.ele-mobile-wrap .ele-header-back {
		position: absolute;
		left: 0;
		top: 0;
		width: 3rem;
		height: 3rem;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/btn_back.png) 50% 50% no-repeat;
		background-size: .625rem 1.125rem;
	}

	.ele-mobile-wrap .ele-header-title {
		text-align: center;
		height: 3rem;
	}

	.ele-mobile-wrap .ele-header-title:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-mobile-wrap .ele-header-title span {
		color: #fff;
		font-size: 1.125rem;
		display: inline-block;
		display: -webkit-inline-box;
		vertical-align: middle;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}


	.ele-promotion-layout .ele-mobile-wrap .ele-user-info-wrap {
		background: linear-gradient(90deg, #397dff, #31b9f6);
	}


	// 签到内容部分
	.ele-sign-wrap {
		padding: 2.4375rem 0 .625rem;
		background: #fff;
		min-height: 100%;
	}


	.ele-mission-wrap.ele-mission-recommend:first-child,
	.ele-mission-wrap.ele-mission-sign:first-child {
		margin-top: 0;
	}


	.ele-mission-wrap {

		margin: 0.5625rem 0.625rem 0;
		border-radius: 1.0625rem;
		height: 3.625rem;
		display: flex;
		padding-left: 0.875rem;
	}

	.ele-mission-wrap {
		background: #f3f3f3;
	}


	.ele-mission-wrap .ele-mission-left {
		// padding-left: .875rem;
		width: calc(100% - 5rem);
	}

	.ele-mission-wrap .ele-mission-left {
		position: relative;
		float: left;
		height: 100%;
	}

	.ele-mission-wrap .ele-middle-block {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
	}

	.ele-mission-wrap .ele-title-wrap {
		font-size: .875rem;
		font-weight: 700;
	}

	.ele-mission-wrap .ele-lang-style {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.ele-mission-wrap .ele-title {
		max-width: calc(100% - 4.6875rem);
	}

	.ele-mission-wrap .ele-title {
		margin-right: .5625rem;
		color: #202020;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-promotion-layout .ele-mission-wrap .ele-detail {
		color: #4380f1;
		background: #d4deff;
	}

	.ele-mission-wrap .ele-detail {
		margin-right: .6875rem;
		border-radius: .6875rem;
		min-width: 3.75rem;
		max-width: 4.875rem;
		height: 1.375rem;
		line-height: 1.375rem;
	}

	.ele-mission-wrap .ele-detail {
		padding: 0 .3125rem;
		color: #fe9e28;
		text-align: center;
		background: #ffe4bf;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}



	.ele-mission-wrap .ele-time {
		font-size: .75rem;
		line-height: 1rem;
	}

	.ele-mission-wrap .ele-time {
		margin-top: .125rem;
		color: #ababab;
		word-break: break-all;
	}


	.ele-mission-wrap .ele-mission-left:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-mission-wrap .ele-mission-left:after {
		content: "";
		position: absolute;
		right: 0;
		top: .625rem;
		bottom: .625rem;
		width: 1px;
		background: #e5e5e5;
	}

	.ele-mission-wrap .ele-mission-right {
		width: 5rem;
	}

	.ele-mission-wrap .ele-mission-right {
		float: right;
		text-align: center;
		height: 100%;
	}

	.ele-mission-wrap .ele-mission-right:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}


	.ele-mission-wrap .ele-mission-btn {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		max-width: 95%;
	}

	.ele-promotion-layout .ele-mission-wrap .ele-mission-btn-status {
		background: linear-gradient(180deg, #53abfd, #286ceb);
	}

	.ele-mission-wrap .ele-mission-btn-status {
		border-radius: .6875rem;
		padding: 0 .1875rem;
		font-size: .75rem;
		font-weight: 700;
		min-width: 3.75rem;
		max-width: 4.375rem;
		height: 1.375rem;
		line-height: 1.375rem;
	}

	.ele-mission-wrap .ele-mission-btn-status {
		color: #fff;
		background: #fe9e28;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-mission-wrap .ele-mission-btn-dot {
		font-size: .75rem;
		width: 1.25rem;
		height: 1.25rem;
		line-height: 1.25rem;
	}

	.ele-mission-wrap .ele-mission-btn-dot {
		position: absolute;
		top: -.625rem;
		right: -.6875rem;
		border-radius: 50%;
		font-weight: 700;
		color: #fff;
		background: #ea1f49;
	}

	.ele-mission-wrap .ele-mission-status,
	.ele-mission-wrap .ele-mission-time {
		font-size: .75rem;
	}

	.ele-mission-wrap .ele-mission-status {
		color: #707070;
		display: inline-block;
		display: -webkit-inline-box;
		vertical-align: middle;
		-webkit-line-clamp: 3;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}



	.ele-mobile-wrap .ele-user-info-wrap {
		padding: 0 .625rem;
		font-size: .75rem;
		color: #fff;
		background: linear-gradient(90deg, #fa8a61, #f89d4d);
		height: 1.875rem;
	}

	.ele-mobile-wrap .ele-user-info-wrap.sign {
		position: fixed;
		width: 100%;
		z-index: 1;
		display: flex;
		justify-content: space-between;
		padding: 0 2%;
	}

	.ele-mobile-wrap .ele-user-info {
		float: left;
		width: 46%;
		height: 100%;
		word-break: break-all;
	}

	.ele-mobile-wrap .ele-user-info.ele-user-amount {
		text-align: right;
	}

	.ele-sign-wrap {
		padding: 2.4375rem 0 .625rem;
		background: #fff;
		min-height: 100%;
	}

	.clearfix:after {
		content: "";
		display: block;
		clear: both;
	}

	.ele-mobile-wrap .ele-user-info:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	/* sign */
	.ele-float-wrap {
		position: fixed;
		z-index: 500;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #f97a4b;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/image_gift.png) 92.5% .4375rem no-repeat, linear-gradient(90deg, #f97a4b, #fa8f31);
		background-size: 5.9375rem 5.9375rem, 100% 100%;
		overflow: hidden;
	}

	.ele-promotion-layout .ele-float-wrap {
		background: #3473ea;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/image_gift.png) 15.3125rem .4375rem no-repeat, linear-gradient(90deg, #3473ea, #00adee);
		background-size: 5.9375rem 5.9375rem, 100% 100%;
	}

	.ele-float-wrap .ele-float-title {
		/* padding: 0 .625rem 1.1875rem; */
		text-align: center;
		font-size: 1.125rem;
		font-weight: 700;
		color: #fff;
		height: 5.375rem;
		line-height: 1.6875rem;
	}

	.ele-float-wrap .ele-float-title:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-float-wrap .ele-float-title span {
		display: inline-block;
		display: -webkit-inline-box;
		/* vertical-align: middle; */
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
		word-break: break-all;
	}

	.ele-type-a {
		position: absolute;
		z-index: 1;
		top: 4.1875rem;
		left: 4.5%;
		border-radius: 1.25rem;
		padding: .125rem .1875rem;
		width: 91%;
		background: #fff;
		box-shadow: 0 .125rem .1875rem 0 rgba(0, 0, 0, .2);
	}

	.ele-promotion-layout .ele-tag-tab-wrap.ele-type-a .ele-tab-bg.current {
		background: linear-gradient(180deg, #fc0, #f90);
	}

	.ele-type-a .ele-tab-bg.current {
		color: #fff;
		background: linear-gradient(90deg, #fe7d54, #ffbb03);
	}

	.ele-type-a .ele-tab-bg {
		float: left;
		border-radius: 1.125rem;
		text-align: center;
		font-size: .9375rem;
		font-weight: 700;
		color: #202020;
	}

	.ele-type-a .ele-tab-item {
		height: 2.1875rem;
	}

	.ele-type-a .ele-tab-item:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-type-a .ele-item-name {
		display: inline-block;
		vertical-align: middle;
		max-width: calc(100% - 2.375rem);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-type-a .ele-item-amount {
		display: inline-block;
		vertical-align: middle;
		max-width: 2.375rem;
	}




	.ele-float-wrap .ele-float-container {
		position: relative;
		border-radius: 1.875rem 1.875rem 0 0;
		background: #fff;
		padding-bottom: 2.875rem;
		// height: calc(100% - 5.375rem);
		height: calc(100% - 8.3125rem);
		overflow-x: auto;
	}

	.ele-sign-check-in-wrap .ele-scroll-block {
		padding: .9375rem .625rem 0;
		height: calc(100% - 5.3125rem);
		overflow-y: scroll;
	}

	.ele-sign-check-in-wrap {
		position: relative;
		border-radius: 1.875rem 1.875rem 0 0;
		height: 100%;
		overflow: hidden;
	}

	.ele-sign-check-in-wrap .ele-float-sign-date-wrap {
		padding: 1.6875rem .9375rem .4375rem;
		font-size: .75rem;
		color: #fe9e28;
		background: #fff9e8;
		// height: 4.3125rem;
		height: 2.3125rem;
		line-height: 1rem;
	}

	.ele-sign-check-in-wrap .ele-float-sign-date-wrap .ele-float-sign-date {
		margin-bottom: .1875rem;
	}

	.ele-sign-check-in-wrap .ele-float-sign-date-wrap .ele-float-sign-date:last-child {
		margin-bottom: 0;
	}

	.ele-sign-check-in-wrap .ele-scroll-block .ele-sign-bar-wrap {
		margin-bottom: .5625rem;
	}

	.ele-promotion-layout .ele-sign-bar-wrap {
		background: linear-gradient(90deg, #286ceb, #53abfd);
	}

	.ele-sign-bar-wrap {
		border-radius: 1.0625rem;
		box-shadow: 0 .125rem .1875rem 0 rgba(0, 0, 0, .1);
	}

	.ele-sign-bar-wrap {
		padding: 1px;
		background: linear-gradient(90deg, #ff6231, #ffbb03);
	}

	.ele-sign-bar-wrap .ele-sign-bar-bg {
		border-radius: 1.0625rem;
		height: 3.3125rem;
	}

	.ele-sign-bar-wrap .ele-sign-bar-bg {
		display: flex;
		align-items: center;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/receiveall_right_bg.png) 100% 0 no-repeat;
		background-size: contain;
		overflow: hidden;
	}

	.ele-sign-bar-wrap .ele-sign-bar-left {
		padding-left: .9375rem;
		font-weight: 700;
		flex-basis: calc(100% - 5.375rem);
	}

	.ele-sign-bar-wrap .ele-sign-bar-left {
		flex-shrink: 1;
	}

	.ele-sign-bar-wrap .ele-left-top {
		color: #fff;
		font-size: .75rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		height: 1rem;
		line-height: 1rem;
	}

	.ele-sign-bar-wrap .ele-left-bottom {
		margin-top: .125rem;
		font-size: .875rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/icon_money.png) 0 50% no-repeat;
		background-size: 1.125rem 1.125rem;
		height: 1.1875rem;
		line-height: 1.1875rem;
	}

	.ele-sign-bar-wrap .ele-left-bottom {
		padding-left: 1.375rem;
		color: #ffee70;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-promotion-layout .ele-sign-bar-wrap .ele-sign-bar-btn {
		color: #4380f1;
	}

	.ele-sign-bar-wrap .ele-sign-bar-btn {
		width: 4.25rem;
		height: 1.5625rem;
		line-height: 1.5625rem;
		margin-right: .9375rem;
	}

	.ele-sign-bar-wrap .ele-sign-bar-btn {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin-left: .5rem;
		text-align: center;
		border-radius: .8125rem;
		padding: 0 .3125rem;
		background: #fff;
		color: #fe9e28;
		font-size: .75rem;
		font-weight: 700;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-sign-bar-wrap .ele-sign-bar-bg:after {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-sign-history-wrap .ele-history-status {
		margin-bottom: .25rem;
		font-size: .875rem;
		line-height: 1.1875rem;
	}

	.ele-sign-history-wrap .ele-bonus-hint {
		font-size: .875rem;
	}

	.ele-sign-history-wrap .ele-bonus-hint {
		color: #202020;
	}

	.ele-promotion-layout .ele-sign-history-wrap .ele-interrupt-hint {
		color: #4380f1;
	}

	.ele-sign-history-wrap .ele-interrupt-hint {
		display: inline-block;
		vertical-align: middle;
		font-weight: 700;
		color: #fe9e28;
	}

	.ele-sign-history-wrap .ele-bonus-hint span {
		color: #fc744d;
	}

	.ele-sign-calendar-wrap.ele-daily-bonus-calendar {
		background: linear-gradient(180deg, #d8e5ff, #f5f5f5);
	}


	.ele-sign-calendar-wrap .ele-sign-calendar-header {
		padding: 0 .625rem .625rem;
		line-height: 1.5rem;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-header .ele-sign-calendar-header-icon {
		font-size: .8125rem;
		width: 1.5rem;
		height: 1.5rem;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-header-icon {
		float: left;
		border-radius: 50%;
		background: #59d785;
		color: #fff;
		text-align: center;
		width: 1.5rem;
		height: 1.5rem;
		line-height: 1.5rem;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-header .ele-sign-calendar-header-hint {
		margin-left: .25rem;
		font-weight: 700;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-header-hint {
		float: left;
		font-size: .75rem;
		color: #707070;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-header-hint span {
		color: #fc744d;
		font-size: .75rem;
	}

	.ele-sign-calendar-wrap {
		margin: .625rem 0 .5625rem;
		border-radius: 1.0625rem;
	}

	.ele-sign-calendar-wrap {
		padding-top: .625rem;
		background: #f3f3f3;
		overflow: hidden;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-day-wrap {
		padding-bottom: .1875rem;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-day-wrap .ele-sign-calendar-day {
		display: inline-block;
		vertical-align: middle;
		padding-bottom: .4375rem;
		text-align: center;
		width: 14.28%;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-day-num {
		color: #ababab;
		font-size: .75rem;
		line-height: 1rem;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-day-wrap .ele-sign-calendar-day .ele-sign-calendar-day-btn {
		margin: .125rem auto;
		font-size: .875rem;
		width: 1.875rem;
		height: 1.875rem;
		line-height: 1.875rem;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-day-btn.ele-status-4 {
		color: #fc744d;
	}

	.ele-promotion-layout .ele-sign-calendar-wrap .ele-sign-calendar-day-btn.ele-status-6 {
		background: #4380f1;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-day-btn.ele-status-6 {
		background: #fe9e28;
		color: #fff;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-day-btn {
		position: relative;
		border: 1px solid transparent;
		border-radius: 50%;
		background: #fff;
		color: #ababab;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-day-btn {
		position: relative;
		border: 1px solid transparent;
		border-radius: 50%;
		background: #fff;
		color: #ababab;
	}

	.ele-sign-calendar-wrap .ele-sign-calendar-day-amount {
		line-height: 1rem;
		color: #fc744d;
		font-size: .75rem;
	}

	.ele-sign-calendar-wrap .ele-sign-rule {
		padding: 0 .625rem;
		background: #fff9e8;
		color: #fe9e28;
		font-size: .75rem;
		height: 1.875rem;
		line-height: 1.875rem;
	}

	.ele-sign-calendar-wrap .ele-sign-rule .ele-sign-rule-arrow {
		float: right;
		margin-top: .6875rem;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme1/icon_arrow_1.png) 50% 50% no-repeat;
		background-size: contain;
		width: .3125rem;
		height: .5rem;
	}


	.ele-task-bonus-wrap {
		margin-top: .5625rem;
		border-radius: 1.0625rem;
		background: linear-gradient(90deg, #ea1f49, #f9474e, #ea1f49);
		height: 3.5625rem;
	}

	.ele-task-bonus-wrap {
		font-size: .75rem;
		overflow: hidden;
	}


	.ele-task-bonus-wrap .ele-task-bonus-left {
		position: relative;
		float: left;
		height: 100%;
	}

	.ele-task-bonus-wrap .ele-task-bonus-left {
		padding-left: 2.8125rem;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/envelope_open_m.png) 0 50% no-repeat;
		background-size: contain;
		width: calc(100% - 5rem);
	}

	.ele-task-bonus-wrap.ele-in-progress .ele-task-bonus-left {
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/envelope_close_m.png) 0 50% no-repeat;
		background-size: contain;
	}

	.ele-task-bonus-wrap .ele-task-bonus-left:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-task-bonus-wrap .ele-middle-block {
		display: inline-block;
		vertical-align: middle;
		max-width: 100%;
	}

	.ele-task-bonus-wrap .ele-task-bonus-title {
		font-weight: 700;
	}

	.ele-task-bonus-wrap .ele-task-bonus-title {
		color: #fff;
		line-height: 1rem;
		display: inline-block;
		display: -webkit-inline-box;
		vertical-align: middle;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}

	.ele-task-bonus-wrap .ele-task-bonus-title span {
		font-size: .875rem;
	}

	.ele-task-bonus-wrap .ele-task-bonus-title span {
		display: inline-block;
	}

	.ele-task-bonus-wrap .ele-task-bonus-amount {
		font-size: 1.0625rem;
		font-weight: 700;
		height: 1.4375rem;
		line-height: 1.4375rem;
	}

	.ele-task-bonus-wrap .ele-task-bonus-amount {
		color: #ffee70;
	}

	.ele-task-bonus-wrap .ele-task-bonus-left:after {
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -1.1875rem;
		content: "";
		width: 1px;
		height: 2.375rem;
		background: #ff524a;
	}

	.ele-task-bonus-wrap .ele-task-bonus-right {
		width: 5rem;
	}

	.ele-task-bonus-wrap .ele-task-bonus-right {
		float: right;
		text-align: center;
		height: 100%;
	}

	.ele-task-bonus-wrap .ele-task-bonus-right:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-task-bonus-wrap .ele-task-bonus-status {
		color: #ffc8c4;
		display: inline-block;
		display: -webkit-inline-box;
		vertical-align: middle;
		-webkit-line-clamp: 3;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}


	.ele-sign-check-in-wrap .ele-float-sign-scroll-hint.ele-show {
		opacity: 1;
		height: 1.75rem;
	}

	.ele-promotion-layout .ele-sign-check-in-wrap .ele-float-sign-scroll-hint {
		color: #4380f1;
	}

	.ele-sign-check-in-wrap .ele-float-sign-scroll-hint {
		position: absolute;
		left: 0;
		bottom: 0;
		color: #fe9e28;
		text-align: center;
		background: #fff;
		opacity: 0;
		width: 100%;
		height: 0;
		line-height: 1rem;
		overflow: hidden;
		transition: opacity .2s linear, height .2s linear;
	}


	.ele-promotion-layout .ele-sign-check-in-wrap .ele-float-sign-scroll-hint span {
		background-image: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme3/icon_slide.png);
	}

	.ele-sign-check-in-wrap .ele-float-sign-scroll-hint span {
		display: inline-block;
		margin-top: .75rem;
		padding-left: .75rem;
		font-size: .75rem;
		font-weight: 700;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme1/icon_slide.png) 0 50% no-repeat;
		background-size: .5rem .3125rem;
	}

	.ele-float-wrap .ele-float-close-bg {
		display: flex;
		position: absolute;
		left: 0;
		right: 0;
		bottom: .6875rem;
		align-items: center;
	}

	.ele-float-wrap .ele-float-operate-close-btn {
		margin: 0 auto;
		width: 10.125rem;
		height: 1.875rem;
		line-height: 1.875rem;
		border-radius: .625rem;
		color: #fff;
		background: #868686;
		font-size: .9375rem;
		text-align: center;
	}

	.ele-promotion-layout .ele-float-wrap .ele-float-activity-btn {
		background: linear-gradient(87deg, #3473ea 2%, #00adee 99%);
	}

	.ele-float-wrap .ele-float-activity-btn {
		margin: 0 auto;
		width: 10.125rem;
		height: 1.875rem;
		line-height: 2rem;
		border-radius: .625rem;
		color: #fff;
		background: linear-gradient(87deg, #ff6231 2%, #ffbb03 99%);
		font-size: .9375rem;
		text-align: center;
	}


	.ele-description-wrap {
		padding-top: 1.5625rem;
		// height: 100%;
	}

	.ele-description-wrap .ele-description-content {
		padding: 0 .9375rem;
		height: 100%;
		overflow: auto;
	}

	.ele-ckeditor-default-style-promotion {
		font-size: 16px;
		flex: 1;
	}

	.ele-sign-commit-wrap {
		border-radius: 1.875rem 1.875rem 0 0;
		padding-top: 2.1875rem;
		background: #fff;
		height: 100%;
	}

	.ele-sign-commit-wrap .ele-receive-bar-wrap {
		margin: 0 .625rem;
	}

	.ele-receive-bar-wrap.ele-receive-bar-disabled {
		background: linear-gradient(90deg, #707070, #c4c4c4);
	}

	.ele-promotion-layout .ele-receive-bar-wrap {
		box-shadow: 0 .125rem .1875rem 0 rgba(0, 0, 0, .1);
		background: linear-gradient(90deg, #286ceb, #53abfd);
	}

	.ele-receive-bar-wrap {
		padding: 1px;
		border-radius: 1.0625rem;
		box-shadow: 0 .125rem .1875rem 0 rgba(0, 0, 0, .1);
		background: linear-gradient(90deg, #ff6231, #ffbb03);
	}

	.ele-receive-bar-wrap .ele-receive-wrap {
		border-radius: 1.0625rem;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/receiveall_right_bg.png) 100% 0 no-repeat;
		background-size: contain;
		overflow: hidden;
		width: 100%;
	}

	.ele-receive-bar-wrap .ele-receive-bar-disabled .ele-receive-bar-left {
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/icon_tick_disabled.png) .5625rem 50% no-repeat;
		background-size: 1.5625rem 1.5625rem;
	}

	@media (max-width: 375px) {
		.ele-receive-bar-wrap .ele-receive-bar-left {
			font-size: .8125rem;
		}
	}

	.ele-receive-bar-wrap .ele-receive-bar-left {
		display: inline-block;
		vertical-align: middle;
		padding-left: 2.625rem;
		font-size: .9375rem;
		font-weight: 700;
		color: #fff;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme1/icon_tick.png) .5625rem 50% no-repeat;
		background-size: 1.5625rem 1.5625rem;
		width: calc(100% - 5.375rem);
		height: 3.3125rem;
		line-height: 3.3125rem;
	}

	.ele-type-b {
		padding: .625rem 0 .5625rem;
		border-radius: .75rem;
	}

	.ele-type-b .ele-tab-bg {
		float: left;
		// padding: 0 .1875rem;
		text-align: center;
		font-size: .75rem;
		color: #202020;
	}

	.ele-type-b .ele-tab-bg.current {
		color: #fe9e28;
		font-weight: 700;
	}

	.ele-type-b .ele-item-amount,
	.ele-type-b .ele-item-name {
		display: inline-block;
		vertical-align: middle;
		height: 1.625rem;
		line-height: 1.625rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-type-b .ele-item-name {
		max-width: calc(100% - 1.875rem);
	}

	.ele-type-b .ele-item-amount {
		max-width: 1.875rem;
	}

	.ele-type-b .ele-item-amount,
	.ele-type-b .ele-item-name {
		display: inline-block;
		vertical-align: middle;
		height: 1.625rem;
		line-height: 1.625rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-sign-commit-wrap .ele-float-sign-reward-wrap {
		padding: 0 .625rem;
		height: calc(100% - 6.25rem);
		overflow-y: auto;
	}

	.ele-task-sign-wrap:first-child {
		margin-top: 0;
	}

	.ele-task-sign-wrap {
		margin-top: .5625rem;
		border-radius: 1.0625rem;
		font-size: .75rem;
	}

	.ele-expand-wrap {
		overflow: hidden;
		transition: height .2s ease;
		position: relative;
	}

	.ele-task-sign-wrap .ele-expand-header {
		height: 3.5625rem;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme1/btn_unfold.png) .5rem 50% no-repeat #f3f3f3;
		background-size: .8125rem .8125rem;
	}

	.ele-promotion-layout .ele-task-sign-wrap .ele-expand-header {
		background-image: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme3/btn_unfold.png);
	}


	.ele-task-sign-wrap .ele-task-sign-left {
		position: relative;
		float: left;
		height: 100%;
		cursor: pointer;
	}

	.ele-task-sign-wrap .ele-task-sign-left {
		// padding-left: 1.8125rem;
		// width: calc(100% - 4.875rem);
		width: calc(100% - 6.6875rem);
		padding-left: 1.8125rem;
	}

	.ele-task-sign-wrap .ele-task-sign-right {
		width: 4.875rem;
	}

	.ele-task-sign-wrap .ele-task-sign-right {
		float: right;
		text-align: center;
		height: 100%;
	}

	.ele-task-sign-wrap .ele-task-sign-right:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-task-sign-wrap .ele-task-sign-left:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-task-sign-wrap .ele-task-sign-left:after {
		content: "";
		position: absolute;
		top: .5rem;
		right: 0;
		bottom: .5rem;
		width: 1px;
		background: #e5e5e5;
	}

	.ele-task-sign-wrap .ele-middle-block {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
	}

	.ele-task-sign-wrap .ele-task-sign-title {
		line-height: 1.1875rem;
		font-weight: 700;
	}

	.ele-task-sign-wrap .ele-task-sign-title {
		color: #202020;
		display: inline-block;
		display: -webkit-inline-box;
		vertical-align: middle;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}

	.ele-task-sign-wrap .ele-task-sign-amount.ele-reward-type-1 {
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/icon_money.png) 0 50% no-repeat;
		background-size: .9375rem .9375rem;
	}

	.ele-task-sign-wrap .ele-task-sign-amount {
		padding-left: 1.1875rem;
		font-size: .875rem;
		font-weight: 700;
		height: 1.3125rem;
		line-height: 1.3125rem;
		background-size: .9375rem .9375rem;
	}


	.ele-task-sign-wrap .ele-task-sign-status {
		color: #707070;
		display: inline-block;
		display: -webkit-inline-box;
		vertical-align: middle;
		-webkit-line-clamp: 3;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}

	.ele-promotion-layout .ele-task-sign-wrap .ele-expand-header.ele-is-expand {
		background-image: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme3/btn_fold.png);
	}

	.ele-task-sign-wrap .ele-expand-content {
		border-radius: 0 0 1.0625rem 1.0625rem;
	}

	.ele-task-sign-wrap .ele-expand-content {
		border: .125rem solid #f3f3f3;
		border-top: none;
		overflow: hidden;
	}

	.ele-task-sign-wrap .ele-task-info-wrap {
		padding: .4375rem .4375rem .4375rem 1.8125rem;
	}

	.ele-task-info-wrap {
		background: #fff9e8;
		color: #fe9e28;
		line-height: 1rem;
	}

	.ele-task-sign-wrap .ele-task-sign-expand.ele-clickable {
		cursor: pointer;
	}

	.ele-task-sign-wrap .ele-task-sign-expand {
		padding: .6875rem 1.1875rem .25rem 1.8125rem;
	}

	.ele-task-sign-wrap .ele-task-sign-expand-title {
		font-weight: 700;
		height: 1rem;
		line-height: 1rem;
	}

	.ele-task-sign-wrap .ele-task-sign-expand-title {
		color: #202020;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ele-promotion-layout .ele-task-sign-wrap .ele-task-sign-expand-content {
		background-image: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme3/icon_gift_m.png);
	}

	.ele-task-sign-wrap .ele-task-sign-expand-content {
		padding-left: 1.9375rem;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/theme1/icon_gift_m.png) 0 50% no-repeat;
		background-size: 1.5rem 1.5rem;
		height: 2.375rem;
	}

	.ele-task-sign-wrap .ele-task-sign-expand-left {
		max-width: 58%;
		height: 2.375rem;
	}

	.ele-task-sign-wrap .ele-task-sign-expand-left {
		float: left;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		color: #ababab;
	}

	.ele-task-sign-wrap .ele-task-sign-expand-left:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-task-sign-wrap .ele-middle-block {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
	}

	.ele-promotion-layout .ele-task-sign-wrap .ele-expand-amount {
		color: #4a4e57;
	}

	.ele-task-sign-wrap .ele-expand-amount {
		font-size: .875rem;
		font-weight: 700;
		height: 1.25rem;
		line-height: 1.25rem;
	}

	.ele-task-sign-wrap .ele-expand-complex {
		height: 1rem;
		line-height: 1rem;
	}

	.ele-task-sign-wrap .ele-task-sign-expand-right {
		max-width: 42%;
		height: 2.375rem;
	}

	.ele-task-sign-wrap .ele-task-sign-expand-right {
		float: right;
		text-align: right;
	}

	.ele-task-sign-wrap .ele-task-sign-expand-right:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	.ele-task-sign-wrap .ele-task-sign-expand-btn,
	.ele-task-sign-wrap .ele-task-sign-expand-status {
		display: inline-block;
		display: -webkit-inline-box;
		vertical-align: middle;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-box-orient: vertical;
	}

	.ele-task-sign-wrap .ele-task-sign-expand-btn {
		padding-right: .9375rem;
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/icon_arrow_gray.png) 100% 50% no-repeat;
		background-size: .3125rem .5rem;
		color: #ababab;
	}

	.ele-ckeditor-default-style-promotion blockquote,
	.ele-ckeditor-default-style-promotion dir,
	.ele-ckeditor-default-style-promotion dl,
	.ele-ckeditor-default-style-promotion fieldset,
	.ele-ckeditor-default-style-promotion form,
	.ele-ckeditor-default-style-promotion h4,
	.ele-ckeditor-default-style-promotion menu,
	.ele-ckeditor-default-style-promotion ol,
	.ele-ckeditor-default-style-promotion p,
	.ele-ckeditor-default-style-promotion ul {
		margin: 1.5em 0;
	}

	.ele-ckeditor-default-style-promotion address,
	.ele-ckeditor-default-style-promotion blockquote,
	.ele-ckeditor-default-style-promotion center,
	.ele-ckeditor-default-style-promotion dd,
	.ele-ckeditor-default-style-promotion dir,
	.ele-ckeditor-default-style-promotion dl,
	.ele-ckeditor-default-style-promotion dt,
	.ele-ckeditor-default-style-promotion fieldset,
	.ele-ckeditor-default-style-promotion form,
	.ele-ckeditor-default-style-promotion h1,
	.ele-ckeditor-default-style-promotion h2,
	.ele-ckeditor-default-style-promotion h3,
	.ele-ckeditor-default-style-promotion h4,
	.ele-ckeditor-default-style-promotion h5,
	.ele-ckeditor-default-style-promotion h6,
	.ele-ckeditor-default-style-promotion hr,
	.ele-ckeditor-default-style-promotion menu,
	.ele-ckeditor-default-style-promotion noframes,
	.ele-ckeditor-default-style-promotion ol,
	.ele-ckeditor-default-style-promotion p,
	.ele-ckeditor-default-style-promotion pre,
	.ele-ckeditor-default-style-promotion ul {
		display: block;
	}

	.ele-ckeditor-default-style-promotion li,
	.ele-ckeditor-default-style-promotion p {
		line-height: 22px;
	}
</style>