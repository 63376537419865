<template>
	<div>
		<div
			style="font-size: 0.5rem; height: 1.3rem; display: flex; align-items: center; justify-content: center; font-weight: 700; color: #0620dc">
			{{ $store.state.appInfo.title }}app下载</div>

		<img style="width: 100%; margin-top: 20px"
			src="/static/image/sports_and_2_title-eb044a43aca57221f5bdc248cbb1356d.png" alt="" />

		<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
			<van-swipe-item><img style="width: 100%" src="/static/image/sports_8.png" alt="" /></van-swipe-item>
			<van-swipe-item><img style="width: 100%" src="/static/image/sports_71.png" alt="" /></van-swipe-item>
			<van-swipe-item><img style="width: 100%" src="/static/image/sports_aa.png" alt="" /></van-swipe-item>
			<van-swipe-item><img style="width: 100%" src="/static/image/sports_and4.png" alt="" /></van-swipe-item>
		</van-swipe>
		<img style="width: 100%; margin-top: 20px"
			src="/static/image/sports_and_1_icon-c53b51a31998cee56a723ab1dcaa4f91.png" alt="" />
		<div style="width: 90%; margin: 0 auto; color: #ccc; text-align: center; margin-top: 20px">可扫描下发二维码进行app下载</div>

		<img style="width: 70%; margin: 0 auto; display: block" :src="$store.state.appInfo.ios_download_qrcode"
			onerror="this.src = '/static/image/appurl.jpg'" alt="" />

		<van-button style="width: 90%; margin: 0 auto; margin-top: 30px; display: block" type="info"
			@click="$parent.goNav('/gamePage?app=1')">立即下载</van-button>
		<div style="width: 90%; margin: 10px auto; color: #ccc">需在同一网络环境下下载安装注册，请勿切换网络； 若无法正常安装，请使用手机自带浏览器打开本页面</div>
		<iframe style="height: 100px; opacity: 0" ref="iframe" scrolling="auto" frameborder="0" id="iframe"></iframe>
	</div>
</template>
<script>
	export default {
		name: 'app',
		data() {
			return {};
		},
		created() {
			let that = this;
		},
		methods: {
			//下载文件
			downloadUrl(url) {
				let that = this;
				this.$parent.showLoading();

				const iframe = document.createElement('iframe');
				iframe.src = url;
				iframe.style.display = 'none'; // 防止影响页面
				iframe.style.height = 0; // 防止影响页面
				document.body.appendChild(iframe); // 必写，iframe挂在到dom树上才会发请求
				// 定时删除节点
				setTimeout(() => {
					that.$parent.hideLoading();

					document.body.removeChild(iframe);
				}, 2000);
			},
		},
		mounted() {
			let that = this;
		},
		updated() {
			let that = this;
		},
		beforeDestroy() {},
	};
</script>
<style lang="scss" scoped>
</style>