<template>

	<div class="app-footer-wrap">

		<!-- 百宝箱 -->

		<!-- <div class="ele-draggable app-floaticon app-floaticon-more app-floaticon-2" v-if="!showbaoxaing"
			@click="showbaoxaing = true"><img class="btn-close"
				src="https://g1.cfvn66.com/web/mobile/backend-image/float-icon/btn_hide.png?v=35"><img
				class="award-icon"
				src="https://g1.cfvn66.com/web/mobile/backend-image/float-icon/zh-cn/btn_treasure_n.png?v=35">
		</div>


		<div class="ele-draggable app-floaticon app-floaticon-more app-floaticon-2 is-expand" v-if="showbaoxaing">
			<div class="floaticon-bg"></div>
			<img class="btn-fold" src="https://g1.cfvn66.com/web/mobile/backend-image/float-icon/btn_fold.png?v=35"
				@click="showbaoxaing = false"><img class="award-icon btn-treasure"
				src="https://g1.cfvn66.com/web/mobile/backend-image/float-icon/zh-cn/btn_treasure_h.png?v=35"><img
				class="award-icon"
				src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/lang/zh-cn/btn_draw.png"><img
				class="award-icon"
				src="https://cdn.cfvn66.com/tpl/promotions/3836/release/quick_original_zh-cn.gif?v=1724895643">
		</div> -->

		<!-- 百宝箱 -->


		<!-- 红包 -->

		<!-- 游戏快选 -->
		<!-- <QuickGames /> -->

		<!-- tabbar -->
		<div class="app-footer-links">

			<div class="footer-item footer-item-home" @click="tabgopage('/')" style="flex-basis: 20%;">
				<div><img class="footer-item-icon"
						src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/link-icon/home.png?v=35">
					<div class="footer-item-title" v-if="getlanguage != 'en_us'">{{$t('首页')}}</div>
				</div>
			</div>

			<div class="footer-item footer-item-home" @click="tabgopage('/promotion/222')" style="flex-basis: 20%;">
				<div><img class="footer-item-icon"
						src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/link-icon/promotion_new.png?v=35">
					<div class="footer-item-title" v-if="getlanguage != 'en_us'">{{$t('优惠')}}</div>
				</div>
			</div>


			<div class="footer-item footer-item-home" v-if="!isLogin" @click="tabgopage('/login')"
				style="flex-basis: 20%;">
				<div><img class="footer-item-icon"
						src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/link-icon/login.png?v=35">
					<div class="footer-item-title" v-if="getlanguage != 'en_us'">{{$t('登录')}}</div>
				</div>
			</div>
			
			<div class="footer-item footer-item-home" v-else @click="tabgopage('/recharge')" style="flex-basis: 20%;">
				<div><img class="footer-item-icon"
						src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/link-icon/e_wallet.png">
					<div class="footer-item-title" v-if="getlanguage != 'en_us'">{{$t('钱包')}}</div>
				</div>
			</div>

			<div class="footer-item footer-item-home" v-if="!isLogin" @click="tabgopage('/joinus')" href="/infe/user/join/#/joinus/mobile"
				style="flex-basis: 20%;">
				<div><img class="footer-item-icon"
						src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/link-icon/join.png?v=35">
					<div class="footer-item-title" v-if="getlanguage != 'en_us'">{{$t('注册')}}</div>
				</div>
			</div>
			
			<div class="footer-item footer-item-home" v-else @click="tabgopage('/recharge')" style="flex-basis: 20%;">
				<div><img class="footer-item-icon"
						src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/link-icon/e_wallet.png">
					<div class="footer-item-title" v-if="getlanguage != 'en_us'">{{$t('充值')}}</div>
				</div>
			</div>

			<div class="footer-item footer-item-home" @click="tabgopage('/mine')" style="flex-basis: 20%;">
				<div><img class="footer-item-icon"
						src="https://g1.cfvn66.com/web/mobile/backend-image/theme/10/link-icon/mcenter.png?v=35">
					<div class="footer-item-title" v-if="getlanguage != 'en_us'">{{$t('我的')}}</div>
				</div>
			</div>

		</div>


		<div class="a2hs-ios-container" v-show="showdesk">

			<div class="text-hint">
				<span>请先点击</span>
				<span class="download-icon"></span>
				<span>添加到主屏幕</span>
			</div>

			<span class="btn-divider"></span>

			<span class="btn-close" @click="setshowdesk">关闭</span>

		</div>
		

	</div>


</template>

<script>
	
	// import RedBall from './RedBall.vue';
	import QuickGames from './QuickGames.vue';
	export default {
		name: "foot",
		data() {
			return {
				index: 0,
				showdesk: true,
				showbaoxaing: false
			}
		},
		components: {
			// RedBall,
			QuickGames,
		},
		
		computed:{
		    userinfo(){
		        return this.$store.state.userInfo
		    },
		    //内容高度
		    isLogin(){
		        return this.$store.state.token;
		    },
			getlanguage() {
				return localStorage.getItem('local');
			},
		},
		
		created() {

			let that = this
			var showdesks = false;//localStorage.getItem('showdesk');
			// console.log(showdesks);
			// if (showdesks == 'false') {
				that.showdesk = false;
			// } else {
			// 	that.showdesk = true;
			// }

		},
		methods: {
			
			setshowdesk() {
				
				this.showdesk = false;
				localStorage.setItem('showdesk', false);
				
			},
			
			tabgopage(url) {
				
				let that = this;

				if (url == this.$route.fullPath) {
					return;
				}

				if (url == '/mine' || url == '/promotion') {
					
					if (!that.$store.state.token) {
						this.$router.push({
							path: '/login'
						});
					}
					
				}

				this.$router.push({
					path: url
				});
				
			},

		},
		mounted() {
			let that = this
		},
		updated() {
			let that = this
		},
		beforeDestroy() {},
		
	}
</script>

<style lang="scss" scoped>
	
	.app-footer-wrap {
		position: fixed;
		z-index: 500;
		text-align: center;
		width: 100%;
		bottom: 0px;
	}


	.app-footer-wrap .app-footer-links {
		display: flex;
		box-sizing: border-box;
		height: 3.375rem;
		align-items: center;
	}


	.app-footer-wrap .app-footer-links {
		border-top: 1px solid #fff;
		border-radius: .625rem .625rem 0 0;
		background-color: #f2f4f8;
		box-shadow: 0 -.125rem .375rem rgba(75, 106, 184, .18);
	}

	.app-footer-wrap .app-footer-links {
		position: relative;
		height: 2.875rem;
	}


	.app-footer-wrap .footer-item {
		color: #4a5c95;
	}

	.app-footer-wrap .footer-item {
		flex: 1 1 auto;
		overflow: hidden;
		text-decoration: none;
	}

	.app-footer-wrap .footer-item .footer-item-icon {
		width: 1.5rem;
		height: 1.5rem;
	}

	.app-footer-wrap .footer-item-icon {
		display: block;
		margin: 0 auto;
		width: 1.75rem;
		height: 1.75rem;
	}

	.app-footer-wrap .footer-item-title {
		margin-top: .125rem;
		overflow: hidden;
		font-size: .75rem;
		text-overflow: ellipsis;
		white-space: nowrap;
	}



	.footer-wrap-box {
		height: 2.875rem;
	}

	.footer-wrap {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 4;
		width: 100%;
		text-align: center;
		background-color: #f2f4f8;
		border: 0;
		border-top: 1px solid #fff;
		border-radius: 10px 10px 0 0;
		height: 2.875rem;
		box-shadow: 0 -2px 6px 0 rgba(75, 106, 184, 0.18);
		box-sizing: border-box;

		&::before {
			content: "";
			display: table;
		}

		.footer-link {
			position: relative;
			float: left;
			padding-top: 0.125rem;
			line-height: 0.9375rem;
			width: 20%;
			color: #4a5c95;
			font-size: 0.7rem;

			.footer-icon {
				display: block;
				width: 24px;
				height: 24px;
				margin: 0 auto;

				.footer-name {
					position: relative;
					z-index: 1;
				}
			}
		}

		&::after {
			clear: both;
			content: "";
			display: table;
		}
	}

	.a2hs-ios-container {
		position: fixed;
		z-index: 500;
		top: auto;
		bottom: .6875rem;
		left: 50%;
		box-sizing: border-box;
		margin-left: -7.5625rem;
		border: 1px solid #aaa;
		border-radius: .375rem;
		padding: .875rem .9375rem;
		width: 15.125rem;
		line-height: .9375rem;
		background: #fff;
		color: #585858;
		font-size: .875rem;
		font-weight: 700;
		text-align: center;
		box-shadow: 0 0 .625rem rgba(128, 128, 128, .5)
	}

	.a2hs-ios-container:after {
		content: "";
		position: absolute;
		bottom: -.3125rem;
		left: 50%;
		margin-left: -.6875rem;
		width: .6875rem;
		height: .6875rem;
		background: #fff;
		box-shadow: 1px 1px #aaa;
		transform: scaleX(.9) rotate(45deg)
	}

	.a2hs-ios-container .btn-divider {
		display: inline-block;
		vertical-align: middle;
		margin: 0 .5625rem;
		width: .125rem;
		height: 1.0625rem;
		background: #d4d4d4
	}

	.a2hs-ios-container .btn-close {
		display: inline-block;
		vertical-align: middle;
		width: 2.8125rem;
		max-width: 1.875rem;
		color: #979797
	}

	.a2hs-ios-container .text-hint {
		display: inline-block;
		vertical-align: middle;
		max-width: 10rem;
		text-align: left
	}

	.a2hs-ios-container .text-hint span {
		display: inline-block;
		vertical-align: middle
	}

	.a2hs-ios-container .text-hint .download-icon {
		margin: 0 1px;
		width: 1.0625rem;
		height: 1.0625rem;
		background: url(https://g1.cfvn66.com/web/mobile/build/asset/DmEaQD34.png) 0 0 no-repeat;
		background-size: contain
	}



	// 百宝箱
	.ele-draggable {
		position: fixed;
		z-index: 500;
	}

	.app-floaticon {
		bottom: 3.75rem;
		right: 0;
	}

	.app-floaticon.is-expand {
		height: 13.875rem;
	}

	.app-floaticon-more {
		width: 6.375rem;
		height: 5.4375rem;
	}

	.app-floaticon.is-expand .floaticon-bg {
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -2.6875rem;
		border-radius: 1.0625rem 1.0625rem 0 0;
		width: 5.375rem;
		height: 13.125rem;
		background: rgba(51, 51, 51, .7);
	}

	.app-floaticon.is-expand .btn-fold {
		position: relative;
		display: block;
		margin: 0 auto;
		width: 1.6875rem;
		height: 1.6875rem;
	}

	.app-floaticon .btn-close {
		position: absolute;
		bottom: -1.25rem;
		left: 50%;
		margin-left: -.625rem;
		width: 1.25rem;
		height: 1.25rem;
	}

	.app-floaticon-more .award-icon {
		display: block;
		width: 6.375rem;
		height: 5.4375rem;
	}


	.app-floaticon.is-expand .award-icon {
		position: relative;
		margin: .25rem auto 0;
		width: 4.8125rem;
		height: 4.8125rem;
	}

	.app-floaticon.is-expand .award-icon.btn-treasure {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 6.375rem;
		height: 1.5625rem;
	}


	// 百宝箱

	// 红包

	.app-floaticon-single,
	.app-floaticon-single .award-icon {
		width: 7.5rem;
		height: 7.5rem;
	}

	// 红包
</style>