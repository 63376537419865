<template>
	<div class="password">
		<BankHeaderVue title="登入密码" :isSHow="false" />
		<MineHeader />
		<div class="member-data-container clearfix">
			<div class="member-children-wrap">
				<div class="login-pw-wrap">

					<form>

						<div class="last-login-time-text">{{$t('上次变更时间')}} : {{$base.formatDate(userinfo.updated_at)}}
						</div>

						<div class="field-wrap">

							<div class="input-pw is-oldpw">

								<span class="title">{{$t('旧密码')}}</span>

								<input type="password" v-model="oldpassword" :placeholder="$t('请输入')" />

								<span class="field-icon"></span>

							</div>

						</div>

						<div class="field-wrap">

							<div class="input-pw is-newpw">

								<span class="title">{{$t('新密码')}}</span>

								<input type="password" v-model="password" :placeholder="$t('请输入')" maxlength="12" />

								<span class="field-icon is-hide"></span>

							</div>

							<!-- <div class="rule-item-wrap is-hide">
								<div class="rule-tip-title">您的密码必须包含：</div>
								<div class="rule-item"><i class="rule-item-icon"></i>至少 6~12码</div>
								<div class="rule-item"><i class="rule-item-icon"></i>至少 一个 A~Z大写字母</div>
								<div class="rule-item"><i class="rule-item-icon"></i>至少 一个 a~z小写字母</div>
								<div class="rule-item"><i class="rule-item-icon"></i>至少 一个 0~9数字</div>
								<div class="rule-item">新密码不可与原密码相同</div>
							</div> -->


						</div>

						<div class="field-wrap">

							<div class="input-pw is-confirmpw">

								<span class="title">{{$t('确认新密码')}}</span>

								<input type="password" v-model="password_confirmation" placeholder="请输入"
									maxlength="12" />

								<span class="field-icon is-hide"></span>

							</div>

						</div>

						<div class="login-btn-wrap">

							<button class="reset-btn" type="button" @click="resetForm">{{$t('取消')}}</button>

							<button class="submit-btn" type="submit" :disabled="isDisabled"
								@click="setpassword">{{$t('确认')}}</button>

						</div>

					</form>

				</div>
			</div>
		</div>
		
		
		<!-- 公共弹窗 -->
		<GlobalModal ref="globalmodal"></GlobalModal>
		
		<Feedback showtype="2" />
		
		<!-- <QuickGames></QuickGames> -->
		
	</div>
</template>
<script>
	import BankHeaderVue from "./libs/BankHeader.vue"
	import MineHeader from "./libs/MineHeader.vue"
	import QuickGames from "./libs/QuickGames.vue"
	import Feedback from "./libs/Feedback.vue"
	export default {
		name: "password",
		data() {
			return {
				oldpassword: '',
				password: '',
				password_confirmation: ''
			}
		},
		components: {
			BankHeaderVue,
			MineHeader,
			QuickGames,
			Feedback
		},
		computed: {
			userinfo() {
				return this.$store.state.userInfo
			},
			//内容高度
			isLogin() {
				return this.$store.state.token;
			},
			isDisabled() {
				// 确保三个字段都已填写并且新密码和确认密码相同
				return !this.oldpassword || !this.password || !this.password_confirmation || this
					.password !== this.password_confirmation;
			}
		},
		created() {
			let that = this
		},
		methods: {
			resetForm() {
				// 清空表单
				this.oldpassword = '';
				this.password = '';
				this.password_confirmation = '';
			},
			setpassword() {
				let that = this;

				// if (!that.oldpassword) {

				// 	that.$refs.globalmodal.open('讯息', '请输入密码!!', '确定'); // 传入标题和消息
				// 	return;

				// }
				// if (!that.password) {

				// 	that.$refs.globalmodal.open('讯息', '请输入密码!!', '确定'); // 传入标题和消息
				// 	return;

				// }
				// if (!that.password_confirmation) {

				// 	that.$refs.globalmodal.open('讯息', '请输入密码!!', '确定'); // 传入标题和消息
				// 	return;

				// }


				var params = {
					oldpassword: that.oldpassword,
					password: that.password,
					password_confirmation: that.password_confirmation
				};

				that.$parent.showLoading();

				that.$apiFun.post('/api/member/password/modify', params).then((res) => {

					if (res.code !== 200) {

						that.$refs.globalmodal.open('讯息', res.message, '确定'); // 传入标题和消息
						return;

					}

					if (res.code === 200) {

						that.$refs.globalmodal.open('讯息', res.message, '确定'); // 传入标题和消息
						return;

						// localStorage.setItem("token", res.data.access_token)

						// that.$store.commit("changToken",res.data.access_token)

						// that.$parent.getUserInfo() //获取用户信息

						// that.$parent.goNav("/")


					}

					that.$parent.hideLoading()

				})
			}
		},
		mounted() {
			let that = this
		},
		updated() {
			let that = this
		},
	}
</script>

<style lang="scss" scoped>
	.password {
		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				clear: both;
				content: "";
				display: table;
			}
		}

		.member-children-wrap {
			padding-left: 10px;
			padding-right: 10px;
		}

		.login-pw-wrap {
			margin: 0 auto;
			padding: 1.563rem 0;
			max-width: 18.75rem;

			.field-wrap {
				margin-bottom: 1.6rem;
				background: #fff;
			}

			.input-pw {
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				border: 1px solid #ccc;
				font-size: 0.938rem;
				padding: 0.25rem 0;
				color: #999;
				position: relative;

				.title {
					width: 32%;
					border-right: 1px solid #999;
					text-align: center;
					text-align: left;
					padding: 0.5rem 0 0.5rem 0.625rem;
				}

				.title,
				input {
					display: inline-block;
					box-sizing: border-box;
					vertical-align: middle;
				}

				input {
					min-height: 2.625rem;
					width: calc(68% - 31px);
					border: none;
					outline: none;
					text-indent: 1.625rem;
				}
			}

			.field-icon {
				position: absolute;
				top: 50%;
				right: 5px;
				display: block;
				width: 26px;
				height: 26px;
				cursor: pointer;
				margin-top: -13px;
				background: url("/static/image/icon_eye.png") 50% 50% no-repeat;
				background-size: cover;
			}

			.field-icon.is-hide {
				background: url("/static/image/icon_noeye.png") 50% 50% no-repeat;
				background-size: cover;
			}

			.rule-item-wrap {
				background-color: #eee;
				padding: 1.25rem 0.875rem;
			}

			.rule-tip-title {
				font-size: 0.938rem;
				line-height: 20px;
				color: #a0a0a0;
				margin-bottom: 3px;
			}

			.rule-item {
				line-height: 1.1875rem;
				font-size: 0.875rem;
				color: #a0a0a0;

				i {
					display: inline-block;
					width: 12px;
					height: 12px;
					background: url("/static/image/member_password_tip_icon.png") 50% -12px no-repeat;
					margin-right: 6px;
				}
			}

			.login-btn-wrap {
				text-align: center;
			}

			.reset-btn {
				background-color: #59bafc;
				padding: 0;
				border: none;
				min-width: 7rem;
				height: 2.5rem;
				line-height: 2.5rem;
				border-radius: 2.5rem;
				font-size: 0.938rem;
				color: #fff;
				text-align: center;
				cursor: pointer;
				margin: 0 0.25rem;
				outline: none;
			}

			.submit-btn {
				background-color: #ccc;
				padding: 0;
				border: none;
				min-width: 7rem;
				height: 2.5rem;
				line-height: 2.5rem;
				border-radius: 2.5rem;
				font-size: 0.938rem;
				color: #fff;
				text-align: center;
				cursor: pointer;
				margin: 0 0.25rem;
				outline: none;
				cursor: default;
			}
			
		}

		.last-login-time-text {
			color: #999;
			line-height: 1.5rem;
			font-size: 0.938rem;
			margin-bottom: 0.875rem;
		}
		
	}
	
	.app-gamebar {
	    z-index: auto;
	    bottom: 0;
	    padding-bottom: 0rem;
	}
</style>
