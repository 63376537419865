<template>
	
	<div>
		
		<div v-if="!showredball && showredbally" class="ele-draggable app-floaticon app-floaticon-single app-floaticon-1" style="z-index: 9999;">
			
			<img class="btn-close" @click="setredbally"
				src="https://g1.cfvn66.com/web/mobile/backend-image/float-icon/btn_hide.png?v=35">
			
			<img @click="showredball = true"
				class="award-icon"
				src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/lang/zh-cn/btn_draw.png">
		</div>
		
		
		<div class="redpack-list-wrap" v-if="showredball">
			<div class="list-block">
				
				<span class="close-btn" @click="setredball">
					
					<svg class="svg-inline--fa fa-xmark fa-w-10" aria-hidden="true"
						data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 320 512">
						<path class="" fill="currentColor"
							d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z">
						</path>
					</svg></span><span class="img-ingots"></span>
				<div class="list-content">
					<div class="list-item immediate"><img class="list-item-bg"
							src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/list-wrap/btn_list_floor_r.png"><img
							class="list-item-bg"
							src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/list-wrap/btn_list_floor_g.png"
							style="display: none;">
						<div class="item-block">
							<div class="item-header"><span class="item-label">限时</span><span class="item-date">2024/09/17
									起</span></div>
							<div class="item-centent"><span class="item-title">中秋月圆 礼遇丰盈 红包送福 共庆团圆</span><span
									class="item-btn"><img class="item-btn-bg"
										src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/list-wrap/btn_list_Label_r.png"><span
										class="item-btn-block">立即抢</span></span></div>
						</div>
					</div>
					<div class="list-item"><img class="list-item-bg"
							src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/list-wrap/btn_list_floor_g.png">
						<div class="item-block">
							<div class="item-header"><span class="item-date">2024/09/18 起</span></div>
							<div class="item-centent"><span class="item-title">中秋月圆 礼遇丰盈 中秋博饼 玩赚奖赏</span><span
									class="item-btn"><img class="item-btn-bg"
										src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/list-wrap/btn_list_Label_g.png"><span
										class="item-btn-block">立即抢</span></span></div>
						</div>
					</div>
					<div class="list-item"><img class="list-item-bg"
							src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/list-wrap/btn_list_floor_g.png">
						<div class="item-block">
							<div class="item-header"><span class="item-date">2024/08/28 起</span></div>
							<div class="item-centent"><span class="item-title">澳门娱乐场 2024 恋爱津贴 让恋爱成本更轻松</span><span
									class="item-btn"><img class="item-btn-bg"
										src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/list-wrap/btn_list_Label_g.png"><span
										class="item-btn-block">立即抢</span></span></div>
						</div>
					</div>
					<div class="list-item"><img class="list-item-bg"
							src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/list-wrap/btn_list_floor_g.png">
						<div class="item-block">
							<div class="item-header"><span class="item-date">2024/03/17 起</span></div>
							<div class="item-centent"><span class="item-title">亿元红包天天抢</span><span class="item-btn"><img
										class="item-btn-bg"
										src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/list-wrap/btn_list_Label_g.png"><span
										class="item-btn-block">立即抢</span></span></div>
						</div>
					</div>
				</div><span class="more-btn show"></span><span class="img-cat"><img class="img-cat-bg"
						src="https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/list-wrap/image_cat.png"></span>
			</div>
		</div>
		
	</div>




</template>

<script>
	export default {
		name: 'quickgames',
		data() {
			return {
				isVisible: false,
				title: '', // 用于存储传入的标题
				message: '', // 用于存储传入的消息
				confirm: '', // 用于存储传入的消息
				cancel: '', // 用于存储传入的消息
				prompt: false,
				showredball: false,
				showbaoxaing: false,
				// showredbally: localStorage.getItem('showredbally') ? false : true
				showredbally: false
			};
		},
		created() {
			
		},
		methods: {

			setredball() {
				this.showredball = !this.showredball;
			},
			setredbally(){
				this.showredbally = false;
				localStorage.setItem('showredbally', false);
			},
			setshowdesk() {
				this.showdesk = false;
				localStorage.setItem('showdesk', false);
			},
			open(title = 'Default Title', message = 'Default Message', confirm = 'confirm', prompt = false, cancel = '') {
				this.title = title;
				this.message = message;
				this.confirm = confirm;
				this.prompt = prompt;
				this.cancel = cancel;
				this.isVisible = true; // 打开弹窗
			},
			close() {
				this.isVisible = false; // 关闭弹窗
			}
		}
	};
</script>

<style lang="scss" scoped>
	
	// [lang=zh_cn] .redpack-list-wrap {
	//     font-family: STHeiti, Microsoft YaHei, 微软雅黑, SimSun, 宋体, arial, sans-serif;
	// }
	
	
	.ele-draggable {
		position: fixed;
		z-index: 500;
	}
	
	.app-floaticon {
	    bottom: 3.75rem;
	    right: 0;
	}
	
	.app-floaticon-single, .app-floaticon-single .award-icon {
	    width: 7.5rem;
	    height: 7.5rem;
	}
	
	
	.app-floaticon .btn-close {
	    position: absolute;
	    bottom: -1.25rem;
	    left: 50%;
	    margin-left: -.625rem;
	    width: 1.25rem;
	    height: 1.25rem;
	}
	
	.app-floaticon-single, .app-floaticon-single .award-icon {
	    width: 7.5rem;
	    height: 7.5rem;
	}
	
	
	
	.redpack-list-wrap {
		position: fixed;
		z-index: 800;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .8);
		font-size: 16px;
		font-family: STHeiti, Microsoft YaHei, 微软雅黑, SimSun, 宋体, arial, sans-serif;
	}

	.redpack-list-wrap .list-block {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		border: .125rem #e0cb8d solid;
		border-radius: .9375rem;
		padding-top: 9%;
		width: 94%;
		height: 65%;
		background: #fcfcfc;
		background: linear-gradient(to bottom, #fcfcfc, #f5eac9);
	}

	.redpack-list-wrap .close-btn {
		position: absolute;
		z-index: 2;
		bottom: -40px;
		left: 50%;
		margin-left: -15px;
		border: 1px solid #fff;
		border-radius: .9375rem;
		width: 28px;
		height: 28px;
		line-height: 28px;
		color: #fff;
		font-size: 20px;
		text-align: center;
		cursor: pointer;
	}

	.redpack-list-wrap .img-ingots {
		position: absolute;
		top: -24.5px;
		right: 14.5px;
		width: 51px;
		height: 45px;
		background: url(https://g1.cfvn66.com/web/static/image/element/float-redpacket/mobile/list-wrap/image_ingots.png) 0 0 no-repeat;
	}

	.redpack-list-wrap .list-content {
		height: 77%;
		overflow-y: auto;
	}

	.list-item {
		position: relative;
		margin: 0 auto 2%;
		width: 94%;
	}

	.list-item .item-block {
		position: absolute;
		top: 0;
		left: 0;
		padding-top: 6%;
		width: 100%;
		height: 72%;
	}

	.list-item .item-header {
		position: absolute;
		top: 0;
		width: 100%;
		height: 25%;
	}
	
	.list-item .list-item-bg {
	    display: block;
	    width: 100%;
	}
	
	
	.list-item .item-label {
	    position: absolute;
	    top: 0;
	    left: 5%;
	    display: flex;
	    vertical-align: top;
	    border-radius: .3125rem;
	    justify-content: center;
	    align-items: center;
	    min-width: 22%;
	    height: 100%;
	    overflow: hidden;
	    background-color: #9d2206;
	    color: #fff;
	    font-size: 75%;
	    text-align: center;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	}

	.list-item .item-date {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		padding-top: 1%;
		width: 42%;
		height: 100%;
		color: #fff;
		font-size: 90%;
		font-weight: 700;
	}

	.list-item .item-centent {
		display: flex;
		height: 100%;
		align-items: center;
	}

	.list-item .item-title {
		display: -webkit-box;
		margin-left: 11.5%;
		width: 50%;
		overflow: hidden;
		color: #9d2206;
		font-size: 100%;
		font-weight: 700;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	[lang=zh_hk] .item-label,
	[lang=zh_cn] .item-label,
	[lang=zh_hk] .item-btn,
	[lang=zh_cn] .item-btn {
		font-size: 100%;
	}

	.list-item .item-btn {
		position: relative;
		margin: 0 2%;
		width: 27%;
		overflow: hidden;
		color: #fff;
		font-size: 75%;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;
	}

	.list-item .item-btn .item-btn-bg {
		display: block;
		width: 100%;
	}

	.list-item .item-btn .item-btn-block {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
	}
	
	.redpack-list-wrap .more-btn {
	    display: block;
	    margin-top: .625rem;
	    height: 1.125rem;
	}
	.redpack-list-wrap .img-cat {
	    position: absolute;
	    bottom: -1.875rem;
	    right: .625rem;
	    width: 48.8%;
	}
	
	.redpack-list-wrap .img-cat .img-cat-bg {
	    width: 100%;
	}
	
</style>