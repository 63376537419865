<template>
	<div class="gameNews">
		<BankHeaderVue title="游戏公告" :isSHow="false" />
		<MineHeader />
		<div class="gamenews-page-wrap">
			<div class="m-gn-nav">
				<div><span :class="[tabIndex == 0 ? 'current': '']" @click="changeTab(0)">BB视讯</span><span
						:class="[tabIndex == 1 ? 'current': '']" @click="changeTab(1)">BB电子</span><span
						:class="[tabIndex == 2 ? 'current': '']" @click="changeTab(2)">BB彩票</span></div>
			</div>
			<div class="m-gn-wrap">
				<div class="bet-info-wrap live-info clearfix" v-show="tabIndex == 0">
					<div class="top-select clearfix">
						<select class="select-date">
							<option value="2024-08-08">2024-08-08</option>
							<option value="2024-08-07">2024-08-07</option>
							<option value="2024-08-06">2024-08-06</option>
						</select><select class="select-live">
							<option value="">全部</option>
							<option value="3001">百家乐</option>
							<option value="3003">龙虎斗</option>
							<option value="3007">轮盘</option>
							<option value="3008">骰宝</option>
							<option value="3011">色碟</option>
							<option value="3012">牛牛</option>
							<option value="3015">番摊</option>
							<option value="3023">抢庄牛牛</option>
							<option value="3025">区块链百家乐</option>
						</select>
					</div>
					<table>
						<thead>
							<tr>
								<th>日期</th>
								<th>内容</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="td-head">2024-08-08 08:00:36</td>
								<td class="td-body">敬请客户留意: 因例行性维护，本游戏将会更换新的游戏赌具并开始新局，不便之处敬请见谅。</td>
							</tr>
							<tr>
								<td class="td-head">2024-08-08 04:58:01</td>
								<td class="td-body">敬请客户留意: 香港时间 2024/08/08 桌号: 骰宝 A 局号: 551172364
									因该局翘(叠)骰,造成无法辨识,本局将会重新进行摇骰,不便之处敬请见谅.</td>
							</tr>
							<tr>
								<td class="td-head">2024-08-08 04:24:19</td>
								<td class="td-body">敬请客户留意!! 【 香港时间 2024-08-14 06:00:00~2024-08-14 09:00:00 游戏： 百家乐 桌号：
									AS7 】因系统优化将进行维护，不便之处敬请见谅。</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bet-info-wrap casino-info clearfix" v-show="tabIndex == 1">
					<div class="top-select clearfix">
						<select class="select-date">
							<option value="2024-08-08">2024-08-08</option>
							<option value="2024-08-07">2024-08-07</option>
							<option value="2024-08-06">2024-08-06</option>
							<option value="2024-08-05">2024-08-05</option>
							<option value="2024-08-04">2024-08-04</option>
							<option value="2024-08-03">2024-08-03</option>
							<option value="2024-08-02">2024-08-02</option>
						</select>
					</div>
					<div class="no-data-wrap">
						<img src="/static/image/bg_no_announcement.png" alt="" />无游戏公告
					</div>
				</div>
				<div class="bet-info-wrap lottery-info clearfix" v-show="tabIndex == 2">
					<div class="top-select clearfix">
						<select class="select-date">
							<option value="2024-08-08">2024-08-08</option>
							<option value="2024-08-07">2024-08-07</option>
							<option value="2024-08-06">2024-08-06</option>
							<option value="2024-08-05">2024-08-05</option>
							<option value="2024-08-04">2024-08-04</option>
							<option value="2024-08-03">2024-08-03</option>
							<option value="2024-08-02">2024-08-02</option>
						</select>
					</div>
					<div class="no-data-wrap">
						<img src="/static/image/bg_no_announcement.png" alt="" />无游戏公告
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import BankHeaderVue from "./libs/BankHeader.vue"
	import MineHeader from "./libs/MineHeader.vue"
	export default {
		name: "gameNews",
		data() {
			return {
				tabIndex: 0
			}
		},
		components: {
			BankHeaderVue,
			MineHeader,
		},
		methods: {
			changeTab(index) {
				this.tabIndex = index
			}
		}
	}
</script>
<style lang="scss" scoped>
	.gameNews {
		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				clear: both;
				content: "";
				display: table;
			}
		}

		.gamenews-page-wrap {
			.m-gn-nav {
				margin-bottom: 1rem;
				width: 100%;
				border-bottom: 2px solid #eaeaea;
				overflow-x: auto;
				font-size: 14px;
				white-space: nowrap;

				div {
					height: 2.625rem;
				}

				span {
					display: inline-block;
					border-bottom: 2px solid transparent;
					padding: 0.625rem;
				}

				span.current {
					color: #0089f5;
					border-bottom: 2px solid #0089f5;
				}
			}

			.m-gn-wrap {
				padding: 0 0.625rem 2rem;
			}

			.top-select {
				margin-bottom: 0.625rem;

				select {
					width: 100%;
					float: left;
					line-height: 3.125rem;
					height: 3.125rem;
					margin-bottom: 0.625rem;
					font-size: 0.938rem;
					border: 1px solid #ccc;
					border-radius: 4px;
					color: #999;
					outline: none;

					&:nth-child(1n + 1) {
						margin-left: 0;
						margin-right: -100%;
						clear: none;
					}
				}
			}

			.live-info .top-select select {
				width: 49.15254%;
				float: left;

				&:nth-child(odd) {
					margin-right: -100%;
					clear: both;
					margin-left: 0;
				}

				&:nth-child(2n + 2) {
					margin-left: 50.84746%;
					margin-right: -100%;
					clear: none;
				}
			}

			table {
				margin-bottom: 24px;
				font-size: 0.938rem;
				width: 100%;
				border-collapse: collapse;
				border-spacing: 0;

				thead {
					display: none;
					background-color: #ccc;
					color: #fff;
					line-height: 3.125rem;
				}

				td,
				th {
					padding: 0;
				}

				tbody {
					border: 1px solid #eee;
				}

				td {
					display: block;
					padding: 0.625rem 4%;
				}

				td.td-head {
					line-height: 1.25rem;
					background-color: #ccc;
					color: #fff;
					white-space: nowrap;
				}

				td.td-body {
					color: #313f52;
					word-break: break-all;
				}
			}

			.no-data-wrap {
				margin-top: 3.125rem;
				font-size: 14px;
				text-align: center;

				img {
					display: block;
					margin: 0 auto 16px;
					width: 50%;
					max-width: 180px;
				}
			}
		}
	}
</style>