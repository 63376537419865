<template>

	<!-- <div class="paging-wrap">
		<span :class="['paging-btn paging-first', !canFirst ? 'disabled' : '']" @click="changePage(1)"></span><span
			:class="['paging-btn paging-previous', !canPrev ? 'disabled' : '']"
			@click="changePage(nowPage - 1)"></span><select v-model="nowPage">
			<option value="1">1</option>
			<option value="2">2</option>
		</select><span class="page-text"> /{{ totalPage }}</span><span
			:class="['paging-btn paging-next', !canNext ? 'disabled' : '']"
			@click="changePage(nowPage + 1)"></span>
			
			<span :class="['paging-btn paging-last', !canLast ? 'disabled' : '']" @click="changePage(totalPage)"></span>
	
	</div> -->

	<div class="user-msg-paging-bg">

		<div class="paging-wrap ">

			<span class="paging-btn paging-first-disabled" @click="changePage(1)"></span>
			<span class="paging-btn paging-previous-disabled" @click="changePage(nowPage - 1)"></span>

			<select v-model="nowPage">
				
				<option v-for="item in totalPage" :key="item" :value="item">
					{{ item }}
				</option>
				
			</select>

			<span class="page-text">/{{totalPage}}</span>

			<span class="paging-btn paging-next-disabled" @click="changePage(nowPage + 1)"></span>
			<span class="paging-btn paging-last-disabled" @click="changePage(1)"></span>
		</div>

	</div>


</template>

<script>
	export default {
		name: "PageFooter",
		props: {
			totalPage: {
				type: Number,
				default: 1,
			},
		},
		data() {
			return {
				nowPage: 1,
				canFirst: false,
				canPrev: false,
				canNext: false,
				canLast: false,
			}
		},
		watch: {
			nowPage(newValue) {
				this.pageUpdate(newValue)
			},
		},
		created() {
			this.pageUpdate(this.nowPage)
		},
		methods: {
			pageUpdate(newValue) {
				if (newValue > 1) {
					this.canFirst = true
					this.canPrev = true
				} else {
					this.canFirst = false
					this.canPrev = false
				}
				if (newValue >= this.totalPage) {
					this.canNext = false
					this.canLast = false
				} else {
					this.canNext = true
					this.canLast = true
				}
			},
			changePage(page) {
				if (page < 1 || page > this.totalPage) return
				this.nowPage = page
			},
		},
	}
</script>

<style lang="scss" scoped>
	.user-msg-paging-bg {

		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: #fff;
		box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, .05);
		padding: 1rem 0;

	}

	.paging-wrap {
		text-align: center;
		margin-top: 1.5rem;
	}

	.user-msg-paging-bg .paging-wrap {
		margin-top: 0;
	}


	.paging-wrap .paging-btn {
		display: inline-block;
		margin: 0 6px;
		width: 42px;
		height: 42px;
		border-radius: 4px;
		background: url("/static/image/member_page_arrow.png") 0 0 no-repeat;
		border: 1px solid #ccc;
		vertical-align: middle;
	}

	.paging-wrap .paging-first-disabled {
		background-position: -120px -40px;
		border: 1px solid #f0f0f0;
	}

	.paging-wrap .paging-previous-disabled {
		background-position: -80px -40px;
		border: 1px solid #f0f0f0;
	}

	.mobile-wrap .mobile-content-wrap .paging-wrap .paging-previous-disabled {
		background-position: -83px -42px;
	}


	.mobile-wrap .mobile-content-wrap .paging-wrap .paging-btn {
		width: 36px;
		height: 36px;
	}

	.mobile-wrap .mobile-content-wrap .paging-wrap .paging-first-disabled {
		background-position: -123px -42px;
	}


	.paging-wrap .page-text,
	.paging-wrap select {
		display: inline-block;
		height: 40px;
		line-height: 40px;
		color: #999;
		font-size: 15px;
		vertical-align: middle;
	}

	.paging-wrap select {
		margin-left: 10px;
		min-width: 70px;
		border-radius: 4px;
		border: 1px solid #ccc;
	}

	.mobile-wrap .mobile-content-wrap .paging-wrap select {
		min-width: 50px;
		height: 38px;
		line-height: 38px;
	}

	.paging-wrap option {
		background-color: #eee;
	}

	.paging-wrap .page-text,
	.paging-wrap select {
		display: inline-block;
		height: 40px;
		line-height: 40px;
		color: #999;
		font-size: 15px;
		vertical-align: middle;
	}

	.paging-wrap .page-text {
		margin: 0 10px 0 8px;
	}

	.paging-wrap .paging-next-disabled {
		background-position: 0 -40px;
		border: 1px solid #f0f0f0;
	}

	.mobile-wrap .mobile-content-wrap .paging-wrap .paging-next-disabled {
		background-position: 0 -42px;
	}

	.paging-wrap .paging-last-disabled {
		background-position: -40px -40px;
		border: 1px solid #f0f0f0;
	}

	.mobile-wrap .mobile-content-wrap .paging-wrap .paging-last-disabled {
		background-position: -40px -42px;
	}
</style>