<template>


	<!-- <div v-if="isVisible" class="popmsg-dialogbox"> -->
	<div class="app-gamebar" :class="{'is-open':showkuaixuan}">
		<div class="app-gamebar-toggle">
			<span class="btn-open" @click="setshowkuaixuan">游戏快选</span>
		</div>
		<div class="app-gamebar-body">
			<div class="app-gamebar-menu">


				<!-- <svg class="svg-inline--fa fa-star fa-w-18"
						aria-hidden="true" data-prefix="fas" data-icon="star" role="img"
						xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
						<path class="" fill="currentColor"
							d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z">
						</path>
					</svg> -->

				<div class="menu-item active"><span class="star-icon"></span> 大奖游戏</div>
				<div class="menu-item"><span class="star-icon"></span> BB大奖</div>
				<div class="menu-item"><span class="star-icon"></span> PG大奖</div>
				<div class="menu-item"><span class="star-icon"></span> JDB大奖</div>
				<div class="menu-item"><span class="star-icon"></span> 大满贯</div>
				<div class="menu-item"><span class="star-icon"></span> CQ9大奖</div>
				<div class="menu-item"><span class="star-icon"></span> MG大奖</div>


			</div>
			<div class="app-gamebar-list">

				<div class="gamebar-games">

					<div class="game-entry" favupdatemodule="all">
						<div class="game-entry-inner"><img
								data-src="https://g1.cfvn66.com/game_picture/mobile/58/58045.png?v=1726708303"
								class="game-entry-image"
								src="https://g1.cfvn66.com/game_picture/mobile/58/58045.png?v=1726708303"
								data-loaded="true"><span class="game-entry-nickname">PG</span></div>
						<div class="game-entry-name">麻将胡了</div>
					</div>

				</div>

			</div>
		</div>
	</div>


</template>

<script>
	export default {
		name: 'quickgames',
		data() {
			return {
				isVisible: false,
				title: '', // 用于存储传入的标题
				message: '', // 用于存储传入的消息
				confirm: '', // 用于存储传入的消息
				cancel: '', // 用于存储传入的消息
				prompt: false,
				showkuaixuan: false,
				showbaoxaing: false
			};
		},
		methods: {

			setshowkuaixuan() {
				this.showkuaixuan = !this.showkuaixuan;
			},
			setshowdesk() {
				this.showdesk = false;
				localStorage.setItem('showdesk', false);
			},
			open(title = 'Default Title', message = 'Default Message', confirm = 'confirm', prompt = false, cancel = '') {
				this.title = title;
				this.message = message;
				this.confirm = confirm;
				this.prompt = prompt;
				this.cancel = cancel;
				this.isVisible = true; // 打开弹窗
			},
			close() {
				this.isVisible = false; // 关闭弹窗
			}
		}
	};
</script>

<style lang="scss" scoped>
	.app-gamebar {
		position: absolute;
		z-index: 500;
		bottom: 100%;
		left: 0;
		width: 100%;
		font-size: .75rem;
	}


	.app-gamebar {
		z-index: auto;
		bottom: 0;
		padding-bottom: 2.875rem;
	}

	.app-gamebar.is-open {
		background: #1b1d1b;
	}

	.app-gamebar-toggle {
		text-align: center;
	}

	.app-gamebar .app-gamebar-toggle {
		position: absolute;
		top: 0;
		left: 50%;
		// border-radius: .1875rem .1875rem 0 0;
		padding: .1875rem;
		line-height: 1rem;
		transform: translate(-50%, -100%);

		border: 1px solid #fff;
		border-bottom: 0;
		border-radius: .5rem .5rem 0 0;
		background: linear-gradient(#ffc700, #dc9800);
		color: #fff;
	}

	.app-gamebar .btn-open {
		padding-right: 0.3rem;
		padding-left: 0.3rem;
	}


	.app-gamebar-body {
		color: #fff;
	}

	.app-gamebar.is-open .app-gamebar-body {
		height: 13.3125rem;
	}

	.app-gamebar .app-gamebar-body {
		height: 0;
		overflow: hidden;
		transition: height .3s ease-in;
	}

	.app-gamebar-menu {
		display: flex;
		overflow-x: scroll;
		white-space: nowrap;
		align-items: stretch;
	}

	.app-gamebar-menu {
		border-top: 1px solid #fff;
		background: #000;
	}


	.app-gamebar-menu .menu-item {
		flex: 0 0 auto;
		padding: 0 .5rem;
		max-width: 7em;
		height: 1.875rem;
		line-height: 1.875rem;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.app-gamebar-menu .menu-item.active {
		background: #1b1d1b;
		color: #f2c925;
	}


	.app-gamebar-list {
		background-color: #1b1d1b;
	}

	.app-gamebar-list {
		box-sizing: border-box;
		padding: .5625rem .5rem;
		height: 11.4375rem;
	}

	.gamebar-games {
		display: grid;
		grid-template: repeat(auto-fill, 4.5rem) / repeat(auto-fill, 3.75rem);
		grid-auto-flow: column;
		gap: .625rem;
		height: 100%;
		overflow-x: scroll;
	}


	.gamebar-games.game-entry {
		padding: 0;
	}

	.game-entry {
		display: inline-block;
		vertical-align: top;
		padding-right: .625rem;
		width: 3.75rem;
	}

	.game-entry .game-entry-inner {
		position: relative;
		margin: 0 auto;
		width: 3.75rem;
		height: 3.75rem;
	}

	.gamebar-games .game-entry .game-entry-inner {
		width: 3.625rem;
		height: 3.625rem;
	}

	.game-entry .game-entry-inner .game-entry-image {
		display: block;
		width: 100%;
		height: 100%;
	}

	.game-entry .game-entry-nickname {
		position: absolute;
		bottom: .4375rem;
		left: 50%;
		margin-left: -1.5rem;
		border-radius: 6.25rem;
		width: 3rem;
		height: .6875rem;
		line-height: .6875rem;
		overflow: hidden;
		background-color: rgba(6, 1, 5, .6);
		color: #fff;
		font-size: .75rem;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.gamebar-games .game-entry .game-entry-nickname {
		bottom: .3125rem;
	}



	.app-gamebar-list .game-entry .game-entry-name {
		color: #999;
	}

	.gamebar-games .game-entry .game-entry-name {
		margin-top: .125rem;
		padding: 0;
	}

	.game-entry .game-entry-name {
		padding: 0 .3125rem;
		overflow: hidden;
		color: #989898;
		font-size: .75rem;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>