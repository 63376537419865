<template>
	<div>
		<!-- 当 type 为 0 时 -->
		<div class="ele-float-icon-wrap" v-if="showtype == 0">
			<div class="ele-float-icon ele-cash-record" @click="feedgopage('/')"></div>
			<div class="ele-float-icon ele-feedback" @click="feedgopage('/feedback')"></div>
		</div>

		<!-- 当 type 为 1 或 2 时 -->
		<div class="ele-float-icon-wrap" v-else-if="showtype == 1 || showtype == 2">
			<div class="ele-float-icon ele-cash-record" v-if="showtype == 1" @click="feedgopage('/')"></div>
			<div class="ele-float-icon ele-feedback" v-if="showtype == 2" @click="feedgopage('/feedback')"></div>
		</div>
		
	</div>
	
</template>

<script>
	export default {
		name: 'feedback',
		props: {
			showtype: {
				type: String, // 将类型设为 Number 而不是 String，因为你使用了数值判断
				default: 0 // 默认类型为 0
			}
		},
		methods: {
			feedgopage(url) {
				this.$router.push({
					path: url
				});
			}
		}
	};
</script>


<style lang="scss" scoped>
	.ele-float-icon-wrap {
		position: fixed;
		z-index: 400;
		right: 0;
		bottom: 30px;
	}

	.ele-float-icon-wrap .ele-float-icon.ele-cash-record {
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/btn_tag_dispatch_record.png) 0 0 no-repeat;
		background-size: contain;
	}

	.ele-float-icon-wrap .ele-float-icon {
		width: 47px;
		height: 42px;
		margin-bottom: 13px;
	}

	.ele-float-icon-wrap .ele-float-icon:last-child {
		margin-bottom: 0;
	}

	.ele-float-icon-wrap .ele-float-icon.ele-feedback {
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/btn_tag_feedback.png) 0 0 no-repeat;
		background-size: contain;
	}

	.ele-float-icon-wrap .ele-float-icon.ele-cash-record {
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/btn_tag_dispatch_record.png) 0 0 no-repeat;
		background-size: contain;
	}

	.ele-home-wrap .ele-float-icon-wrap .ele-float-icon {
		width: 47px;
		height: 42px;
		margin-bottom: 13px;
	}

	.ele-home-wrap .ele-float-icon-wrap .ele-float-icon:last-child {
		margin-bottom: 0;
	}

	.ele-home-wrap .ele-float-icon-wrap .ele-float-icon.ele-feedback {
		background: url(https://g1.cfvn66.com/web/static/image/hall/promotion/btn_tag_feedback.png) 0 0 no-repeat;
		background-size: contain;
	}
</style>