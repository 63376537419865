//引入刚才的http.js文件
import https from './http.js';

//设置个对象，就不用一个个暴露了，直接暴露对象
let apiFun = {};

/* 获取列表 */

apiFun.get = function(url, params) {
	params.lang = localStorage.getItem('local') || 'zh_cn';
	return https.get(url, params)
}

apiFun.post = function(url, params) {
	var url = url + '?lang=' + localStorage.getItem('local') || 'zh_cn';
	return https.post(url, params)
}

//暴露出这个对象
export default apiFun;