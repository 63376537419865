<template>
	<div class="gameZone">
		<BankHeaderVue title="绑定装置管理" :isSHow="false" />
		<MineHeader />
		<div class="member-data-container clearfix">
			<div class="member-children-wrap">
				<div class="zone-data-wrap">
					<div>
						<div>账号：ylc8888 未绑定任何装置</div>
						<table class="zone-info">
							<thead>
								<tr>
									<th>操作系统</th>
									<th>厂牌</th>
									<th>装置名称</th>
									<th>操作</th>
								</tr>
							</thead>
							<tbody></tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import BankHeaderVue from "./libs/BankHeader.vue"
	import MineHeader from "./libs/MineHeader.vue"
	export default {
		name: "gameZone",
		components: {
			BankHeaderVue,
			MineHeader,
		},
	}
</script>
<style lang="scss" scoped>
	.gameZone {
		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				clear: both;
				content: "";
				display: table;
			}
		}

		.member-children-wrap {
			padding-left: 10px;
			padding-right: 10px;
		}

		.zone-data-wrap {
			position: relative;
			margin: 0 auto;
			padding-bottom: 1.25rem;
			font-size: 0.938rem;
			margin-top: 1.25rem;
		}

		.zone-info {
			width: 100%;
			box-sizing: border-box;

			thead {
				display: none;
				background: #ccc;
				color: #fff;
			}

			tr {
				border: 1px solid #eee;
				display: block;
				margin-bottom: 0.625rem;
			}

			th {
				padding: 1rem 0;
				line-height: 1.25rem;
			}
		}
	}
</style>