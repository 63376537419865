<template>

	<div class="cashRecord">

		<BankHeaderVue title="往来记录" :isShow="false" />
		<MineHeader />

		<div class="cashrecord-page-wrap">

			<div v-show="showFilter != 0" class="cashrecord-mask" @click="showFilter = 0"></div>
			
			
			<div class="search-bar-wrap">

				<!-- <div class="select-pay-way" @click="showFilter = 1">全部</div> -->

				<div class="search-date" @click="showFilter = 2">{{showtis}}</div>

				<div class="search-btn" @click="getmoneylog">{{$t('查询')}}</div>

				<div class="date-text" v-if="selectedOption === 'custom'">{{$t('时间')}} {{strtime}}~{{endtime}}</div>

				<div class="dropdown-wrap" v-show="showFilter != 0">
					<div class="select-wrap">
						
						<div class="option-wrap way-pay-type" v-show="showFilter == 1">
							<div class="option-item current">全部</div>
							<div class="option-item">入款 . 出款交易</div>
							<div class="option-item">优惠</div>
							<div class="option-item">BB视讯</div>
							<div class="option-item">BB电子</div>
							<div class="option-item">BB彩票</div>
							<div class="option-item">BB捕鱼大师</div>
							<div class="option-item">BB棋牌</div>
						</div>

						<div class="option-wrap way-date" v-show="showFilter == 2">
							<!-- 今日 -->
							<div class="option-item" :class="{ current: selectedOption === 'today' }"
								@click="checktime('today')">今日</div>

							<!-- 昨日 -->
							<div class="option-item" :class="{ current: selectedOption === 'yesterday' }"
								@click="checktime('yesterday')">昨日</div>

							<!-- 近7日 -->
							<div class="option-item" :class="{ current: selectedOption === 'last7days' }"
								@click="checktime('last7days')">近7日</div>

							<!-- 自定义 -->
							<div class="option-item" :class="{ current: selectedOption === 'custom' }"
								@click="checktime('custom')">自定义</div>
						</div>


						<div class="customize-date clearfix" v-if="selectedOption === 'custom'">

							<div class="date-title">开始日期</div>

							<select class="selected-date" v-model="strtime" changePage>

								<option v-for="item in strlist" :key="item" :value="item">
									{{ item }}
								</option>

							</select>

							<!-- <select class="date-select">
								
								<option value="2024-10-13">2024-10-13</option>
								<option value="2024-10-12">2024-10-12</option>
								<option value="2024-10-11">2024-10-11</option>
								<option value="2024-10-10">2024-10-10</option>
								<option value="2024-10-09">2024-10-09</option>
								<option value="2024-10-08">2024-10-08</option>
								<option value="2024-10-07">2024-10-07</option>
								<option value="2024-10-06">2024-10-06</option>
								<option value="2024-10-05">2024-10-05</option>
								<option value="2024-10-04">2024-10-04</option>
								<option value="2024-10-03">2024-10-03</option>
								<option value="2024-10-02">2024-10-02</option>
								<option value="2024-10-01">2024-10-01</option>
							</select> -->

						</div>


						<div class="customize-date clearfix" v-if="selectedOption === 'custom'">
							<div class="date-title">结束日期</div>

							<select class="selected-date" v-model="endtime" changePage>

								<option v-for="item in strlist" :key="item" :value="item">
									{{ item }}
								</option>

							</select>

							<!-- <div class="selected-date"></div><select class="date-select">
								<option value="2024-10-13">2024-10-13</option>
								<option disabled="" value="2024-10-12">2024-10-12</option>
								<option disabled="" value="2024-10-11">2024-10-11</option>
								<option disabled="" value="2024-10-10">2024-10-10</option>
								<option disabled="" value="2024-10-09">2024-10-09</option>
								<option disabled="" value="2024-10-08">2024-10-08</option>
								<option disabled="" value="2024-10-07">2024-10-07</option>
								<option disabled="" value="2024-10-06">2024-10-06</option>
								<option disabled="" value="2024-10-05">2024-10-05</option>
								<option disabled="" value="2024-10-04">2024-10-04</option>
								<option disabled="" value="2024-10-03">2024-10-03</option>
								<option disabled="" value="2024-10-02">2024-10-02</option>
								<option disabled="" value="2024-10-01">2024-10-01</option>
							</select> -->

						</div>

					</div>
				</div>
			</div>
			
			
			<div class="search-result">
				<div class="no-data-img">{{$t('查无纪录')}}</div>
			</div>
			
			
		</div>
		
		
		
		<Diyloading ref="loadmodal"></Diyloading>
		
		
	</div>
</template>
<script>
	
	import BankHeaderVue from "./libs/BankHeader.vue";
	
	import MineHeader from "./libs/MineHeader.vue";
	
	import Diyloading from "./libs/Diyloading.vue"
	
	export default {
		name: "cashRecord",
		data() {
			return {
				showFilter: 0,
				selectedOption: 'today', // 默认选中的项
				usetime: [],
				strlist: [],
				endlist: [],
				strtime: '',
				endtime: '',
				showtis: '今日'
			};
		},
		components: {
			BankHeaderVue,
			MineHeader,
			Diyloading,
		},
		mounted() {
			
			// 执行“今日”的逻辑
			const today = new Date();
			
			const todayYMD = this.formatDate(today); // 格式化今天的日期

			this.strtime = todayYMD;
			
			this.endtime = todayYMD;

			this.usetime = [todayYMD, todayYMD];
			
			this.getmoneylog();
			
		},
		
		// created() {
		// 	// 执行“今日”的逻辑
		// 	const today = new Date();
		// 	const todayYMD = this.formatDate(today); // 格式化今天的日期

		// 	this.strtime = todayYMD;
		// 	this.endtime = todayYMD;

		// 	this.usetime = [todayYMD, todayYMD];
		// 	this.getmoneylog();
		// },
		
		methods: {

			checktime(timeOption) {
				
				this.selectedOption = timeOption; // 更新当前选中的选项

				switch (timeOption) {

					case 'today':
						// 执行“今日”的逻辑
						const today = new Date();
						const todayYMD = this.formatDate(today); // 格式化今天的日期
						// console.log('今日:', todayYMD);
						this.usetime = [todayYMD, todayYMD];
						// 执行你需要的“今日”逻辑

						this.showtis = '今日';

						break;

					case 'yesterday':

						// 执行“昨日”的逻辑
						const yesterday = new Date();
						yesterday.setDate(yesterday.getDate() - 1); // 日期减去一天
						const yesterdayYMD = this.formatDate(yesterday); // 格式化昨日日期
						// console.log('昨日:', yesterdayYMD);
						this.usetime = [yesterdayYMD, yesterdayYMD];

						this.showtis = '昨日';
						// 执行你需要的“昨日”逻辑
						break;

					case 'last7days':
						// 执行“近7日”的逻辑
						const last7Days = new Date();

						const today7end = this.formatDate(last7Days); // 格式化今天的日期

						last7Days.setDate(last7Days.getDate() - 7); // 当前日期减去7天

						const last7str = this.formatDate(last7Days); // 获取7天前的日期

						console.log('近7日:', last7str, ' 到 ', today7end);
						this.usetime = [last7str, today7end];

						this.showtis = '近7日';
						// 执行你需要的“近7日”逻辑
						break;

					case 'custom':

						// 执行自定义日期逻辑，获取过去 15 天的日期

						const days = 15;

						const todaylist = new Date();


						const todaynow = this.formatDate(todaylist); // 格式化今天的日期

						this.strtime = todaynow;
						
						this.endtime = todaynow;

						const strlist2 = [];
						
						// 循环获取过去 15 天的日期
						for (let i = -1; i < days; i++) {
							
							const date = new Date(todaylist);
							
							date.setDate(todaylist.getDate() - i);
						
							// 格式化日期为 YYYY-MM-DD
							const formattedDate = date.toISOString().split('T')[0];
						
							// 添加到 strlist 数组
							strlist2.push(formattedDate);
						}
						
						this.strlist = strlist2;
						
						this.showFilter = 2;
						
						// 设置显示提示
						this.showtis = '自定义';

						break;

					default:

						console.log('未知的时间选项');
				}
			},

			formatDate(date) {

				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要+1
				const day = String(date.getDate()).padStart(2, '0'); // 补齐两位数
				return `${year}-${month}-${day}`;

			},

			getmoneylog() {

				let that = this;
				
				that.showFilter = 0;
				
				// 发送新的请求
				that.$refs.loadmodal.open(); // 传入标题和消息
				
				const param = {
					money_type: "money",
					created_at: that.usetime
				};

				that.$apiFun.get("/api/moneylog", param).then((res) => {

				// 	console.log(JSON.stringify(res));


				// 	// 请求成功，假设 res.data.money 包含返回的余额信息
				// 	if (res.code === 200) {

				// 		that.recommendlists = res.data;

				// 		let casino = [];
				// 		res.data.map((item, index) => {
				// 			casino.push({
				// 				title: item.title,
				// 				game_type: item.game_type,
				// 				mobile_pic: item.mobile_pic,
				// 				tags: item.tags,
				// 				params: item.params,
				// 				api_name: item.api_name,
				// 				class_name: item.class_name,
				// 				weight: item.weight,
				// 				game_type_text: item.game_type_text,
				// 				game_type_cn_text: item.game_type_cn_text,
				// 				cimg1: ''
				// 			});
				// 		});

				// 		// 需要快速存储到@store
				// 		localStorage.setItem('casinolist', JSON.stringify(casino));
				// 		that.$store.commit('changcasinolist');

				// 	}

				}).catch((err) => {
					console.error("请求失败：", err);
				});
				
				that.$refs.loadmodal.close(); // 传入标题和消息
			}

		}
	};
</script>
<style lang="scss" scoped>
	.cashRecord {
		.cashrecord-page-wrap {
			position: relative;

			.cashrecord-mask {
				position: fixed;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: rgba(0, 0, 0, 0.5);
			}

			.search-bar-wrap {
				position: relative;
				background: #f0f7ff;
				text-align: center;
				padding: 0.625rem 0.625rem 0;

				.search-date,
				.second-pay-way,
				.select-pay-way {
					position: relative;
					display: inline-block;
					vertical-align: middle;
					color: #202020;
					background: #fff;
					border: 1px solid #f4f4f4;
					text-align: left;
					box-sizing: border-box;
					height: 2.125rem;
					line-height: 2.125rem;
					border-radius: 0.25rem;
					padding: 0 1.5rem 0 0.625rem;
					font-size: 0.9375rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.search-date,
				.second-pay-way,
				.select-pay-way {
					&::after {
						content: "";
						position: absolute;
						right: 0.5rem;
						top: 50%;
						margin-top: -2px;
						border-color: #ababab transparent transparent;
						border-style: solid;
						border-width: 6px 4px 0;
						width: 0;
						height: 0;
					}
				}

				.select-pay-way {
					width: 40.56%;
					margin-right: 2.81%;
				}

				.search-date {
					// width: 31.26%;
					width: 70%;
				}

				.search-btn {
					display: inline-block;
					vertical-align: middle;
					margin-left: 2.81%;
					background: #55b9ff;
					color: #fff;
					width: 21.3%;
					height: 2.125rem;
					line-height: 2.125rem;
					border-radius: 0.25rem;
					font-size: 1rem;
				}

				.date-text {
					color: #707070;
					text-align: right;
					line-height: 1.25rem;
					padding: 0.3125rem 0.625rem;
					font-size: 0.875rem;
				}

				.dropdown-wrap {
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					text-align: left;
					font-size: 0.7rem;
				}

				.select-wrap {
					position: relative;
					background: #fff;
					transition: height 0.2s ease;
					overflow: hidden;
				}

				.option-wrap {
					padding: 0 0.625rem 0.625rem;
				}

				.option-item {
					display: inline-block;
					vertical-align: middle;
					min-width: 22.81%;
					margin-right: 2.92%;
					border: 1px solid transparent;
					background: #f3f3f3;
					color: #707070;
					box-sizing: border-box;
					text-align: center;
					height: 1.5625rem;
					line-height: 1.4375rem;
					margin-top: 0.625rem;
					border-radius: 0.25rem;
					padding: 0 0.3125rem;
				}

				.option-item.current,
				.option-item:active {
					border-color: #55b9ff;
					background: #f0f7ff;
					color: #55b9ff;
				}

				.option-wrap.way-pay-type .option-item {
					min-width: 31.26%;
					margin-right: 2.07%;
				}

				.option-item:last-child {
					margin-right: 0;
				}
			}

			
			.search-result {
				text-align: center;
				margin: 30% 0 0;
				font-size: 0.938rem;
				color: #313f52;

				&::before {
					content: "";
					display: inline-block;
					vertical-align: middle;
					height: 100%;
				}

				.no-data-img {
					display: inline-block;
					vertical-align: middle;
					color: #202020;
					width: 140px;
					width: 5.75rem;
					padding-top: 5.75rem;
					font-size: .9rem;
					background: url("/static/image/image_nogift.png") 50% 0 no-repeat;
					background-size: contain;
				}
			}
		}
	}


	.cashrecord-page-wrap .search-bar-wrap .customize-date {
		position: relative;
		border-bottom: 1px solid #e9e9e9;
		color: #707070;
		padding: 0 .625rem;
		height: 46px;
		height: 2.875rem;
		line-height: 46px;
		line-height: 2.875rem;
	}

	.clearfix:after,
	.clearfix:before {
		content: "";
		display: table;
	}

	.cashrecord-page-wrap .search-bar-wrap .date-title {
		float: left;
	}

	.cashrecord-page-wrap .search-bar-wrap .selected-date {
		float: right;
		background: url("/static/image/arrow-forward.png") 100% 50% no-repeat;
		background-size: 5px 8px;
		background-size: .3125rem .5rem;
		padding-right: 1rem;
	}

	/* 移除 select 元素的默认下拉箭头 */
	.selected-date {
		-webkit-appearance: none;
		/* 针对 Chrome、Safari、iOS */
		-moz-appearance: none;
		/* 针对 Firefox */
		appearance: none;
		/* 针对标准浏览器 */
		background: transparent;
		/* 背景透明 */
		border: none;
		/* 如果不想要边框也可以去掉 */
		padding-right: 20px;
		/* 预留空间 */
		position: relative;
	}

	select {
		height: 2.875rem;
		line-height: 2.875rem;
		color: #666;
		border: none;
		/* 移除边框 */
		outline: none;
		/* 移除高亮边框 */
	}
</style>