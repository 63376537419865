<template>

	<div class="reward">

		<BankHeaderVue :title="$t('优惠中心')" :isShow="false" />

		<div class="reward-box">

			<MineHeader />

			<div class="reward-page">

				<div class="dibv" @click="$parent.goNav('/fanshui')">
					<div class="sub-links">{{$t('我的返水')}}</div>
					<i class="arrow-icon"></i>
				</div>

				<div class="dibv" @click="$parent.goNav('/promotion/222')">
					<div class="sub-links">{{$t('我的优惠')}}</div>
					<i class="arrow-icon"></i>
				</div>

				<div class="dibv" @click="$parent.goNav('/interest')">
					<div class="sub-links">{{$t('利息宝')}}</div>
					<i class="arrow-icon"></i>
				</div>

			</div>

		</div>

	</div>

</template>
<script>
	import BankHeaderVue from "./libs/BankHeader.vue"
	import MineHeader from "./libs/MineHeader.vue"
	export default {
		name: "reward",
		data() {
			return {}
		},
		components: {
			BankHeaderVue,
			MineHeader
		},
	}
</script>
<style lang="scss" scoped>
	
	.reward {
		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				clear: both;
				content: "";
				display: table;
			}
		}
	}
	
	.reward-page{
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	
	.dibv {
		color: #3d3d4a;
		height: 43px;
		line-height: 43px;
		border-bottom: 1px solid #d8d8d8;
		padding: 0 16px;
		background: #fff;
		font-size: .7rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	.sub-links {
		width: calc(100% - 58px);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
		
	.arrow-icon {
		width: 16px;
		height: 16px;
		background: url('/static/image/icon_arrow_back.png') 100% 50% no-repeat;
		background-size: contain;
	}
</style>