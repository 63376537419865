<template>
	<div>
		
		<!-- <van-nav-bar v-show="hidettitle" style="position: fixed; top: 0; left: 0; width: 100%; background-color: #edf2fd" title=""
			left-arrow @click-left="$router.back(-1)" :title="title" />

		<div style="height: 46px" v-show="hidettitle"></div> -->

		<!-- <div style="height: calc(100vh - 46px); overflow-y: scroll; -webkit-overflow-scrolling: touch">

			<iframe :src="url" style="height: 100%; width: 100%" ref="iframe" scrolling="auto" frameborder="0"
				id="iframe"></iframe>

		</div> -->

	</div>
</template>
<script>
	export default {
		name: 'gamePage',
		data() {
			return {
				url: "",
				title:"",
				type:0,
				hidettitle:true
			};
		},
		
		created() {
			
			let that = this;
			var query = that.$route.query;
			// gamePlay?name=麻将糊了&gamecode=5902&gametype=2&apicode=pg
			if (query.name) {
				that.title = query.name;
				
				// 扑鱼游戏隐藏标题
				that.type = query.gametype;
				if(that.type == 6){
					that.hidettitle = false;
				}
				if(that.type == 2){
					that.hidettitle = false;
				}
				
				that.goGamePage(query.name, query.gamecode, query.gametype, query.apicode);
			}
			
		},
		methods: {
			// 打开游戏
			goGamePage(name, gamecode, gametype, apicode) {
				let that = this;
				// api/game/login?gameCode=0&gameType=1&api_code=ag&isMobile=1&money_type=money
				that.$apiFun
					.get('/api/game/login', {
						gameCode: gamecode,
						gameType: gametype,
						api_code: apicode,
						isMobile: 1,
						money_type: 'money'
					})
					.then(res => {
						console.log(res);
						if (res.code != 200) {
							that.$parent.showTost(0, res.message);
						}

						if (res.code == 200) {
							that.url = res.game_url;
							// window.location.href = res.game_url;
							window.open(res.game_url);
						}

					})
					.catch(res => {});
			},
		},
		mounted() {
			let that = this;
		},
		updated() {
			let that = this;
			that.$refs.iframe.contentWindow.location.replace(that.url);
		},
	};
</script>

<style lang="scss" scoped>
</style>