<template>
	<div class="interest">
		<BankHeader :title="$t('活动列表')" :isShow="false" />
		<MineHeader />
		<div class="interest-main-page">
			<div class="interest-activity-list">
				<div class="history-select-date-wrap"><span
						class="history-select-date-label"><!-- react-text: 8535 -->开放区间<!-- /react-text --><!-- react-text: 8536 -->：<!-- /react-text --></span>
					<div class="history-select-date-inner-wrap clearfix">
						<div class="history-select-date-picker">
							<div>
								<div class="react-datepicker-wrapper">
									<div class="react-datepicker__input-container">
										<div class="">2024-10-22</div>
									</div>
								</div>
							</div>
						</div><span class="history-select-date-line">~</span>
						<div class="history-select-date-picker">
							<div>
								<div class="react-datepicker-wrapper">
									<div class="react-datepicker__input-container">
										<div class="">2024-10-22</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="activity-list-status"><span
						class="activity-list-status-label"><!-- react-text: 8551 -->状态<!-- /react-text --><!-- react-text: 8552 -->：<!-- /react-text --></span><select
						class="activity-list-status-select">
						<option value="0">全部</option>
						<option value="1">进行中</option>
						<option value="2">未开放</option>
					</select></div>
				<div class="activity-list-search-btn">查询</div>
				<table class="activity-list-table-wrap">
					<!-- <thead>
						<tr>
							<th>活动名称</th>
							<th>开放区间</th>
							<th>计息最终时间</th>
							<th>活动利率</th>
							<th>状态</th>
						</tr>
					</thead> -->
					<tbody class="active">
						<tr>
							<td class="label">活动名称</td>
							<td class="first"><!-- react-text: 8570 -->利息宝-100%年利率<!-- /react-text --><i></i></td>
						</tr>
						<tr>
							<td class="label">开放区间</td>
							<td>2023-12-01 00:00:00~2024-12-31 00:59:59</td>
						</tr>
						<tr>
							<td class="label">计息最终时间</td>
							<td>2025-01-01 00:59:59</td>
						</tr>
						<tr>
							<td class="label">活动利率</td>
							<td><!-- react-text: 8581 -->100.00<!-- /react-text --><!-- react-text: 8582 -->%<!-- /react-text --><!-- react-text: 8583 --><!-- /react-text -->
							</td>
						</tr>
						<tr>
							<td class="label">状态</td>
							<td class="green"><!-- react-text: 8587 -->进行中<!-- /react-text --></td>
						</tr>
					</tbody>
				</table>
				<div class="paging-wrap "><span class="paging-btn paging-first-disabled"></span><span
						class="paging-btn paging-previous-disabled"></span><select>
						<option value="1">1</option>
					</select><span class="page-text"><!-- react-text: 8594 -->
						/<!-- /react-text --><!-- react-text: 8595 -->1<!-- /react-text --><!-- react-text: 8596 -->
						<!-- /react-text --></span><span class="paging-btn paging-next-disabled"></span><span
						class="paging-btn paging-last-disabled"></span></div>
			</div>
		</div>
	</div>
</template>

<script>
	import BankHeader from "./libs/BankHeader.vue"
	import MineHeader from "./libs/MineHeader.vue"
	import PageFooter from "./libs/PageFooter.vue"
	export default {
		name: "interest",
		data() {
			return {
				tabIndex: 0,
				secendTabIndex: 0,
			}
		},
		components: {
			BankHeader,
			MineHeader,
			PageFooter
		},
	}
</script>
<style lang="scss" scoped>
	.clearfix:after {
		clear: both;
	}

	.clearfix:after,
	.clearfix:before {
		content: "";
		display: table;
	}

	.interest-main-page {
		font-size: 14px;
	}

	.interest-main-page .interest-activity-list .history-select-date-wrap {
		margin-top: 10px;
	}

	.interest-main-page .history-select-date-wrap {
		text-align: center;
		margin: 0 10px 10px;
	}

	.interest-main-page .interest-activity-list .history-select-date-wrap .history-select-date-label {
		width: 70px;
		text-align: right;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.interest-main-page .history-select-date-wrap .history-select-date-label {
		display: inline-block;
		vertical-align: middle;
		color: #666;
		font-size: 13px;
	}

	.interest-main-page .interest-activity-list .history-select-date-wrap .history-select-date-inner-wrap {
		width: 206px;
	}

	.interest-main-page .history-select-date-wrap .history-select-date-inner-wrap {
		display: inline-block;
		vertical-align: middle;
		border: 1px solid #d8d8d8;
		padding: 10px;
		color: #3d3d4a;
		font-size: 12px;
	}

	.interest-main-page .history-select-date-wrap .history-select-date-inner-wrap .history-select-date-picker {
		float: left;
	}

	.react-datepicker,
	.react-datepicker-wrapper {
		display: inline-block;
	}

	.react-datepicker__input-container {
		position: relative;
		display: inline-block;
	}

	.interest-main-page .history-select-date-wrap .history-select-date-inner-wrap .history-select-date-picker .react-datepicker__input-container div {
		min-width: 95px;
	}

	.interest-main-page .history-select-date-wrap .history-select-date-inner-wrap .history-select-date-line {
		float: left;
		margin: 0 3px;
	}


	.interest-main-page .interest-activity-list .activity-list-status {
		margin: 0 10px 10px;
		text-align: center;
	}

	.interest-main-page .interest-activity-list .activity-list-status .activity-list-status-label {
		display: inline-block;
		vertical-align: middle;
		width: 70px;
		color: #666;
		font-size: 13px;
		text-align: right;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.interest-main-page .interest-activity-list .activity-list-status .activity-list-status-select {
		display: inline-block;
		vertical-align: middle;
		border: 1px solid #d8d8d8;
		padding: 10px;
		background: url('https://g1.cfvn66.com/m/img/payonline/icon_mcenter/ic_select_arrow_dropdown.png') 95% 50% no-repeat;
		background-size: 16px 16px;
		width: 228px;
		color: #3d3d4a;
		font-size: 12px;
		-webkit-appearance: none;
	}

	.interest-main-page .interest-activity-list .activity-list-search-btn {
		margin: 0 10px 10px;
		padding: 10px;
		background: #0085f9;
		color: #fff;
		font-size: 14px;
		text-align: center;
		clear: both;
	}

	.interest-main-page .interest-activity-list .activity-list-table-wrap {
		display: block;
		padding: 0 10px;
	}

	.interest-main-page .interest-activity-list .activity-list-table-wrap tr td.label {
		border-color: #ccc;
		padding: 9px 14px 9px 9px;
		background: #ccc;
		color: #fff;
		text-align: right;
		width: 35%;
	}

	.interest-main-page .interest-activity-list .activity-list-table-wrap tr td {
		position: relative;
		border-bottom: 1px solid #dadada;
		padding: 9px 28px 9px 11px;
		width: 65%;
		color: #43434f;
		word-break: break-all;
	}

	.interest-main-page .interest-activity-list .activity-list-table-wrap tr td.first {
		color: #0085f8;
	}

	.interest-main-page .interest-activity-list .activity-list-table-wrap tr td.green {
		color: #26ab28;
	}

	.interest-main-page .interest-activity-list .activity-list-table-wrap tbody {
		display: table;
		margin-top: 10px;
		border: 1px solid #d8d8d8;
		width: 100%;
	}
</style>