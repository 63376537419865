<template>
	<div class="interest">
		<BankHeader :title="$t('利息宝')" :isShow="false" />
		<MineHeader />
		<div class="interest-main-page">
			<div>
				<div class="main-radio-wrap clearfix">

					<div :class="[tabIndex == 0 ? 'current' : '']" @click="settabIndex(0)">{{$t('活动资​​讯')}}</div>
					<div :class="[tabIndex == 1 ? 'current' : '']" @click="settabIndex(1)">{{$t('利息账户')}}</div>
				</div>

				<!-- <div class="main-balance clearfix"><span class="title">{{$t('利息宝')}}</span><span>-- RMB</span></div> -->

				<div v-show="tabIndex == 0">



					<!-- 	2.	SettingName:
					"挖坑" — 配置的名称，中文“挖坑”，可能是某个项目或功能的代号。
						3.	MinAmount:
					100 — 最小金额，可能表示操作或交易的最小额度为 100。
						4.	MaxAmount:
					100000 — 最大金额，表示操作或交易的最大额度为 100,000。
						5.	SettleTime:
					1 — 结算时间，可能表示结算操作的时间周期或延迟，具体含义可能取决于业务逻辑。
						6.	IsCycleSettle:
					10 — 是否周期性结算。通常这个值可能是布尔值（0 或 1）来表示是否周期结算。但这里是 10，可能是某种特殊的设定，需查看具体业务规则。
						7.	Rate:
					"100.00" — 利率或比率，通常用于计算某种费用或收益。
						8.	TotalCount:
					"100000.00" — 总数或总额度，可能表示该配置下可以处理的最大金额或总量。
						9.	LimitInterest:
					"0.00" — 利息上限，表示在某种情况下利息的最大值（此处为 0，可能表示没有限制或没有利息）。
						10.	LimitOrderIntervalTime:
					null — 限制订单间隔时间，当前为 null，可能表示没有设定。
						11.	InterestAuditMultiple:
					"1.0" — 利息审核倍数，可能与利息计算或审计相关。
						12.	LimitUserOrderCount:
					1000000 — 限制用户订单数量，表示用户在该配置下允许的最大订单数量为 1,000,000。
						13.	is_open:
					1 — 是否开启，1 表示已开启，0 表示关闭。
						14.	weight:
					10 — 权重，可能用来排序或优先级控制，值越大优先级可能越高。
						15.	lang:
					"zh_cn" — 语言标识，表示使用简体中文。
						16.	created_at:
					null — 创建时间，当前未设定。
						17.	updated_at:
					null — 更新时间，当前未设定。
						18.	RemainingCount:
					"100000.00" — 剩余数量或额度，可能表示当前还能处理或使用的剩余额度。
						19.	UserOrderCount:
					null — 用户订单数量，当前为 null，可能表示尚未有订单或未统计。
						20.	LimitOrderInterval:
					null — 限制订单间隔，当前为 null，表示没有设定订单之间的时间间隔。 -->


					<div class="activity-info activity-info-2 clearfix">

						<div class="activity-info-act-wrap">

							<div class="activity-info-act-container" v-for="(item, index) in yuebaolist" :key="index"
								style="transform: translate(calc(0% + 0px))">

								<div class="activity-info-act">

									<div class="activity-text-wrap">

										<div class="clearfix">

											<span>{{$t('当前活动')}}：</span><span>{{item.SettingName}}</span>
											<!-- <span class="activity-text-popbtn">更多活动</span> -->

										</div>

										<div class="clearfix">

											<span>{{$t('结算方式')}}：</span>

											<span>{{item.SettleTime}}{{$t('小时')}}</span>

										</div>

										<!-- <div class="clearfix">
											
											<span>{{$t('开放区间')}}：</span>
											
											<span>2023-12-01 00:00:00 ~ 2024-12-31 00:59:59</span>
											
										</div> -->

										<div class="clearfix">

											<span>{{$t('活动利率')}}：</span><span>{{item.Rate}}</span>
											<!-- <span class="activity-text-popbtn">利息详情/试算</span> -->

										</div>

										<!-- <div class="clearfix">
											
											<span>{{$t('存款总额')}}：</span>
											
											<span>0元 / 可再存入1,000,000元</span>
											
										</div> -->

									</div>

									<div class="activity-deposit-wrap">

										<div class="activity-deposit-input">

											<input type="number" v-model="amount" placeholder="单笔 100 ~ 无上限" value="" />

										</div>

									</div>

									<!-- disable -->
									<div class="submit-btn">

										<span @click="buy">{{$t('马上存')}}</span>

									</div>

								</div>

							</div>

						</div>

					</div>

					<!-- <div class="activity-overview-wrap clearfix">
						<div class="activity-overview activity-overview-sum">
							<ul>
								<li>
									<div class="activity-overview-title coin"><span>预期总收益</span></div>
									<div class="activity-overview-content">0.0000000000</div>
									<div class="activity-overview-countdown">
										<span>7s</span><svg class="svg-countdown" viewBox="0 0 60 60">
											<circle class="svg-circle-bg" cx="30" cy="30" r="27"></circle>
											<circle class="svg-circle-bar" cx="30" cy="30" r="27"
												style="stroke-dashoffset: 149.858px"></circle>
										</svg>
									</div>
								</li>
							</ul>
						</div>
						<div class="activity-overview activity-overview-period">
							<ul>
								<li class="clearfix">
									<div class="activity-overview-title coin"><span>今日收益</span></div>
									<div class="activity-overview-content">0.0000000000</div>
								</li>
								<li class="clearfix">
									<div class="activity-overview-title coin"><span>本周收益</span></div>
									<div class="activity-overview-content">0.0000000000</div>
								</li>
								<li class="clearfix">
									<div class="activity-overview-title coin"><span>本月收益</span></div>
									<div class="activity-overview-content">0.0000000000</div>
								</li>
							</ul>
						</div>
						<div class="activity-overview activity-overview-date">
							<ul>
								<li class="clearfix">
									<div class="activity-overview-title"><span>最近转入时间</span></div>
									<div class="activity-overview-content">--</div>
								</li>
								<li class="clearfix">
									<div class="activity-overview-title"><span>最近转出时间</span></div>
									<div class="activity-overview-content">--</div>
								</li>
							</ul>
						</div>
					</div> -->


				</div>
				<div class="record-wrap" v-show="tabIndex == 1">

					<div class="record-radio-wrap clearfix">

						<div :class="[secendTabIndex == 0 ? 'current' : '']" @click="getsecendTabIndex(0)">计息中</div>

						<div :class="[secendTabIndex == 1 ? 'current' : '']" @click="getsecendTabIndex(1)">已结束</div>

						<!-- <div :class="[secendTabIndex == 2 ? 'current' : '']" @click="secendTabIndex = 2">历史记录</div> -->

					</div>

					<div v-show="secendTabIndex == 0">

						<!-- <div class="overdue-balance clearfix"><span class="title">可领本利总额</span><span>--RMB</span></div>
						<div class="withdraw-all-btn disable">一键领回</div> -->


						<div class="record-table-wrap" v-if="planslist.length > 0">

							<table v-for="(item, index) in planslist" :key="index">
								<tbody>
									<tr>
										<td class="label">{{$t('存入金额')}}</td>
										<td>{{item.amount}}<i></i></td>
									</tr>
									<tr>
										<td class="label">{{$t('利率')}}</td>
										<td class="">{{item.Rate}}</td>
									</tr>

									<tr>
										<td class="label">{{$t('存入时间')}}</td>
										<!-- <td class="">7时34分<span>/1日</span><span></span></td> -->
										<td class="">{{item.CreatedOn}}</td>
									</tr>

									<tr>
										<td class="label">{{$t('可领利息')}}</td>
										<td class="first">{{item.Interest}}</td>
									</tr>

									<tr>
										<td class="label">{{$t('本利金额')}}</td>
										<td class="">{{item.amount + item.Interest}}</td>
									</tr>

									<tr>
										<td colspan="2" class="collect" @click="shuhui(item)">领回</td>
									</tr>

								</tbody>
							</table>

						</div>

						<div class="record-table-wrap" v-else>
							<div class="no-data">
								<div class="no-data-icon"></div>
								<div class="no-data-text">目前无资料</div>
							</div>
						</div>

					</div>


					<div v-show="secendTabIndex == 1">

						<!-- <div class="overdue-balance clearfix"><span class="title">可领本利总额</span><span>--RMB</span></div> -->
						<!-- <div class="withdraw-all-btn disable">一键领回</div> -->

						<div class="record-table-wrap" v-if="planslist.length > 0">

							<table v-for="(item, index) in planslist" :key="index">
								<tbody>
									<tr>
										<td class="label">{{$t('存入金额')}}</td>
										<td>{{item.amount}}<i></i></td>
									</tr>
									<tr>
										<td class="label">{{$t('利率')}}</td>
										<td class="">{{item.Rate}}</td>
									</tr>

									<tr>
										<td class="label">{{$t('存入时间')}}</td>
										<!-- <td class="">7时34分<span>/1日</span><span></span></td> -->
										<td class="">{{item.CreatedOn}}</td>
									</tr>

									<tr>
										<td class="label">{{$t('可领利息')}}</td>
										<td class="first">{{item.Interest}}</td>
									</tr>

									<tr>
										<td class="label">{{$t('本利金额')}}</td>
										<td class="">{{item.amount + item.Interest}}</td>
									</tr>

									<!-- <tr>
										<td colspan="2" class="collect" @click="shuhui(item)">领回</td>
									</tr> -->

								</tbody>
							</table>

						</div>

						<div class="record-table-wrap" v-else>
							<div class="no-data">
								<div class="no-data-icon"></div>
								<div class="no-data-text">目前无资料</div>
							</div>
						</div>


					</div>

					<!-- <div v-show="secendTabIndex == 2">

						<div class="history-radio-wrap">
							<label class="radio-label"><input type="radio" value="1" /><span
									class="radio-icon active"><i></i></span><span
									class="radio-text">转出时间</span></label><label class="radio-label"><input type="radio"
									value="2" /><span class="radio-icon"><i></i></span><span
									class="radio-text">存入时间</span></label>
						</div>

						<div class="history-select-date-wrap">
							<span class="history-select-date-label">时间：</span>
							<div class="history-select-date-inner-wrap clearfix">
								<div class="history-select-date-picker">
									<div>
										<div class="react-datepicker-wrapper">
											<div class="react-datepicker__input-container">
												<div class="">2024-08-07</div>
											</div>
										</div>
									</div>
								</div>
								<span class="history-select-date-line">~</span>
								<div class="history-select-date-picker">
									<div>
										<div class="react-datepicker-wrapper">
											<div class="react-datepicker__input-container">
												<div class="">2024-08-07</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="history-search-btn">查询</div>
						<div class="history-total-balance clearfix"><span class="title">已领利息</span><span>--RMB</span>
						</div>
					</div> -->

					<!-- <div class="record-table-wrap">
						<div class="no-data">
							<div class="no-data-icon"></div>
							<div class="no-data-text">目前无资料</div>
						</div>
					</div> -->

					<!-- <div class="last-update-time">最后更新时间：--</div> -->

					<!-- <PageFooter :totalPage="10" /> -->

				</div>
			</div>
		</div>


		<Diyloading ref="loadmodal"></Diyloading>

	</div>
</template>

<script>
	import BankHeader from "./libs/BankHeader.vue"

	import MineHeader from "./libs/MineHeader.vue"

	import PageFooter from "./libs/PageFooter.vue"

	import Diyloading from "./libs/Diyloading.vue"

	import {
		Dialog
	} from 'vant';

	export default {

		name: "interest",
		data() {
			return {
				tabIndex: 0,
				secendTabIndex: 0,
				yuebaolist: [],
				amount: '',
				plan_id: '',
				planslist: []
			}
		},
		components: {
			BankHeader,
			MineHeader,
			PageFooter,
			Diyloading
		},

		mounted() {

			this.getyuebao();

		},

		methods: {
			
			settabIndex(e){
				
				let that = this;
				that.tabIndex = e;
				if(that.tabIndex == 1){
					
					that.secendTabIndex = 0;
					that.getsecendTabIndex(that.secendTabIndex);
					
					
				}
				
			},
			shuhui(it) {

				let that = this;

				Dialog.confirm({
						title: '提示',
						message: '确定领回吗？',
					}).then(() => {
						
						// 用户点击 "确定" 后的逻辑
						that.$refs.loadmodal.open(); // 传入标题和消息
						that.$apiFun.post('/api/yuebao/withdrawal', {
							record_id: it.member_plan_id
						}).then(res => {
							
							// console.log(res);
							if (res.code != 200) {
								that.$parent.showTost(0, res.message);
							}

							if (res.code == 200) {
								that.$parent.showTost(0, res.message);
							}
							
							that.$refs.loadmodal.close(); // 传入标题和消息
							
						}).catch(res => {});

					})
					.catch(() => {
						// 用户点击 "取消" 后的逻辑
						console.log('用户取消了操作');
					});

			},
			
			getsecendTabIndex(ty) {

				let that = this;

				that.secendTabIndex = ty;
				
				that.$refs.loadmodal.open(); // 传入标题和消息

				that.planslist = [];

				that.$apiFun.post('/api/yuebao/getMemberPlans', {
					page: 1,
					status: ty
				}).then(res => {

					// console.log(res);
					if (res.code != 200) {
						that.$parent.showTost(0, res.message);
					}

					if (res.code == 200) {
						
						that.planslist = res.data.data;
						
						that.$refs.loadmodal.close(); // 传入标题和消息
					}

				}).catch(res => {});

			},

			buy() {

				let that = this;


				if (!that.plan_id) {

					that.$parent.showTost(0, '缺少ID');
					return;

				}

				if (!that.amount) {

					that.$parent.showTost(0, '请输入金额');
					return;

				}

				that.$apiFun.post('/api/yuebao/buy', {
						plan_id: 1,
						amount: that.amount,
					}).then(res => {

						// console.log(res);

						if (res.code != 200) {
							that.$parent.showTost(0, res.message);
						}

						if (res.code == 200) {
							that.amount = '';
							that.tabIndex = 1;
						}

					})
					.catch(res => {});

			},

			getyuebao() {
				let that = this;
				// api/game/login?gameCode=0&gameType=1&api_code=ag&isMobile=1&money_type=money
				that.$apiFun
					.post('/api/yuebao/getMemberYuebaoList', {})
					.then(res => {

						console.log(res);

						// if (res.code != 200) {
						// 	that.$parent.showTost(0, res.message);
						// }

						if (res.code == 200) {
							that.yuebaolist = res.data;
							that.plan_id = res.data[0].id;
						}

					})
					.catch(res => {});
			},
		},


	}
</script>

<style lang="scss" scoped>
	.interest {

		min-height: 100vh;
		background: #ffffff;


		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				content: "";
				display: table;
				clear: both;
			}
		}

		.interest-main-page {

			background: #ffffff;
			font-size: 14px;

			.main-radio-wrap {
				border-bottom: 1px solid #d8d8d8;
				width: 100%;
				display: flex;
				align-items: center;

				div {
					float: left;
					border-bottom: 2px solid transparent;
					padding: 6px 0;
					width: 50%;
					color: #3d3d4a;
					text-align: center;
				}

				div.current {
					border-color: #0085f9;
					color: #0085f9;
				}

				div:active {
					border-color: #0085f9;
					color: #0085f9;
				}
			}

			.main-balance {
				margin: 11px 10px 8px;
				padding: 10px;
				background: #f3f3f3;
				color: #3d3d4a;
				font-size: 12px;

				span {
					float: right;
				}

				.title {
					float: left;
				}
			}

			.activity-info {
				margin: 0 10px;
				font-size: 12px;

				.activity-info-act-wrap {
					overflow: hidden;
				}

				.activity-info-act-container {
					white-space: nowrap;
					transition: transform 0.2s;
				}

				.activity-info-act {
					display: inline-block;
					vertical-align: top;
					box-sizing: border-box;
					padding: 0 4px;
					width: 100%;
					white-space: normal;
				}

				.activity-text-wrap {
					color: #3d3d4a;

					div {
						padding-bottom: 7px;
						min-height: 23px;

						span {
							float: left;
							padding: 4px 0;
							word-break: break-word;
						}

						span.activity-text-popbtn {
							float: right;
							box-sizing: border-box;
							padding: 4px;
							width: 38%;
							text-align: center;
							color: #0085f9;
							background: #f9f9f9;
							border: 1px solid #0085f9;
						}
					}
				}

				.activity-deposit-wrap {
					position: relative;
					margin: 4px 0 10px;
					padding-left: 70px;
					min-height: 36px;

					.activity-deposit-input {
						position: relative;

						input {
							display: block;
							border: 1px solid #d8d8d8;
							padding: 9px 12px;
							box-sizing: border-box;
							width: 100%;
							height: 36px;
							color: #999;
						}
					}
				}

				.submit-btn {
					margin-bottom: 10px;
					padding: 10px;
					background: #0085f9;
					color: #fff;
					font-size: 14px;
					text-align: center;
				}

				.submit-btn.disable {
					color: #b6b6b6;
					background: #d8d8d8;
				}
			}

			.activity-info.activity-info-2 {
				position: relative;
				padding: 10px 18px;
				background: #f3f3f3;

				.activity-deposit-wrap {
					float: left;
					padding-left: 0;
					width: 60%;
					font-size: 16px;
				}

				.submit-btn {
					float: right;
					margin: 4px 0 10px 2%;
					padding: 7px 0;
					width: 38%;
					font-size: 16px;
				}
			}

			.activity-overview-wrap {
				position: relative;
				margin: 8px 10px 0;
				font-size: 13px;

				.activity-overview-sum {
					margin-bottom: 8px;
					padding: 3px 50px 3px 10px;
					background: #e3eefb;

					.activity-overview-title {
						padding: 0;
					}

					.activity-overview-title.coin:before {
						width: 22px;
						height: 22px;
					}

					.activity-overview-content {
						padding: 0;
					}
				}

				ul {
					margin: 0;
					padding: 0;
					list-style-type: none;
				}

				.activity-overview-title {
					color: #3d3d49;
					font-weight: 700;

					span {
						vertical-align: middle;
						line-height: 30px;
					}
				}

				.activity-overview-title.coin:before {
					content: "";
					display: inline-block;
					vertical-align: middle;
					margin-right: 2px;
					width: 17px;
					height: 17px;
					background: url("/static/image/icon_coin_m.png") 50% 50% no-repeat;
					background-size: contain;
				}

				.activity-overview-content {
					padding: 2px 5px;
					color: #0085f9;
					font-size: 16px;

					&::before {
						content: "";
						display: inline-block;
						vertical-align: middle;
						height: 100%;
					}
				}

				.activity-overview-countdown {
					position: absolute;
					top: 4px;
					right: 4px;
					width: 50px;
					height: 50px;
					text-align: center;

					span {
						color: #0085f9;
						font-size: 20px;
						font-weight: 700;
						line-height: 50px;
					}
				}

				.svg-countdown {
					position: absolute;
					top: 0;
					left: 0;
					width: 50px;
					height: 50px;
					transform: rotate(-90deg);

					.svg-circle-bg {
						fill: transparent;
						stroke: #43b4ff;
						stroke-width: 3px;
					}

					.svg-circle-bar {
						fill: transparent;
						stroke: #0085f9;
						stroke-width: 5px;
						stroke-dasharray: 169.65;
						stroke-linecap: round;
						stroke-linejoin: round;
						transition: stroke-dashoffset 1s cubic-bezier(0.5, 1, 1, 1);
					}
				}

				.activity-overview-period {
					float: right;
					width: 49%;

					li {
						margin-bottom: 8px;
						border: 1px solid #ccc;
					}

					.activity-overview-title {
						text-align: center;
						background: #e3eefb;
					}

					.activity-overview-content {
						text-align: right;
						height: 36px;
					}
				}

				.activity-overview-date {
					float: left;
					width: 49%;

					li {
						margin-bottom: 8px;
						border: 1px solid #ccc;
					}

					.activity-overview-title {
						background: #d8d8d8;
						text-align: center;
					}

					.activity-overview-content {
						text-align: right;
						height: 76px;
						color: #3d3d4a;
						font-size: 13px;
					}
				}
			}

			.record-wrap {
				margin-bottom: 20px;

				.record-radio-wrap {
					background: #ccc;

					div {
						float: left;
						box-sizing: border-box;
						padding: 9px;
						// width: 33.33%;
						width: 50%;
						color: #fff;
						text-align: center;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}

					div.current {
						background: #3d3d4a;
					}
				}

				.overdue-balance {
					margin: 10px;
					padding: 10px;
					background: #f3f3f3;

					span {
						float: right;
					}

					.title {
						float: left;
					}
				}

				.withdraw-all-btn {
					margin: 0 10px 10px;
					padding: 10px;
					background: #0085f9;
					color: #fff;
					text-align: center;
				}

				.withdraw-all-btn.disable {
					color: #b6b6b6;
					background: #d8d8d8;
				}

				.history-radio-wrap {
					margin: 10px 0;
					text-align: center;

					.radio-label {
						display: inline-block;
						margin: 0 22px;

						input {
							display: none;
						}

						.radio-icon {
							position: relative;
							float: left;
							border: 1px solid #d8d8d8;
							border-radius: 100%;
							width: 20px;
							height: 20px;
						}

						.radio-icon.active {
							border-color: #0085f9;

							i {
								position: absolute;
								top: 4px;
								left: 4px;
								border-radius: 100%;
								background-color: #0085f9;
								width: 12px;
								height: 12px;
							}
						}

						.radio-text {
							float: left;
							padding-left: 6px;
							height: 22px;
							line-height: 22px;
							color: #3d3d4a;
							font-size: 13px;
						}
					}
				}

				.history-select-date-wrap {
					text-align: center;
					margin: 0 10px 10px;

					.history-select-date-label {
						display: inline-block;
						vertical-align: middle;
						color: #666;
						font-size: 13px;
					}

					.history-select-date-inner-wrap {
						display: inline-block;
						vertical-align: middle;
						border: 1px solid #d8d8d8;
						padding: 10px;
						color: #3d3d4a;
						font-size: 12px;

						.history-select-date-picker {
							float: left;

							.react-datepicker-wrapper {
								display: inline-block;
							}

							.react-datepicker__input-container {
								position: relative;
								display: inline-block;

								div {
									min-width: 95px;
								}
							}
						}

						.history-select-date-line {
							float: left;
							margin: 0 3px;
						}
					}
				}

				.history-search-btn {
					margin: 0 10px 10px;
					padding: 10px;
					background: #0085f9;
					color: #fff;
					text-align: center;
				}

				.history-total-balance {
					margin: 11px 10px 8px;
					padding: 10px;
					background: #f3f3f3;
					color: #3d3d4a;
					font-size: 12px;

					span {
						float: right;
					}

					.title {
						float: left;
					}
				}

				.record-table-wrap {
					padding: 0 10px;

					.no-data {
						margin-top: 132px;

						.no-data-icon {
							height: 68px;
							background: url("/static/image/ic_no_data.png") 50% 0 no-repeat;
							background-size: contain;
						}

						.no-data-text {
							padding: 0 30px;
							margin-top: 11px;
							color: #ccc;
							font-size: 15px;
							text-align: center;
						}
					}
				}

				.last-update-time {
					margin-top: 24px;
					color: #999;
					text-align: center;
				}
			}
		}
	}

	.record-table-wrap {
		padding: 0 10px;
	}

	.record-table-wrap table {
		margin-top: 10px;
		border: 1px solid #d8d8d8;
		width: 100%;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	.record-table-wrap table tr td.label {
		border-color: #ccc;
		padding: 9px 14px 9px 9px;
		background: #ccc;
		color: #fff;
		text-align: right;
		width: 35%;
	}

	.record-table-wrap table tr td {
		position: relative;
		border-bottom: 1px solid #dadada;
		padding: 9px 28px 9px 11px;
		width: 65%;
		color: #43434f;
		word-break: break-all;
	}

	.record-table-wrap table tr:last-child td {
		border: none;
	}

	.interest-main-page .record-wrap .record-table-wrap table tr td.collect {
		color: #fff;
		text-align: center;
		background: #0085f9;
	}

	// .info-table {
	// 	table {
	// 		width: 100%;
	// 		border-collapse: collapse;
	// 		border-spacing: 0;

	// 		th {
	// 			width: 33%;
	// 			height: 32px;
	// 			font-size: 0.9375rem;
	// 			padding: 5px;
	// 			text-align: center;
	// 			background: #ccc;
	// 			color: #fff;
	// 		}

	// 		td {
	// 			width: 33%;
	// 			height: 32px;
	// 			font-size: 0.9375rem;
	// 			padding: 5px;
	// 			text-align: center;
	// 			background: #fff;
	// 			border: 1px solid #eee;
	// 		}
	// 	}

	// 	.total {
	// 		margin-bottom: 15px;

	// 		th {
	// 			background: #ececec;
	// 			color: #313f52;
	// 		}

	// 		td {
	// 			background: #fff;
	// 			border: 1px solid #eee;
	// 		}
	// 	}

	// 	.no-data {
	// 		padding: 56px 0;
	// 		color: #c1c5cc;
	// 	}
	// }
</style>