<template>

	<div class="betRecord">

		<BankHeaderVue :title="$t('投注记录')" :isShow="false" />

		<MineHeader />

		<div class="betrecord-page-wrap">

			<div class="betrecord-info-container">

				<div>

					<div class="betrecord-search-mask" v-show="showMore" @click="showMore = false"></div>

					<div class="betrecord-search-bg">

						<!-- <div class="betrecord-page-name">{{gamename.toUpperCase()}}</div> -->

						<div class="search-bar-wrap">

							<select class="selected-date" v-model="gamename" changePage>
								
								<option v-for="(item, index) in getcasinolist" :key="index" :value="item.class_name">
									{{ item.class_name.toUpperCase() }}

								</option>

							</select>

							<div class="search-date" @click="showFilter = 2">{{showtis}}</div>

							<div class="search-btn" @click="getmoneylog">{{$t('查询')}}</div>

						</div>

						<div class="dropdown-wrap" v-show="showFilter != 0">

							<div class="date-text" v-if="selectedOption === 'custom'">{{$t('时间')}}
								{{strtime}}~{{endtime}}
							</div>

							<div class="select-wrap">

								<div class="option-wrap way-date" v-show="showFilter == 2">
									<!-- 今日 -->
									<div class="option-item" :class="{ current: selectedOption === 'today' }"
										@click="checktime('today')">今日</div>

									<!-- 昨日 -->
									<div class="option-item" :class="{ current: selectedOption === 'yesterday' }"
										@click="checktime('yesterday')">昨日</div>

									<!-- 近7日 -->
									<div class="option-item" :class="{ current: selectedOption === 'last7days' }"
										@click="checktime('last7days')">近7日</div>

									<!-- 自定义 -->
									<div class="option-item" :class="{ current: selectedOption === 'custom' }"
										@click="checktime('custom')">{{$t('自定义')}}</div>

								</div>


								<div class="customize-date" v-if="selectedOption === 'custom'">

									<div class="date-title">{{$t('开始日期')}}</div>

									<select class="selected-date" v-model="strtime" changePage>

										<option v-for="item in strlist" :key="item" :value="item">
											{{ item }}

										</option>

									</select>


								</div>


								<div class="customize-date" v-if="selectedOption === 'custom'">
									<div class="date-title">{{$t('结束日期')}}</div>

									<select class="selected-date" v-model="endtime" changePage>

										<option v-for="item in strlist" :key="item" :value="item">
											{{ item }}
										</option>

									</select>

								</div>


							</div>
						</div>



						<!-- <div class="betrecord-select-wrap clearfix" @click="showMore = true">
							<div class="betrecord-select betrecord-select-bettype left">{{$t('未结算注单')}}</div>
						</div>
						
						<div class="betrecord-dropdown-wrap" style="max-height: 454px" v-show="showMore">
							<div :class="['betrecord-option-item',zhudanIndex == 0 ? 'active' : '']"
								@click="zhudanIndex = 0">{{$t('未结算注单')}}</div>
							<div :class="['betrecord-option-item',zhudanIndex == 1 ? 'active' : '']"
								@click="zhudanIndex = 2">{{$t('已结算注单')}}</div>
						</div> -->


					</div>


					<!-- <div class="betrecord-search-content">
						<div class="table-game-record">
							<div class="table-tr is-header">
								<div class="table-th">游戏类别</div>
								<div class="table-th">笔数</div>
								<div class="table-th">投注金额</div>
							</div>
							<div class="table-tr">
								<div class="table-td">总计</div>
								<div class="table-td">0</div>
								<div class="table-td">0.00</div>
							</div>
						</div>
					</div> -->


					<!-- <thead>
						<tr>
							<th class="table-time">日期</th>
							<th class="table-amount">投注金额</th>
							<th class="table-effective">有效投注额</th>
							<th class="table-wingold">派彩</th>
						</tr>
					</thead> -->

					<div class="betrecord-search-content" ref="scrollContainer">

						<div class="table-effect">

							<table class="table-effect">


								<tbody>


									<tr class="tr-effect-control" v-for="(item, index) in recordlists" :key="index">

										<td class="clearfix table-time">

											<span class="td-head">{{$t('日期')}}</span>

											<span class="td-body">
												<span>{{formatDateAlone(item.created_at)}}</span>
											</span>

										</td>

										<td class="clearfix table-amount">

											<span class="td-head">{{$t('投注金额')}}</span>

											<span class="td-body">{{$t(item.betAmount)}}</span>

										</td>

										<td class="clearfix table-effective">

											<span class="td-head">{{$t('输赢金额')}}</span>

											<span class="td-body">{{$t(item.netAmount)}}</span>

										</td>

										<td class="clearfix table-effective">

											<span class="td-head">{{$t('游戏详情')}}</span>

											<span class="td-body"
												@click="openurl(item.betContent)">{{$t('立即查看')}}</span>

										</td>

										<!-- <td class="clearfix table-wingold">
											<div class="clearfix">
												<span class="td-head">派彩</span>
												<span class="td-body">
													<font class="">0.00</font>
												</span>
											</div>
										</td> -->

									</tr>



								</tbody>
							</table>

						</div>

					</div>

				</div>

				<!-- <div class="betrecord-select-btn-bg">
					<div class="betrecord-select-btn" @click="showPingTai = true">{{$t('选择平台')}}</div>
				</div> -->

				<div v-show="showPingTai">

					<div class="betrecord-platform-pop-mask" @click="showPingTai = false">
						<div class="betrecord-platform-wrap" @click.stop>

							<div class="betrecord-platform-header">
								<div class="betrecord-platform-close" @click="showPingTai =false">{{$t('取消')}}</div>
								<div class="betrecord-platform-title">{{$t('选择平台')}}</div>
							</div>
							<div class="betrecord-platform-content">
								<!-- <div class="betrecord-platform-subtitle">类型筛选</div>
								<div class="betrecord-menu-list-wrap clearfix">
									<div :class="['betrecord-menu-btn',pingtaiType== 1 ? 'active' : '']"
										@click="chooseType(1)">全部类型</div>
									<span :class="['betrecord-menu-btn',pingtaiType== 2 ? 'active' : '']"
										@click="chooseType(2)">体育</span><span
										:class="['betrecord-menu-btn',pingtaiType== 3 ? 'active' : '']"
										@click="chooseType(3)">视讯</span><span class="betrecord-menu-btn">电子</span><span
										class="betrecord-menu-btn">彩票</span><span class="betrecord-menu-btn">棋牌</span>
								</div> -->
								<div class="betrecord-platform-subtitle">{{$t('平台筛选')}}</div>

								<div class="betrecord-menu-list-wrap clearfix">

									<div :class="['betrecord-menu-btn', gamename == item.class_name ? 'active' : ''] "
										v-for="(item, index) in getcasinolist" :key="index"
										@click="choosePingTai(item)">{{item.class_name.toUpperCase()}}
									</div>

								</div>
							</div>

							<!-- <div class="betrecord-platform-bottom">
								<div class="betrecord-platform-search">关键字搜寻</div>
								<div class="betrecord-platform-search-input"><input type="text" value="" maxlength="15"
										placeholder="请输入平台名称" /></div>
							</div> -->


						</div>
					</div>
				</div>
			</div>

		</div>


		<Diyloading ref="loadmodal"></Diyloading>

	</div>

</template>

<script>
	
	import BScroll from 'better-scroll';

	import BankHeaderVue from "./libs/BankHeader.vue"

	import MineHeader from "./libs/MineHeader.vue"

	import Diyloading from "./libs/Diyloading.vue"

	export default {

		name: "betRecord",

		data() {

			return {

				showMore: false,
				zhudanIndex: 0,
				showPingTai: false,
				pingtaiType: 1,


				pingtaiId: 1,

				gamename: 'fc',
				gameType: '2',

				showFilter: 0,
				selectedOption: 'today', // 默认选中的项
				usetime: [],
				strlist: [],
				endlist: [],
				strtime: '',
				endtime: '',
				showtis: '今日',

				current_page: 1,
				recordlists: [],
				first_page_url: "",
				from: 1,
				last_page: 0,
				last_page_url: "",
				next_page_url: "",
				path: "",
				per_page: 0,
				prev_page_url: null,
				to: 0,
				total: 0,
				isLoading: false, // 请求状态标志，控制是否在加载
				currentRequest: null, // 当前请求，用于取消前一个请求


			}

		},
		components: {

			BankHeaderVue,
			MineHeader,
			Diyloading

		},

		created() {

			// 执行“今日”的逻辑
			// const today = new Date();

			// const todayYMD = this.formatDate(today); // 格式化今天的日期

			// this.strtime = todayYMD;

			// this.endtime = todayYMD;

			// this.usetime = [todayYMD, todayYMD];

			// this.getmoneylog();

		},
		computed: {

			userinfo() {
				return this.$store.state.userInfo
			},

			//内容高度
			isLogin() {
				return this.$store.state.token;
			},

			getcasinolist() {
				return this.$store.state.casinolist;
			}

		},
		
		methods: {
			
			openurl(url) {
				window.open(url, '_blank'); // 在新窗口或标签页中打开
			},

			// 格式化时间
			formatDateAlone(dateString) {
				
				const date = new Date(dateString);
				const year = date.getFullYear();
				const month = ('0' + (date.getMonth() + 1)).slice(-2); // 补0
				const day = ('0' + date.getDate()).slice(-2); // 补0
				return `${year}-${month}-${day}`;
				
			},

			getmoneylog() {


				let that = this;

				console.log(that.usetime);

				that.$refs.loadmodal.open(); // 传入标题和消息


				let param = {

					api_name: that.gamename,
					betTime: that.usetime,//["2024-09-01", "2024-10-13"], //
					gameType: that.gameType,
					page: that.current_page,

				};


				that.$apiFun.post("/api/game/record", param).then((res) => {

					// 处理响应数据
					if (res.code === 200) {

						that.current_page = res.data.current_page;

						that.recordlists = res.data.data; // 获取消息列表数据

						// 更新分页参数
						that.first_page_url = res.data.first_page_url;
						that.last_page = res.data.last_page;
						that.next_page_url = res.data.next_page_url;
						that.prev_page_url = res.data.prev_page_url;
						that.total = res.data.total;

						that.showFilter = 0;

						that.$refs.loadmodal.close(); // 传入标题和消息

					}


				}).catch((err) => {
					console.error("请求失败：", err);
				});
				
				
			},

			checktime(timeOption) {

				this.selectedOption = timeOption; // 更新当前选中的选项

				switch (timeOption) {

					case 'today':
						// 执行“今日”的逻辑
						const today = new Date();
						const todayYMD = this.formatDate(today); // 格式化今天的日期
						// console.log('今日:', todayYMD);
						this.usetime = [todayYMD, todayYMD];
						// 执行你需要的“今日”逻辑

						this.showtis = '今日';

						break;

					case 'yesterday':

						// 执行“昨日”的逻辑
						const yesterday = new Date();
						yesterday.setDate(yesterday.getDate() - 1); // 日期减去一天
						const yesterdayYMD = this.formatDate(yesterday); // 格式化昨日日期
						// console.log('昨日:', yesterdayYMD);
						this.usetime = [yesterdayYMD, yesterdayYMD];

						this.showtis = '昨日';
						// 执行你需要的“昨日”逻辑

						this.showFilter = 0;

						break;

					case 'last7days':
						// 执行“近7日”的逻辑
						const last7Days = new Date();

						const today7end = this.formatDate(last7Days); // 格式化今天的日期

						last7Days.setDate(last7Days.getDate() - 7); // 当前日期减去7天

						const last7str = this.formatDate(last7Days); // 获取7天前的日期

						console.log('近7日:', last7str, ' 到 ', today7end);
						this.usetime = [last7str, today7end];

						this.showtis = '近7日';
						// 执行你需要的“近7日”逻辑

						this.showFilter = 0;

						break;

					case 'custom':

						// 执行自定义日期逻辑，获取过去 15 天的日期

						const days = 15;

						const todaylist = new Date();

						const todaynow = this.formatDate(todaylist); // 格式化今天的日期

						console.log(todaynow);

						this.strtime = todaynow;

						this.endtime = todaynow;

						// 定义一个存储日期的数组

						const strlist2 = [];

						// 循环获取过去 15 天的日期
						for (let i = -1; i < days; i++) {

							const date = new Date(todaylist);

							date.setDate(todaylist.getDate() - i);

							// 格式化日期为 YYYY-MM-DD
							const formattedDate = date.toISOString().split('T')[0];

							// 添加到 strlist 数组
							strlist2.push(formattedDate);
						}

						this.strlist = strlist2;

						// 设置显示提示
						this.showtis = '自定义';

						this.showFilter = 2;

						break;

					default:

						console.log('未知的时间选项');
				}


			},

			formatDate(date) {

				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要+1
				const day = String(date.getDate()).padStart(2, '0'); // 补齐两位数
				return `${year}-${month}-${day}`;

			},

			chooseType(typeId) {
				this.pingtaiType = typeId
			},

			choosePingTai(item) {

				this.gamename = item.class_name;
				
				this.gameType = JSON.parse(item.params).gameType;

				this.showPingTai = false;
			},

			getrecordlist() {

				let that = this;

				console.log(1111);


				let param = {
					api_name: that.gamename,
					betTime: that.usetime,
					gameType: that.gameType,
					page: that.current_page
				};


				that.$apiFun.post("/api/game/record", param).then((res) => {

					// 处理响应数据
					if (res.code === 200) {

						that.current_page = res.data.current_page;

						that.recordlists = res.data.data; // 获取消息列表数据

						// 更新分页参数
						that.first_page_url = res.data.first_page_url;
						that.last_page = res.data.last_page;
						that.next_page_url = res.data.next_page_url;
						that.prev_page_url = res.data.prev_page_url;
						that.total = res.data.total;

						that.showFilter = 0;
						// that.$refs.loadmodal.close(); // 传入标题和消息

					}


				}).catch((err) => {
					console.error("请求失败：", err);
				});

			}

		},

		mounted() {
			
			// 执行“今日”的逻辑
			const today = new Date();
			
			const todayYMD = this.formatDate(today); // 格式化今天的日期
			
			this.strtime = todayYMD;
			
			this.endtime = todayYMD;
			
			this.usetime = [todayYMD, todayYMD];
			
			console.log(this.usetime);

			// 初始化 better-scroll
			this.scroll = new BScroll(this.$refs.scrollContainer, {
				pullDownRefresh: true,
				click: true,
				probeType: 2, // 监听滚动事件
			});
			
			// 监听下拉刷新
			this.scroll.on('pullingDown', () => {
				
			  // 在这里调用刷新数据的逻辑
			  // this.getmoneylog(); // 例如刷新第一页数据
			  // 完成下拉刷新后要通知 better-scroll 完成动作
			  this.scroll.finishPullDown();
			  this.scroll.refresh(); // 重新计算滚动区域
			  
			});
			
			// 监听滚动到底部的事件
			this.scroll.on('scroll', (position) => {
				
			  const scrollDiv = this.$refs.scrollContainer;
			
			  console.log('scrollHeight', scrollDiv.scrollHeight);
			  console.log('scrollTop', scrollDiv.scrollTop);
			  console.log('clientHeight + 50', scrollDiv.clientHeight + 50);
			  console.log('isLoading', this.isLoading);
			  console.log('last_page', this.last_page);
			  console.log('current_page', this.current_page);
			
			  // 检查滚动是否接近底部，提前 50px 触发加载更多
			  if (scrollDiv.scrollHeight - scrollDiv.scrollTop < scrollDiv.clientHeight + 50
			      && !this.isLoading 
			      && this.current_page < this.last_page) {
			    this.getmoneylog(); // 加载下一页数据
			  }
			});

		},
		updated() {

		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll);
		},
	}
</script>

<style lang="scss" scoped>
	select {
		width: 33%;
		text-align: center;
		height: 2.125rem;
		line-height: 2.125rem;
		color: #666;
		border: 1px solid #f3f3f3;
		border-radius: 4px;
		/* 移除边框 */
		// outline: none;
		/* 移除高亮边框 */
	}


	.betRecord {

		// overflow-y: auto;
		// height: 100vh;
		
		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				clear: both;
				content: "";
				display: table;
			}
		}

		// .betrecord-page-wrap {
		// 	position: relative;
		// 	color: #202020;
		// 	overflow-y: auto;
		// }

		// .betrecord-info-container {
		// 	position: relative;
		// 	height: 100%;
		// 	overflow-y: auto;
		// }

		.betrecord-search-mask {
			
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(0, 0, 0, .5);
			
		}

		.betrecord-search-bg {

			position: relative;
			background: #ffffff;
			padding: 0.625rem;

		}

		.betrecord-page-name {
			line-height: 1.25rem;
			color: #404e67;
			font-size: 0.875rem;
			font-weight: 700;
			margin: 0 0 0.3125rem 0.5625rem;
		}

		.betrecord-select-wrap {
			
			font-size: 0.9375rem;

			.betrecord-select {
				position: relative;
				width: 100%;
				line-height: 2.125rem;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				background-color: #fff;
				border: 1px solid #f3f3f3;
				border-radius: 0.25rem;
				box-sizing: border-box;
				padding: 0 1.875rem 0 0.625rem;

				&::after {
					position: absolute;
					top: 50%;
					right: 0.9375rem;
					width: 0;
					height: 0;
					content: "";
					border-color: #ababab transparent transparent;
					border-style: solid;
					border-width: 0.375rem 0.25rem 0;
					margin-top: -0.1875rem;
				}
			}

			.betrecord-select.left {
				float: left;
			}
			
		}

		.betrecord-dropdown-wrap {
			
			position: absolute;
			top: 100%;
			left: 0;
			z-index: 1;
			width: 100%;
			overflow-y: scroll;
			background-color: #fff;
			box-sizing: border-box;
			padding: 0.625rem 0.625rem 0;

			.betrecord-option-item {
				display: inline-block;
				min-width: 31.26%;
				height: 1.5625rem;
				line-height: 1.5625rem;
				color: #707070;
				font-size: 0.75rem;
				text-align: center;
				vertical-align: top;
				background: #f3f3f3;
				border: 1px solid transparent;
				box-sizing: border-box;
				border-radius: 0.25rem;
				padding: 0 0.3125rem;
				margin: 0 2.07% 2.8% 0;
			}

			.betrecord-option-item.active {
				color: #55b9ff;
				background: #f0f7ff;
				border-color: #55b9ff;
			}
		}

		.betrecord-search-content {

			font-size: 0.875rem;
			padding: 10px 10px 75px;

			height: 400px;
			/* 固定高度，确保有滚动条 */
			overflow-y: auto;
			/* 启用垂直滚动 */

		}

		.table-game-record {
			display: table;
			width: 100%;
			color: #000;
			font-size: 0.9375rem;
			text-align: center;
			border: 1px solid #eee;
			box-sizing: border-box;
			border-radius: 0.25rem 0.25rem 0 0;

			.table-tr {
				display: table-row;
			}

			.is-header {
				background-color: #ececec;
			}

			.table-th {
				display: table-cell;
				line-height: 2.5rem;
			}

			.table-th+.table-th {
				border-left: 1px solid #fff;
			}

			.table-td {
				display: table-cell;
				line-height: 2.375rem;
			}
			
		}

		.betrecord-select-btn-bg {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			background-color: #f0f7ff;
			box-sizing: border-box;
			box-shadow: 1px 0 8px 0 hsla(0, 0%, 45.5%, 0.2);
			padding: 0.625rem;
		}

		.betrecord-select-btn {
			height: 2.5rem;
			line-height: 2.5rem;
			background: #56b4fd;
			background: linear-gradient(90deg, #7ec6ff, #56b4fd);
			color: #fff;
			font-size: 1rem;
			text-align: center;
			border-radius: 0.25rem;
		}

		.betrecord-platform-pop-mask {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 100;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.8);
		}

		.betrecord-platform-wrap {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			color: #464040;
			transition: bottom 0.2s ease-in;
			overflow: hidden;
			background: #fff;
			border-radius: 1.25rem 1.25rem 0 0;
			box-sizing: border-box;
			font-size: 0.75rem;
		}

		.betrecord-platform-header {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			line-height: 3rem;
			text-align: center;
			background: #fff;
			box-shadow: 0 1px 9px 0 rgba(15, 13, 13, 0.05);
		}

		.betrecord-platform-close {
			position: absolute;
			top: 0;
			left: 1rem;
			color: #c0c4cc;
		}

		.betrecord-platform-content {
			min-height: 65vh;
			max-height: 80vh;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			box-sizing: border-box;
			padding: 3.3125rem 0.625rem 5.5rem;
		}

		.betrecord-platform-subtitle {
			line-height: 1.125rem;
			margin-bottom: 0.5rem;
		}

		.betrecord-menu-btn,
		.betrecord-menu-list-wrap {
			margin-bottom: 0.625rem;
		}

		.betrecord-menu-btn {
			float: left;
			width: 31.3%;
			line-height: 1.5rem;
			color: #707070;
			text-align: center;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			background-color: #f3f3f3;
			border: 1px solid transparent;
			border-radius: 0.25rem;
			box-sizing: border-box;
			padding: 0 0.3125rem;
			margin-right: 3%;
		}

		.betrecord-menu-btn.active {
			color: #55b9ff;
			background-color: #f0f7ff;
			border-color: #55b9ff;
		}

		.betrecord-menu-btn:nth-child(3n) {
			margin-right: 0;
		}

		.betrecord-platform-subtitle {
			line-height: 1.125rem;
			margin-bottom: 0.5rem;
		}

		.betrecord-platform-bottom {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			background-color: #fff;
			box-shadow: 1px 0 8px 0 hsla(0, 0%, 45.5%, 0.2);
			box-sizing: border-box;
			padding: 0.625rem 0.625rem 0.75rem;
		}

		.betrecord-platform-search {
			height: 1.0625rem;
			line-height: 1.0625rem;
			margin-bottom: 0.5rem;
		}

		.betrecord-platform-search-input {
			position: relative;
		}

		.betrecord-platform-search-input input {
			width: 100%;
			height: 2.375rem;
			color: #464040;
			outline: none;
			-webkit-appearance: none;
			background-color: #fff;
			border-radius: 0.25rem;
			border: 1px solid #ddd;
			box-sizing: border-box;
			padding: 0 0.5rem;
		}
	}




	.search-bar-wrap {

		position: relative;
		// background: #f0f7ff;
		text-align: center;
		padding: 0.625rem 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.search-date,
		.second-pay-way,
		.select-pay-way {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			color: #202020;
			background: #fff;
			border: 1px solid #f4f4f4;
			text-align: left;
			box-sizing: border-box;
			height: 2.125rem;
			line-height: 2.125rem;
			border-radius: 0.25rem;
			padding: 0 1.5rem 0 0.625rem;
			font-size: 0.9375rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.search-date,
		.second-pay-way,
		.select-pay-way {
			&::after {
				content: "";
				position: absolute;
				right: 0.5rem;
				top: 50%;
				margin-top: -2px;
				border-color: #ababab transparent transparent;
				border-style: solid;
				border-width: 6px 4px 0;
				width: 0;
				height: 0;
			}
		}

		.select-pay-way {
			width: 40.56%;
			margin-right: 2.81%;
		}

		.search-date {
			// width: 31.26%;
			width: 33%;
		}

		.search-btn {
			display: inline-block;
			vertical-align: middle;
			margin-left: 2.81%;
			background: #55b9ff;
			color: #fff;
			width: 21.3%;
			height: 2.125rem;
			line-height: 2.125rem;
			border-radius: 0.25rem;
			font-size: 1rem;
			height: 2.125rem;
		}


	}

	.date-text {
		color: #707070;
		text-align: right;
		line-height: 1.25rem;
		padding: 0.3125rem 0.625rem;
		font-size: 0.675rem;
		background: #fff;
	}

	.dropdown-wrap {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		text-align: left;
		font-size: 0.7rem;
		z-index: 99;
	}

	.select-wrap {
		position: relative;
		background: #fff;
		transition: height 0.2s ease;
		overflow: hidden;
	}

	.option-wrap {
		padding: 0 0.625rem 0.625rem;
	}

	.option-item {
		display: inline-block;
		vertical-align: middle;
		min-width: 22.81%;
		margin-right: 2.92%;
		border: 1px solid transparent;
		background: #f3f3f3;
		color: #707070;
		box-sizing: border-box;
		text-align: center;
		height: 1.5625rem;
		line-height: 1.4375rem;
		margin-top: 0.625rem;
		border-radius: 0.25rem;
		padding: 0 0.3125rem;
	}

	.option-item.current,
	.option-item:active {
		border-color: #55b9ff;
		background: #f0f7ff;
		color: #55b9ff;
	}

	.option-wrap.way-pay-type .option-item {
		min-width: 31.26%;
		margin-right: 2.07%;
	}

	.option-item:last-child {
		margin-right: 0;
	}

	.customize-date {
		position: relative;
		border-bottom: 1px solid #e9e9e9;
		color: #707070;
		padding: 0 .625rem;
		height: 46px;
		height: 2.875rem;
		line-height: 46px;
		line-height: 2.875rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.clearfix:after,
	.clearfix:before {
		content: "";
		display: table;
	}



	.table-effect {
		width: 100%;
		color: #202020;
		font-size: 13px;
		font-size: .8125rem;
	}

	.table-effect tr {
		display: block;
		overflow: hidden;
		border: 1px solid #ececec;
		border-top: none;
		border-radius: 4px 4px 0 0;
		border-radius: .25rem .25rem 0 0;
		margin-bottom: .75rem;
	}

	.table-effect td:first-child {
		background-color: #ececec;
		padding: .625rem 1.25rem;
		margin: 0;
	}

	.table-effect td {
		display: block;
		border-bottom: 1px solid #e5e5e5;
		// margin: 0 1.25rem;
	}

	.table-effect td .td-head {
		float: left;
		width: 27%;
		text-align: left;
		word-break: break-all;
	}

	.table-effect td .td-body {
		float: left;
		width: 71%;
		padding-left: 2%;
		word-break: break-all;
	}

	.table-effect td {
		display: block;
		border-bottom: 1px solid #e5e5e5;
		margin: 0 1.25rem;
	}

	.table-effect td,
	.table-effect th {
		padding: .375rem 0;
	}

	.betrecord-search-content {
		font-size: 14px;
		font-size: .875rem;
		padding: 10px 10px 75px;
		background: #ffffff;
	}
</style>