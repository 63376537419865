<template>
	<div class="processingOrder">
		<BankHeaderVue title="处理中订单" :isShow="false" />
		<MineHeader />
		<div class="order-page clearfix">
			<div class="order-list-wrap is-mobile">
				<div class="order-list-mask" v-show="showMore" @click="showMore = false"></div>
				<div class="search-header">
					<div class="select-item-wrap single">
						<div class="select-item current">存款</div>
					</div>
					<div class="select-pay-wrap" @click="showMore = true">
						<div class="select-pay">线上存款</div>
					</div>
					<div class="dropdown-wrap" v-show="showMore">
						<div class="order-tab current">线上存款</div>
						<div class="order-tab">公司入款</div>
						<div class="order-tab">加密货币</div>
					</div>
				</div>
				<div class="search-result-hint clearfix">
					<span class="et-time">※页面以美东时间呈现</span><span class="icon-order rever-order current"></span><span
						class="icon-order"></span>
				</div>
				<div>
					<div class="no-data-wrap">
						<img src="/static/image/bg_no_betting.png" alt="" />无处理中订单
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import BankHeaderVue from "./libs/BankHeader.vue";
	import MineHeader from "./libs/MineHeader.vue";
	export default {
		name: "processingOrder",
		data() {
			return {
				showMore: false
			};
		},
		components: {
			BankHeaderVue,
			MineHeader,
		},
	};
</script>
<style lang="scss" scoped>
	.processingOrder {
		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				clear: both;
				content: "";
				display: table;
			}
		}

		.order-list-wrap.is-mobile {
			margin-bottom: 0.625rem;

			.order-list-mask {
				position: fixed;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: rgba(0, 0, 0, 0.5);
			}

			.search-header {
				position: relative;

				.select-item-wrap {
					background: #fff;
					height: 2.375rem;
				}

				.select-item {
					position: relative;
					display: inline-block;
					vertical-align: middle;
					width: 50%;
					color: #202020;
					padding: 0.5625rem;
					font-size: 0.875rem;
					text-align: center;
					box-sizing: border-box;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					cursor: pointer;
				}

				.select-item.current {
					color: #4daffc;

					&::after {
						content: "";
						position: absolute;
						width: 45%;
						height: 2px;
						background: #4daffc;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
					}
				}

				.select-item-wrap.single .select-item {
					width: 100%;
				}

				.select-item-wrap.single .select-item.current::after {
					content: "";
					width: 100%;
				}

				.select-pay-wrap {
					padding: 0.625rem;
					text-align: center;
					background: #f0f7ff;

					.select-pay {
						position: relative;
						display: inline-block;
						width: 100%;
						color: #202020;
						background: #fff;
						border: 1px solid #f4f4f4;
						border-radius: 4px;
						padding: 0 1.3125rem;
						height: 2.125rem;
						line-height: 2.125rem;
						font-size: 0.9375rem;
						box-sizing: border-box;
						cursor: pointer;

						&::after {
							content: "";
							position: absolute;
							right: 15px;
							top: 50%;
							transform: translateY(-50%);
							border-color: #ababab transparent transparent;
							border-style: solid;
							border-width: 6px 4px 0;
							width: 0;
							height: 0;
						}
					}
				}

				.dropdown-wrap {
					position: absolute;
					width: 100%;
					padding: 0 10px 10px;
					background: #fff;
					top: 100%;
					left: 0;
					box-sizing: border-box;

					.order-tab {
						font-size: 0.7rem;
						display: inline-block;
						vertical-align: middle;
						min-width: 31.26%;
						margin-right: 2.07%;
						border: 1px solid transparent;
						background: #f3f3f3;
						color: #707070;
						box-sizing: border-box;
						text-align: center;
						height: 1.5625rem;
						line-height: 1.4375rem;
						margin-top: 0.625rem;
						border-radius: 0.25rem;
						padding: 0 0.3125rem;
					}

					.order-tab.current {
						border-color: #55b9ff;
						background: #f0f7ff;
						color: #55b9ff;
					}
				}
			}

			.search-result-hint {
				padding: 0 0.625rem;
				margin: 1rem 0;

				.et-time {
					font-size: .7rem;
					display: inline-block;
					vertical-align: middle;
					width: calc(100% - 100px);
					color: #676363;
				}

				.icon-order {
					display: inline-block;
					vertical-align: middle;
					width: 40px;
					height: 40px;
					background: #ccc url("/static/image/record_sort_icon.png") 0 -40px no-repeat;
					cursor: pointer;
					border-radius: 4px;
				}

				.icon-order.current,
				.icon-order:hover {
					background-color: #313f52;
				}

				.rever-order {
					background-position: 0 0;
					margin-right: 0.3125rem;
				}
			}

			.no-data-wrap {
				margin-top: 0;
				font-size: 14px;
				text-align: center;

				img {
					display: block;
					margin: 0 auto 16px;
					width: 50%;
					max-width: 180px;
				}
			}
		}
	}
</style>