<template>
	<div class="fanshui">

		<BankHeader :title="$t('我的返水')" :isShow="false" />
		<MineHeader />

		<div class="myrekeback-page-new">

			<div class="radio-wrap clearfix">

				<label class="radio-label">
					<input v-model="fanTab" type="radio" name="realtime" value="realtime" />
					<span class="radio-icon">
						<i :class="[fanTab == 'realtime' ? 'active' : '']"></i>
					</span>
					<span class="radio-text">{{$t('实时返水')}}</span>
				</label>

				<label class="radio-label">
					<input v-model="fanTab" type="radio" name="history" value="history" />
					<span class="radio-icon">
						<i :class="[fanTab == 'history' ? 'active' : '']"></i>
					</span>
					<span class="radio-text">{{$t('返水历史')}}</span>
				</label>

			</div>

			<div class="realtime-wrap clearfix" v-show="fanTab == 'realtime'">

				<div class="step-wrap">
					<div class="step-img"><span></span></div>
					<div class="step-text step-one">{{$t('试算')}}</div>
					<div class="step-text step-two">{{$t('领取')}}</div>
				</div>

				<!-- <div class="time-wrap">
					<div class="time-title">{{$t('本次计算起始')}}<span>(美东时间)</span></div>
					<div class="time-sp-line">|</div>
					<div class="time-content">2024-08-07 00:00:00</div>
				</div> -->

				<!-- disable -->

				<!-- <div :class="[shisuaning == 0 ? 'try-btn disable' : 'try-btn']">{{$t('试算')}}</div> -->

				<!-- <div class="try-btn" @click="getfanshui()">{{$t('试算')}}</div>
				
				<div class="try-btn disable">试算(04:55)</div> -->

				<div class="try-btn" :class="{ disable: isDisabled }" @click="handleClick">
					<span v-if="isDisabled">试算 ({{ countdownTime }})</span>
					<span v-else>{{$t('试算')}}</span>
				</div>

				<!-- <div class="maintain-hint">下列游戏维护中，不列入有效投注计算：PS电子</div> -->

				<table>

					<thead>

						<tr>

							<th>{{$t('周期')}}</th>
							<!-- <th>{{$t('计算区间')}}</th> -->
							<th>{{$t('有效投注')}}</th>
							<th>{{$t('返水金额')}}</th>
							<th>{{$t('操作')}}</th>

						</tr>

					</thead>

					<tbody>

						<tr class="table-column">
							<td>{{$t('周期')}}</td>

							<td>
								<p>{{usetime}} 返水</p>
								<!-- <span class="red">返水限定 9999次 / 可用9999次</span> -->
							</td>

						</tr>

						<!-- <tr class="table-column">
							<td>{{$t('计算区间')}}</td>
							<td>--</td>
							<td><div><div>2024-11-09 00:00:00</div><div>2024-11-09 07:15:05</div></div></td>
						</tr> -->

						<tr>
							<td>{{$t('有效投注')}}</td>
							<td class="" v-if="!canuselink">
								<div class="">--</div>
							</td>
							<td class="" v-else>
								
								<div class="">{{total_valid}}</div>
																	
							</td>
						</tr>

						<tr>
							<td>{{$t('返水金额')}}</td>
							<td class="" v-if="!canuselink">
								<div class="">--</div>
							</td>
							<td class="" v-else>
								
								<div class="">{{fs_money}}</div>
																	
							</td>
						</tr>

						<tr class="table-last-column">

							<td>{{$t('操作')}}</td>

							<td class="" v-if="!canuselink">
								<div class="">--</div>
							</td>

							<td class="" v-else>
								
								<div @click="collectRebate" style="width: 50px;
																	height: 40px;
																	background: rgb(89, 186, 252);
																	border-radius: 4px;
																	text-align: center;
																	line-height: 40px;
																	color: #fff;
																	font-size: 14px;">领取</div>
																	
							</td>

						</tr>

					</tbody>

				</table>

				<ul>

					<li>有效投注额计算以日返为周期，自美东时间0点为计算始点。美东时间每日00:30:00后方可进行试算申请，领取后可至返水历史查看记录。</li>
					<li>系统资料刷新为确保数据正确与有效性，投注额最多可能会有30分钟延迟，建议投注30分钟后进行试算申请实时返水。 《试算服务：每5分钟可使用一次》</li>
					<li>实际领取金额与有效投注计算时间以按下&lt;领取&gt;的时间为主，最低返水金额不得小于0，若未达最低返水金额，则当次不提供领取。</li>
					<li>当日未申请之返水金额，将于次日14:00起由系统自动派发。</li>
					<li>系统资料刷新同步可能造成试算及领取存在落差，如有遗漏或偏差敬请见谅。</li>

				</ul>

			</div>

			<div class="history-wrap" v-show="fanTab == 'history'">

				<div class="tab-wrap clearfix">
					
					<div :class="[dateIndex == 0 ? 'current' : '']" @click="chooseDate(0)">{{$t('今日')}}</div>
					<div :class="[dateIndex == 1 ? 'current' : '']" @click="chooseDate(1)">{{$t('昨日')}}</div>
					<div :class="[dateIndex == 2 ? 'current' : '']" @click="chooseDate(2)">{{$t('最近一周')}}</div>
					
				</div>

				<div class="amount-total" v-if="dateIndex == 0 || dateIndex == 1">{{usetime}}<span>已派金额总计</span>0.00
				</div>

				<!-- <table>

					<tbody>
						<tr>
							<th>{{$t('排序')}}</th>
							<th>{{$t('周期')}}</th>
							<th>{{$t('计算区间')}}</th>
							<th>{{$t('本次有效投注额')}}</th>
							<th>{{$t('返水金额')}}</th>
							<th>{{$t('状态')}}</th>
						</tr>
					</tbody>

				</table> -->

				<div class="no-data">{{$t('尚未产生数据')}}</div>
				<PageFooter :totalPage="10" />
			</div>
		</div>
	</div>
</template>
<script>
	import BankHeader from "./libs/BankHeader.vue"
	import MineHeader from "./libs/MineHeader.vue"
	import PageFooter from "./libs/PageFooter.vue"

	export default {
		name: "fanshui",
		data() {
			return {
				fanTab: "realtime",
				// 返水历史
				dateIndex: 0,
				shisuaning: 0,
				usetime: '',
				fsnowlist: [],

				total_valid: '',
				gameType: '',
				api_names: '',
				ids: '',
				rate: '',
				fs_money: '',
				game_type_text: '',

				isDisabled: false, // 控制按钮禁用状态
				countdownTime: '', // 倒计时显示
				countdown: null, // 倒计时的定时器

				canuselink: false,

			}
		},

		components: {
			BankHeader,
			MineHeader,
			PageFooter
		},

		watch: {


		},
		created() {
			let that = this
		},
		methods: {

			formatDate(date) {

				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要+1
				const day = String(date.getDate()).padStart(2, '0'); // 补齐两位数
				return `${year}-${month}-${day}`;

			},

			chooseDate(index) {

				this.dateIndex = index;

				switch (index) {

					case 0:
						// 执行“今日”的逻辑
						const today = new Date();
						const todayYMD = this.formatDate(today); // 格式化今天的日期
						// console.log('今日:', todayYMD);
						this.usetime = todayYMD;
						break;

					case 1:

						// 执行“昨日”的逻辑
						const yesterday = new Date();
						yesterday.setDate(yesterday.getDate() - 1); // 日期减去一天
						const yesterdayYMD = this.formatDate(yesterday); // 格式化昨日日期
						// console.log('昨日:', yesterdayYMD);
						this.usetime = yesterdayYMD;

						break;

					case 2:
						// 执行“近7日”的逻辑
						// 		const last7Days = new Date();

						// 		const today7end = this.formatDate(last7Days); // 格式化今天的日期

						// 		last7Days.setDate(last7Days.getDate() - 7); // 当前日期减去7天

						// 		const last7str = this.formatDate(last7Days); // 获取7天前的日期

						// 		console.log('近7日:', last7str, ' 到 ', today7end);
						// 		this.usetime = [last7str, today7end];

						// 		this.showtis = '近7日';
						// 		// 执行你需要的“近7日”逻辑

						// 		this.showFilter = 0;

						break;

					case 'custom':

						// 执行自定义日期逻辑，获取过去 15 天的日期

						const days = 15;

						const todaylist = new Date();

						const todaynow = this.formatDate(todaylist); // 格式化今天的日期

						console.log(todaynow);

						this.strtime = todaynow;

						this.endtime = todaynow;

						// 定义一个存储日期的数组

						const strlist2 = [];

						// 循环获取过去 15 天的日期
						for (let i = -1; i < days; i++) {

							const date = new Date(todaylist);

							date.setDate(todaylist.getDate() - i);

							// 格式化日期为 YYYY-MM-DD
							const formattedDate = date.toISOString().split('T')[0];

							// 添加到 strlist 数组
							strlist2.push(formattedDate);
						}

						this.strlist = strlist2;

						// 设置显示提示
						this.showtis = '自定义';

						this.showFilter = 2;

						break;

					default:

						console.log('未知的时间选项');
				}

			},

			handleClick() {
				// 点击试算按钮后的逻辑
				if (!this.isDisabled) {
					this.startCountdown(5 * 1); // 启动 5 分钟倒计时
				}
			},
			startCountdown(duration) {
				// 启动 5 分钟倒计时
				const endTime = Date.now() + duration * 1000;
				localStorage.setItem('countdownEndTime', endTime); // 存储结束时间到 localStorage
				this.updateCountdown(endTime);
			},
			updateCountdown(endTime) {
				this.isDisabled = true; // 禁用试算按钮
				this.countdown = setInterval(() => {
					const remainingTime = Math.floor((endTime - Date.now()) / 1000);
					if (remainingTime <= 0) {
						clearInterval(this.countdown);
						this.isDisabled = false;
						this.countdownTime = '';
						localStorage.removeItem('countdownEndTime'); // 清除倒计时结束时间
						this.getfanshui(); // 倒计时结束后调用 getfanshui 进行自动检查
					} else {
						const minutes = String(Math.floor(remainingTime / 60)).padStart(2, '0');
						const seconds = String(remainingTime % 60).padStart(2, '0');
						this.countdownTime = `${minutes}:${seconds}`;
					}
				}, 1000);
			},
			checkCountdown() {
				// 页面加载时检查倒计时状态
				const endTime = localStorage.getItem('countdownEndTime');
				if (endTime && Date.now() < endTime) {
					this.updateCountdown(endTime);
				} else {
					this.isDisabled = false; // 如果没有倒计时，则启用试算按钮
				}
			},
			getfanshui() {
				// 5分钟后自动调用的函数，检查是否符合领取条件
				this.$apiFun.post('/api/fsnow/list', {})
					.then(res => {
						console.log(res);
						if (res.code != 200) {
							this.$parent.showTost(0, res.message);
						} else if (res.code == 200 && res.data.length > 0) {
							const data = res.data[0];
							this.total_valid = data.total_valid;
							this.gameType = data.gameType;
							this.api_names = data.api_names;
							this.ids = data.ids;
							this.rate = data.rate;
							this.fs_money = data.fs_money;
							this.game_type_text = data.game_type_text;
							this.canuselink = true; // 如果返回数据满足条件，允许领取
						} else {
							this.canuselink = false; // 不满足条件时禁用领取按钮
						}
					})
					.catch(error => {
						console.error(error);
					});
			},

			collectRebate() {
				
				// 领取按钮的点击事件
				if (this.canuselink) {
					
					console.log("领取反水金额");
					// 在这里执行领取逻辑

					this.$apiFun.post('/api/fsnow/fetch', {})
						.then(res => {
							
							console.log(res);
							
							if (res.code != 200) {
								
								this.$parent.showTost(0, res.message);
								
							} else if (res.code == 200) {
								
								this.$parent.showTost(1, res.message);

								this.total_valid = '';
								this.gameType = '';
								this.api_names = '';
								this.ids = '';
								this.rate = '';
								this.fs_money = '';
								this.game_type_text = '';
								this.canuselink = false; // 如果返回数据满足条件，允许领取
							}
						})
						.catch(error => {
							console.error(error);
						});

				}
			}

		},
		mounted() {
			let that = this;
			// 执行“今日”的逻辑
			const today = new Date();
			const todayYMD = this.formatDate(today); // 格式化今天的日期

			that.usetime = todayYMD;

			this.checkCountdown(); // 检查倒计时状态
		},

		beforeDestroy() {
			if (this.countdown) {
				clearInterval(this.countdown); // 清除倒计时以避免内存泄漏
			}
		},

		updated() {
			let that = this
		},

	}
</script>

<style lang="scss" scoped>
	// @import '../../static/css/chunk-10680dc4.7fc8cdde.css';

	.fanshui {

		font-size: 1.125rem;
		padding-bottom: 2rem;
		min-height: 100vh;
		background: #ffffff;

		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				content: "";
				display: table;
				clear: both;
			}
		}

		table {

			border: 1px solid #eaeaea;
			width: 100%;

			thead {
				display: none;
				background-color: #ccc;
				color: #fff;
			}

			tr:first-child {
				border-top: 1px solid #eaeaea;
				border-bottom: 1px solid #eaeaea;
				background-color: #fafafa;
			}

			th {
				padding: 1rem;
				vertical-align: middle;
			}

			td {

				line-height: 20px;
				padding: 15px;

				// .red {
				// 	color: #ff4c72;
				// }

			}

			// td:last-child {
			// 	color: #313f52;
			// }

		}

		.no-data {
			padding: 6rem 0.625rem;
			border: 1px solid #eaeaea;
			color: #ccc;
			text-align: center;
		}

		.myrekeback-page-new {
			padding: 1.875rem 1rem 0;
			box-sizing: border-box;
			background: #fff;

			.radio-wrap {
				text-align: center;

				.radio-label {
					display: inline-block;

					input {
						display: none;
					}

					.radio-icon {
						height: 1.75rem;
						width: 1.75rem;
						position: relative;
						float: left;
						border: 1px solid #ccc;
						border-radius: 100%;

						.active {
							top: 0.25rem;
							left: 0.25rem;
							height: 1.25rem;
							width: 1.25rem;
							position: absolute;
							background-color: #ccc;
							border-radius: 100%;
						}
					}

					.radio-text {
						padding-left: 0.4rem;
						padding-right: 1rem;
						height: 1.875rem;
						line-height: 1.875rem;
						float: left;
						color: #313f52;
					}
				}
			}

			.realtime-wrap {
				.step-wrap {
					text-align: center;
					margin-top: 1.938rem;
					margin-bottom: 1.75rem;

					.step-img {
						margin: 0 auto 0.313rem;
						width: 11.625rem;
						height: 2.75rem;
						line-height: 2.75rem;
						font-size: 1.375rem;
						position: relative;
						color: #fff;

						&::before {
							content: "1";
							width: 2.75rem;
							height: 2.75rem;
							position: absolute;
							top: 0;
							left: 0;
							border-radius: 50%;
							background: #304154;
						}

						&::after {
							content: "2";
							width: 2.75rem;
							height: 2.75rem;
							position: absolute;
							top: 0;
							right: 0;
							border-radius: 50%;
							background: #304154;
						}

						span {
							position: absolute;
							top: 1.375rem;
							left: 2.75rem;
							width: 6.125rem;
							height: 0.125rem;
							background: #304154;
						}
					}

					.step-text {
						display: inline-block;
						width: 6.25rem;
						color: #304154;
						vertical-align: top;
					}

					.step-text.step-one {
						margin-right: 1.313rem;
					}

					.step-text.step-two {
						margin-left: 1.313rem;
					}
				}

				.time-wrap {
					margin-bottom: 1.063rem;
					border: 1px solid #e5e5e5;
					background: #f6f6f6;

					div {
						padding: 0.938rem;
					}

					.time-title {
						border-bottom: 1px solid #e5e5e5;

						span {
							font-size: 0.938rem;
						}
					}

					.time-sp-line {
						display: none;
						color: #ebebeb;
					}

					.time-content {
						background: #fff;
					}
				}

				.try-btn {

					background-color: #59bafc;
					padding: 0;
					border: none;
					min-width: 8rem;
					height: 3.125rem;
					line-height: 3.125rem;
					border-radius: 3.125rem;
					font-size: 1.125rem;
					color: #fff;
					text-align: center;
					cursor: pointer;
					margin: 1.063rem 0 1.063rem;

				}

				.disable {
					background-color: #eee;
					color: #ccc;
					cursor: not-allowed;
				}

				.maintain-hint {
					margin-bottom: 1.063rem;
					color: #ff4c72;
					text-align: center;
				}

				// ul {
				// 	color: #999;
				// 	line-height: 2.5rem;
				// }
			}

			.history-wrap {
				.tab-wrap {
					margin-top: 1.5rem;
					margin-bottom: 1.5rem;

					div {
						padding-top: 1.25rem;
						padding-bottom: 1.25rem;
						line-height: 1.25rem;
						float: left;
						border-right: 1px solid #fff;
						background: #ccc;
						width: 33.3%;
						font-weight: 700;
						text-align: center;
						color: #fff;
						cursor: pointer;
						box-sizing: border-box;
						word-break: break-all;
					}

					div.current {
						background: #314053;
					}
				}

				.amount-total {
					padding: 1.063rem 4%;
					border: 1px solid #eaeaea;
					border-bottom: none;
					color: #54b9ff;

					span {
						margin: 0 4% 0 2%;
						color: #313f52;
					}
				}
			}
		}
	}

	ul {
		display: block;
		list-style-type: disc;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
		unicode-bidi: isolate;
	}

	ul {
		color: #999;
		line-height: 40px;
		line-height: 2.5rem;
	}

	::marker {
		unicode-bidi: isolate;
		font-variant-numeric: tabular-nums;
		text-transform: none;
		text-indent: 0px !important;
		text-align: start !important;
		text-align-last: start !important;
	}

	td {

		line-height: 20px;
		padding: 15px;

		.red {
			color: #ff4c72;
		}

	}

	tr {

		line-height: 20px;
		padding: 15px;

		.red {
			color: #ff4c72;
		}

	}
</style>