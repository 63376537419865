import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/components/Main'

import index from '@/components/mode/index'
import app from '@/components/mode/app'
import gamePage from '@/components/mode/gamePage'
import gamePlay from '@/components/gamePlay'
import mine from '@/components/mode/mine'

import login from '@/components/login'
import joinus from '@/components/joinus'
import boutBallBet from '@/components/boutBallBet'
import message from '@/components/message'
import fanshui from '@/components/fanshui'
import userInfo from '@/components/userInfo'
import password from '@/components/password'
import betRecord from '@/components/betRecord'
import recharge from '@/components/recharge'
import withdrawal from '@/components/withdrawal'
import transfer from '@/components/transfer'
import reward from '@/components/reward'
import interest from '@/components/interest'
import interestActivityList from '@/components/interestActivityList' //更多活动
import moneySwitch from '@/components/moneySwitch'
import effectiveBetting from '@/components/effectiveBetting'
import betInfo from '@/components/betInfo'
import cashRecord from '@/components/cashRecord'
import processingOrder from '@/components/processingOrder'
import news from '@/components/news'
import gameNews from '@/components/gameNews'
import feedback from '@/components/feedback'
import withdrawPassword from '@/components/withdrawPassword'
import email from '@/components/email'
import gameZone from '@/components/gameZone'
import zoneHistory from '@/components/zoneHistory'
import language from '@/components/language'
import forgetpassword from '@/components/forgetpassword'

import promotion from '@/components/promotion'
import sign from '@/components/sign'
import recommend from '@/components/recommend'
import card from '@/components/card'
import casino from '@/components/casino'


Router.prototype.goBack = function(n) {
	this.isBack = true
	window.history.go(n)
}

Vue.use(Router);

export default new Router({

	// mode: 'history',
	// meta: {
	//   requireAuth: true,
	//   keepAlive: true,//是否缓存组件
	//   useCatch:false//是否用缓存
	// }
	// mode: 'hash',

	routes: [

		{
			path: '/',
			name: 'Main',
			component: Main,
			children: [{
					path: '/',
					name: 'index',
					component: index,
					meta: {
						keepAlive: true, //是否缓存组件
						useCatch: false, //是否用缓存
						requireAuth: false, // 是否需要登录
					}
				},

				{
					path: '/mine',
					name: 'mine',
					component: mine,
					meta: {
						keepAlive: true, //是否缓存组件
						useCatch: false, //是否用缓存
						requireAuth: true, //是否需要登录
					}
				},
				{
					path: '/app',
					name: 'app',
					component: app,
					meta: {
						keepAlive: true, //是否缓存组件
						useCatch: false, //是否用缓存
						requireAuth: false,
					}
				},
			]
		},

		{
			path: '/login',
			name: 'login',
			component: login,
		},

		{
			path: '/joinus',
			name: 'joinus',
			component: joinus,
		},

		{
			path: '/gamePage',
			name: 'gamePage',
			component: gamePage,
		},
		
		{
			path: '/gamePlay',
			name: 'gamePlay',
			component: gamePlay,
			meta: {
				requireAuth: true,
			}
		},
		
		{
			path: '/boutBallBet',
			name: 'boutBallBet',
			component: boutBallBet,
		}, {
			path: '/message',
			name: 'message',
			component: message,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/fanshui',
			name: 'fanshui',
			component: fanshui,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/userInfo',
			name: 'userInfo',
			component: userInfo,
			meta: {
				requireAuth: true,
			}
		},

		{
			path: '/sign',
			name: '/sign',
			component: sign,
			meta: {
				requireAuth: true,
			}
		},

		{
			path: '/forget-password',
			name: '/forget-password',
			component: forgetpassword,
			meta: {
				requireAuth: false,
			}
		},

		{

			path: '/promotion/:recommend',
			name: '/promotion',
			component: promotion,
			meta: {
				requireAuth: false,
			},

		},


		{

			path: '/recommend',
			name: '/recommend',
			component: recommend,
			meta: {
				requireAuth: false,
			}

		},

		{

			path: '/casino',
			name: '/casino',
			component: casino,
			meta: {
				requireAuth: false,
			}

		},

		{

			path: '/card',
			name: '/card',
			component: card,
			meta: {
				requireAuth: false,
			}

		},


		{

			path: '/password',
			name: 'password',
			component: password,
			meta: {
				requireAuth: true,
			}

		},


		{
			path: '/betRecord',
			name: 'betRecord',
			component: betRecord,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/recharge',
			name: 'recharge',
			component: recharge,
			meta: {
				requireAuth: true,
				keepAlive: true, //是否缓存组件
				useCatch: false //是否用缓存
			}
		},

		{
			path: '/withdrawal',
			name: 'withdrawal',
			component: withdrawal,
			meta: {
				requireAuth: true,

			}
		}, {
			path: '/transfer',
			name: 'transfer',
			component: transfer,
			meta: {
				requireAuth: true,

			}
		}, {
			path: '/reward',
			name: 'reward',
			component: reward,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/interest',
			name: 'interest',
			component: interest,
			meta: {
				requireAuth: true,
			}
		},
		{
			path: '/interestActivityList',
			name: 'interestActivityList',
			component: interestActivityList,
			meta: {
				requireAuth: true,
			}
		},
		{
			path: '/moneySwitch',
			name: 'moneySwitch',
			component: moneySwitch,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/effectiveBetting',
			name: 'effectiveBetting',
			component: effectiveBetting,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/betInfo',
			name: 'betInfo',
			component: betInfo,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/cashRecord',
			name: 'cashRecord',
			component: cashRecord,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/processingOrder',
			name: 'processingOrder',
			component: processingOrder,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/news',
			name: 'news',
			component: news,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/gameNews',
			name: 'gameNews',
			component: gameNews,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/feedback',
			name: 'feedback',
			component: feedback,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/withdrawPassword',
			name: 'withdrawPassword',
			component: withdrawPassword,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/email',
			name: 'email',
			component: email,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/gameZone',
			name: 'gameZone',
			component: gameZone,
			meta: {
				requireAuth: true,
			}
		}, {
			path: '/zoneHistory',
			name: 'zoneHistory',
			component: zoneHistory,
			meta: {
				requireAuth: true,
			}
		},

		{
			path: '/language',
			name: 'language',
			component: language,
			meta: {
				requireAuth: false,
			}
		},

		{
			path: '*', // 重定向页面地址
			redirect: '/'
		}

	]
})