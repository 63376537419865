<template>
	<div class="mine">
		<div class="account-info-area-wrap">

			<div class="account-page-title">
				会员中心<a class="account-info-logout-btn" @click="$parent.outLogin">登出</a>
			</div>

			<div class="account-user-info-area">

				<div class="account-user-img-edit">
					<!-- <img src="https://g1.lizixin.cool/m/img/payonline/icon_mcenter/theme/theme10/icon_photo.png" /> -->
					<img src="/static/image/icon_photo.png" />
					<span class="account-user-edit-icon"></span>
					<input type="file" accept="image/x-png,image/gif,image/jpg,image/jpeg" />
				</div>

				<div class="account-userinfo-inner">

					<div class="userinfo-item userinfo-account">
						<span class="user-account" v-if="$store.state.token">{{userinfo.name}}</span>
					</div>

					<div class="userinfo-item userinfo-balance">
						{{userinfo.money}}
						<div class="userinfo-right-wrap" @click="showBalance = true">
							<span class="userinfo-balance-btn">{{$t('更多余额')}}</span>
						</div>

					</div>

				</div>


			</div>

		</div>


		<div class="mobile-vip-bg">

			<div class="mobile-vip-detail is-single">

				<div class="vip-title full">

					<div class="vip-name-wrap">
						<div class="vip-name-info">
							<img class="vip-icon" src="/static/image/level_customized_default.png" />
							<div class="vip-name">一般会员</div>
							<span class="vip-more-btn" @click="vipdetail()">详情</span>
						</div>
					</div>

				</div>

				<div class="vip-progress-wrap undefined">

					<div class="vip-progress">
						<div class="vip-progress-inner" style="width: 0%"></div>
						<div class="vip-progress-dot-wrap">
							<div class="vip-progress-dot" style="left: 0%">0%</div>
						</div>
					</div>
					<div class="vip-info-name-wrap clearfix">
						<div class="vip-info-name">一般会员</div>
						<div class="vip-info-nextname">VIP {{userinfo.nextvip}}</div>
					</div>

				</div>

			</div>
		</div>


		<div class="money-link-info-wrap">

			<div class="money-link-wrap clearfix">
				<a class="money-link money-link-depositPlus money-length-4" @click="$parent.goNav('/recharge')"><span><i
							class="money-icon-depositPlus"></i>
						<p>{{$t('充值')}}</p>
					</span></a><a class="money-link money-link-withdrawPlus money-length-4"
					@click="$parent.goNav('/withdrawal')"><span><i class="money-icon-withdrawPlus"></i>
						<p>{{$t('提现')}}</p>
					</span></a><a class="money-link money-link-reward money-length-4"
					@click="$parent.goNav('/reward')"><span><i class="money-icon-reward"></i>
						<p>{{$t('优惠中心')}}</p>
					</span></a><a class="money-link money-link-moneySwitch money-length-4"
					@click="$parent.goNav('/moneySwitch')"><span><i class="money-icon-moneySwitch"></i>
						<p>{{$t('额度转换')}}</p>
					</span></a>
			</div>

		</div>

		<div class="mobile-custom-content">


			<!-- <div class="mobile-custom-promotion-wrap" @click="bannerClick('/')">

				<img src="/static/image/banner.png" />

			</div> -->


			<div class="bank-data-container clearfix">

				<div class="info-link-wrap">

					<div class="info-link-group">
						
						<div class="info-link-title">{{$t('我的账户')}}</div>
						
						<a class="info-link info-link-memberData" @click="$parent.goNav('/userInfo')">{{$t('基本资料')}}<i
								class="right-arrow"></i></a>
						
						<a class="info-link info-link-effectiveBetting"
							@click="$parent.goNav('/effectiveBetting')">{{$t('有效投注额')}}<i class="right-arrow"></i></a>
						
						<a class="info-link info-link-betInfo" @click="$parent.goNav('/userInfo')"> 
							{{$t('修改密码')}} <i class="right-arrow"></i></a>
						
						
						<!-- <a
							class="info-link info-link-betInfo" @click="$parent.goNav('/betInfo')">{{$t('个人游戏限额')}}<i
								class="right-arrow"></i></a> -->

						<!-- <a class="info-link info-link-feedback" @click="$parent.goNav('/feedback')">{{$t('意见回馈')}}<i
								class="right-arrow"></i></a> -->

					</div>

					<div class="info-link-group">

						<div class="info-link-title">{{$t('自助服务')}}</div>


						<a class="info-link info-link-myRakeback" @click="$parent.goNav('/fanshui')">
							{{$t('我的返水')}}
							<i class="right-arrow"></i>

						</a>

						<div class="info-link info-link-offer" @click="$parent.goNav('/promotion/222')">
							{{$t('我的优惠')}}
							<i class="right-arrow"></i>
						</div>

						<a class="info-link info-link-interest" href="#/interest">{{$t('利息宝')}}<i
								class="right-arrow"></i></a>
					</div>

					<div class="info-link-group">
						<div class="info-link-title">{{$t('往来记录')}}</div>
						<a class="info-link info-link-betRecord" @click="$parent.goNav('/betRecord')">{{$t('投注记录')}}<i
								class="right-arrow"></i></a><a class="info-link info-link-cashRecord"
							@click="$parent.goNav('/cashRecord')">{{$t('往来记录')}}<i class="right-arrow"></i></a><a
							class="info-link info-link-processingOrder"
							@click="$parent.goNav('/processingOrder')">{{$t('处理中订单')}}<i class="right-arrow"></i></a>
					</div>

					<div class="info-link-group">
						
						<div class="info-link-title">{{$t('信息公告')}}</div>
						
						<a class="info-link info-link-news" @click="$parent.goNav('/news')">{{$t('最新公告')}}<i class="right-arrow"></i></a>
						<!-- <a class="info-link info-link-userMsg" @click="$parent.goNav('/message')">{{$t('个人信息')}}
						<span class="unread-count-icon">1</span>
						<i class="right-arrow"></i></a> -->

						<!-- gameNews -->

						<!-- <a class="info-link info-link-gameNews"
							@click="$parent.goNav('/error')">{{$t('游戏公告')}}<i class="right-arrow"></i></a> -->
						
						<a class="info-link info-link-feedback" @click="$parent.goNav('/feedback')">{{$t('意见回馈')}}<i
								class="right-arrow"></i></a>

					</div>
				</div>

			</div>

		</div>

		<!-- vipshow -->

		<van-popup v-model="vipshow" position="center" style="background-color: transparent;">


			<div class="dialog-content">
				<div class="dialog-close-btn" @click="vipshow = false"></div>
				<div class="dialog-body">
					<div class="dialog-list" style="max-height: 587px;">
						<div class="dialog-item">
							<div class="dialog-item-title">
								<div class="dialog-item-icon-wrap"><img class="dialog-item-icon"
										src="https://g1.cfvn66.com/infe/mcenter/images/level_customized_default.png">
								</div>
								<div class="dialog-name-wrap">
									<div class="dialog-name-left">
										<div class="dialog-item-name">终身累计打码</div>
									</div>
									<div class="dialog-name-right">每日04:00更新</div>
								</div>
								<div class="vip-progress-wrap dialog-progress-bar">
									<div class="vip-progress">
										<div class="vip-progress-inner" style="width: 0%;"></div>
										<div class="vip-progress-dot-wrap">
											<div class="vip-progress-dot" style="left: 0%;">
												<!-- react-text: 280 -->0<!-- /react-text --><!-- react-text: 281 -->%<!-- /react-text -->
											</div>
										</div>
									</div>
									<div class="vip-info-name-wrap clearfix">
										<div class="vip-info-name">一般会员</div>
										<div class="vip-info-nextname">VIP 1</div>
									</div>
								</div>
								<!-- react-text: 285 --><!-- /react-text --><!-- react-text: 286 --><!-- /react-text -->
								<div class="vip-multiple-wrap"></div>
							</div>
							<div class="update-period">本次结算周期: 2024-10-26 ~ 2024-10-26</div>
							<div class="dialog-item-main">
								<div class="dialog-item-main-head">升级还需</div>
								<div class="dialog-item-main-body">
									<!-- react-text: 292 -->有效投注<!-- /react-text --><!-- react-text: 293 -->：<!-- /react-text --><span>1,000</span>
								</div>
								<div class="dialog-item-main-body">
									<!-- react-text: 296 -->存款金额<!-- /react-text --><!-- react-text: 297 -->：<!-- /react-text --><span>1</span>
								</div>
								<div class="dialog-item-main-body">
									<!-- react-text: 300 -->存款次数<!-- /react-text --><!-- react-text: 301 -->：<!-- /react-text --><span>1</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		</van-popup>
		
		

		<Balance :showBalance="showBalance" :showBtn="false" @closeBalance="showBalance = false" />

		<Foot />

	</div>
</template>
<script>
	import Foot from '../libs/Foot.vue';
	import Balance from "../libs/Balance.vue"
	export default {
		name: "mine",
		data() {
			return {
				showBalance: false,
				vipshow: false,
			}
		},
		components: {
			Foot,
			Balance
		},

		computed: {
			userinfo() {
				return this.$store.state.userInfo
			},
			//内容高度
			isLogin() {
				return this.$store.state.token;
			},
		},
		created() {

			let that = this;
			that.isLogin = that.$store.state.token;
			const userinfo = that.$store.state.userInfo; //localStorage.getItem('userInfo');
			if (userinfo) {
				that.userinfo = userinfo;
			}

		},
		methods: {

			vipdetail() {
				this.vipshow = true;
			},
			// 轮播图跳转
			bannerClick(item) {

				if (item.jump_link) {
					if (item.is_new_window) {
						window.open(item.jump_link, '_blank'); // 在新窗口中打开链接
					} else {
						window.location.href = item.jump_link; // 在当前窗口中打开链接
					}
				}

			},
		},
		mounted() {
			let that = this
		},
		updated() {
			let that = this
		},
		beforeDestroy() {},
	}
</script>

<style lang="scss" scoped>
	// @import '../../../static/css/chunk-4deac3d0.11461b5f.css';
	.mine {
		position: relative;
		background-color: #f8f8f8;

		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				clear: both;
				content: "";
				display: table;
			}
		}

		.account-info-area-wrap {
			height: 134px;
			color: #fff;
			font-size: 13px;
			background: linear-gradient(#4eaafc, #1c5cfb);

			.account-page-title {
				position: relative;
				height: 39px;
				line-height: 39px;
				font-size: 16px;
				text-align: center;

				.account-info-logout-btn {
					position: absolute;
					top: 0;
					right: 19px;
					color: #fff;
					font-size: 13px;
					text-decoration: none;
				}
			}

			.account-user-info-area {
				position: relative;
				height: 60px;
				padding: 0 15px 0 88px;
				margin-top: 8px;

				.account-user-img-edit {
					position: absolute;
					top: 0;
					left: 15px;
					background-color: #fff;
					border-radius: 50%;
					padding: 2px;

					img {
						display: block;
						width: 56px;
						height: 56px;
						border-radius: 50%;
					}

					.account-user-edit-icon {
						position: absolute;
						bottom: 4px;
						right: 1px;
						width: 15px;
						height: 15px;
						background-image: url("/static/image/icon_change.png");
						background-size: 100%;
					}

					input {
						position: absolute;
						top: 0;
						left: 0;
						width: 60px;
						height: 60px;
						text-indent: -99999px;
						outline: none;
						background-color: transparent;
					}
				}

				.account-userinfo-inner {
					padding-top: 6px;

					.userinfo-item {
						height: 23px;
						line-height: 23px;

						.userinfo-right-wrap {
							float: right;

							.userinfo-balance-btn {
								display: block;
								height: 23px;
								line-height: 23px;
								font-size: 12px;
								background: #fff;
								color: #2472fc;
								border-radius: 10px;
								padding: 0 11px;
							}
						}
					}

					.userinfo-account {
						.user-account {
							color: #ffd220;
							font-size: 0.75rem;
						}
					}
				}
			}
		}

		.mobile-vip-bg {
			float: none;
			position: relative;
			top: 0;
			left: 0;
			margin-top: -1.25rem;
			width: 100%;
			border: 0;

			.mobile-vip-detail {
				background: linear-gradient(#fff, #fff 27%, #edf1fe);
				border: 1px solid #ddd;
				border-top: none;
				border-radius: 0 0 4px 4px;
				border-radius: 0 0 0.25rem 0.25rem;
				padding: 1rem 0.75rem;
				margin: 0 10px;

				.vip-title {
					color: #3f3f3f;
					line-height: 0.9375rem;
					font-size: 0.9375rem;
					position: relative;

					.vip-name-wrap {
						position: relative;

						.vip-name-info {
							padding-left: 1.875rem;
							height: 1.5rem;
							position: relative;
							display: inline-block;
							vertical-align: top;
							max-width: 100%;
							box-sizing: border-box;
							padding: 0 3.375rem 0 2.5rem;
							font-size: 0.875rem;

							.vip-icon {
								width: 1.5rem;
								height: 1.5rem;
								position: absolute;
								top: 0.0625rem;
								left: 0;
							}

							.vip-name {
								line-height: 1.5rem;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
								font-weight: 700;
							}

							.vip-more-btn {
								color: #989898;
								height: 1.5rem;
								line-height: 1.5rem;
								background: url("/static/image/icon_arrow_back.png") 100% 50% no-repeat;
								background-size: 0.3125rem 0.5rem;
								max-width: 2.5rem;
								padding-right: 0.625rem;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								position: absolute;
								top: 0;
								right: 0;
							}
						}
					}
				}

				.vip-progress-wrap {
					font-size: 0.6875rem;

					.vip-progress {
						position: relative;
						border-radius: 0.25rem;
						background: #bbb;

						.vip-progress-inner {
							background: #d6a566;
							border-radius: 3px;
							height: 0.5rem;
						}

						.vip-progress-dot-wrap {
							position: absolute;
							left: 0;
							top: 0;
							margin-left: 1.4375rem;
							width: calc(100% - 2.875rem);
							height: 0.5rem;

							.vip-progress-dot {
								position: absolute;
								top: -0.3125rem;
								z-index: 1;
								margin-left: -1.4375rem;
								border-radius: 0.5625rem;
								background: #d6a566;
								width: 2.875rem;
								height: 1.125rem;
								line-height: 1.125rem;
								text-align: center;
								color: #fff;
								font-weight: 700;
							}
						}
					}

					.vip-info-name-wrap {
						padding-top: 0.625rem;

						.vip-info-name {
							float: left;
							color: #d6a566;
							width: 50%;
						}

						.vip-info-nextname {
							float: right;
							color: #bbb;
							text-align: right;
							width: 50%;
						}
					}
				}
			}

			.is-single {
				padding-bottom: 1.0625rem;

				.vip-title {
					margin-bottom: 0.6875rem;
				}

				.full {
					padding-right: 0;
				}
			}
		}

		.money-link-info-wrap {
			margin-top: 0.25rem;
			overflow: hidden;
			background-color: #fff;
			background: linear-gradient(#fff, #fff 27%, #edf1fe);
			border: 1px solid #e9e9e9;
			border-radius: 4px;
			margin: 5px 10px 4px;
			position: relative;
			z-index: 1;

			.money-link-wrap {
				display: table;
				width: 100%;
				table-layout: fixed;
				box-sizing: border-box;
				text-align: center;

				.money-link {
					color: #989898;
					font-size: 13px;
					font-weight: 400;
					background: #fff;
					border: none;
					display: table-cell;
					vertical-align: middle;
					text-align: center;
					padding: 8px 0 9px;
					box-sizing: border-box;

					span {
						position: relative;
						display: block;

						i {
							display: inline-block;
							width: 42px;
							height: 42px;
						}

						.money-icon-depositPlus {
							background: url("/static/image/btn_deposit_n.png") 0 0 no-repeat;
							background-size: contain;
						}

						.money-icon-withdrawPlus {
							background: url("/static/image/btn_withdrawal_n.png") 0 0 no-repeat;
							background-size: contain;
						}

						.money-icon-reward {
							background: url("/static/image/btn_backwater_n.png") 0 0 no-repeat;
							background-size: contain;
						}

						.money-icon-moneySwitch {
							background: url("/static/image/btn_trans_n.png") 0 0 no-repeat;
							background-size: contain;
						}

						p {
							margin-top: 4px;
							margin-bottom: 0;
							word-break: break-word;
						}
					}
				}

				.money-length-4 {
					width: 25%;
				}
			}
		}

		.mobile-custom-content {
			padding: 0 9px;
			margin-bottom: 80px;

			.mobile-custom-promotion-wrap {
				display: block;
				overflow: hidden;
				border: 1px solid #e9e9e9;
				border-radius: 4px;
				margin-bottom: 4px;

				img {
					display: block;
					max-width: 100%;
				}
			}

			.bank-data-container {
				.info-link-wrap {
					background: linear-gradient(#fff, #fff 27%, #edf1fe);
					border: 1px solid #e9e9e9;
					border-radius: 4px;
					padding: 0 25px;

					.info-link-group {
						position: relative;
						border-bottom: 1px solid #e9e9e9;
						padding: 10px 0 6px 20%;

						&::before {
							position: absolute;
							top: 43px;
							left: 0;
							width: 0.25rem;
							height: 22px;
							content: "";
							vertical-align: middle;
							background-color: #3b3b3b;
							margin-top: -12px;
						}

						.info-link-title {
							position: absolute;
							top: 43px;
							left: 10px;
							width: 20%;
							height: 18px;
							line-height: 18px;
							color: #3b3b3b;
							font-size: 13px;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							margin-top: -9px;
						}

						.info-link {
							display: inline-block;
							width: 33.333%;
							color: #3b3b3b;
							text-align: center;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							vertical-align: middle;
							padding: 10px 0;
							font-size: 0.7rem;

							&::before {
								display: block;
								width: 30px;
								height: 30px;
								content: "";
								background-size: cover;
								margin: 0 auto 3px;
							}
						}

						.info-link-memberData {
							&::before {
								background-image: url("/static/image/icon_memberdata_n.png");
							}
						}

						.info-link-effectiveBetting {
							&::before {
								background-image: url("/static/image/icon_effectivebetting_n.png");
							}
						}

						.info-link-betInfo {
							&::before {
								background-image: url("/static/image/icon_betinfo_n.png");
							}
						}

						.info-link-myRakeback {
							&::before {
								background-image: url("/static/image/icon_myrakeback_n.png");
							}
						}

						.info-link-offer {
							&::before {
								background-image: url("/static/image/icon_offer_n.png");
							}
						}

						.info-link-interest {
							&::before {
								background-image: url("/static/image/icon_interest_n.png");
							}
						}

						.info-link-betRecord {
							&::before {
								background-image: url("/static/image/icon_betrecord_n.png");
							}
						}

						.info-link-cashRecord {
							&::before {
								background-image: url("/static/image/icon_cashrecord_n.png");
							}
						}

						.info-link-processingOrder {
							&::before {
								background-image: url("/static/image/icon_processingorder_n.png");
							}
						}

						.info-link-news {
							&::before {
								background-image: url("/static/image/icon_news_n.png");
							}
						}

						.info-link-userMsg {
							position: relative;

							&::before {
								background-image: url("/static/image/icon_usermsg_n.png");
							}

							.unread-count-icon {
								position: absolute;
								top: 3px;
								right: 50%;
								width: 30px;
								height: 17px;
								line-height: 17px;
								transform: translateX(25px);
								background: #ff4c73;
								border-radius: 10px;
								text-align: center;
								font-size: 12px;
								color: #fff;
							}
						}

						.info-link-gameNews {
							&::before {
								background-image: url("/static/image/icon_gamenews_n.png");
							}
						}

						.info-link-feedback {
							&::before {
								background-image: url("/static/image/icon_feedback_n.png");
							}
						}
					}
				}
			}
		}
	}


	.dialog-content {
		// position: relative;
		// z-index: 2;
		// margin: 0 auto;
		width: 94vw;
		// max-width: 600px;
		// text-align: left;
		padding-bottom: 2.1875rem;
		font-size: 75%;
	}

	.dialog-content .dialog-close-btn {
		position: absolute;
		bottom: 0;
		left: 50%;
		background: url('https://g1.cfvn66.com/infe/mcenter/images/mobile/btn_close.png') 0 100% no-repeat;
		background-size: cover;
		width: 24px;
		height: 24px;
		margin-left: -.9375rem;
	}

	.dialog-content .dialog-body {
		background: #fff;
		border-radius: 10px;
		border-radius: .625rem;
	}

	.dialog-body {
		position: relative;
	}

	.dialog-list {
		box-sizing: border-box;
		border-radius: 10px;
		padding-top: 1rem;
		background: #fff;
		overflow: auto;
	}

	.dialog-item {
		position: relative;
		color: #34343f;
		padding-bottom: .75rem;
	}

	.dialog-item .dialog-item-title {
		position: relative;
		min-height: 53px;
		min-height: 3.3125rem;
		color: #34343f;
		box-sizing: border-box;
		padding: .1875rem 1.0625rem 0 4.5625rem;
	}

	.dialog-item .dialog-item-title .dialog-item-icon-wrap {
		position: absolute;
		top: 0;
		left: 17px;
		left: 1.0625rem;
		width: 50px;
		width: 3.125rem;
		height: 50px;
		height: 3.125rem;
	}

	.dialog-item .dialog-item-title .dialog-item-icon {
		display: block;
		width: 100%;
	}

	.dialog-item .dialog-item-title .dialog-name-wrap {
		display: flex;
		align-items: center;
		padding-bottom: .3125rem;
	}

	.dialog-item .dialog-item-title .dialog-name-wrap .dialog-name-left {
		flex: 3;
	}

	.dialog-item .dialog-item-title .dialog-name-wrap .dialog-name-left .dialog-item-name {
		font-weight: 700;
		color: #999;
	}

	.dialog-item .dialog-item-title .dialog-name-wrap .dialog-name-right {
		flex: 2;
		text-align: right;
		color: #999;
	}

	.vip-progress-wrap.dialog-progress-bar {
		margin: .375rem 0 .1875rem;
	}

	.vip-progress-wrap {
		font-size: 11px;
		font-size: .6875rem;
	}

	.dialog-item .dialog-item-title .vip-multiple-wrap {
		color: #868686;
	}

	.dialog-item .update-period {
		background: #f3f3f3;
		text-align: center;
		color: #a0a0a0;
		padding: .3125rem .1875rem;
		margin: .3125rem 1.1875rem .5625rem;
	}

	.dialog-item .dialog-item-main {
		color: #3f3f3f;
	}

	.dialog-item .dialog-item-main {
		box-sizing: border-box;
		padding: 0 1.1875rem;
		margin-bottom: .375rem;
	}

	.dialog-item .dialog-item-main .dialog-item-main-head {
		font-size: 13px;
		font-size: .8125rem;
		color: #34343f;
		font-weight: 700;
	}

	.dialog-item .dialog-item-main .dialog-item-main-body {
		padding: 2px 0;
		word-break: break-all;
	}

	.dialog-item .dialog-item-main .dialog-item-main-body span {
		color: #d6a566;
	}
	
	.vip-progress-wrap .vip-progress {
	    position: relative;
	    border-radius: 4px;
	    border-radius: .25rem;
	    background: #bbb;
	}
	.vip-progress-wrap .vip-progress .vip-progress-inner {
	    background: #d6a566;
	    border-radius: 3px;
	    width: 0;
	    height: 8px;
	    height: .5rem;
	}
	.vip-progress-wrap .vip-progress .vip-progress-dot-wrap {
	    position: absolute;
	    left: 0;
	    top: 0;
	    margin-left: 1.4375rem;
	    width: calc(100% - 2.875rem);
	    height: 8px;
	    height: .5rem;
	}
	
	.vip-progress-wrap .vip-progress .vip-progress-dot-wrap .vip-progress-dot {
	    position: absolute;
	    top: -5px;
	    top: -.3125rem;
	    left: 0;
	    z-index: 1;
	    margin-left: -1.4375rem;
	    border-radius: 9px;
	    border-radius: .5625rem;
	    background: #d6a566;
	    width: 46px;
	    width: 2.875rem;
	    height: 18px;
	    height: 1.125rem;
	    line-height: 18px;
	    line-height: 1.125rem;
	    text-align: center;
	    color: #fff;
	    font-weight: 700;
	}
	.vip-progress-wrap .vip-info-name-wrap {
	    padding-top: .625rem;
	}
	.clearfix:after, .clearfix:before {
	    content: "";
	    display: table;
	}
	.clearfix:after {
	    clear: both;
	}
	.vip-progress-wrap .vip-info-name-wrap .vip-info-name {
	    float: left;
	    color: #d6a566;
	    width: 50%;
	}
	.vip-progress-wrap.dialog-progress-bar .vip-info-nextname {
	    color: #34343f;
	}
	.vip-progress-wrap .vip-info-name-wrap .vip-info-nextname {
	    float: right;
	    color: #bbb;
	    text-align: right;
	    width: 50%;
	}
</style>