<template>

	<div class="feedback">

		<BankHeaderVue :title="$t('意见回馈')" :isShow="false" />

		<div>

			<div class="feedback-nav-wrap">

				<div :class="['feedback-nav',tabIndex == 0 ? 'current' : '']" @click="settabIndex(0)">
					<div class="feedback-nav-title">{{$t('意见回馈')}}</div>
				</div>

				<div :class="['feedback-nav false',tabIndex == 1 ? 'current' : '']" @click="settabIndex(1)">
					<div class="feedback-nav-title">{{$t('我的回馈')}}</div>
				</div>

			</div>

			<div v-show="tabIndex == 0">

				<div class="m-reply-form">

					<div class="m-reply-form-item">

						<div class="m-reply-form-title">

							<span class="m-reply-form-start">*</span>{{$t('主题')}}：
						</div>

						<div class="m-reply-form-content">

							<!-- <div class="m-reply-form-select-wrap">
								
								<select v-model="feedid" changePage>
									
									<option disabled="" value="">请选择您要反馈的问题</option>
									<option v-for="(item, index) in feeblist" :key="index" :value="item.id">
										{{ item.name }}
									</option>
								
								</select>
								<span></span>
								
							</div> -->

							<input type="text" v-model="title" placeholder="请输入标题(限100字)" maxlength="100" value="" />

						</div>
					</div>

					<div class="m-reply-form-item">

						<div class="m-reply-form-title">
							<span class="m-reply-form-start">*</span>内容：
						</div>

						<div class="m-reply-form-content">
							<textarea id="reply-textarea" cols="30" rows="10" maxlength="1000"
								placeholder="请描述您的问题 (限1000字)" v-model="replyContent"
								@input="updateCharCount"></textarea>
							<!-- <div class="char-count" id="content">0 / 1000</div> -->
						</div>

					</div>

					<div class="m-reply-form-item">

						<div class="m-reply-form-title"></div>

						<div class="m-reply-form-content">

							<div class="m-reply-image" v-if="uploadedImages.length == 0">

								<div class="m-reply-image-box">

									<div class="m-reply-image-txt">

										<label>
											上传图片
											<input type="file" id="upload" accept="image/png, image/jpg, image/jpeg"
												@change="handleFileUpload" style="display: none;" />
										</label>

										<div class="m-reply-image-txt-info">
											图片格式限png、jpg、jpeg，档案大小限2MB，保留7日
										</div>

									</div>

									<div class="m-reply-image-icon">
										<div>{{ uploadedImages.length }}/{{ maxImages }}</div>
									</div>

								</div>

							</div>

							<div class="m-reply-image has-image" v-if="uploadedImages.length > 0">

								<div class="m-reply-image-list" v-for="(image, index) in uploadedImages" :key="index">

									<div class="m-reply-image-close-btn" @click="removeImage(index)"></div>

									<div class="m-reply-image-list-image">
										<img :src="image" alt="Uploaded Image" />
									</div>

								</div>

								<div class="m-reply-image-box" v-if="uploadedImages.length < 3">
									<div class="m-reply-image-icon" style="position: relative;">
										<div>
											{{uploadedImages.length}}/{{maxImages}}
										</div>
										<input type="file" accept="image/png, image/jpg, image/jpeg"
											@change="handleFileUpload"
											style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;" />
									</div>
								</div>

							</div>

						</div>

					</div>

					<div class="m-reply-form-item">

						<div class="m-reply-form-btn-wrap">

							<button :class="{'m-reply-form-btn': true, 'is-disable': !isFormValid}"
								:disabled="!isFormValid" @click="upfeed">
								确认送出
							</button>

						</div>

					</div>


				</div>

			</div>

			<div v-show="tabIndex == 1">


				<!-- <div class="m-reply-no-data-wrap">
					<div class="m-reply-no-data">暂无纪录</div>
				</div> -->

				<div class="m-reply-list">

					<div :class="['m-reply-info-wrap', page === 1 ? 'is-reply' : '']"
						v-for="(item, index) in messagelist" :key="index" @click="messagedetail(index)">

						<div class="m-reply-info-title-wrap">

							<div class="m-reply-info-title">{{item.title}}</div>
							<div class="m-reply-info-time">{{$base.formattedDate(item.created_at)}}</div>

						</div>

						<div class="m-reply-info-content-wrap">

							<div class="m-reply-info-content">{{findchild(item.child) || item.content}}</div>

							<span v-if="findchild(item.child)">已回覆</span>

						</div>

					</div>

				</div>

				<div class="m-reply-page">

					<div class="paging-wrap">

						<span :class="['paging-btn', page === 1 ? 'paging-first-disabled' : 'paging-first-show']"
							@click="changePage(1)"></span>


						<span :class="['paging-btn', page === 1 ? 'paging-previous-disabled' : 'paging-previous-show']"
							@click="changePage(page - 1)"></span>


						<select v-model="page" @change="changePage(page)">

							<option v-for="item in last_page" :key="item" :value="item">
								{{ item }}
							</option>

						</select>

						<span class="page-text">/{{ last_page }}</span>

						<span :class="['paging-btn', page === last_page ? 'paging-next-disabled' : 'paging-next-show']"
							@click="changePage(page + 1)"></span>

						<span :class="['paging-btn', page === last_page ? 'paging-last-disabled' : 'paging-last-show']"
							@click="changePage(last_page)"></span>

					</div>


				</div>



				<div class="m-reply-detail-page" v-show="showPopup">
					
					<div class="m-reply-detail-header">
						
						<span class="m-reply-detail-back-icon" @click="setshowPopup()"></span>
						详情
						
					</div>
					
					<div class="m-reply-detail-container">
						
						<div>
							<div class="m-reply-detail-subject">看不懂</div>
							<div class="m-reply-detail-content-wrap">
								<div class="m-reply-conversation">
									<div class="m-reply-conversation-name">{{userinfo.name}}</div>
									<div class="m-reply-conversation-box">
										<div class="m-reply-conversation-content">
											<div></div>
										</div>
									</div>
									<div class="m-reply-conversation-time">2024-10-27 08:17:29</div>
								</div>
								<div class="m-reply-conversation is-admin">
									<div class="m-reply-conversation-name">管理员回覆</div>
									<div class="m-reply-conversation-box">
										<div class="m-reply-conversation-content">
											<div></div>
										</div>
									</div>
									<div class="m-reply-conversation-time">2024-10-27 08:18:35</div>
								</div>
								
							</div>
						</div>
					</div>
					
					<div class="m-reply-detail-footer">
						
						<!-- <div class="m-reply-detail-footer-img-btn"></div> -->
						<div class="m-reply-detail-footer-input">
							
							<input type="text" maxlength="1000" value="">
							
						</div>
						
						<div class="m-reply-detail-footer-submit-btn is-disable">送出</div>
						
					</div>
					
				</div>


			</div>

		</div>

		<!-- <QuickGames /> -->

		<Diyloading ref="loadmodal"></Diyloading>

		<!-- <van-popup v-model="showPopup" position="center"> -->

		<!-- <div role="dialog" aria-labelledby="发送站内信" class="van-dialog">
				
				<div class="van-dialog__header">发送站内信</div>

				<div class="van-dialog__content">
					<div class="van-cell-group van-hairline--top-bottom">

						<van-field v-model="title" label="标题" placeholder="请输入标题" />

						<van-field v-model="content" label="内容" placeholder="请输入内容" type="textarea" autosize />

					</div>
				</div>

				<div class="van-hairline--top van-dialog__footer van-dialog__footer--buttons">
					<van-button type="default" size="large" @click="onCancel">取消</van-button>
					<van-button type="primary" size="large" @click="onConfirm">确认</van-button>
				</div>
			</div> -->


		<!-- </van-popup> -->

	</div>
</template>

<script>
	import QuickGames from './libs/QuickGames.vue';

	import BankHeaderVue from "./libs/BankHeader.vue";

	import Diyloading from "./libs/Diyloading.vue"

	export default {

		name: "feedback",

		data() {
			return {

				tabIndex: 0,
				feeblist: [{
						id: '2357',
						name: '存款问题',
					},
					{
						id: '2358',
						name: '取款问题',
					},
					{
						id: '2359',
						name: '游戏问题',
					},
					{
						id: '2360',
						name: '优惠问题',
					},
					{
						id: '2361',
						name: '其他',
					},
				],

				feedid: '',

				title: '',

				content: 0,

				uploadedImages: [],

				uploadedImages2: [],

				maxImages: 3,

				replyContent: '', // Holds the content of the textarea

				page: 1, // 当前页码

				current_page: 1,
				messagelist: [],
				fawenlist: [],
				first_page_url: "",
				from: 1,
				last_page: 11,
				last_page_url: "",
				next_page_url: "",
				path: "",
				per_page: 0,
				prev_page_url: null,
				to: 0,
				total: 0,
				showPopup: false
			}

		},
		
		components: {

			QuickGames,
			BankHeaderVue,
			Diyloading

		},

		computed: {
			
			userinfo() {
				return this.$store.state.userInfo
			},
			//内容高度
			isLogin() {
				return this.$store.state.token;
			},
						
			getlanguage() {
				return localStorage.getItem('local');
			},

			isFormValid() {
				// return this.title.trim() !== '' && this.content > 0 && this.uploadedImages.length > 0;
				return this.title.trim() !== '' && this.content > 0;
			}
		},

		methods: {
			
			setshowPopup(){
				console.log(11111);
				this.showPopup = false;
			},
			
			findchild(con) {

				if (con.length > 0) {
					var index = con.length - 1;
					return con[index].content;
				}

				return false;

			},

			changePage(newPage) {

				if (newPage < 1 || newPage > this.last_page) {
					return; // 如果页码超出范围，不执行任何操作
				}
				this.page = newPage; // 更新当前页码

				if (this.tabIndex == 1) {
					this.getMessageList(); // 重新获取数据
				}
			},


			messagedetail(index) {

				let that = this;
				that.showPopup = true;

			},

			settabIndex(e) {

				this.tabIndex = e;
				if (this.tabIndex == 1) {
					this.page = 1; // 更新当前页码
					this.getMessageList(); // 重新获取数据
				}

			},

			getMessageList() {

				let that = this;

				that.$refs.loadmodal.open(); // 传入标题和消息

				that.$apiFun.post("/api/member/message/send_list", {
						page: that.page // 使用当前页码请求数据
					})

					.then((res) => {
						// 处理响应数据
						if (res.code === 200) {

							that.current_page = res.data.current_page;

							that.messagelist = res.data.data; // 获取消息列表数据

							// 更新分页参数
							that.first_page_url = res.data.first_page_url;
							that.last_page = res.data.last_page;
							that.next_page_url = res.data.next_page_url;
							that.prev_page_url = res.data.prev_page_url;
							that.total = res.data.total;

						}

						// 隐藏加载动画
						that.$refs.loadmodal.close(); // 传入标题和消息
					})
					.catch((err) => {
						console.error("请求失败", err);
						// 隐藏加载动画
						that.$refs.loadmodal.close(); // 传入标题和消息
					});
			},



			// 上传信息
			upfeed() {

				let that = this;

				// console.log(that.usetime);

				that.$refs.loadmodal.open(); // 传入标题和消息


				let param = {
					title: that.title,
					content: that.replyContent,
				};

				if (that.uploadedImages.length > 0) {
					param.image = that.uploadedImages2
				}


				that.$apiFun.post("/api/member/message/send", param).then((res) => {

					// 处理响应数据
					if (res.code === 200) {

						that.$parent.showTost(0, '反馈成功');

						that.$refs.loadmodal.close(); // 传入标题和消息
					}




				}).catch((err) => {
					console.error("请求失败：", err);
				});

			},

			updateCharCount(event) {
				this.content = event.target.value.length;
				// document.getElementById('content').textContent = `${this.content} / 1000`;
			},

			handleFileUpload(event) {

				let that = this;

				const file = event.target.files[0];

				const reader = new FileReader();

				reader.onload = (e) => {
					that.uploadedImages.push(e.target.result);
				};

				reader.readAsDataURL(file);

				// Upload file to API
				const formData = new FormData();

				formData.append('file', file);

				that.$apiFun.post("/api/upload/upload", formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then((res) => {

					// 处理响应数据
					if (res.code === 200) {

						that.$parent.showTost(0, '上传成功');

						that.uploadedImages2.push(res.file_url);

						that.$refs.loadmodal.close(); // 传入标题和消息
					}


				}).catch((err) => {
					console.error("请求失败：", err);
				});


				// } else {

				// 	alert("图片格式或大小不符合要求，请上传png、jpg、jpeg格式，且不超过2MB的图片。");

				// }

				// }


			},

			isValidImage(file) {
				const validFormats = ["image/png", "image/jpg", "image/jpeg"];
				const maxSize = 2 * 1024 * 1024; // 2MB
				return validFormats.includes(file.type) && file.size <= maxSize;
			},

			removeImage(index) {

				this.uploadedImages.splice(index, 1);
				this.uploadedImages2.splice(index, 1);

			},

		}
	}
</script>

<style lang="scss" scoped>
	// 引入分页
	@import "/page.css";

	.m-reply-page {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: #fff;
		box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, .05);
		padding: 1rem 0;
	}

	#upload {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
	}

	.feedback {

		min-height: 100vh;
		background: #ffffff;

		.feedback-nav-wrap {
			border-bottom: 2px solid #eaeaea;
			overflow-x: scroll;
			font-size: 14px;

			.feedback-nav {
				position: relative;
				height: 2.5rem;
				float: left;
				border-bottom: 2px solid transparent;
				box-sizing: border-box;
				width: 50%;
				text-align: center;
			}

			.feedback-nav.current {
				color: #0089f5;
				border-bottom: 2px solid #0089f5;
			}

			.feedback-nav-title {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				box-sizing: border-box;
				display: inline-block;
				position: relative;
				max-width: 100%;
				padding: 10px;
			}
		}

		.m-reply-form {
			padding: 12px 12px 0;
		}

		.m-reply-form-content,
		.m-reply-form-title {
			display: inline-block;
			box-sizing: border-box;
		}

		.m-reply-form-title {
			font-size: .875rem;
			padding: 10px 2px 0 0;
			color: #666;
			width: 73px;
			text-align: right;
			position: relative;
		}

		.m-reply-form-start {
			color: red;
			margin-right: 2px;
		}

		.m-reply-form-content {
			width: calc(100% - 73px);
			vertical-align: top;

			input,
			select,
			textarea {
				background: #fff;
				font-size: .875rem;
				box-sizing: border-box;
				border: 1px solid #ccc;
				display: block;
				width: 100%;
				padding: 10px;
				color: #999;
				margin-bottom: 12px;
			}

			select {
				-webkit-appearance: none;
			}

			textarea {
				resize: none;
			}
		}

		.m-reply-form-select-wrap {
			width: 100%;
			position: relative;

			span {
				position: absolute;
				right: 10px;
				top: 50%;
				width: 18px;
				height: 18px;
				margin-top: -9px;
				background: url('/static/image/icon_list_arrow_n.png') 0 0 no-repeat;
				background-size: 18px 18px;
			}
		}

		.m-reply-image {
			border: 1px solid #d8d8d8;
		}

		.m-reply-image-box {
			vertical-align: top;
			width: 100%;
		}

		.m-reply-image-box,
		.m-reply-image-icon,
		.m-reply-image-txt {
			display: inline-block;
		}

		.m-reply-image-txt {
			box-sizing: border-box;
			vertical-align: middle;
			width: calc(100% - 80px);
			padding: 0 10px;
			border-right: 1px solid #d8d8d8;

			label {
				font-size: .875rem;
				color: #666;
				margin-bottom: 10px;
			}

			input#upload {
				display: none;
			}
		}

		.m-reply-image-txt-info {
			font-size: .75rem;
			line-height: 1.0625rem;
			color: #999;
			padding-top: 6px;

		}

		.m-reply-image-icon {
			vertical-align: middle;
			box-sizing: border-box;
			display: inline-block;
			width: 80px;
			height: 80px;
			padding-top: 50px;
			text-align: center;
			color: #999;
			background: url('/static/image/icon_image.png') 50% 13px no-repeat;
			background-size: 34px 34px;
			font-size: .7rem;
		}

		.m-reply-form-btn-wrap {
			padding-top: 12px;
		}

		.m-reply-form-btn {
			font-size: .875rem;
			width: 100%;
			height: 40px;
			background: #0085f9;
			color: #fff;
			border: none;
			margin: 0 auto;
		}

		.m-reply-form-btn.is-disable {
			background: #ccc;
		}

		.m-reply-no-data-wrap {
			text-align: center;
			padding-top: 150px;
		}

		.m-reply-no-data {
			font-size: 1.1875rem;
			padding-top: 80px;
			display: inline-block;
			color: #cdcdcd;
			min-width: 80px;
			background: url('/static/image/icon_no_data.png') 0 0 no-repeat;
			background-size: 80px 71px;
		}
	}

	.app-gamebar {
		z-index: auto;
		bottom: 0;
		padding-bottom: 0;
	}

	.m-reply-image.has-image {
		border: none;
	}

	.m-reply-image-list {
		vertical-align: top;
		display: inline-block;
		font-size: 0;
		position: relative;
		margin: 0 12px 12px 0;
	}



	.m-reply-image.has-image .m-reply-image-icon {
		border: 1px solid #d8d8d8;
	}

	.m-reply-image-close-btn {
		position: absolute;
		top: 0;
		right: 0;
		display: inline-block;
		width: 15px;
		height: 15px;
		background: url('https://g1.cfvn66.com/infe/mcenter/images/common/btn_delete.png') 0 0 no-repeat;
		background-size: 15px 15px;
	}

	.m-reply-image-list-image {
		overflow: hidden;
		display: inline-block;
		width: 80px;
		height: 80px;
	}

	.m-reply-image-list-image img {
		width: 100%;
	}

	img {
		border: 0;
	}

	.m-reply-image.has-image .m-reply-image-box {
		width: auto;
	}

	.m-reply-image-box,
	.m-reply-image-icon,
	.m-reply-image-txt {
		display: inline-block;
	}

	.m-reply-list {
		padding-bottom: 72px;
	}

	.m-reply-info-wrap {
		position: relative;
		padding: 10px 10px 10px 28px;
		border-bottom: 1px solid #d8d8d8;
	}

	.m-reply-info-title-wrap {
		position: relative;
		width: 100%;
		color: #666;
		margin-bottom: 7px;
		line-height: 20px;
		font-size: 0;
		padding-right: 5.625rem;
	}

	.m-reply-info-title {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 14px;
		font-size: .875rem;
		position: relative;
		max-width: 100%;
		padding-right: 25px;
	}

	.m-reply-info-time {
		font-size: 14px;
		font-size: .875rem;
		text-align: right;
		color: #999;
	}

	.m-reply-info-time,
	.m-reply-info-title span {
		position: absolute;
		right: 0;
		top: 0;
	}

	.m-reply-info-content,
	.m-reply-info-time,
	.m-reply-info-title,
	.m-reply-info-title-wrap {
		box-sizing: border-box;
		display: inline-block;
		vertical-align: middle;
	}

	.m-reply-info-content-wrap {
		position: relative;
		font-size: 0;
		line-height: 18px;
	}

	.m-reply-info-content {
		font-size: 13px;
		font-size: .8125rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
		color: #999;
		height: 18px;
	}

	.m-reply-info-content,
	.m-reply-info-time,
	.m-reply-info-title,
	.m-reply-info-title-wrap {
		box-sizing: border-box;
		display: inline-block;
		vertical-align: middle;
	}

	.m-reply-info-wrap.is-reply .m-reply-info-content-wrap span {
		display: inline-block;
	}

	.m-reply-info-content-wrap span {
		font-size: 13px;
		font-size: .8125rem;
		width: 60px;
		width: 3.75rem;
		box-sizing: border-box;
		position: absolute;
		right: 0;
		top: -3px;
		display: none;
		padding: 3px;
		border-radius: 4px;
		color: #fff;
		background: #5dba2c;
		text-align: center;
	}

	.m-reply-detail-page {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 99;
		width: 100%;
		height: 100%;
		background-color: #fff;
	}

	.m-reply-detail-header {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 39px;
		height: 2.4375rem;
		line-height: 39px;
		line-height: 2.4375rem;
		color: #fff;
		font-size: 17px;
		font-size: 1.0625rem;
		text-align: center;
		background: #3d3d4a;
	}

	.m-reply-detail-back-icon {
		position: absolute;
		top: 50%;
		left: 13.5px;
		left: .84375rem;
		width: 23px;
		width: 1.4375rem;
		height: 23px;
		height: 1.4375rem;
		background: url("/static/image/nav-back.png") 50% 50% no-repeat;
		background-size: 100%;
		background-position: 0 0;
		margin-top: -.6875rem;
	}

	.m-reply-detail-container {
		position: relative;
		height: 100%;
		color: #666;
		font-size: 16px;
		font-size: 1rem;
		overflow-y: scroll;
		box-sizing: border-box;
		padding: 2.5rem 0 5.3125rem;
	}

	.m-reply-detail-subject {
		line-height: 21px;
		line-height: 1.3125rem;
		font-size: 18px;
		font-size: 1.125rem;
		font-weight: 700;
		word-break: break-word;
		background-color: #f3f3f3;
		padding: .875rem;
	}

	.m-reply-detail-content-wrap {
		padding: 14px;
	}

	.m-reply-conversation-name {
		font-size: 13px;
		font-size: .8125rem;
		font-weight: 700;
		color: #3d3d4a;
		margin-bottom: 15px;
	}

	.m-reply-conversation-box {
		padding: 11px;
		background: #ebf8ff;
	}

	.m-reply-conversation-content {
		font-size: 13px;
		font-size: .8125rem;
		line-height: 21px;
		line-height: 1.3125rem;
		color: #666;
	}

	.m-reply-conversation-content div {
		min-height: 21px;
		word-wrap: break-word;
	}

	.m-reply-conversation-time {
		font-size: 12px;
		font-size: .75rem;
		text-align: right;
		color: #d1d9e4;
	}
	.m-reply-conversation.is-admin .m-reply-conversation-box {
	    background: #f2f2f2;
	}
	.m-reply-detail-footer {
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    z-index: 2;
	    width: 100%;
	    background-color: #f3f3f3;
	    box-sizing: border-box;
	    border-top: 1px solid #d8d8d8;
	    padding: 1.4375rem .9375rem;
	}
	.m-reply-detail-footer-img-btn {
	    vertical-align: top;
	    display: inline-block;
	    width: 38px;
	    height: 38px;
	    background: url('https://g1.cfvn66.com/infe/mcenter/images/common/btn_image.png') 0 0 no-repeat;
	    background-size: 38px 38px;
	}
	.m-reply-detail-footer-input {
	    display: inline-block;
	    box-sizing: border-box;
	    // width: calc(100% - 90px);
	    width: calc(100% - 55px);
	    padding: 0 8px;
	}
	.m-reply-detail-footer-input input {
	    box-sizing: border-box;
	    font-size: 14px;
	    font-size: .875rem;
	    padding: 10px;
	    width: 100%;
	    height: 38px;
	    border: 1px solid #d8d8d8;
	    border-radius: 5px;
	    color: #666;
	}
	.m-reply-detail-footer-submit-btn.is-disable {
	    background: #ccc;
	}
	.m-reply-detail-footer-submit-btn {
	    font-size: 14px;
	    font-size: .875rem;
	    vertical-align: top;
	    display: inline-block;
	    width: 52px;
	    height: 38px;
	    border-radius: 5px;
	    text-align: center;
	    line-height: 38px;
	    background: #0085f9;
	    color: #fff;
	}
</style>
