<template>
	<div class="container">
		<BankHeaderVue :title="$t('最新公告')" :isShow="false" />
		<MineHeader />
		<div class="news">
			<!-- <div class="m-news-nav">
				<span :class="[tabIndex == 0 ? 'current' : '']" @click="changeTab(0)">最新公告</span><span
					:class="[tabIndex == 1 ? 'current' : '']" @click="changeTab(1)">历史讯息</span>
			</div> -->
			<div class="news-wrap">
				<div>
					<table>
						<!-- <thead>
							<tr>
								<th class="td-title">日期</th>
								<th class="td-content">内容</th>
							</tr>
						</thead> -->
						<tbody>

							<!-- messagelist -->

							<tr v-for="(item, index) in messagelist" :key="index">

								<td class="td-title">{{item.title}}</td>
								<td class="td-content">
									{{item.content}}
								</td>

							</tr>

							<!-- <tr>
								<td class="td-title">2024-07-28 00:29:54</td>
								<td class="td-content">
									❤️ 澳门娱乐场 2355.COM 特别推出【USDT/电子钱包 存款回归
									双重豪礼】已上线，快快通知好友马上回归 超大回归豪礼等您来领取~
								</td>
							</tr> -->

						</tbody>
					</table>

					<!-- <PageFooter :totalPage="10" /> -->

					<!-- 分页部分 -->

					<!-- <div class="user-msg-paging-bg">
						<div class="paging-wrap">
							<span :class="['paging-btn', { 'paging-first-disabled': page === 1 }]"
								@click="changePage(1)"></span>

							<span :class="['paging-btn', { 'paging-previous-disabled': page === 1 }]"
								@click="changePage(page - 1)"></span>

							<select v-model="page" @change="changePage(page)">
								<option v-for="item in last_page" :key="item" :value="item">
									{{ item }}
								</option>
							</select>

							<span class="page-text">/{{ last_page }}</span>

							<span :class="['paging-btn', { 'paging-next-disabled': page === last_page }]"
								@click="changePage(page + 1)"></span>

							<span :class="['paging-btn', { 'paging-last-disabled': page === last_page }]"
								@click="changePage(last_page)"></span>
						</div>

					</div> -->



				</div>
			</div>
		</div>

		<Feedback showtype="2" />
		<!-- <QuickGames /> -->

	</div>
</template>

<script>
	
	import BankHeaderVue from "./libs/BankHeader.vue";
	import MineHeader from "./libs/MineHeader.vue";
	import QuickGames from './libs/QuickGames.vue';
	import Feedback from "./libs/Feedback.vue"
	
	// import PageFooter from "./libs/PageFooter.vue";
	export default {
		name: "news",
		data() {
			return {
				tabIndex: 0,

				page: 1, // 当前页码

				current_page: 1,
				messagelist: [],
				first_page_url: "",
				from: 1,
				last_page: 11,
				last_page_url: "",
				next_page_url: "",
				path: "",
				per_page: 0,
				prev_page_url: null,
				to: 0,
				total: 0
			};
		},

		components: {
			BankHeaderVue,
			MineHeader,
			Feedback,
			QuickGames,
			// PageFooter,
		},

		mounted() {
			let that = this;
			this.getMessageList(); // 初次加载获取第一页数据
		},

		methods: {

			changeTab(index) {
				this.tabIndex = index
			},

			changePage(newPage) {
				if (newPage < 1 || newPage > this.last_page) {
					return; // 如果页码超出范围，不执行任何操作
				}
				this.page = newPage; // 更新当前页码
				this.getMessageList(); // 重新获取数据
			},

			getMessageList() {
				
				let that = this;

				that.$parent.showLoading();

				that.$apiFun.get("/api/system/notices", {
					page: that.page // 使用当前页码请求数据
				}).then((res) => {
					if (res.code == 200) {
						that.messagelist = res.data;
					}
				});

				// that.$apiFun.post("/api/member/message/list", {
				// 		page: that.page // 使用当前页码请求数据
				// 	})
				// 	.then((res) => {
				// 		// 处理响应数据
				// 		if (res.code === 200) {

				// 			that.current_page = res.data.current_page;

				// 			that.messagelist = res.data.data; // 获取消息列表数据

				// 			// 更新分页参数
				// 			that.first_page_url = res.data.first_page_url;
				// 			that.last_page = res.data.last_page;
				// 			that.next_page_url = res.data.next_page_url;
				// 			that.prev_page_url = res.data.prev_page_url;
				// 			that.total = res.data.total;

				// 		}

				// 		// 隐藏加载动画
				// 		that.$parent.hideLoading();
				// 	})
				// 	.catch((err) => {
				// 		console.error("请求失败", err);
				// 		// 隐藏加载动画
				// 		that.$parent.hideLoading();
				// 	});

			},


		}
	};
</script>
<style lang="scss" scoped>
	.container {
		background: #fff;
		width: 100%;
		height: 100vh;
	}

	.app-gamebar {
		z-index: auto;
		bottom: 0;
		padding-bottom: 0px;
	}


	.news {
		.m-news-nav {
			margin-bottom: 1rem;
			border-bottom: 2px solid #eaeaea;
			overflow-x: scroll;
			font-size: 14px;

			span {
				float: left;
				border-bottom: 2px solid transparent;
				padding: 0.625rem;
				box-sizing: border-box;
				width: 50%;
				text-align: center;
			}

			span.current {
				color: #0089f5;
				border-bottom: 2px solid #0089f5;
			}
		}

		.news-wrap {
			margin: 0 auto;
			// width: 99%;
			font-size: 1.125rem;
			padding: 0 10px;
			box-sizing: border-box;
			padding-top: 5px;
		}

		table {
			border-collapse: collapse;
			border-spacing: 0;
			width: 100%;
		}

		thead {
			display: none;
			background-color: #ccc;
			color: #fff;

			th {
				padding: 1rem;
			}
		}

		tr {
			border-bottom: 1px solid #eee;
		}

		tbody {
			border: 1px solid #eee;
			border-bottom: none;
		}

		td {
			display: block;
			line-height: 1.25rem;
			padding: .625rem;
			color: #313f52;
			word-break: break-word;

			&:first-child {
				background-color: #ccc;
				color: #fff;
			}
		}
	}
</style>