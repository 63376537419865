var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ele-promotion-layout ele-mobile"},[_c('div',{staticClass:"ele-mobile-wrap",staticStyle:{"height":"667px"}},[_c('div',{staticClass:"ele-header"},[_c('div',{staticClass:"ele-header-back",on:{"click":function($event){return _vm.gopage('/')}}}),_vm._m(0)]),(_vm.isLogin)?_c('div',{staticClass:"ele-user-info-wrap clearfix"},[_c('div',{staticClass:"ele-user-info"},[_c('span',[_vm._v(_vm._s(_vm.userinfo.name)+"@55")])]),_c('div',{staticClass:"ele-user-info ele-user-amount"},[_c('span',[_vm._v(_vm._s(_vm.userinfo.money)+" RMB")])])]):_vm._e(),_c('div',{staticClass:"ele-home-wrap"},[(!_vm.isLogin)?_c('div',{staticClass:"ele-login-wrap clearfix"},[_c('div',{staticClass:"ele-not-login-icon"}),_c('div',{staticClass:"ele-login-link",on:{"click":function($event){return _vm.gopage('/login')}}},[_vm._v("登入 / 注册新会员")])]):_vm._e(),_c('Feedback',{attrs:{"showtype":"2"}}),(_vm.isLogin)?_c('div',{staticClass:"ele-vip-wrap clearfix"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"ele-right-tag-wrap"},[_c('div',{staticClass:"ele-tag-block"},[_vm._m(3),_c('div',{staticClass:"ele-right-tag ele-star",on:{"click":function($event){return _vm.gopage('/sign')}}},[_c('div',{staticClass:"ele-hint-dot"}),_c('div',{staticClass:"ele-tag-name"},[_vm._v("签到送")])])])])]):_vm._e(),_c('div',{staticClass:"ele-home-content"},[(_vm.isLogin)?_c('div',{staticClass:"ele-tag-tab-wrap clearfix ele-type-a ele-home"},[_c('div',{staticClass:"ele-tab-item-wrap clearfix"},[_c('div',{class:['ele-tab-bg', _vm.topaction == 2 ? 'current' : ''],staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.settopaction(2)}}},[_vm._m(4)])])]):_vm._e(),(_vm.topaction == 2)?_c('div',{staticClass:"ele-copy-list-wrap"},[_vm._m(5)]):_vm._e()])],1),_c('div',{staticClass:"ele-loading-mask",staticStyle:{"display":"none"}}),_c('div',{staticClass:"ele-server-message mobile-server-message"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ele-header-title"},[_c('span',[_vm._v("优惠活动")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ele-vip-icon"},[_c('img',{attrs:{"src":"https://g1.cfvn66.com/web/static/image/hall/promotion/icon_vip.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ele-vip-center"},[_c('div',[_c('div',{staticClass:"ele-reward-title"},[_vm._v("您的奖励")]),_c('div',{staticClass:"ele-reward-tag"},[_vm._v("去查看")])]),_c('div',{staticClass:"ele-reward-bottom"},[_vm._v("超值奖励等你拿")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ele-right-tag ele-heart"},[_c('div',{staticClass:"ele-tag-name"},[_vm._v("好友推荐")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ele-tab-item"},[_c('div',{staticClass:"ele-item-name"},[_vm._v("活动")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ele-scroll-block"},[_c('div',{staticClass:"ele-copy-wrap clearfix ele-column-1 ele-interval-2"})])
}]

export { render, staticRenderFns }