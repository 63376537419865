<template>

	<div class="withdrawPassword">


		<BankHeaderVue :title="$t('取款密码')" :isSHow="false" />

		<MineHeader />


		<div class="member-data-container clearfix">


			<div class="member-children-wrap">
				<div class="withdraw-pw-wrap">
					<div class="withdraw-pw-wrap">


						<div class="withdraw-select-wrap" v-if="userinfo.has_qk_pwd">

							<div class="withdraw-pw-title">{{$t('旧密码')}}</div>

							<div style="display: flex;align-items: center;justify-content: space-between;">

								<select v-for="(item, index) in p1" :key="index" v-model="p1[index]"
									:name="'oldPw' + index">
									<!-- 默认选项 -->
									<option value="0" disabled> -- </option>
									<option v-for="(pwd, pwdIndex) in pwdlist" :key="pwdIndex" :value="pwdIndex + 1">
										{{ pwd }}
									</option>
								</select>

							</div>



						</div>

						<div class="withdraw-select-wrap">

							<div class="withdraw-pw-title" v-if="userinfo.has_qk_pwd">{{$t('新密码')}}</div>
							
							<div class="withdraw-pw-title" v-else>{{$t('设置取款密码')}}</div>

							<div style="display: flex;align-items: center;justify-content: space-between;">
								
								<select v-for="(item, index) in p2" :key="index" v-model="p2[index]" :name="'newPw' + index">
									
									<!-- 默认选项 -->
									<option value="0" disabled> -- </option>
									
									<option v-for="(pwd, pwdIndex) in pwdlist" :key="pwdIndex" :value="pwdIndex + 1">
										{{ pwd }}
									</option>
									
								</select>
								
							</div>
							
						</div>


						<div class="withdraw-btn-wrap" v-if="userinfo.has_qk_pwd">

							<button type="button" class="reset-btn" @click="sepall">取消</button>

							<button :class="[isValidpALl ? 'reset-btn' : 'submit-btn']" :disabled="!isValidpALl"
								@click="setpaypassword2">确认</button>


						</div>


						<div class="withdraw-btn-wrap" v-else>

							<button type="button" class="reset-btn" @click="sepall">取消</button>

							<button :class="[isValidp2 ? 'reset-btn' : 'submit-btn']" :disabled="!isValidp2"
								@click="setpaypassword">确认</button>


						</div>

					</div>
				</div>
			</div>
		</div>


		<Diyloading ref="loadmodal"></Diyloading>


	</div>
</template>
<script>
	
	import Diyloading from "./libs/Diyloading.vue"

	import BankHeaderVue from "./libs/BankHeader.vue"

	import MineHeader from "./libs/MineHeader.vue"

	export default {

		name: "withdrawPassword",

		data() {
			return {

				pwdlist: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],

				p1: ['0', '0', '0', '0', '0', '0'],
				p2: ['0', '0', '0', '0', '0', '0'],

				qk_pwd: '',
				old_qk_pwd: '',
				qk_pwd_confirmation: '',

			}
		},

		components: {

			BankHeaderVue,
			MineHeader,
			Diyloading

		},

		computed: {

			userinfo() {
				return this.$store.state.userInfo
			},

			//内容高度
			isLogin() {
				return this.$store.state.token;
			},

			getcasinolist() {
				return this.$store.state.casinolist;
			},

			// 计算属性，用于判断 p1 和 p2 数组是否全部选择了非 '0' 的值
			isValidpALl() {
				// 检查 p1 和 p2 是否都没有 '0'
				const allSelectedP1 = this.p1.every(item => item !== '0');
				const allSelectedP2 = this.p2.every(item => item !== '0');
				return allSelectedP1 && allSelectedP2;
			},

			isValidp2() {
				// 检查 p1 和 p2 是否都没有 '0'
				const allSelectedP2 = this.p2.every(item => item !== '0');
				return allSelectedP2;
			}

		},

		methods: {

			sepall() {

				this.p1 = ['0', '0', '0', '0', '0', '0'];
				this.p2 = ['0', '0', '0', '0', '0', '0'];

			},

			setpaypassword() {

				let that = this;

				that.$refs.loadmodal.open(); // 传入标题和消息

				let param = [];

				// 如果是false
				that.qk_pwd = that.p2.filter(item => item !== '0').join(''); // 不加任何分隔符
				// 91010101010
				param = {
					qk_pwd: that.qk_pwd
				};

				that.$apiFun.post("/api/member/drawing_pwd/set", param).then((res) => {

					console.log(res);

					if (res.code === 200) {

						that.sepall();

						that.$parent.getUserInfo() //获取用户信息


					}
					
					that.$refs.loadmodal.close(); // 传入标题和消息

				}).catch((err) => {
					console.error("请求失败：", err);
				});


			},


			setpaypassword2() {

				let that = this;

				that.$refs.loadmodal.open(); // 传入标题和消息

				let param = [];

				// 如果是false
				that.old_qk_pwd = that.p1.filter(item => item !== '0').join(''); // 不加任何分隔符
				
				that.qk_pwd = that.p2.filter(item => item !== '0').join(''); // 不加任何分隔符
				
				that.qk_pwd_confirmation = that.qk_pwd;

				// console.log(p1String); // 输出 '0000'
				// console.log(that.qk_pwd); // 输出 '0000'

				param = {
					
					old_qk_pwd: that.old_qk_pwd,
					qk_pwd: that.qk_pwd,
					qk_pwd_confirmation: that.qk_pwd_confirmation,
					
				};

				that.$apiFun.post("/api/member/drawing_pwd/modify", param).then((res) => {

					console.log(res);
					
				
					if (res.code !== 200) {
						that.$parent.showTost(2, res.message);
						return;
					}

					if (res.code === 200) {
						that.sepall();
						that.$parent.getUserInfo() //获取用户信息
					}
					
					that.$refs.loadmodal.close(); // 传入标题和消息

				}).catch((err) => {
					console.error("请求失败：", err);
				});


			},

		}
	}
</script>
<style lang="scss" scoped>
	.withdrawPassword {
		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				clear: both;
				content: "";
				display: table;
			}
		}

		.member-children-wrap {
			padding-left: 10px;
			padding-right: 10px;
		}

		.withdraw-pw-wrap {
			margin: 0 auto;
			padding: 1.563rem 0;
			max-width: 26.875rem;
		}

		.withdraw-select-wrap {
			margin-bottom: 2.5rem;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;

			.withdraw-pw-title {
				margin-bottom: .25rem;
				line-height: 1.5;
				font-size: .938rem;
				color: #999;
			}

			select {
				// width: 23%;
				// width: 10%;
				// float: left;
				// margin-right: 1.69492%;
				border: 1px solid #ccc;
				// padding-left: 1rem;
				width: 3rem;
				height: 3rem;
				font-size: 1rem;
				outline: none;
				text-align: center;

				option {
					background: #eee;
					line-height: 1.5;
					font-size: inheirt;
				}
			}

			&::after {
				content: '';
				display: block;
				clear: both;
			}
		}

		.withdraw-btn-wrap {
			text-align: center;
		}

		.reset-btn {
			padding: 0;
			border: none;
			min-width: 7rem;
			height: 2.5rem;
			line-height: 2.5rem;
			border-radius: 2.5rem;
			font-size: .938rem;
			color: #fff;
			text-align: center;
			cursor: pointer;
			margin: 0 .25rem;
			outline: none;
		}

		.submit-btn {
			padding: 0;
			border: none;
			min-width: 7rem;
			height: 2.5rem;
			line-height: 2.5rem;
			border-radius: 2.5rem;
			font-size: .938rem;
			color: #fff;
			text-align: center;
			cursor: pointer;
			margin: 0 .25rem;
			cursor: default;
			outline: none;
		}

		.submit-btn,
		.submit-btn:hover {
			background-color: #ccc;
		}

		button[disabled],
		html input[disabled] {
			cursor: default;
		}

		.reset-btn,
		.submit-btn.is-enable,
		.submit-btn.is-enable:hover {
			background-color: #59bafc;
		}
	}
</style>