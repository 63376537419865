import Vue from 'vue'
import App from './App'
import router from './router'
import apiFun from "@/http/api.js";
// 引入 我们准备好的 store
import store from '@/store/index.js'
import i18n from '@/locale/index.js'
import base from '@/assets/js/base.js'  //公用js

import GlobalModal from '@/components/libs/GlobalModal.vue';
// import QuickGames from '@/components/libs/QuickGames.vue';
// import RedBall from '@/components/libs/RedBall.vue';

Vue.component('GlobalModal', GlobalModal);
// Vue.component('QuickGames', QuickGames);
// Vue.component('RedBall', RedBall);

// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css'
// Vue.use(ElementUI);
// import  "amfe-flexible";

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// 全局注册
Vue.prototype.$base = base;
Vue.prototype.$apiFun = apiFun; //请求接口api

Vue.config.productionTip = false

router.afterEach((to, from, next) => {

	window.scrollTo(0, 0); //每到一个新的页面 就自动回顶
	if (document.querySelector(".index-page")) {
		document.querySelector(".index-page").scrollTo(0, 0); //
	}

});

router.beforeEach((to, from, next) => {
	// 用的时候打开

	const lang = localStorage.getItem('local') || 'zh_cn'; // 从路由中获取语言，默认是 'zh-CN'
	document.documentElement.lang = lang;

	let loginSign = localStorage.getItem("token") ? true : false;
	let title = to.meta.title;
	if (title) document.title = title;
	let needAuth = to.matched.some(item => item.meta.requireAuth);

	if (needAuth) { // 判断该路由是否需要登录权限
		if (loginSign) { // 判断是否登录
			next();
		} else {
			next({
				path: '/login', // 未登录则跳转至login页面
				query: {
				    redirect:to.fullPath,
				}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
			});
			router.app.$options.store.commit('setLoadStatus', false);
		}
	} else {
		next();
	}
});

new Vue({
	render: h => h(App),
	router,
	i18n,
	store
}).$mount('#app')