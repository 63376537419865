<template>
	<div class="money-switch">
		<BankHeader title="额度转换" :isShow="true" />
		<MineHeader />
		<div class="moneyswitch-page-new">

			<!-- <div class="switch-wrap">
				<div class="ms-title">
					<div class="wallet-now">当前使用</div>
					<div class="switch-type-inner clearfix">
						<div class="switch-item switch-free-transfer-wallet is-current"><i
								class="wallet-icon"></i>免转钱包<i class="wallet-hot-icon"></i></div>
					</div>
				</div>
				<p class="ms-switch-tip clearfix">温馨提示： 系统自动将余额转入正开启的游戏平台</p>
			</div> -->

			<div class="one-wrap clearfix is-interest-show">

				<div class="Select has-value is-disabled Select--single">

					<div class="Select-control">


						<!-- <div class="Select-multi-value-wrapper" id="react-select-2--value"> -->
						<!-- <div class="Select-value"> -->
						<!-- <span class="Select-value-label" role="option" aria-selected="true" id="react-select-2--value-item"> -->
						<!-- <div class="select-multi"> -->

						<div class="m-reply-image-txt">

							<label>

								<div>
									{{$t('从')}}:{{ fromCurrency.toUpperCase() }}{{$t('余额')}}
								</div>

								<div>
									{{$t('可用余额')}}:{{fromusemoney}}
								</div>

							</label>

							<select v-model="fromCurrency">
								
								<option v-for="currency in currencies1" :key="currency" :value="currency"
									:disabled="currency === toCurrency">
									{{ currency.toUpperCase() }}
								</option>
								
							</select>

						</div>


						<div class="m-reply-image-txt">

							<!-- 到币种选择 -->
							<label>
								<div>
									{{$t('到')}}: {{ toCurrency.toUpperCase() }}{{$t('余额')}}
								</div>
								<div>
									{{$t('可用余额')}}:{{toCurrency == 'account' ? userinfo.money : tousemoney}}
								</div>
							</label>

							<select v-model="toCurrency">
								
								<!-- 过滤掉已经在fromCurrency中选中的币种 -->
								<option v-for="currency in currencies2" :key="currency" :value="currency"
									:disabled="currency === fromCurrency">
									{{ currency.toUpperCase() }}
								</option>
								
								
							</select>

						</div>

						<div class="m-reply-image-txt">

							<label>{{$t('金额')}}:</label>
							<input type="number" :placeholder="$t('金额必须大于或等于1')" v-model="amount" />

						</div>

						<!-- <div class="select-total-wrap clearfix">
								<span>总余额</span>
								<span class="amount">0.00</span>
							</div> -->

						<!-- <div class="clearfix">
								<span>总游戏余额</span>
								<span class="amount">0.00</span>
							</div> -->

						<!-- <div class="clearfix"><span>利息宝(不可转换)</span><span class="amount">--</span></div> -->
						<!-- </div> -->
						<!-- </span> -->
						<!-- </div> -->

						<!-- <div aria-expanded="false" aria-owns="" aria-activedescendant="react-select-2--value"
								aria-disabled="true" class="Select-input" role="combobox" tabindex="0"
								style="border: 0px; width: 1px; display: inline-block">
							</div> -->
						<!-- </div> -->

					</div>
				</div>
				<div class="one-btn">
					<!-- <div class="one-btn-text">一键归户</div> -->
					<div class="one-btn-text-sub" @click="goexf">立即转换</div>
				</div>
			</div>

			<!-- <div class="help-wrap">
				<div class="mobile-wallet-intro-btn">帮助</div>
			</div> -->

		</div>

		<Feedback showtype="2" />
		<!-- <QuickGames /> -->
		
		<Diyloading ref="loadmodal"></Diyloading>
		
	</div>
</template>
<script>
	
	import BankHeader from "./libs/BankHeader.vue"
	
	import MineHeader from "./libs/MineHeader.vue"

	import QuickGames from './libs/QuickGames.vue';
	
	import Feedback from "./libs/Feedback.vue"
	
	import Diyloading from "./libs/Diyloading.vue"

	export default {

		name: "moneySwitch",

		data() {

			return {
				
				currencies1: ["fc", "ag", "bbin", "bg", "fg", "mt", "pp", "pg", "cq9", 'jdb', "mg", "leg", "ky"],
				currencies2: ["account","fc", "ag", "bbin", "bg", "fg", "mt", "pp", "pg", "cq9", 'jdb', "mg", "leg", "ky"],
				
				fromCurrency: "ag", // 默认源币种
				toCurrency: "bbin", // 默认目标币种
				
				amount: '', // 用户输入金额
				
				fromusemoney: 0.00, // 可用额度
				
				tousemoney: 0.00, // 默认可用额度
				
			}

		},

		components: {

			BankHeader,
			MineHeader,
			QuickGames,
			Feedback,
			Diyloading
			

		},
		
		computed: {
			userinfo() {
				return this.$store.state.userInfo
			},
			//内容高度
			isLogin() {
				return this.$store.state.token;
			},
		
			getlanguage() {
				return localStorage.getItem('local');
			},
		},

		watch: {

			fromCurrency(newValue) {
				this.getfromusemoney(newValue);
			},
			toCurrency(newValue) {
				this.gettousemoney(newValue);
			}

		},

		created() {
			let that = this;
			// 获取从
			that.getfromusemoney(that.fromCurrency);
			// 获取到
			that.gettousemoney(that.toCurrency);
		},

		methods: {

			getfromusemoney(code) {

				let that = this;
				let params = {
					api_code: code,
				};
				that.$apiFun.post("/api/game/balance", params).then((res) => {

					// 请求成功，假设 res.data.money 包含返回的余额信息
					if (res.code === 200) {
						that.fromusemoney = res.money.toFixed(2);
					}

				}).catch((err) => {
					console.error("请求失败：", err);
				});

			},

			gettousemoney(code) {

				let that = this;
				
				if(code != 'account'){
					let params = {
						api_code: code,
					};
					that.$apiFun.post("/api/game/balance", params).then((res) => {
					
						// 请求成功，假设 res.data.money 包含返回的余额信息
						if (res.code === 200) {
							that.tousemoney = res.money.toFixed(2);
						}
					
					}).catch((err) => {
						console.error("请求失败：", err);
					});
				}
				
				

			},

			// 立即转换
			goexf() {

				let that = this;
				
				that.$refs.loadmodal.open(); // 传入标题和消息
				

				if (!that.amount) {
					
					that.$refs.loadmodal.close(); // 传入标题和消息
					that.$parent.$toast(that.fromCurrency.toUpperCase()+that.$t('金额必须大于或等于1'));
					
					return;
				}
				
				if (that.fromusemoney <= 0) {
					
					that.$refs.loadmodal.close(); // 传入标题和消息
					that.$parent.$toast(that.fromCurrency.toUpperCase()+that.$t('金额必须大于或等于1'));
					
					return;
				}
				
				let params = {
					api_code_in: that.toCurrency,
					api_code_out: that.fromCurrency,
					money: that.amount
				};

				that.$apiFun.post("/api/game/credit_conversion", params).then((res) => {
					
					// 请求成功，假设 res.data.money 包含返回的余额信息
					if (res.code === 200) {
						
						that.$parent.$toast(that.$t('成功'));
						// 获取从
						that.getfromusemoney(that.fromCurrency);
						
						if(that.toCurrency == 'account'){
							
							that.$parent.getUserInfo();
							
							that.tousemoney = this.userinfo.money.toFixed(2);
							
						}else{
							// 获取到
							that.gettousemoney(that.toCurrency);
						}
						
						
						
					} else {
						that.$refs.loadmodal.close(); // 传入标题和消息
						that.$parent.$toast(res.message);
					}
					
					that.$refs.loadmodal.close(); // 传入标题和消息
					
				}).catch((err) => {
					console.error("请求失败：", err);
					that.$refs.loadmodal.close(); // 传入标题和消息
				});

			},

		}
	}
</script>
<style lang="scss" scoped>
	.app-gamebar {
		z-index: auto;
		bottom: 0;
		padding-bottom: 0;
	}

	.m-reply-image-txt {
		display: flex;
		flex-direction: column;
	}

	.m-reply-image-txt {

		width: 100%;

		label {
			font-size: .875rem;
			color: #666;
			margin: 10px 0;
			display: flex;
			justify-content: space-between;
		}

		select {
			height: 2.2rem;
			line-height: 2.2rem;
			color: #666;
		}

		input {
			height: 2rem;
			line-height: 2rem;
			color: #666;
		}
	}

	.money-switch {
		.clearfix {
			&::before {
				content: "";
				display: table;
			}

			&::after {
				content: "";
				display: table;
				clear: both;
			}
		}

		.moneyswitch-page-new {
			// padding: 2.1875rem 1.5rem 2.438rem;
			padding: 1.2rem;
			box-sizing: border-box;
		}

		.switch-wrap {
			margin-bottom: 2.5rem;

			.wallet-now {
				height: 20px;
				line-height: 20px;
			}

			.switch-type-inner {
				margin-top: 1.75rem;

				.switch-item {
					margin-right: 2.0625rem;
					margin-bottom: 0.5rem;
					line-height: 1.5625rem;
					float: left;
					color: #a5a5a5;

					.wallet-icon {
						display: inline-block;
						width: 25px;
						width: 1.5625rem;
						height: 25px;
						height: 1.5625rem;
						vertical-align: top;
						background: url("/static/image/check_wallet_icon.png") 0 0 no-repeat;
						margin-right: 13px;
					}

					.wallet-hot-icon {
						margin-left: 0.25rem;
						display: inline-block;
						width: 37px;
						height: 16px;
						vertical-align: middle;
						background: url("/static/image/hot.gif") 0 0 no-repeat;
					}
				}

				.switch-item.is-current {
					color: #54b9ff;

					.wallet-icon {
						background-position: 100% 0;
					}
				}
			}
		}

		.ms-title {
			margin-bottom: 0.5rem;
			line-height: 1;
			color: #314053;
			font-size: 0.938rem;
			font-weight: 700;
		}

		.ms-switch-tip {
			color: #888;
			margin-top: 1.75rem;
			margin-bottom: 0;
			font-size: 0.8125rem;
		}

		.one-wrap {
			.Select {
				position: relative;
			}

			.Select-control {
				min-height: 11.25rem;
				background: #fff;
				border-radius: 4px;
				border: 1px solid #ccc;
				// add
				padding: 1rem;
			}

			.Select.is-disabled>.Select-control {
				background-color: #f9f9f9;
			}

			.one-wrap.is-interest-show .Select-control {
				height: 180px;
				height: 11.25rem;
				background: #fff;
			}

			.Select-control> :last-child {
				padding-right: 5px;
			}

			.Select--single>.Select-control .Select-value,
			.Select-placeholder {
				bottom: 0;
				color: #aaa;
				left: 0;
				line-height: 34px;
				padding-left: 10px;
				padding-right: 10px;
				position: absolute;
				right: 0;
				top: 0;
				max-width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.Select-value {
				padding: 0.625rem 1rem;
				color: #333;
			}

			.select-multi {
				line-height: 1.25rem;
				font-size: 0.938rem;
				color: #303f52;

				.select-total-wrap {
					margin-bottom: 0.625rem;
					padding-bottom: 0.625rem;
					line-height: 1.625rem;
					border-bottom: 1px solid #ccc;
					font-weight: 700;
				}

				span {
					float: left;
					width: 100%;
				}

				.amount {
					font-size: 1.25rem;
				}

				span.amount {
					float: right;
					width: auto;
				}
			}

			.select-multi>div {
				margin-bottom: 0.375rem;
			}

			.one-btn {
				border: none;
				min-width: 128px;
				min-width: 8rem;
				height: 70px;
				// height: 4.375rem;
				height: 3.375rem;
				// line-height: 70px;
				// line-height: 4.375rem;
				line-height: 3.375rem;
				border-radius: 70px;
				border-radius: 3.375rem;
				font-size: 15.008px;
				font-size: 0.938rem;
				color: #fff;
				text-align: center;
				cursor: pointer;
				margin: 1.5rem 0 1.875rem;
				// padding: 0.75rem 0 0;
				line-height: normal;
				font-weight: 700;
				box-sizing: border-box;
				background-color: #59bafc;

				.one-btn-text {
					margin: 0 auto;
					width: 95%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 1.25rem;
					line-height: 1.75rem;
				}

				.one-btn-text-sub {
					margin: 0 auto;
					width: 95%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 0.938rem;
					line-height: 3.375rem;
				}
			}
		}

		.help-wrap {
			height: 39pz;

			.mobile-wallet-intro-btn {
				position: fixed;
				left: 0;
				bottom: 0;
				background: #7e7e7e;
				width: 100%;
				height: 39px;
				line-height: 39px;
				color: #fff;
				font-size: 15px;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>